import * as React from 'react';
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

export const ActionCtaAppPageShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="actionId" reference="actions" link="show" />
      <DateField source="createdAt" showTime />
      <TextField source="page" />
    </SimpleShowLayout>
  </Show>
);
