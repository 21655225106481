/**
 * @description cut the string to the nearest space char
 * @param {string} str
 * @param {number} maxLen
 * @returns {string} cutted string
 */
export default (str?: string, maxLen: number = 20) => {
  if (!str || str.length <= maxLen) {
    return str;
  }
  // cut the string to the nearest space char:]
  return str.substring(0, str.lastIndexOf(' ', maxLen)) + '…';
};
