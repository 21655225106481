import React from 'react';
import { Button, useRecordContext, useRedirect } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';

export const FeatureFlagUsersCustomList = () => {
  const { enabledAt, users } = useRecordContext();
  const redirect = useRedirect();
  const { canUpdate } = useRoleBasedPermissions();

  if (enabledAt) {
    return <p>✅ Enabled on all users at {enabledAt}</p>;
  }

  return (
    <table width={'100%'} style={{ borderSpacing: 0 }}>
      <tbody>
        <tr style={{ textAlign: 'left', backgroundColor: '#d5d5d5' }}>
          <th>Username</th>
          <th>Enabled At</th>
          <th></th>
        </tr>
        {users
          .sort((u1: any, u2: any) => (u1.username > u2.username ? 1 : -1))
          .map((user: any, i: number) => (
            <tr
              key={i}
              style={{
                height: 100,
                backgroundColor: i % 2 === 1 ? '#f9f9f9' : undefined,
                cursor: 'pointer',
              }}
              onClick={() => {
                redirect('show', 'users', user.id);
              }}
            >
              <td>
                <strong>{user.username}</strong>
              </td>
              <td>{user.addedAt}</td>
              <td>
                {canUpdate('users_feature_flags') && (
                  <Button
                    href={`#/users_feature_flags/${user.userFeatureFlagId}`}
                    label="edit"
                    onClick={e => e.stopPropagation()}
                    startIcon={<EditIcon />}
                  />
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
