import * as React from 'react';
import { Edit, ImageField, ImageInput, SimpleForm, TextInput } from 'react-admin';
import ResourceTitleActionBar from 'src/components/resource_title_action_bar';

const EditActions = <ResourceTitleActionBar mode="edit" resourceName="Organizations" />;

export const CRMOrganizationEdit = () => (
  <Edit mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <TextInput source="name" fullWidth />
      <TextInput source="email" fullWidth />
      <TextInput source="websiteUrl" fullWidth />
      <TextInput source="location" fullWidth />

      <ImageField source="imageUrl" fullWidth />
      <ImageInput source="picture" accept="image/*" maxSize={5000000} label="Image">
        <ImageField source="src" title="title" />
      </ImageInput>

      <hr style={{ width: '100%', height: 1 }} />
      <TextInput source="notes" fullWidth multiline />
      <hr style={{ width: '100%', height: 1 }} />

      <TextInput source="facebookUrl" fullWidth />
      <TextInput source="instagramUrl" fullWidth />
      <TextInput source="linkedinUrl" fullWidth />
      <TextInput source="threadUrl" fullWidth />
      <TextInput source="tiktokUrl" fullWidth />
      <TextInput source="twitterUrl" fullWidth />
    </SimpleForm>
  </Edit>
);
