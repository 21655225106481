import * as React from 'react';
import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = <ResourceTitleActionBar mode="show" />;

export const ProfilePromptShow = () => (
  <Show actions={ShowActions}>
    <SimpleShowLayout>
      <TextField source="questionEN" />
      <TextField source="questionFR" />
      <TextField source="placeholderEN" />
      <TextField source="placeholderFR" />
      <DateField
        source="createdAt"
        showTime
        label={`Created at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
      <DateField
        source="updatedAt"
        showTime
        label={`Updated at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
    </SimpleShowLayout>
  </Show>
);
