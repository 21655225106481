import React from 'react';
import { Edit, required, TextInput } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `deals/${data.dealId}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" title="Deals" />;

export const DealCouponEdit = () => {
  return (
    <Edit
      redirect={redirectAfterCreateEdit}
      mutationMode="pessimistic"
      actions={EditActions}
    >
      <CustomReferenceInput
        source="dealId"
        reference="deals"
        optionText="nameEN"
        validate={required()}
        disabled
      />
      <TextInput source="code" validate={required()} />
    </Edit>
  );
};
