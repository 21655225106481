import React, { useCallback, useMemo, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { ActionType } from 'types/action';
import { CampaignType } from 'types/campaign';

import ActionCTATypeChip from '@components/action_cta_type_chip';
import { ActionCreateDialog } from '@models/actions/components/create_dialog';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

const DraftActionsBlock = () => {
  const campaign: CampaignType = useRecordContext();
  const [hoveredActionId, setHoveredActionId] = useState<string | null>(null);

  const draftActions = useMemo(() => {
    return campaign.actions?.filter(action => !action.publishedAt);
  }, [campaign.actions]);

  /**
   * Render a draft action
   * @param action
   * @returns {JSX.Element}
   */
  const renderDraftAction = useCallback(
    (action: ActionType) => (
      <a
        key={action.id}
        style={{
          ...styles.actionBlock,
          ...(action.id === hoveredActionId ? styles.actionBlockHovered : {}),
        }}
        href={`#/actions/${action.id}/show`}
        onMouseEnter={() => setHoveredActionId(action.id)}
        onMouseLeave={() => setHoveredActionId(null)}
      >
        {action.name || action.descriptionEN || action.descriptionFR}
        {action.ctaType && <ActionCTATypeChip ctaType={action.ctaType} size="small" />}
      </a>
    ),
    [hoveredActionId]
  );

  return (
    <div style={styles.draftActions}>
      <div style={styles.actionsHeader}>
        Unpublished (drafts)
        <ActionCreateDialog campaignId={campaign.id} />
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: SpacingStyle.normal,
        }}
      >
        {!draftActions || draftActions.length === 0 ? (
          <span style={{ color: Colors.Grey[400] }}>no drafts</span>
        ) : (
          draftActions?.map(renderDraftAction)
        )}
      </div>
    </div>
  );
};

const styles: any = {
  createAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${SpacingStyle[4]}px ${SpacingStyle[8]}px`,
    cursor: 'pointer',
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: '600',
    border: `1px solid ${Colors.Black.primary}`,
    borderRadius: BorderStyle.Radius.huge,
  },
  draftActions: {
    marginBottom: SpacingStyle.normal,
    padding: SpacingStyle.normal,
    backgroundColor: Colors.Magenta[50],
    borderRadius: BorderStyle.Radius.normal,
  },
  actionsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: SpacingStyle.normal,
    color: Colors.OffBlack.primary,
    fontSize: FontStyle.sizeMedium,
    fontWeight: '700',
  },
  actionBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.small,
    padding: SpacingStyle.small,
    backgroundColor: Colors.White.primary,
    borderRadius: BorderStyle.Radius.small,
    width: 200,
    fontSize: FontStyle.sizeVerySmall,
    lineHeight: 1.2,
    color: Colors.OffBlack.primary,
    textDecoration: 'none',
  },
  actionBlockHovered: {
    boxShadow: `0 0 0 1px ${Colors.Magenta[200]}`,
  },
  publishedAt: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle[4],
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: '400',
    color: Colors.Grey[400],
  },
};

export default DraftActionsBlock;
