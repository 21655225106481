import * as React from 'react';
import { List, Datagrid, ReferenceField, TextField } from 'react-admin';
import { PER_PAGE, PER_PAGE_OPTIONS } from '../../components/list';

export const UserBadgeList = () => (
  <List perPage={PER_PAGE} pagination={<PER_PAGE_OPTIONS />} emptyWhileLoading>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField source="userId" reference="users" link="show" />
      <ReferenceField source="badgeId" reference="badges" link="show" />
    </Datagrid>
  </List>
);
