import React from 'react';
import { required, useInput } from 'react-admin';

import CRMSearchDialog from '@components/crm/search_dialog';

import { EmailRecipientsStyles } from './style';
import EmailRecipient from './email_recipient';

const EmailRecipientsInput = ({ source, isRequired }: any) => {
  const {
    field,
    fieldState: { error: fieldError },
  } = useInput({
    source,
    validate: !!isRequired ? [required(`Field ${source} is missing`)] : undefined,
  });
  const recipients = field.value ? field.value.split(',') : [];

  const onRecipientAdd = (basePath: string, id: string, entity: any) => {
    if (!entity) {
      window.alert('No entity selected');
      return;
    }
    if (!entity.email) {
      window.alert('No email address found');
      return;
    }
    const model = basePath === 'crm_persons' ? 'CRMPerson' : 'CRMOrganization';
    const recipient = `{{${model}:${id}}}`;
    const newRecipients = [...recipients, recipient];
    field.onChange(newRecipients.join(','));
  };

  const onRecipientDelete = (index: number) => {
    const newRecipients = [...recipients];
    newRecipients.splice(index, 1);
    field.onChange(newRecipients.join(','));
  };

  return (
    <div
      style={{
        ...EmailRecipientsStyles.container,
        ...EmailRecipientsStyles.containerEditable,
        ...(fieldError && EmailRecipientsStyles.containerError),
      }}
    >
      <div style={EmailRecipientsStyles.label}>
        {source}
        {isRequired ? ' *' : ''}
      </div>
      <div style={EmailRecipientsStyles.input}>
        {recipients.map((recipient: any, index: number) => (
          <EmailRecipient
            key={`${index}-${recipient}`}
            recipient={recipient}
            onDelete={() => onRecipientDelete(index)}
          />
        ))}
        <CRMSearchDialog
          buttonLabel="Add someone"
          ctaLabel="Add to recipients"
          onClick={onRecipientAdd}
        />
      </div>
    </div>
  );
};

export default EmailRecipientsInput;
