import * as React from 'react';
import {
  Button,
  DatagridConfigurable,
  FunctionField,
  List,
  SelectColumnsButton,
  TextField,
  UrlField,
} from 'react-admin';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import CRMCreateDialog from '@components/crm/create_dialog';
import CRMSearchDialog from '@components/crm/search_dialog';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CRMTagChip from '@models/crm/crm_tags/components/chip';

import { CRMPersonImage } from './components/image';

const CampaignListActions = () => {
  const { canCreate } = useRoleBasedPermissions();

  return (
    <ResourceTitleActionBar
      mode="list"
      resourceName="Persons"
      actions={
        <>
          <SelectColumnsButton variant="outlined" />
          <CRMSearchDialog />
          {canCreate('crm_persons') && (
            <Button
              href="/#/crm_persons/import"
              label="Import CSV"
              variant="outlined"
              startIcon={<UploadFileIcon />}
            />
          )}
          {canCreate('crm_persons') && <CRMCreateDialog />}
        </>
      }
    />
  );
};

export const CRMPersonList = () => (
  <List
    sort={{ field: 'createdAt', order: 'DESC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    exporter={false}
    actions={<CampaignListActions />}
    emptyWhileLoading
  >
    <DatagridConfigurable
      rowClick="show"
      expandSingle
      omit={[
        'facebookUrl',
        'instagramUrl',
        'linkedinUrl',
        'threadUrl',
        'tiktokUrl',
        'twitterUrl',
      ]}
      bulkActionButtons={false}
    >
      <CRMPersonImage />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <TextField source="email" />
      <TextField source="organizationName" />
      <TextField source="jobTitle" />
      <UrlField source="facebookUrl" />
      <UrlField source="instagramUrl" />
      <UrlField source="linkedinUrl" />
      <UrlField source="threadUrl" />
      <UrlField source="tiktokUrl" />
      <UrlField source="twitterUrl" />
      <FunctionField
        label="Tags"
        render={(record: any) => (
          <div style={{ display: 'flex', gap: 8 }}>
            {record.crmTags?.map((tag: any) => <CRMTagChip tag={tag} key={tag.id} />)}
          </div>
        )}
      />
    </DatagridConfigurable>
  </List>
);
