import * as React from 'react';
import { SimpleForm, Create } from 'react-admin';
import CustomReferenceInput from '../../components/inputs/custom_reference_input';

const redirectAfterCreateEdit = (_basePath: any, _id: any, data: any) =>
  `users/${data.userId}/show/campaigns`;

export const UserCampaignCreate = () => (
  <Create redirect={redirectAfterCreateEdit}>
    <SimpleForm>
      <CustomReferenceInput source="userId" reference="users" queryKey="username" />
      <CustomReferenceInput source="campaignId" reference="campaigns" queryKey="name" />
    </SimpleForm>
  </Create>
);
