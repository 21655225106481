import React, { useCallback, useEffect, useState } from 'react';
import { ReferenceField, useDataProvider, useRecordContext } from 'react-admin';
import { UserType } from 'types/user';
import { ActionType } from 'types/action';
import { ActionOwnerType } from 'types/action_owner';
import moment from 'moment-timezone';

import ellipsis from '@services/ellipsis';
import ActionCTATypeChip from '@components/action_cta_type_chip';
import LoadingAnimation from '@components/svgs/loading_animation';
import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

const OwnedActionsTab = () => {
  const user = useRecordContext<UserType>();
  const dataProvider = useDataProvider();
  const [actions, setActions] = useState<ActionType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchActions = useCallback(async () => {
    if (!user?.id) {
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await dataProvider.getList('actions_owners', {
        filter: { ownerId: user.id },
        sort: { field: 'createdAt', order: 'DESC' },
        pagination: { page: 1, perPage: 100 },
      });
      const actions = data
        .map((actionOwner: ActionOwnerType) => actionOwner.action)
        .filter(Boolean)
        .sort((a, b) => {
          if (!a?.publishedAt) {
            return -1;
          }
          if (!b?.publishedAt) {
            return 1;
          }
          return moment(a.publishedAt).isAfter(b.publishedAt) ? -1 : 1;
        });
      setActions(actions as ActionType[]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [dataProvider, user.id]);

  useEffect(() => {
    fetchActions();
  }, [fetchActions]);

  if (!user) {
    return null;
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: SpacingStyle.big,
        }}
      >
        <LoadingAnimation />
      </div>
    );
  }

  if (actions.length === 0) {
    return <small>{user.username} do not own any action.</small>;
  }

  return (
    <table style={{ borderSpacing: 0, width: '100%' }}>
      <thead
        style={{
          textAlign: 'left',
          backgroundColor: Colors.Grey[100],
        }}
      >
        <tr>
          <th>Campaign</th>
          <th>Name</th>
          <th>Type</th>
          <th>Published At</th>
        </tr>
      </thead>
      <tbody>
        {actions.map((action, index) => (
          <tr
            key={action.id}
            style={{
              backgroundColor:
                index % 2 === 0 ? Colors.Background.white : Colors.Background.grey,
            }}
          >
            <td>
              <ReferenceField
                record={action}
                reference="campaigns"
                source="campaignId"
                link="show"
              />
            </td>
            <td>
              <a
                href={`#/actions/${action.id}/show`}
                style={{
                  fontWeight: '500',
                  fontSize: FontStyle.sizeSmall,
                  color: action.publishedAt
                    ? Colors.OffBlack.primary
                    : Colors.Grey.primary,
                  textDecoration: 'none',
                }}
              >
                {!action.publishedAt && '[Draft] '}
                {ellipsis(action.name || action.titleEN, 60)}
              </a>
            </td>
            <td>
              <ActionCTATypeChip ctaType={action.ctaType} size="small" />
            </td>
            <td>
              {action.publishedAt
                ? moment(action.publishedAt).format('YYYY-MM-DD')
                : 'draft'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OwnedActionsTab;
