import * as React from 'react';
import { Datagrid, FunctionField, List } from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import CRMTagChip from './components/chip';

const ListActions = <ResourceTitleActionBar mode="list" resourceName="Tags" />;

export const CRMTagList = () => (
  <List
    sort={{ field: 'name', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    exporter={false}
    actions={ListActions}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <FunctionField
        label="Tag name"
        render={(resource: any) => <CRMTagChip tag={resource} />}
      />
    </Datagrid>
  </List>
);
