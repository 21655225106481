import React from 'react';
import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';

import { PhoneNumberField } from '@components/phone_number_field';

import { CountryField } from './components/country';

export const ActionCtaPhoneCallList = () => (
  <List exporter={false} emptyWhileLoading>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ReferenceField source="actionId" reference="actions" link="show" />
      <TextField source="script" />
      <PhoneNumberField source="phoneNumber" />
      <CountryField />
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
