import * as React from 'react';
import { SimpleForm, Create } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `users/${data.userId}/show`;

const CreateActions = <ResourceTitleActionBar mode="create" title="Users Tags" />;

export const UserProfileTagCreate = () => (
  <Create redirect={redirectAfterCreate} actions={CreateActions}>
    <SimpleForm>
      <CustomReferenceInput source="userId" reference="users" queryKey="username" />
      <CustomReferenceInput source="tagId" reference="profile_tags" queryKey="labelEN" />
    </SimpleForm>
  </Create>
);
