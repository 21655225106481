import React from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import { Box, Typography } from '@mui/material';
import { DonationType } from 'types/donation';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { SpacingStyle } from '@styles/variables';

import DonationCard from './components/donation_card';

const ShowActions = <ResourceTitleActionBar mode="show" />;

const ShowTabs = () => {
  const donation: DonationType = useRecordContext();
  const { canCreate } = useRoleBasedPermissions();

  return (
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="launchedAt" />
        <TextField source="expiredAt" />

        <hr style={{ width: '100%', height: 1 }} />

        <NumberField source="maxCoins" />
        <p style={{ padding: 0, margin: 0, color: 'GrayText', fontSize: '12px' }}>
          Buckets values:
        </p>

        {/* Buckets Section with Styling */}
        {donation?.buckets && donation.buckets.length > 0 && (
          <Box>
            {donation.buckets.map(bucket => (
              <Typography key={bucket.value} variant="body1" style={{ fontSize: '14px' }}>
                {bucket.descriptionEN} (value: {bucket.value}){' '}
                {bucket.isDefault && <strong>(Most Popular)</strong>}
              </Typography>
            ))}
          </Box>
        )}
        <hr style={{ width: '100%', height: 1 }} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: SpacingStyle.big,
            marginBottom: 120,
          }}
        >
          <DonationCard donation={donation} language="en" />
          <DonationCard donation={donation} language="fr" />
        </div>
      </Tab>

      <Tab label="Recipients">
        {canCreate('donations_recipients') && (
          <Button
            href={`#/donations_recipients/create?source={"donationId":"${donation?.id}"}`}
            label="Link users as recipients of this donation"
            startIcon={<AddIcon />}
          />
        )}
        <ArrayField source="recipients" label="Recipients">
          <Datagrid
            bulkActionButtons={false}
            rowClick={(_id, _resource, record) => {
              return `/donations_recipients/${record.donationRecipientId}/show`;
            }}
          >
            <ReferenceField source="id" reference="users" link="show" />
            <DateField source="createdAt" showTime />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  );
};

export const DonationShow = () => (
  <Show actions={ShowActions}>
    <ShowTabs />
  </Show>
);

export default DonationShow;
