import { UserType } from './user';
import { UserPepperPillReactionType } from './user_pepper_pill_reaction';

export enum PepperPillTypes {
  Happy = 'HAPPY',
  Truth = 'TRUTH',
  Detox = 'DETOX',
}

export type PepperPillType = {
  id: string;

  type: PepperPillTypes;
  titleEN: string;
  titleFR: string;
  contentEN: string;
  contentFR: string;
  sourceUrl?: string;
  imageUrl?: string;

  users?: UserType[];
  reactions?: UserPepperPillReactionType[];

  createdAt: string;
  updatedAt: string;
};
