import { BorderStyle, Colors } from '@styles/variables';

export const EmailRecipientsStyles: any = {
  container: {
    margin: '12px 0',
    width: '100%',
    borderRadius: BorderStyle.Radius.small,
  },
  containerEditable: {
    padding: '4px 12px',
    backgroundColor: Colors.Background.light,
    border: `1px solid ${Colors.Grey[100]}`,
  },
  containerError: {
    border: `1px solid ${Colors.Red.primary}`,
  },
  label: {
    fontSize: 12,
    color: '#666',
    textTransform: 'uppercase',
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 8,
    marginTop: 4,
  },
};
