import * as React from 'react';
import { SimpleForm, Create } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `users/${data.userId}/show`;

const CreateActions = <ResourceTitleActionBar mode="create" title="Users Skills" />;

export const UserProfileSkillCreate = () => (
  <Create redirect={redirectAfterCreate} actions={CreateActions}>
    <SimpleForm>
      <CustomReferenceInput source="userId" reference="users" queryKey="username" />
      <CustomReferenceInput
        source="skillId"
        reference="profile_skills"
        queryKey="labelEN"
      />
    </SimpleForm>
  </Create>
);
