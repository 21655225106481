import React from 'react';
import {
  Button,
  DeleteButton,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRecordContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import CustomReferenceInput from '@components/inputs/custom_reference_input';
import Favicon from '@components/svgs/logos/favicon';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CountryInput from '@components/inputs/country_input';

import {
  GPTCallout,
  cleanPhonenumber,
  validatePhoneNumber,
  validateScriptContent,
} from './create';

const redirectAfterEdit = (_basePath: any, _id: any, data: any) =>
  `actions/${data.actionId}/show`;

const EditActions = () => {
  const params = useParams();
  const record = useRecordContext();
  let actionId = params?.actionId ? params.actionId : record?.actionId;
  return (
    <ResourceTitleActionBar
      mode="edit"
      actions={
        <Button
          href={`#/actions/${actionId}/show`}
          startIcon={<Favicon width={15} height={12} />}
          label="Back to Action"
          variant="outlined"
        />
      }
    />
  );
};

/**
 * @param {*} props
 */
const EditToolbar = ({ ...props }) => {
  const record = useRecordContext(props);
  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton
        redirect={(basePath: any) => redirectAfterEdit(basePath, record.id, record)}
      />
    </Toolbar>
  );
};

export const ActionCtaPhoneCallEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={<EditActions />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <CustomReferenceInput
        source="actionId"
        reference="actions"
        optionText="name"
        disabled
      />

      <br />
      <TextInput
        source="phoneNumber"
        required
        fullWidth
        helperText="Enter the phone number users will call"
        validate={validatePhoneNumber}
        parse={cleanPhonenumber}
      />
      <CountryInput
        source="country"
        helperText="Only users from this country can get this action assigned"
      />
      <br />
      <GPTCallout />
      <TextInput
        source="script"
        required
        multiline
        fullWidth
        label="Script users will read"
        placeholder="Hello, my name is UserName and I'm from City, Region. I'm calling to ask you to take action on this important issue."
        validate={validateScriptContent}
      />
    </SimpleForm>
  </Edit>
);
