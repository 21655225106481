import * as React from 'react';
import { Datagrid, DateField, List, ReferenceField, UrlField } from 'react-admin';

export const ActionCtaShareLinkList = () => (
  <List exporter={false} emptyWhileLoading>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ReferenceField source="actionId" reference="actions" link="show" />
      <UrlField source="link" />
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
