import React, { useEffect, useState } from 'react';
import { Title, useDataProvider } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CampaignType } from 'types/campaign';

import LoadingAnimation from '@components/svgs/loading_animation';
import { WeeklyEvolutionActiveUsers } from '@models/campaigns/components/campaign_analytics/weekly_evolution_active_users';
import { UniqueActiveUsersPercentage } from '@models/campaigns/components/campaign_analytics/unique_active_users_percentage';

const CampaignsAnalytics = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [campaigns, setCampaigns] = useState<CampaignType[]>([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    const { data } = await dataProvider.getList('campaigns', {
      pagination: { page: 1, perPage: 100 },
      filter: {},
      sort: { field: 'name', order: 'ASC' },
    });
    setCampaigns(data);
    setIsLoading(false);
  };

  const renderCampaignAnalytics = (campaign: CampaignType) => (
    <div
      key={campaign.id}
      style={{
        maxWidth: 1000,
        borderBottom: '1px solid #eee',
        padding: '20px 0',
      }}
    >
      <a
        href={`#/campaigns/${campaign.id}/show/analytics`}
        style={{ color: '#000', textDecoration: 'none' }}
      >
        <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
          <img
            src={campaign.imageUrl}
            alt={campaign.name}
            style={{ width: 40, borderRadius: 40, marginRight: 10 }}
          />
          {campaign.name}
          <ChevronRightIcon />
        </h3>
      </a>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <UniqueActiveUsersPercentage campaign={campaign} />
        <WeeklyEvolutionActiveUsers campaign={campaign} />
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <Card>
        <Title title={'Campaign Analytics'} />
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 300,
          }}
        >
          <LoadingAnimation />
          <p>Loading campaign stats...</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <Title title={'Campaign Analytics'} />
      <CardContent>{campaigns.map(renderCampaignAnalytics)}</CardContent>
    </Card>
  );
};

export default CampaignsAnalytics;
