import React from 'react';
import { Button } from 'react-admin';
import { getCountryData, TCountryCode } from 'countries-list';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ActionType } from 'types/action';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import Callout from '@components/callout';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import EmailRecipientsField from './email_recipient/email_recipients_field';

type ActionCtaEmailsActionCardProps = {
  action: ActionType;
  style: any;
  canEdit?: boolean;
};
const ActionCtaEmailsActionCard = ({
  action,
  style,
  canEdit = true,
}: ActionCtaEmailsActionCardProps) => {
  const { canCreate, canUpdate } = useRoleBasedPermissions();

  if (!action || !action.actionCtaEmails) {
    return null;
  }

  return (
    <div
      style={{
        justifyContent: 'space-between',
        ...style,
      }}
    >
      {canEdit && (
        <Callout emoji="🤖" backgroundColor="yellow">
          GPT will randomly take one of the following emails,
          <br />
          and rewrite the <strong>subject</strong> based on the body of the email, before
          sending it.
        </Callout>
      )}
      {action.actionCtaEmails &&
        action.actionCtaEmails.map((email, index) => (
          <div key={`${email.id || 'fake-id'}-${index}`}>
            <div
              style={{
                fontSize: FontStyle.sizeVerySmall,
                fontWeight: 600,
                color: Colors.Grey.primary,
              }}
            >
              Email #{index + 1}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: SpacingStyle.small,
                marginTop: 4,
                marginBottom: SpacingStyle.small,
                paddingTop: SpacingStyle.normal,
                paddingLeft: SpacingStyle.normal,
                paddingRight: SpacingStyle.normal,
                fontSize: FontStyle.sizeVerySmall,
                lineHeight: 1.3,
                whiteSpace: 'pre-wrap',
                backgroundColor: Colors.Background.white,
                border: `1px solid ${Colors.Grey[100]}`,
                borderRadius: BorderStyle.Radius.small,
              }}
            >
              <div>
                <strong>Will be sent by users from:</strong>{' '}
                {getCountryData(email?.country as TCountryCode)?.name || 'Any country'}
              </div>
              <div
                style={{
                  paddingTop: SpacingStyle.small,
                  borderTop: `1px solid ${Colors.Grey[200]}`,
                }}
              >
                <strong>Summary 🇺🇸:</strong> {email.summaryEN}
              </div>
              <div>
                <strong>Summary 🇫🇷:</strong> {email.summaryFR}
              </div>
              <div
                style={{
                  paddingTop: SpacingStyle.small,
                  borderTop: `1px solid ${Colors.Grey[200]}`,
                }}
              >
                {email.to && <EmailRecipientsField record={email} source="to" />}
                {email.cc && <EmailRecipientsField record={email} source="cc" />}
                {email.bcc && <EmailRecipientsField record={email} source="bcc" />}
                {!email.to && !email.cc && !email.bcc && (
                  <div>
                    <strong>To/Cc/Bcc:</strong> Add at least one recipient
                  </div>
                )}
                <strong>Subject:</strong> {email.subject}
              </div>
              <strong>Body:</strong>
              <div
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {email.body && email.body.length > 300
                  ? `${email.body.slice(0, 300).trim()}...`
                  : email.body || ''}
              </div>
              {canUpdate('action_cta_emails') && canEdit && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: SpacingStyle.small,
                  }}
                >
                  <Button
                    label="Duplicate"
                    endIcon={<ContentCopyIcon />}
                    href={`#/action_cta_emails/create?source={"actionId":"${
                      action.id
                    }","to":"${email.to || ''}","cc":"${
                      email.cc || ''
                    }","bcc":"${email.bcc || ''}","subject":"${
                      email.subject
                    }","body":${encodeURIComponent(JSON.stringify(email.body))}}`}
                    variant="outlined"
                  />
                  <Button
                    label="Edit"
                    endIcon={<EditIcon />}
                    href={`#/action_cta_emails/${email.id}/edit`}
                    variant="outlined"
                  />
                </div>
              )}
            </div>
          </div>
        ))}

      {canCreate('action_cta_emails') && canEdit && (
        <Button
          label="Create an email"
          startIcon={<AddIcon />}
          href={`#/action_cta_emails/create?source={"actionId":"${action.id}"}`}
          style={{
            marginTop: SpacingStyle.small,
            marginBottom: SpacingStyle.normal,
          }}
        />
      )}
    </div>
  );
};

export default ActionCtaEmailsActionCard;
