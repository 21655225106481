import * as React from 'react';
import { Edit, SelectInput, SimpleForm, TextInput } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

import { CRMTagColors, optionRenderer } from './create';

const EditActions = <ResourceTitleActionBar mode="edit" resourceName="Tags" />;

export const CRMTagEdit = () => (
  <Edit mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <TextInput source="name" fullWidth required />
      <SelectInput
        source="color"
        required
        choices={CRMTagColors.map(color => {
          return { id: color, name: color };
        })}
        optionText={optionRenderer}
      />
    </SimpleForm>
  </Edit>
);
