export const Colors = {
  White: {
    primary: '#FFFFFF',
    transparent: {
      light: 'rgba(255,255,255,.6)',
      medium: 'rgba(255,255,255,.2)',
      max: 'rgba(255,255,255,.1)',
    },
  },
  OffWhite: {
    primary: '#F9F8FC',
  },
  Black: {
    primary: '#000000',
    transparent: {
      light: 'rgba(0,0,0,.7)',
      medium: 'rgba(0,0,0,.3)',
      max: 'rgba(0,0,0,.05)',
    },
  },
  OffBlack: {
    primary: '#140F14',
  },
  Grey: {
    primary: '#999799',
    25: '#FFFBFF',
    50: '#F2EEF2',
    100: '#EFEFEF',
    200: '#E5E2E5',
    300: '#CCC9CC',
    400: '#B2B0B2',
    500: '#999799',
    600: '#807E80',
    700: '#5E5E5E',
    800: '#4D4B4C',
    900: '#2C2C2C',
    1000: '#121212',
  },
  Red: {
    primary: '#F45555',
    25: '#F4E8E8',
    50: '#F4DCDC',
    100: '#F4C3C3',
    200: '#F48686',
    300: '#F46E6E',
    400: '#F45555',
    500: '#F43D3D',
    600: '#BF281C',
    700: '#A62218',
    800: '#8C1D14',
    900: '#7A271A',
  },
  Blue: {
    primary: '#1570EF',
    25: '#F5FAFF',
    50: '#EFF8FF',
    100: '#D1E9FF',
    200: '#B2DDFF',
    300: '#84CAFF',
    400: '#53B1FD',
    500: '#2E90FA',
    600: '#1570EF',
    700: '#175CD3',
    800: '#1849A9',
    900: '#194185',
  },
  Green: {
    primary: '#47DE98',
    25: '#CBF8E3',
    50: '#B3F8D8',
    100: '#99F8CC',
    200: '#81F8C0',
    300: '#68F8B5',
    400: '#4FF8A9',
    500: '#47DE98',
    600: '#3FC486',
    700: '#37AB75',
    800: '#2F9163',
    900: '#277852',
  },
  Orange: {
    primary: '#FDB022',
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
  },
  Pink: {
    primary: '#FF416C',
    25: '#FFECF0',
    50: '#FFD9E2',
    100: '#FFC6D3',
    200: '#FFB3C4',
    300: '#FF8DA7',
    400: '#FF6789',
    500: '#FF416C',
    600: '#D13457',
    700: '#A32743',
    800: '#741A2E',
    900: '#460D1A',
  },
  Magenta: {
    primary: '#FF4BEB',
    50: '#FFF6FE',
    100: '#FFEDFD',
    200: '#FFE3FC',
    300: '#FFC9F9',
    400: '#FFB0F6',
    500: '#FF96F4',
    600: '#FF7DF1',
    700: '#FF63EE',
    800: '#FF4BEB',
    900: '#E544D6',
    1000: '#CC3DBE',
    1100: '#B235A6',
    1200: '#992D8E',
    1300: '#802577',
    1400: '#661E5F',
  },
  Yellow: {
    primary: '#FDB022',
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
  },
  Background: {
    white: '#FFFFFF',
    light: '#FCFCFC',
    grey: '#F9F8FC',
    dark: '#140F14',
  },
};

export const SpacingStyle = {
  small: 8,
  normal: 16,
  big: 28,
  huge: 40,
  2: 2,
  4: 4,
  8: 8,
  12: 12,
  16: 16,
  20: 20,
  24: 24,
  28: 28,
  32: 32,
  36: 36,
  40: 40,
  56: 56,
  84: 84,
  112: 112,
};

export const BorderStyle = {
  color: Colors.Grey[300],
  Radius: {
    tiny: 4,
    small: 8,
    normal: 12,
    big: 20,
    huge: 28,
  },
  Width: {
    small: 1,
    normal: 2,
    big: 3,
    huge: 4,
    massive: 8,
  },
};

export const FontStyle = {
  sizeVeryVeryVerySmall: 8,
  sizeVeryVerySmall: 10,
  sizeVerySmall: 12,
  sizeSmall: 14,
  sizeMedium: 16,
  sizeNormal: 18,
  sizeBig: 20,
  sizeVeryBig: 24,
  sizeVeryVeryBig: 28,
  sizeHuge: 30,
  sizeVeryHuge: 32,
  sizeVeryVeryHuge: 42,
  sizeMassive: 50,
};
