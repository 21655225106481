import * as React from 'react';
import { SimpleForm, Create } from 'react-admin';
import CustomReferenceInput from '../../components/inputs/custom_reference_input';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `users/${data.userId}/show/feature_flags`;

export const UsersFeatureFlagsCreate = () => (
  <Create redirect={redirectAfterCreate}>
    <SimpleForm>
      <CustomReferenceInput source="userId" reference="users" queryKey="username" />
      <CustomReferenceInput
        source="featureFlagId"
        reference="feature_flags"
        queryKey="name"
      />
    </SimpleForm>
  </Create>
);
