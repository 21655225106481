import * as React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `users/${data.userId}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const UserProfileSkillEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <CustomReferenceInput
        source="userId"
        reference="users"
        queryKey="username"
        disabled
      />
      <CustomReferenceInput
        source="skillId"
        reference="profile_skills"
        queryKey="labelEN"
      />
    </SimpleForm>
  </Edit>
);
