import { CommentType } from './comment';

export enum CommentMediaTypes {
  Audio = 'AUDIO',
  Gif = 'GIF',
  Image = 'IMAGE',
  Video = 'VIDEO',
}

export type CommentMediaType = {
  id: string;
  commentId: CommentType['id'];
  type: CommentMediaTypes;
  url: string;

  createdAt: string;
  updatedAt: string;
};
