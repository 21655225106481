import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetIdentity } from 'react-admin';
import * as z from 'zod';
import moment from 'moment-timezone';
import SendIcon from '@mui/icons-material/Send';
import zodToJsonSchema from 'zod-to-json-schema';

import { useOpenAI } from '@hooks/useOpenAI';
import PepperAvatar, { pepperAvatarUrl } from '@components/pepper_avatar';
import LoadingAnimation from '@components/svgs/loading_animation';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import ChatMessage, { ChatMessageProps } from './components/message';

const SYSTEM_MESSAGE =
  'You are an AI agent, conversing with a user. You are designed to help the user generate structured data based on their input.';

type PepperBuilderChatProps = {
  welcomeText: string;
  previewContainer: React.ReactNode;
  systemMessage?: string;
  zodSchema: z.Schema<any>;
  onMessageSubmitted?: (output: any) => void;
};
const PepperBuilderChat = ({
  welcomeText,
  previewContainer,
  systemMessage,
  onMessageSubmitted,
  zodSchema,
}: PepperBuilderChatProps) => {
  const { data: currentUserIdentity } = useGetIdentity();
  const { isLoading: isOpenAILoading, createStructuredOutput } = useOpenAI();
  const chatConversationRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<ChatMessageProps[]>([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [previousIteration, setPreviousIteration] = useState<any>();

  const isSomethingLoading = useMemo(
    () => isLoading || isOpenAILoading,
    [isLoading, isOpenAILoading]
  );
  const currentUserName = useMemo(
    () => currentUserIdentity?.username,
    [currentUserIdentity]
  );

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const getPrompt = useCallback(
    (conversationHistory: ChatMessageProps[]) => {
      const history = conversationHistory
        .sort((m1, m2) => {
          return moment(m1.postedAt).diff(moment(m2.postedAt));
        })
        .map(message => `- ${message.role}: ${message.text.replace(/\n/gi, '')}`)
        .join('\n');

      const previousIterationInstructions = previousIteration
        ? `
# Previous Iteration
Use the following information from the previous iteration to generate the next response:
\`\`\`json
${JSON.stringify(previousIteration, null, 0)}
\`\`\`

This iteration was generated based on the following schema:
\`\`\`json
${zodToJsonSchema(zodSchema)}
\`\`\
`
        : '';

      return `
# Conversation History
Here is the conversation history between you (the agent) and the user:
${history}
${previousIterationInstructions}
`;
    },
    [previousIteration, zodSchema]
  );

  /**
   * Submit the message and get the response from the server
   */
  const submitMessage = useCallback(async () => {
    if (!textareaRef.current || !textareaRef.current?.value || isSomethingLoading) {
      return;
    }
    setIsLoading(true);
    const inputValue = textareaRef.current.value || '';
    const message: ChatMessageProps = {
      role: 'user',
      text: inputValue || '',
      postedAt: new Date().toISOString(),
      author: currentUserName || 'you',
    };
    const newMessages = [...messages, message];
    setMessages(newMessages);
    textareaRef.current!.value = '';
    adjustHeight();
    chatConversationRef.current?.scrollTo({
      top: chatConversationRef.current?.scrollHeight,
      behavior: 'smooth',
    });

    try {
      const output = await createStructuredOutput({
        systemMessage: `${SYSTEM_MESSAGE}\n${systemMessage || ''}`,
        prompt: getPrompt(newMessages),
        schema: zodSchema,
      });
      setPreviousIteration(output);
      await onMessageSubmitted?.(output);
    } catch (error: any) {
      console.error('PepperBuilderChat.submitMessage', error);
      const errorMessage: ChatMessageProps = {
        role: 'agent',
        author: 'Pepper',
        avatarUrl: pepperAvatarUrl,
        text: `Sorry, I encountered an error. Please try again. (${error.message})`,
        postedAt: new Date().toISOString(),
      };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    }

    setIsLoading(false);
  }, [
    textareaRef,
    systemMessage,
    messages,
    isSomethingLoading,
    currentUserName,
    onMessageSubmitted,
    createStructuredOutput,
    zodSchema,
    getPrompt,
  ]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        submitMessage();
      }
    },
    [submitMessage]
  );

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.chatContainer}>
        <div style={styles.chatConversation} ref={chatConversationRef}>
          {messages.map((message, index) => (
            <ChatMessage {...message} key={index} />
          ))}
          {isSomethingLoading && <LoadingAnimation />}
          {messages.length === 0 && (
            <div style={styles.chatWelcomeMessage}>
              <PepperAvatar width={75} height={75} />
              {welcomeText}
            </div>
          )}
        </div>
        <div
          style={{
            ...styles.chatInputContainer,
            ...(isInputFocused ? styles.chatInputContainerFocused : {}),
          }}
        >
          <textarea
            ref={textareaRef}
            style={styles.chatInputField}
            placeholder="Start typing a message..."
            rows={1}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            onKeyDown={handleKeyDown}
            onInput={adjustHeight}
            spellCheck={false}
          />
          <div
            style={{
              ...styles.chatInputButton,
              ...(isSomethingLoading ? styles.chatInputButtonDisabled : {}),
            }}
            onClick={submitMessage}
          >
            {isSomethingLoading ? (
              <LoadingAnimation
                stroke={Colors.OffWhite.primary}
                width={FontStyle.sizeSmall}
                height={FontStyle.sizeSmall}
              />
            ) : (
              <SendIcon
                style={{ width: FontStyle.sizeSmall, height: FontStyle.sizeSmall }}
              />
            )}
          </div>
        </div>
      </div>
      <div style={styles.preview}>
        <div style={styles.previewTitle}>Preview</div>
        <div
          style={{
            ...styles.previewContent,
            ...(isSomethingLoading ? styles.previewContentLoading : {}),
          }}
        >
          {previewContainer}
        </div>
      </div>
    </div>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    flex: 1,
    height: '100%',
    padding: SpacingStyle.normal,
    gap: SpacingStyle.big,
    backgroundColor: Colors.Background.grey,
    borderRadius: BorderStyle.Radius.normal,
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.5,
    maxWidth: 500,
    height: '100%',
    maxHeight: '90vh',
    border: `1px solid ${Colors.Grey[100]}`,
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: Colors.White.primary,
    boxShadow: '0 1px 20px rgba(0, 0, 0, 0.075)',
  },
  chatConversation: {
    flex: 1,
    height: '100%',
    padding: SpacingStyle.normal,
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: `${Colors.Grey[300]} ${Colors.OffWhite.primary}`,
  },
  chatWelcomeMessage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '30%',
    gap: SpacingStyle.normal,
    fontWeight: '500',
    lineHeight: 1.2,
    textAlign: 'center',
  },
  chatInputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: SpacingStyle.small,
    backgroundColor: Colors.Background.white,
    borderRadius: BorderStyle.Radius.normal,
    border: `1px solid ${Colors.Grey[200]}`,
  },
  chatInputContainerFocused: {
    border: `1px solid ${Colors.Grey[400]}`,
  },
  chatInputField: {
    flex: 1,
    maxHeight: '20vh',
    padding: SpacingStyle[24],
    fontFamily: 'inherit',
    fontSize: FontStyle.sizeSmall,
    border: 'none',
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: 'transparent',
    outline: 'none',
    scrollbarWidth: 'thin',
    scrollbarColor: `${Colors.Grey[300]} ${Colors.White.primary}`,
    resize: 'none',
  },
  chatInputButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 28,
    width: 28,
    marginRight: SpacingStyle.normal,
    color: Colors.White.primary,
    backgroundColor: Colors.OffBlack.primary,
    borderRadius: 20,
    cursor: 'pointer',
  },
  chatInputButtonDisabled: {
    cursor: 'not-allowed',
    backgroundColor: Colors.Grey[400],
  },
  preview: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
  },
  previewTitle: {
    fontWeight: '600',
    fontSize: FontStyle.sizeNormal,
    marginBottom: SpacingStyle.normal,
  },
  previewContent: {},
  previewContentLoading: {
    opacity: 0.2,
    filter: 'blur(1px)',
  },
};

export default PepperBuilderChat;
