import React, { useState, useEffect } from 'react';
import { AutocompleteInput, required as RARequired } from 'react-admin';
import moment from 'moment-timezone';

type TimezoneInputProps = {
  source: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  fullWidth?: boolean;
};
const TimezoneInput = ({
  source,
  label,
  required,
  disabled,
  helperText,
  fullWidth,
}: TimezoneInputProps) => {
  const [timezones, setTimezones] = useState<Record<string, string>[]>([]);

  useEffect(() => {
    const fetchTimezones = () => {
      const allTimezones = moment.tz.names();
      setTimezones(allTimezones.map(tz => ({ id: tz, name: tz })));
    };

    fetchTimezones();
  }, []);

  return (
    <AutocompleteInput
      choices={timezones}
      source={source}
      label={label}
      validate={required ? [RARequired()] : []}
      disabled={disabled}
      helperText={helperText}
      fullWidth={fullWidth}
      style={{
        minWidth: 300,
      }}
    />
  );
};

export default TimezoneInput;
