import { defaultTheme } from 'react-admin';

import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

const MuiComponents = {
  RaTopToolbar: {
    styleOverrides: {
      root: {
        alignItems: 'center',
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: '0px !important',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: Colors.Magenta.primary,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minWidth: '50px !important',
        fontWeight: '500',
        fontSize: FontStyle.sizeVeryVerySmall,
        color: Colors.Grey[500],
        '&.Mui-selected': {
          fontWeight: '600',
          color: Colors.Magenta.primary,
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        margin: SpacingStyle[24],
        backgroundColor: Colors.Background.white,
        borderRadius: BorderStyle.Radius.normal,
        boxShadow: '0 10px 10px rgba(0, 0, 0, 0.02)',
        border: `1px solid ${Colors.Grey[50]}`,
        overflow: 'hidden',
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        paddingBottom: SpacingStyle[4],
        '& .MuiCardHeader-title': {
          fontSize: FontStyle.sizeNormal,
          fontWeight: '600',
          color: Colors.OffBlack.primary,
        },
        '& .MuiCardHeader-avatar': {
          fontSize: FontStyle.sizeBig,
          color: Colors.OffBlack.primary,
        },
        '& .MuiCardHeader-subheader': {
          fontSize: FontStyle.sizeVerySmall,
          fontWeight: '400',
          color: Colors.Grey[500],
        },
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: SpacingStyle.normal,
        '&:last-child': {
          paddingBottom: SpacingStyle.normal,
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: Colors.Background.light,
        border: `1px solid ${Colors.Grey[100]}`,
        borderRadius: BorderStyle.Radius.small,
        '&:hover:not(.Mui-disabled)': {
          backgroundColor: 'rgba(0,0,0,.01)',
          border: `1px solid ${Colors.Grey[200]}`,
        },
        '&:before,&:after': {
          display: 'none',
        },
        '&.Mui-focused': {
          backgroundColor: 'rgba(0,0,0,.03)',
        },
        '&.Mui-error': {
          borderColor: Colors.Red.primary,
        },
      },
    },
  },
  RaFileInput: {
    styleOverrides: {
      root: {
        '& .RaFileInput-dropZone': {
          border: `1px dashed ${Colors.Magenta[500]}`,
          borderRadius: `${BorderStyle.Radius.normal}px !important`,
          backgroundColor: `${Colors.Magenta[50]} !important`,
          color: `${Colors.Magenta.primary} !important`,
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        backgroundColor: Colors.OffBlack.primary,
        color: Colors.White.primary,
        borderRadius: BorderStyle.Radius.huge,
        boxShadow: 'none',
        fontWeight: '700',
        textTransform: 'none',
        padding: `${SpacingStyle[4]}px ${SpacingStyle.normal}px`,
        margin: `${SpacingStyle[8]}px ${SpacingStyle[4]}px`,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: Colors.Grey[900],
        },
      },
      outlined: {
        backgroundColor: 'transparent',
        color: Colors.OffBlack.primary,
        border: `1px solid ${Colors.Grey[500]}`,
        '&:hover': {
          backgroundColor: Colors.White.primary,
          border: `1px solid ${Colors.OffBlack.primary}`,
        },
      },
      outlinedError: {
        borderColor: Colors.Red.primary,
        color: Colors.Red.primary,
        '&:hover': {
          backgroundColor: Colors.Red.primary,
          border: `1px solid ${Colors.Red.primary}`,
          color: Colors.White.primary,
        },
      },
      text: {
        margin: 0,
        padding: 0,
        backgroundColor: 'transparent',
        fontWeight: '600',
        color: Colors.OffBlack.primary,
        '&:hover': {
          backgroundColor: Colors.White.primary,
        },
      },
      textError: {
        color: Colors.Red.primary,
      },
      sizeSmall: {
        padding: `${SpacingStyle[2]}px ${SpacingStyle[8]}px`,
        fontWeight: '600 !important',
      },
    },
  },
};

const RaComponents = {
  RaLogin: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
        backgroundColor: Colors.Background.light,
      },
    },
  },
  RaReferenceField: {
    styleOverrides: {
      root: {
        '& .RaReferenceField-link>*': {
          textDecoration: 'underline',
        },
        '& .RaReferenceField-link:hover>*': {
          textDecoration: 'none',
        },
      },
    },
  },
  RaLabeled: {
    styleOverrides: {
      root: {
        '& .RaLabeled-label': {
          marginTop: '1em',
        },
      },
    },
  },
  RaDatagrid: {
    styleOverrides: {
      root: {
        '& .RaDatagrid-headerCell': {
          fontSize: FontStyle.sizeVeryVerySmall,
          fontWeight: '600',
          textTransform: 'uppercase',
          color: Colors.Grey[400],
        },
        '& .RaDatagrid-rowOdd': {
          backgroundColor: 'rgba(27,7,23,.01)',
        },
      },
    },
  },
  RaList: {
    styleOverrides: {
      root: {
        '& .RaList-content': {
          overflow: 'hidden',
        },
      },
    },
  },
  RaFilterForm: {
    styleOverrides: {
      root: {
        flex: '1 !important',
      },
    },
  },
};

const RaSidebar = {
  RaSidebar: {
    styleOverrides: {
      root: {
        height: '100vh',
        '.RaSidebar-fixed': {
          height: '100vh',
        },
        '&.RaSidebar-appBarCollapsed': {
          marginTop: 0,
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      root: {
        zIndex: 10,
      },
    },
  },
  RaMenu: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        height: '100%',
        margin: 0,
        padding: 0,
      },
    },
  },
  RaMenuItemLink: {
    styleOverrides: {
      root: {
        margin: `${SpacingStyle[2]}px ${SpacingStyle[16]}px`,
        padding: `${SpacingStyle[12]}px ${SpacingStyle[12]}px`,
        color: Colors.Grey[700],
        fontSize: FontStyle.sizeSmall,
        fontWeight: '500',
        borderRadius: BorderStyle.Radius.normal,
        '&.RaMenuItemLink-active': {
          color: Colors.OffBlack.primary,
          backgroundColor: Colors.Magenta[100],
        },
        '&:hover+not(RaMenuItemLink-active)': {
          backgroundColor: Colors.Magenta[50],
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 'auto !important',
        marginRight: SpacingStyle[12],
      },
    },
  },
};

const DialogComponents = {
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: BorderStyle.Radius.normal,
      },
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        lineHeight: 1.42,
        fontSize: FontStyle.sizeSmall,
        color: Colors.Grey[700],
        marginBottom: SpacingStyle[4],
      },
    },
  },
};

const customTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: Colors.OffBlack.primary,
      secondary: Colors.Magenta.primary,
      error: Colors.Red.primary,
    },
    background: {
      default: Colors.Background.light,
      paper: Colors.Background.white,
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    ...defaultTheme.components,
    ...MuiComponents,
    ...RaComponents,
    ...RaSidebar,
    ...DialogComponents,
  },
};

export default customTheme;
