import React from 'react';
import { countries } from 'countries-list';
import { AutocompleteInput, Labeled, SelectField } from 'react-admin';

const countriesChoices = Object.entries(countries).map(([countryCode, country]) => ({
  id: countryCode,
  name: `${country.name} (${countryCode})`,
}));

export const CountryField = () => (
  <SelectField source="country" choices={countriesChoices} emptyText="Any country" />
);

type CountrySelectProps = {
  source: string;
  onSelect: (selectedCountryCode: string) => void;
  helperText?: string;
};

export const CountrySelect = ({ onSelect, source, helperText }: CountrySelectProps) => (
  <>
    <AutocompleteInput
      source={source}
      choices={countriesChoices}
      emptyText="All countries"
      fullWidth
      onSelect={onSelect}
      helperText={helperText}
    />
  </>
);
