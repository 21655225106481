import * as React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';
import ResourceTitleActionBar from 'src/components/resource_title_action_bar';

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const CauseCreate = () => (
  <Create actions={CreateActions}>
    <SimpleForm>
      <TextInput source="name" fullWidth required />
      <TextInput source="emoji" fullWidth />
      <ColorInput label="Color" source="color" />
      <TextInput source="descriptionFR" fullWidth required />
      <TextInput source="descriptionEN" fullWidth required />
    </SimpleForm>
  </Create>
);
