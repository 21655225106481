import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import QRCodeStyling from 'qr-code-styling';
import { useLogin, useNotify } from 'react-admin';

import LoadingAnimation from '@components/svgs/loading_animation';
import { Colors } from '@styles/variables';

import Config from '../../config';

import { styles as LoginStyle } from '.';

const WIDTH = 220;
const IMAGE_MARGIN = 5;
const IMAGE_SIZE = 0.2;
const DOTS_TYPE = 'rounded';
const CORNERS_TYPE = 'extra-rounded';
const CORNERS_DOT_TYPE = 'dot';

const qrCode = new QRCodeStyling({
  width: WIDTH,
  height: WIDTH,
  image:
    'https://res.cloudinary.com/hsiz9ovy1/image/upload/v1715671047/public_assets/CHILLI_FAVICON_BLACK_RGB_gtc7ks.png',
  dotsOptions: {
    color: Colors.OffBlack.primary,
    type: DOTS_TYPE,
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: IMAGE_MARGIN,
    imageSize: IMAGE_SIZE,
  },
  cornersSquareOptions: {
    type: CORNERS_TYPE,
    color: Colors.OffBlack.primary,
  },
  cornersDotOptions: {
    type: CORNERS_DOT_TYPE,
    color: Colors.OffBlack.primary,
  },
  backgroundOptions: {
    color: 'transparent',
  },
});

const QRCode = () => {
  const refBlack = useRef<HTMLDivElement>(null);
  const [qrToken, setQrToken] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const notify = useNotify();
  const login = useLogin();

  /**
   * Fetches the auth token from the server
   * and logs the user in if the token is valid
   */
  const fetchAuthToken = useCallback(async () => {
    const url = `${Config.API_URL}/auth/qr_token/${qrToken}/login`;
    try {
      const {
        data: { data },
      } = await axios.get(url);
      if (data.authToken) {
        login({ authToken: data.authToken });
      }
    } catch (error: any) {
      notify(error.message);
      setError('Something went wrong, please try again');
    }
  }, [qrToken, notify, login]);

  /**
   * Generates a new QR code
   */
  const generateQRCode = useCallback(async () => {
    const {
      data: { data },
    } = await axios.post(`${Config.API_URL}/auth/qr_token`);
    const { qrToken, url } = data;
    setQrToken(qrToken);
    qrCode.update({ data: url });
    if (refBlack.current) {
      refBlack.current.innerHTML = '';
      qrCode.append(refBlack.current);
    }
  }, []);

  useEffect(() => {
    generateQRCode();
  }, [generateQRCode]);

  useEffect(() => {
    if (error && intervalId) {
      clearInterval(intervalId);
    }
  }, [error, intervalId]);

  useEffect(() => {
    if (!qrToken) {
      return;
    }

    const interval = setInterval(() => fetchAuthToken(), 2000);
    setIntervalId(interval);
    return () => clearInterval(interval);
  }, [qrToken, fetchAuthToken]);

  return (
    <div style={styles.container}>
      <div style={LoginStyle.subtitle}>
        Scan this with <strong>your iPhone camera</strong> to login 📸
      </div>
      {!qrToken && (
        <div style={styles.loadingContainer}>
          <LoadingAnimation />
        </div>
      )}
      {!error ? (
        <div ref={refBlack} />
      ) : (
        <div style={styles.error} onClick={() => window.location.reload()}>
          {error}
        </div>
      )}
    </div>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: WIDTH,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: WIDTH,
    width: WIDTH,
  },
  error: {
    color: Colors.Red[700],
    cursor: 'pointer',
  },
};

export default QRCode;
