import React, { useState } from 'react';
import { Button } from 'react-admin';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

import Styles from '@components/crm/styles';
import Copyable from '@components/copyable';
import CRMTagChip from '@models/crm/crm_tags/components/chip';

type CRMOrganizationCardProps = {
  crmOrganization: any;
  onClick?: (entityType: string, entityId: string, entity: any) => void;
  ctaIcon?: any;
  ctaLabel?: string;
};
const CRMOrganizationCard = ({
  crmOrganization,
  onClick,
  ctaIcon,
  ctaLabel,
}: CRMOrganizationCardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const renderImage = () => {
    if (!crmOrganization.imageUrl) {
      const name = crmOrganization.name || crmOrganization.email;
      return (
        <img
          style={Styles.crmEntity.image}
          src={`https://ui-avatars.com/api?name=${name}&length=1&background=EFEFEF`}
        />
      );
    }
    return <img style={Styles.crmEntity.image} src={crmOrganization.imageUrl} />;
  };

  const renderName = () => (
    <div style={{ fontWeight: 600, textTransform: 'capitalize' }}>
      <Copyable text={crmOrganization.name?.trim() || 'No organization name'} />
    </div>
  );

  const renderEmail = () => {
    if (!crmOrganization.email) {
      return null;
    }
    return (
      <div style={{ ...Styles.flexRow, marginBottom: 8, fontSize: 12 }}>
        ✉️ <Copyable text={crmOrganization.email.trim()} />
      </div>
    );
  };

  const renderWebsite = () => {
    if (!crmOrganization.websiteUrl) {
      return null;
    }
    return (
      <div style={{ ...Styles.flexRow, marginBottom: 8, fontSize: 12 }}>
        🧑‍💻{' '}
        <Copyable
          text={crmOrganization.websiteUrl
            .trim()
            .replace('https://', ''.replace('http://', ''))}
        />
      </div>
    );
  };

  const renderLocation = () => {
    if (crmOrganization.location === null) {
      return null;
    }
    return (
      <div
        style={{
          fontSize: 12,
          textTransform: 'capitalize',
          marginBottom: 8,
        }}
      >
        📍 <span style={{ marginLeft: 8 }}>{crmOrganization.location?.trim()}</span>
      </div>
    );
  };

  const renderTags = () => {
    if (!crmOrganization.crmTags || crmOrganization.crmTags.length === 0) {
      return null;
    }
    return (
      <div style={{ ...Styles.flexRow, ...Styles.crmEntity.tagsContainer }}>
        {crmOrganization.crmTags.map((tag: any) => (
          <CRMTagChip key={tag.id} tag={tag} />
        ))}
      </div>
    );
  };

  const renderSocial = (url: string, icon: JSX.Element) => {
    if (!url) {
      return null;
    }
    return (
      <a
        style={{
          ...Styles.flexRow,
          marginRight: 8,
          color: '#555',
          textDecoration: 'none',
          fontSize: '0.8em',
        }}
        href={url}
        target="_blank"
        onClick={e => !!onClick && e.preventDefault()}
      >
        {React.cloneElement(icon, {
          htmlColor: '#555',
        })}
      </a>
    );
  };

  const renderSocials = () => (
    <div style={{ ...Styles.flexRow, marginTop: 8 }}>
      {renderSocial(crmOrganization.facebookUrl, <FacebookIcon />)}
      {renderSocial(crmOrganization.instagramUrl, <InstagramIcon />)}
      {renderSocial(crmOrganization.linkedinUrl, <LinkedInIcon />)}
      {renderSocial(crmOrganization.threadUrl, <div>Thread</div>)}
      {renderSocial(crmOrganization.tiktokUrl, <div>TikTok</div>)}
      {renderSocial(crmOrganization.twitterUrl, <TwitterIcon />)}
    </div>
  );

  const onClickHandler = () => {
    if (!onClick) {
      window.location.href = `#/crm_organizations/${crmOrganization.id}/show`;
      return;
    }
    return onClick('crm_organizations', crmOrganization.id, crmOrganization);
  };

  return (
    <div
      style={{
        ...Styles.crmEntity.container,
        ...(isHovered ? Styles.crmEntity.containerHover : null),
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{ ...Styles.flexRow, justifyContent: 'space-between' }}>
        <div style={{ ...Styles.flexRow, marginBottom: 8 }}>
          {renderImage()}
          {renderName()}
        </div>
        {(ctaLabel || ctaIcon) && (
          <Button
            style={Styles.flexRow}
            label={ctaLabel}
            startIcon={ctaIcon}
            onClick={onClickHandler}
          />
        )}
      </div>
      {renderLocation()}
      {renderEmail()}
      {renderWebsite()}
      {renderSocials()}
      {renderTags()}
    </div>
  );
};

export default CRMOrganizationCard;
