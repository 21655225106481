import { ActionType } from './action';
import { CommentMediaType } from './comment_media';
import { TopicType } from './topic';
import { TopicHighlightType } from './topic_highlight';
import { UserType } from './user';
import { UserCommentReactionType } from './user_comment_reaction';
import { WinType } from './win';

export enum CommentEntityType {
  ACTION = 'ACTION',
  TOPIC = 'TOPIC',
  WIN = 'WIN',
}

export type CommentType = {
  id: string;
  actionId: ActionType['id'];
  winId: WinType['id'];
  authorId: UserType['id'];
  author?: UserType;
  parentCommentId?: CommentType['id'];
  entityType: CommentEntityType;

  createdAt: string;
  updatedAt: string;

  message?: string;
  messageSanitized?: string;
  messageForSpies?: string;
  silentMessageEN?: string;
  silentMessageFR?: string;

  action?: ActionType;
  medias?: CommentMediaType[];
  reactions?: UserCommentReactionType[];
  highlight?: TopicHighlightType;
  replies?: CommentType[];
  topic?: TopicType;
  win?: WinType;
};
