import * as React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `actions/${data.actionId}/show`;

export const ActionCtaShareLinkCreate = () => (
  <Create redirect={redirectAfterCreate}>
    <SimpleForm>
      <CustomReferenceInput
        source="actionId"
        reference="actions"
        queryKey="descriptionEN"
        validate={[required()]}
        disabled
      />
      <TextInput source="link" required fullWidth />
    </SimpleForm>
  </Create>
);
