import { z } from 'zod';

export enum SocialPlatformComment {
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  FACEBOOK = 'facebook',
  TIKTOK = 'tiktok',
  SNAPCHAT = 'snapchat',
}

export enum ActionCTAType {
  APP_PAGE = 'app_page',
  EMAIL = 'email',
  EXTERNAL_LINK = 'external_link',
  GOOGLE_MAPS = 'google_maps',
  NOTHING = 'nothing',
  PHONE_CALL = 'phone_call',
  QUESTION = 'question',
  SHARE_LINK = 'share_link',
  SOCIAL_COMMENT = 'social_comment',
  SOCIAL_POST = 'social_post',
  WEBPAGE = 'webpage',
}

export enum ActionDifficulty {
  Easy = 1,
  Medium = 2,
  Hard = 3,
}

// Reusable message array schema
const messageArraySchema = z.object({
  message1: z.string().min(1, 'At least one message is required'),
  message2: z.string().optional().nullable(),
  message3: z.string().optional().nullable(),
  message4: z.string().optional().nullable(),
  message5: z.string().optional().nullable(),
  message6: z.string().optional().nullable(),
  message7: z.string().optional().nullable(),
  message8: z.string().optional().nullable(),
  message9: z.string().optional().nullable(),
  message10: z.string().optional().nullable(),
});

// CTA Type-specific Schemas
const emailCtaSchema = z.object({
  to: z.string().min(1, 'Recipient required'),
  cc: z.string().optional().nullable(),
  bcc: z.string().optional().nullable(),
  subject: z.string().min(1, 'Subject required').max(100, 'Subject too long'),
  body: z.string().min(1, 'Body required').max(5000, 'Body too long'),
  summaryEN: z.string().min(1, 'English summary required').max(500, 'Summary too long'),
  summaryFR: z.string().min(1, 'French summary required').max(500, 'Summary too long'),
  country: z.string().length(2, 'Invalid country code').optional().nullable(),
});

const socialCommentSchema = z
  .object({
    postUrl: z.string().url('Must be a valid social media URL'),
    socialPlatform: z.nativeEnum(SocialPlatformComment),
    ...messageArraySchema.shape,
  })
  .refine(data => {
    if (data.socialPlatform === SocialPlatformComment.INSTAGRAM) {
      return data.postUrl.includes('/p/');
    }
    if (data.socialPlatform === SocialPlatformComment.TWITTER) {
      return data.postUrl.includes('/status/');
    }
    if (data.socialPlatform === SocialPlatformComment.LINKEDIN) {
      return data.postUrl.includes('/posts/');
    }
    return true;
  });

const externalLinkSchema = z.object(
  {
    externalUrl: z
      .string({
        message: 'Must be a valid URL',
      })
      .url('Must be a valid URL'),
    ...messageArraySchema.shape,
    message1: z.string().optional().nullable(),
  },
  {
    message: 'You need to provide a valid URL',
  }
);

const googleMapsSchema = z.object({
  googleMapsUrl: z.string().url('Must be a valid Google Maps URL'),
  ...messageArraySchema.shape,
});

const phoneCallSchema = z.object({
  phoneNumber: z.string().min(1, 'Phone number required'),
  country: z.string().length(2, 'Invalid country code'),
  script: z.string().min(1, 'Script required').max(1000, 'Script too long'),
});

export enum ActionBuilderStepId {
  Owners = 'owners',
  Cause = 'cause',
  Content = 'content',
  CtaConfiguration = 'ctaConfiguration',
  Country = 'country',
  Difficulty = 'difficulty',
  Priority = 'priority',
  Points = 'points',
  Publishing = 'publishing',
}

// Step Schemas mapping
export const STEP_SCHEMAS = {
  [ActionBuilderStepId.Cause]: z.object({
    name: z.string().min(1, 'Action name is required'),
    causeId: z.string({ message: 'Must select a cause' }).min(1, 'Must select a cause'),
  }),
  [ActionBuilderStepId.Owners]: z.object({
    name: z.string().min(1, 'Action name is required'),
    owners: z.array(z.any()).min(1, 'Must have at least one owner'),
  }),
  [ActionBuilderStepId.Content]: z.object({
    titleEN: z.string().min(1, 'English title is required').max(100, 'Title too long'),
    titleFR: z.string().min(1, 'French title is required').max(100, 'Title too long'),
    descriptionEN: z
      .string()
      .min(1, 'English description is required')
      .max(300, 'Description too long'),
    descriptionFR: z
      .string()
      .min(1, 'French description is required')
      .max(300, 'Description too long'),
  }),

  [ActionBuilderStepId.CtaConfiguration]: z.discriminatedUnion('ctaType', [
    z.object({
      ctaType: z.literal(ActionCTAType.EMAIL),
      actionCtaEmails: z.array(emailCtaSchema).min(1, 'Must have at least one email'),
    }),
    z.object({
      ctaType: z.literal(ActionCTAType.SOCIAL_COMMENT),
      actionCtaSocialComments: z
        .array(socialCommentSchema)
        .min(1, 'Must have at least one comment'),
    }),
    z.object({
      ctaType: z.literal(ActionCTAType.EXTERNAL_LINK),
      actionCtaExternalLink: externalLinkSchema,
    }),
    z.object({
      ctaType: z.literal(ActionCTAType.GOOGLE_MAPS),
      actionCtaGoogleMaps: z
        .array(googleMapsSchema)
        .min(1, 'Must have at least one location'),
    }),
    z.object({
      ctaType: z.literal(ActionCTAType.PHONE_CALL),
      actionCtaPhoneCalls: z
        .array(phoneCallSchema)
        .min(1, 'Must have at least one phone call'),
    }),
    z.object({
      ctaType: z.literal(ActionCTAType.SHARE_LINK),
      actionCtaShareLink: z.object({
        shareLinkUrl: z.string().url('Must be a valid URL'),
        ...messageArraySchema.shape,
      }),
    }),
  ]),

  [ActionBuilderStepId.Country]: z.object({
    difficulty: z.nativeEnum(ActionDifficulty).default(ActionDifficulty.Medium),
  }),

  [ActionBuilderStepId.Difficulty]: z.object({
    difficulty: z.nativeEnum(ActionDifficulty).default(ActionDifficulty.Medium),
  }),

  [ActionBuilderStepId.Priority]: z.object({
    priority: z
      .number()
      .min(1, 'Minimum priority is 1')
      .max(5, 'Maximum priority is 5')
      .default(1),
  }),

  [ActionBuilderStepId.Points]: z.object({
    points: z.number().min(0, 'Points must be positive').default(10),
  }),

  [ActionBuilderStepId.Publishing]: z.object({
    publishedAt: z
      .date({ message: 'A publish date is required to finish' })
      .optional()
      .nullable(),
    expiresIn: z.number().min(1, 'Must have an expiration').optional().nullable(),
  }),
};

export type StepSchema = typeof STEP_SCHEMAS;

// Utils
export const validateStepCompletion = (
  stepId: ActionBuilderStepId,
  data: any
): boolean => {
  try {
    const schema = STEP_SCHEMAS[stepId];
    if (!schema) {
      return false;
    }

    schema.parse(data);
    return true;
  } catch (error) {
    return false;
  }
};

export const getCtaConfigSchema = (ctaType?: ActionCTAType) => {
  switch (ctaType) {
    case ActionCTAType.EMAIL:
      return emailCtaSchema;
    case ActionCTAType.SOCIAL_COMMENT:
      return socialCommentSchema;
    case ActionCTAType.EXTERNAL_LINK:
      return externalLinkSchema;
    case ActionCTAType.GOOGLE_MAPS:
      return googleMapsSchema;
    case ActionCTAType.PHONE_CALL:
      return phoneCallSchema;
    default:
      return z.object({}).optional();
  }
};
