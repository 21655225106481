import moment from 'moment-timezone';

/**
 *
 * @param date
 * @param short - if true, return short day of the week
 * @param withTime - if true, return time as well
 * @returns Day of the week - Month Day
 */
type DateFormatterOptions = {
  short?: boolean;
  withTime?: boolean;
  isUTC?: boolean;
};
export const dateFormatter = (date: string, options?: DateFormatterOptions) => {
  const { short = false, withTime = false, isUTC = false } = options || {};

  let dayFormat = short ? 'ddd' : 'dddd';
  let formatString = `${dayFormat}, MMM Do`;
  if (!moment(date).isSame(moment(), 'year')) {
    formatString = `${dayFormat}, MMM Do, YYYY`;
  }
  if (withTime) {
    formatString = `${formatString}, h:mma`;
  }
  if (isUTC) {
    return moment.utc(date).format(formatString);
  }
  return moment(date).format(formatString);
};

export const durationFormatter = (duration: number) => {
  return moment.duration(duration, 'days').humanize();
};
