import React, { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { ActionCTAType, ActionType } from 'types/action.d';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import ActionCTAEmoji from '@components/action_cta_emoji';
import TodoChecklist from '@components/todo_checklist';
import ActionCtaEmailsActionCard from '@models/action_ctas/emails/components/action_card';
import ActionCtaExternalLinkCreateEditDialog from '@models/action_ctas/external_links/components/create_update_dialog';
import ActionCtaGoogleMapsReviewActionCard from '@models/action_ctas/googlemaps_reviews/components/action_card_show';
import ActionCtaGoogleMapsReviewActionCardDialog from '@models/action_ctas/googlemaps_reviews/components/action_card_dialog';
import ActionCtaPhoneCallsActionCard from '@models/action_ctas/phone_calls/components/action_card';
import ActionCtaQuestionsActionCardDialog from '@models/action_ctas/question/components/action_card_dialog';
import ActionCtaQuestionsActionCardShow from '@models/action_ctas/question/components/action_card_show';
import ActionCtaSocialCommentsActionCard from '@models/action_ctas/social_comments/components/action_card_show';
import ActionCtaSocialCommentsActionCardDialog from '@models/action_ctas/social_comments/components/action_card_dialog';
import ActionCtaWebpageCreateEditDialog from '@models/action_ctas/webpages/components/create_update_dialog';
import ActionCtaShareLinkActionCard from '@models/action_ctas/share_links/components/action_card';
import ActionCtaExternalLinkActionCard from '@models/action_ctas/external_links/components/action_card_show';
import { selectCTAChoices } from '@models/actions/components/cta_type';
import { usersObjectiveValues } from '@models/actions/components/users_objective';
import { FontStyle } from '@styles/variables';

const CTACard = () => {
  const action: ActionType = useRecordContext();
  const { canUpdate: canUpdateFromRBP } = useRoleBasedPermissions();
  const canEdit: boolean = useMemo(() => canUpdateFromRBP('actions'), [canUpdateFromRBP]);

  const renderCTAType = () => {
    return (
      <TodoChecklist
        checked={!!action?.ctaType}
        label="Choose a type action"
        cta={
          canEdit && (
            <UpdateFieldDialog
              resource="actions"
              record={action}
              type="select"
              field="ctaType"
              buttonLabel={!action?.ctaType ? 'Choose a CTA type' : 'Edit'}
              buttonVariant="outlined"
              selectOptions={selectCTAChoices}
            />
          )
        }
      >
        {action?.ctaType && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <ActionCTAEmoji
              type={action.ctaType}
              style={{ fontSize: FontStyle.sizeBig }}
            />
            <div
              style={{
                fontSize: FontStyle.sizeVerySmall,
                fontWeight: 600,
              }}
            >
              {action?.ctaType?.replace(/_/gi, ' ')}
            </div>
          </div>
        )}
      </TodoChecklist>
    );
  };

  /**
   * @returns Action CTA Emails
   */
  const ActionCTAEmailsBlock = () => (
    <TodoChecklist checked={!!action?.actionCtaEmails?.length} label="Write some emails">
      <ActionCtaEmailsActionCard
        action={action}
        style={{ width: '100%', maxWidth: 800 }}
        canEdit={canEdit}
      />
    </TodoChecklist>
  );

  /**
   * @returns Action CTA External Link
   */
  const ActionCTAExternalLinkBlock = () => {
    return (
      <TodoChecklist
        checked={!!action?.actionCtaExternalLink}
        label="Set an URL"
        cta={canEdit && <ActionCtaExternalLinkCreateEditDialog action={action} />}
      >
        {action?.actionCtaExternalLink && (
          <ActionCtaExternalLinkActionCard action={action} />
        )}
      </TodoChecklist>
    );
  };

  /**
   * @returns Action CTA Google Maps Review
   */
  const ActionCTAGoogleMapsReview = () => (
    <TodoChecklist
      checked={!!action?.actionCtaGoogleMapsReview}
      label="Write reviews to a place on Google Maps"
      cta={canEdit && <ActionCtaGoogleMapsReviewActionCardDialog action={action} />}
    >
      {action?.actionCtaGoogleMapsReview && (
        <ActionCtaGoogleMapsReviewActionCard action={action} />
      )}
    </TodoChecklist>
  );

  /**
   * @returns Action CTA Social Comments
   */
  const ActionCTASocialComments = () => (
    <TodoChecklist
      checked={
        !!action?.actionCtaSocialComments && action?.actionCtaSocialComments.length > 0
      }
      label="Write comments to a social media post"
      cta={
        canEdit &&
        action.actionCtaSocialComments?.length === 0 && (
          <ActionCtaSocialCommentsActionCardDialog action={action} />
        )
      }
    >
      {action?.actionCtaSocialComments && action?.actionCtaSocialComments.length > 0 && (
        <ActionCtaSocialCommentsActionCard action={action} canEdit={canEdit} />
      )}
    </TodoChecklist>
  );

  /**
   * @returns Action CTA Website
   */
  const ActionCTAWebsiteBlock = () => (
    <TodoChecklist
      checked={!!action?.actionCtaWebpage}
      label="Configure a website"
      cta={canEdit && <ActionCtaWebpageCreateEditDialog action={action} />}
    >
      {action?.actionCtaWebpage && (
        <a
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            fontWeight: '600',
          }}
          href={action?.actionCtaWebpage?.websiteUrl}
          target="_blank"
        >
          {action?.actionCtaWebpage?.websiteUrl}
        </a>
      )}
    </TodoChecklist>
  );

  /**
   * @returns Action CTA Question
   */
  const ActionCTAQuestion = () => (
    <TodoChecklist
      checked={!!action?.actionCtaQuestion}
      label="Ask a question"
      cta={
        canEdit && (
          <ActionCtaQuestionsActionCardDialog
            action={action}
            actionCtaQuestion={action.actionCtaQuestion}
          />
        )
      }
    >
      {action?.actionCtaQuestion && <ActionCtaQuestionsActionCardShow action={action} />}
    </TodoChecklist>
  );

  /**
   * @returns Action CTA Phone Call
   */
  const ActionCTAPhoneCallsBlock = () => (
    <TodoChecklist
      checked={!!action?.actionCtaPhoneCalls?.length}
      label="Write some phone calls scripts"
    >
      <ActionCtaPhoneCallsActionCard
        action={action}
        style={{ width: '100%', maxWidth: 800 }}
        canEdit={canEdit}
      />
    </TodoChecklist>
  );

  /**
   * @returns Action CTA Share Link
   */
  const ActionCTAShareLinkBlock = () => (
    <TodoChecklist checked={!!action?.actionCtaShareLink} label="Instructions">
      <ActionCtaShareLinkActionCard action={action} />
    </TodoChecklist>
  );

  /**
   * @returns Action CTA per type
   */
  const renderTodoListPerType = () => {
    if (!action?.ctaType) {
      return <></>;
    }

    if (action?.ctaType === ActionCTAType.EMAIL) {
      return <ActionCTAEmailsBlock />;
    }
    if (action?.ctaType === ActionCTAType.EXTERNAL_LINK) {
      return <ActionCTAExternalLinkBlock />;
    }
    if (action?.ctaType === ActionCTAType.GOOGLE_MAPS) {
      return <ActionCTAGoogleMapsReview />;
    }
    if (action?.ctaType === ActionCTAType.SOCIAL_COMMENT) {
      return <ActionCTASocialComments />;
    }
    if (action?.ctaType === ActionCTAType.WEBPAGE) {
      return <ActionCTAWebsiteBlock />;
    }
    if (action?.ctaType === ActionCTAType.QUESTION) {
      return <ActionCTAQuestion />;
    }
    if (action?.ctaType === ActionCTAType.PHONE_CALL) {
      return <ActionCTAPhoneCallsBlock />;
    }
    if (action?.ctaType === ActionCTAType.SHARE_LINK) {
      return <ActionCTAShareLinkBlock />;
    }
  };

  const renderCTAObjective = () => {
    return (
      <TodoChecklist
        checked={!!action?.usersObjective}
        label="Objective to reach"
        cta={
          canEdit && (
            <UpdateFieldDialog
              resource="actions"
              record={action}
              type="select"
              field="usersObjective"
              buttonLabel={!action?.usersObjective ? 'Set an objective to reach' : 'Edit'}
              buttonVariant="outlined"
              selectOptions={usersObjectiveValues}
            />
          )
        }
      >
        <div
          style={{
            fontSize: FontStyle.sizeMedium,
            fontWeight: 600,
            lineHeight: 1,
            textAlign: 'center',
          }}
        >
          {action.usersObjective ? action.usersObjective + ' people' : 'No objective set'}
        </div>
      </TodoChecklist>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        width: '100%',
        columnSpan: 'all',
      }}
    >
      {renderCTAType()}
      {renderTodoListPerType()}
      {renderCTAObjective()}
    </div>
  );
};

export default CTACard;
