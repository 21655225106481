import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { SubtitleBlockType, SubtitleStudioContext } from '../context';
import { checkErrors } from '../services/subtitles.services';

type SubtitlePopoverProps = {
  subtitleBlockIndex: number;
};
const SubtitleTimelineBlock = ({ subtitleBlockIndex }: SubtitlePopoverProps) => {
  const { videoHtmlElement, subtitleBlocks, setSubtitleBlocks, widthPerSecond } =
    useContext(SubtitleStudioContext);
  const [subtitleBlock, setSubtitleBlock] = useState<SubtitleBlockType>();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isLeftBracketHovered, setIsLeftBracketHovered] = useState<boolean>(false);
  const [isRightBracketHovered, setIsRightBracketHovered] = useState<boolean>(false);
  const [currentVideoTime, setCurrentVideoTime] = useState<number>(0);
  const [previousSubtitleBlock, setPreviousSubtitleBlock] = useState<SubtitleBlockType>();
  const [nextSubtitleBlock, setNextSubtitleBlock] = useState<SubtitleBlockType>();
  const [errors, setErrors] = useState<string[]>([]);
  const isResizingLeft = useRef(false);
  const isResizingRight = useRef(false);
  const resizeStartX = useRef(0);
  const resizeStartWidth = useRef(0);

  useEffect(() => {
    if (!videoHtmlElement) {
      return;
    }
    videoHtmlElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => videoHtmlElement.removeEventListener('timeupdate', handleTimeUpdate);
  }, [videoHtmlElement]);

  useEffect(() => {
    if (!subtitleBlocks || subtitleBlockIndex === undefined) {
      return;
    }
    setSubtitleBlock(subtitleBlocks[subtitleBlockIndex]);
  }, [subtitleBlocks, subtitleBlockIndex]);

  useEffect(() => {
    if (!subtitleBlock || !subtitleBlocks || subtitleBlockIndex === undefined) {
      return;
    }
    if (subtitleBlockIndex > 0) {
      setPreviousSubtitleBlock(subtitleBlocks[subtitleBlockIndex - 1]);
    }
    if (subtitleBlockIndex < subtitleBlocks.length - 1) {
      setNextSubtitleBlock(subtitleBlocks[subtitleBlockIndex + 1]);
    }
    setErrors(checkErrors(subtitleBlock));
  }, [subtitleBlock, subtitleBlocks, subtitleBlockIndex]);

  /**
   * Handle the time update event on the video element
   * Set the current video time
   */
  const handleTimeUpdate = () => {
    if (!videoHtmlElement) {
      return;
    }
    setCurrentVideoTime(videoHtmlElement.currentTime);
  };

  /**
   * Get the width of the subtitle block in pixels
   */
  const subtitleBlockWidth = useMemo(() => {
    if (!subtitleBlock) {
      return 0;
    }
    return (subtitleBlock.endTime - subtitleBlock.startTime) * widthPerSecond;
  }, [subtitleBlock, subtitleBlock?.startTime, subtitleBlock?.endTime, widthPerSecond]);

  /**
   *  Check if the subtitle block is currently playing
   */
  const isCurrentlyPlaying = useMemo(() => {
    if (!subtitleBlock) {
      return false;
    }
    const startTime = Math.round(subtitleBlock.startTime * 100) / 100;
    const endTime = Math.round(subtitleBlock.endTime * 100) / 100;
    return currentVideoTime >= startTime && currentVideoTime < endTime;
  }, [subtitleBlock, subtitleBlock?.startTime, subtitleBlock?.endTime, currentVideoTime]);

  /**
   * Handle the mouse down event on the resize bracket
   * @param direction
   * @param event
   */
  const handleResizeMouseDown = (
    direction: 'left' | 'right',
    event: React.MouseEvent
  ) => {
    if (direction === 'left') {
      isResizingLeft.current = true;
    } else if (direction === 'right') {
      isResizingRight.current = true;
    }
    resizeStartX.current = event.clientX;
    resizeStartWidth.current = subtitleBlockWidth;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  /**
   * Handle the mouse down event
   * @param event
   */
  const handleMouseMove = (event: MouseEvent) => {
    if (!subtitleBlock) {
      return;
    }
    requestAnimationFrame(() => {
      if (isResizingLeft.current) {
        const newStartDate =
          subtitleBlock.startTime -
          (resizeStartX.current - event.clientX) / widthPerSecond;

        // Prevent the subtitle block from overlapping the next one
        // Prevent the subtitle block from overlapping the previous one
        if (
          (nextSubtitleBlock && newStartDate >= nextSubtitleBlock.startTime) ||
          (previousSubtitleBlock && newStartDate <= previousSubtitleBlock.endTime) ||
          newStartDate < 0
        ) {
          return;
        }
        subtitleBlock.startTime = newStartDate;
        setSubtitleBlock({ ...subtitleBlock });
        resizeStartX.current = event.clientX;
      } else if (isResizingRight.current) {
        const newEndDate =
          subtitleBlock.endTime - (resizeStartX.current - event.clientX) / widthPerSecond;

        // Prevent the subtitle block from overlapping the previous one
        // Prevent the subtitle block from overlapping the next one
        if (
          (nextSubtitleBlock && newEndDate >= nextSubtitleBlock.startTime) ||
          (previousSubtitleBlock && newEndDate <= previousSubtitleBlock.endTime)
        ) {
          return;
        }
        subtitleBlock.endTime = newEndDate;
        setSubtitleBlock({ ...subtitleBlock });
        resizeStartX.current = event.clientX;
      }
    });
  };

  /**
   * Handle the mouse up event
   */
  const handleMouseUp = async () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    isResizingLeft.current = false;
    isResizingRight.current = false;

    if (!subtitleBlock || !subtitleBlocks || subtitleBlockIndex === undefined) {
      return;
    }
    const newSubtitleBlocks = [...subtitleBlocks];
    newSubtitleBlocks[subtitleBlockIndex] = subtitleBlock;
    setSubtitleBlocks(newSubtitleBlocks);
  };

  /**
   * Handle the click event on the subtitle block
   */
  const onClick = () => {
    if (!videoHtmlElement || !subtitleBlock) {
      return;
    }
    videoHtmlElement.currentTime = subtitleBlock.startTime;
  };

  if (!subtitleBlock) {
    return null;
  }

  return (
    <div
      key={subtitleBlock.startTime}
      style={{
        ...StyleSheet.container,
        ...(isCurrentlyPlaying && StyleSheet.subtitleBlockCurrent),
        ...(isHovered && StyleSheet.subtitleBlockHovered),
        ...(errors.length > 0 && StyleSheet.subtitleBlockError),
        marginLeft: subtitleBlock.startTime * widthPerSecond,
        width: subtitleBlockWidth,
      }}
      onMouseDown={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          ...StyleSheet.subtitleBracket,
          ...(isLeftBracketHovered && StyleSheet.subtitleBracketHovered),
          left: 0,
        }}
        onMouseEnter={() => setIsLeftBracketHovered(true)}
        onMouseLeave={() => setIsLeftBracketHovered(false)}
        onMouseDown={e => handleResizeMouseDown('left', e)}
      />
      <div
        style={{
          ...StyleSheet.subtitleBracket,
          ...(isRightBracketHovered && StyleSheet.subtitleBracketHovered),
          right: 0,
        }}
        onMouseEnter={() => setIsRightBracketHovered(true)}
        onMouseLeave={() => setIsRightBracketHovered(false)}
        onMouseDown={e => handleResizeMouseDown('right', e)}
      />
      <div style={{ overflow: 'hidden' }}>{subtitleBlock.text}</div>
    </div>
  );
};

const StyleSheet: any = {
  container: {
    position: 'absolute',
    top: 2,
    bottom: 2,
    left: 0,
    display: 'flex',
    margin: 0,
    padding: 4,
    fontSize: 11,
    color: 'white',
    lineHeight: 1.2,
    backgroundColor: '#595EDA',
    borderRadius: 4,
    border: '1px solid #C6C5F8',
    cursor: 'pointer',
  },
  subtitleBlockCurrent: {
    backgroundColor: '#7276e8',
    border: '1px solid #fff',
  },
  subtitleBlockHovered: {
    border: '1px solid #fff',
  },
  subtitleBlockError: {
    border: '2px solid #f55',
    backgroundColor: 'rgba(255,100,100,.2)',
  },
  subtitleBracket: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 12,
    borderRadius: 2,
    cursor: 'ew-resize',
  },
  subtitleBracketHovered: {
    backgroundColor: 'rgba(0,0,0,.1)',
  },
};

export default SubtitleTimelineBlock;
