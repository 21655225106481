import React from 'react';
import { useEffect, useState } from 'react';
import { AutocompleteInput } from 'react-admin';
import dataProvider from 'src/dataProvider';

const CategoryInput = () => {
  const [choices, setChoices] = useState<{ id: string; name: string }[]>([]);

  const fetchCategories = async () => {
    const { data: categories } = await dataProvider.getCategories('profile_skills');
    setChoices(categories.map((category: string) => ({ id: category, name: category })));
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <AutocompleteInput
      source="category"
      fullWidth
      choices={choices}
      createLabel="Start typing to create a new category..."
      onCreate={(categoryName = 'none') => {
        const newCategory = { id: categoryName, name: categoryName };
        setChoices([...choices, newCategory]);
        return newCategory;
      }}
    />
  );
};

export default CategoryInput;
