import * as React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `topic_highlights/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const TopicHighlightEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <TextInput source="titleEN" label="Title EN" fullWidth />
      <TextInput source="subtitleEN" label="Subtitle EN" fullWidth />
      <hr />
      <TextInput source="titleFR" label="Title FR" fullWidth />
      <TextInput source="subtitleFR" label="Subtitle FR" fullWidth />
    </SimpleForm>
  </Edit>
);
