import React from 'react';
import { AutocompleteInput, required as RARequired } from 'react-admin';

const locales = [
  { id: 'da-DK', name: 'Danish (Denmark)' },
  { id: 'de-CH', name: 'German (Switzerland)' },
  { id: 'de-DE', name: 'German (Germany)' },
  { id: 'en-GB', name: 'English (United Kingdom)' },
  { id: 'en-US', name: 'English (United States)' },
  { id: 'en-ZA', name: 'English (South Africa)' },
  { id: 'es-ES', name: 'Spanish (Spain)' },
  { id: 'es-MX', name: 'Spanish (Mexico)' },
  { id: 'fi-FI', name: 'Finnish (Finland)' },
  { id: 'fr-BE', name: 'French (Belgium)' },
  { id: 'fr-CA', name: 'French (Canada)' },
  { id: 'fr-CH', name: 'French (Switzerland)' },
  { id: 'fr-FR', name: 'French (France)' },
  { id: 'it-IT', name: 'Italian' },
  { id: 'nb-NO', name: 'Norwegian' },
  { id: 'nl-BE', name: 'Dutch (Belgium)' },
  { id: 'nl-NL', name: 'Dutch (Netherlands)' },
  { id: 'pt-BR', name: 'Portuguese (Brazil)' },
  { id: 'pt-PT', name: 'Portuguese (Portugal)' },
  { id: 'sv-SE', name: 'Swedish (Sweden)' },
];

type LocaleInputProps = {
  source: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  fullWidth?: boolean;
};
const LocaleInput = ({
  source,
  label,
  required,
  disabled,
  helperText,
  fullWidth,
}: LocaleInputProps) => (
  <AutocompleteInput
    choices={locales}
    source={source}
    label={label}
    validate={required ? [RARequired()] : []}
    disabled={disabled}
    helperText={helperText}
    fullWidth={fullWidth}
    style={{
      minWidth: 300,
    }}
  />
);

export default LocaleInput;
