import * as React from 'react';
import { Datagrid, Filter, List, SearchInput, TextField } from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import { PhoneNumberField } from '@components/phone_number_field';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { Colors } from '@styles/variables';

import { UserAvatar } from './components/user_avatar';

const ListActions = (
  <ResourceTitleActionBar
    mode="list"
    filters={
      <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
        <small style={{ color: Colors.Grey.primary }}>Search by:</small>
        <Filter>
          <SearchInput source="username" alwaysOn placeholder="username" />
          <SearchInput source="phoneNumber" alwaysOn placeholder="phone number" />
        </Filter>
      </div>
    }
  />
);

export const UserList = () => (
  <List
    sort={{ field: 'username', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    exporter={false}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <UserAvatar label="Avatar" />
      <TextField source="username" />
      <PhoneNumberField source="phoneNumber" />
    </Datagrid>
  </List>
);
