import * as React from 'react';
import { Create, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { TagStyle } from './components/chip';
import ResourceTitleActionBar from 'src/components/resource_title_action_bar';

export const CRMTagColors = [
  '#FBF8CC',
  '#FDE4CF',
  '#FFCFD2',
  '#F1C0E8',
  '#CFBAF0',
  '#A3C4F3',
  '#90DBF4',
  '#8EECF5',
  '#98F5E1',
  '#B9FBC0',
  '#B9BBB8',
];

export const optionRenderer = (choice: any) => (
  <div
    style={{
      ...TagStyle,
      backgroundColor: choice.name,
    }}
  >
    {choice.name}
  </div>
);

const CreateActions = <ResourceTitleActionBar mode="create" resourceName="Tags" />;

export const CRMTagCreate = () => (
  <Create actions={CreateActions}>
    <SimpleForm>
      <TextInput source="name" required />
      <SelectInput
        source="color"
        required
        choices={CRMTagColors.map(color => {
          return { id: color, name: color };
        })}
        optionText={optionRenderer}
      />
    </SimpleForm>
  </Create>
);
