import React from 'react';
import {
  FormDataConsumer,
  ImageField,
  ImageInput,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';

import TranslateButton from '@components/translate_button';
import { SpacingStyle } from '@styles/variables';

import BadgeTypeInput from './badge_type_input';
import BadgePreview from './preview';

type BadgeFormProps = {
  type: 'create' | 'edit';
};
const BadgeForm = ({ type }: BadgeFormProps) => (
  <SimpleForm>
    <BadgeTypeInput validate={[required()]} />
    <NumberInput
      label="Badge Score"
      source="score"
      fullWidth
      helperText="The score required to unlock this badge. Leave empty if badge is not score-based."
    />
    <NumberInput
      label="Coins Reward"
      source="coinsAmount"
      fullWidth
      min={0}
      max={2000}
      helperText="The amount of coins the user will receive when unlocking this badge. Leave empty if badge doesn't have coin reward."
    />
    <TextInput
      label="Page"
      helperText="Once users have claimed the badge, they will be redirected to this page"
      source="page"
      fullWidth
    />
    <div style={styles.languagesContent}>
      <div>
        <div style={styles.title}>🇺🇸 English</div>
        <TextInput label="Name" source="nameEN" validate={[required()]} fullWidth />
        <TextInput
          label="Description EN"
          helperText="Displayed before user won it"
          source="descriptionEN"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="Congratulation message EN"
          helperText="Displayed when assigned to user"
          source="congratulationMessageEN"
          fullWidth
        />
      </div>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={styles.title}>🇫🇷 French</div>
          <TranslateButton
            keys={[
              { from: 'nameEN', to: 'nameFR' },
              { from: 'descriptionEN', to: 'descriptionFR' },
              { from: 'congratulationMessageEN', to: 'congratulationMessageFR' },
            ]}
          />
        </div>
        <TextInput label="Name FR" source="nameFR" validate={[required()]} fullWidth />
        <TextInput
          label="Description FR"
          helperText="Displayed before user won it"
          source="descriptionFR"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="Congratulation Message FR"
          helperText="Displayed when assigned to user"
          source="congratulationMessageFR"
          fullWidth
        />
      </div>
    </div>

    <br />
    <ImageField source="imageUrl" title="Image" />
    <ImageInput
      source="picture"
      accept="image/*"
      maxSize={5000000}
      validate={type === 'create' ? [required()] : []}
    >
      <ImageField source="src" title="title" />
    </ImageInput>

    {type === 'edit' && (
      <>
        <ColorInput label="Color 1" source="color1" />
        <ColorInput label="Color 2" source="color2" />
        <ColorInput label="Color 3" source="color3" />
      </>
    )}

    <br />
    <br />

    <div style={styles.title}>Preview</div>
    <br />
    <FormDataConsumer>
      {({ formData }) => <BadgePreview badge={formData as any} />}
    </FormDataConsumer>
  </SimpleForm>
);

const styles: any = {
  languagesContent: {
    display: 'flex',
    gap: SpacingStyle.big,
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    marginTop: SpacingStyle.normal,
  },
};

export default BadgeForm;
