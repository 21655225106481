import * as React from 'react';
import { Create, SelectInput, SimpleForm, TextInput, required } from 'react-admin';
import CustomReferenceInput from '../../components/inputs/custom_reference_input';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `videos/${data.videoId}/show/subtitles`;

const SRT_REGEX =
  /([0-9]+)\n([0-9]{2}:[0-9]{2}:[0-9]{2},[0-9]{3}) --> ([0-9]{2}:[0-9]{2}:[0-9]{2},[0-9]{3})\n(.+)/g;

/**
 * Validates the SRT format
 */
export const validateSRT = (value?: string) => {
  if (!value) {
    return null;
  }
  const errors = [];
  const srtLines = value.split('\n\n');
  for (const srtLine of srtLines) {
    if (!srtLine.match(SRT_REGEX)) {
      errors.push('#' + srtLine.split('\n')[0]);
    }
  }
  if (errors.length > 0) {
    return 'Invalid SRT, check the following blocks: ' + errors.join(', ');
  }
  return null;
};

export const SubtitleCreate = () => (
  <Create redirect={redirectAfterCreateEdit}>
    <SimpleForm>
      <CustomReferenceInput source="videoId" reference="videos" queryKey="name" />
      <SelectInput
        source="locale"
        choices={[
          { id: 'en', name: 'English' },
          { id: 'fr', name: 'French' },
          { id: 'es', name: 'Spanish' },
          { id: 'it', name: 'Italian' },
          { id: 'de', name: 'German' },
          { id: 'pt', name: 'Portuguese' },
          { id: 'ru', name: 'Russian' },
          { id: 'ja', name: 'Japanese' },
        ]}
        validate={[required()]}
      />
      <TextInput source="text" fullWidth multiline required validate={validateSRT} />
    </SimpleForm>
  </Create>
);
