import * as React from 'react';
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = <ResourceTitleActionBar mode="show" />;

export const SubtitleShow = () => (
  <Show actions={ShowActions}>
    <SimpleShowLayout>
      <ReferenceField source="videoId" reference="videos" link="show" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <TextField source="locale" />
      <TextField source="text" component="pre" style={{ whiteSpace: 'pre-wrap' }} />
    </SimpleShowLayout>
  </Show>
);
