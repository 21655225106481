import * as React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';

import { AppPageSelectInput } from './components/app_page_select_input';

const redirectAfterEdit = (_basePath: any, _id: any, data: any) =>
  `actions/${data.actionId}/show`;

export const ActionCtaAppPageEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic">
    <SimpleForm>
      <CustomReferenceInput source="actionId" reference="actions" disabled />
      <AppPageSelectInput />
    </SimpleForm>
  </Edit>
);
