import React from 'react';
import { SelectField } from 'react-admin';

const SocialPlatformField = ({ source }: any) => (
  <SelectField
    source={source}
    choices={[
      { id: 'instagram_feed', name: 'instagram_feed' },
      { id: 'instagram_stories', name: 'instagram_stories' },
      { id: 'facebook_stories', name: 'facebook_stories' },
      { id: 'twitter', name: 'twitter' },
    ]}
  />
);

export default SocialPlatformField;
