import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { ActionDifficulty, ActionType } from 'types/action.d';
import FeedbackIcon from '@mui/icons-material/Feedback';

import { dateFormatter } from '@services/date';
import { UserIcon } from '@components/icons';
import ActionCTATypeChip from '@components/action_cta_type_chip';
import CauseChip from '@models/causes/components/chip';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

type FloatingTagProps = {
  label: string | React.ReactNode;
  backgroundColor?: string;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  rotate?: number;
};
const FloatingTag = ({
  label,
  backgroundColor,
  top,
  right,
  bottom,
  left,
  rotate = 0,
}: FloatingTagProps) => {
  return (
    <div
      style={{
        ...floatingTag.content,
        backgroundColor,
        top,
        right,
        bottom,
        left,
        transform: `rotate(${rotate}deg)`,
      }}
    >
      <div style={floatingTag.label}>{label}</div>
    </div>
  );
};

type ActionCardProps = {
  action: ActionType;
  withCampaign?: boolean;
  withCause?: boolean;
};
const ActionCard = ({
  action,
  withCampaign = false,
  withCause = false,
}: ActionCardProps) => {
  const [hovered, setHovered] = useState<boolean>(false);

  /**
   * Calculate the health emoji
   * @returns {boolean}
   */
  const badHealth = useMemo(() => {
    const { score, totalFlowStarts } = action.statistics || {};
    if (!score || !totalFlowStarts || totalFlowStarts < 20) {
      return '';
    }

    return score <= 60;
  }, [action.statistics]);

  /**
   * Check if the action is expired
   * @returns {boolean}
   */
  const isExpired = useMemo(
    () =>
      moment(action.publishedAt)
        .add(action.expiresIn, 'days')
        .isBefore(moment(), 'minutes'),
    [action.publishedAt, action.expiresIn]
  );
  /**
   * Calculate the expiration date
   * @returns {Moment}
   */
  const expiresOn = useMemo(
    () => moment(action.publishedAt).add(action.expiresIn, 'days'),
    [action.publishedAt, action.expiresIn]
  );

  /**
   * Show expiry date if it is within a week
   */
  const showExpiryDate = useMemo(() => {
    return expiresOn.isSameOrBefore(moment().add(1, 'week'));
  }, [expiresOn]);

  /**
   * Go to the campaign page
   */
  const goToCampaign = useCallback(() => {
    if (action.campaignId) {
      window.location.href = `#/campaigns/${action.campaignId}/show`;
    }
  }, [action.campaignId]);

  /**
   * Go to the action page
   */
  const goToAction = useCallback(() => {
    if (action.id) {
      window.location.href = `#/actions/${action.id}/show`;
    }
  }, [action.id]);

  if (!action) {
    return;
  }

  return (
    <div
      key={action.id}
      style={{
        ...styles.action,
        ...(isExpired && styles.actionExpired),
        ...(hovered && styles.actionHovered),
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* Priority - All hands on deck tag */}
      {action?.priority && action.priority > 1 && (
        <FloatingTag
          label="all hands on deck"
          backgroundColor={Colors.Orange.primary}
          top={-8}
          left={2}
        />
      )}
      {/* Difficulty - easy tag */}
      {action?.difficulty && action.difficulty === ActionDifficulty.Easy && (
        <FloatingTag
          label="easy"
          backgroundColor={Colors.Blue.primary}
          top={-8}
          right={-4}
          rotate={8}
        />
      )}
      {/* Users feedback tag */}
      {action?.usersFeedbacksCount && action.usersFeedbacksCount > 0 && (
        <FloatingTag
          label={
            <>
              <FeedbackIcon style={{ fontSize: 'inherit' }} />
              {action.usersFeedbacksCount}
            </>
          }
          backgroundColor={Colors.Red.primary}
          bottom={-7}
          right={2}
        />
      )}

      {/* Campaign header */}
      {withCampaign && action.campaignId && (
        <div style={styles.header} onClick={() => goToCampaign()}>
          {action.campaign?.imageUrl && (
            <img
              src={action.campaign?.imageUrl}
              alt={action.campaign?.name}
              style={styles.campaignImage}
            />
          )}
          {action.campaign?.name && (
            <div style={styles.campaignName}>{action.campaign?.name}</div>
          )}
        </div>
      )}

      {/* Action's health */}
      {badHealth && (
        <div style={{ ...styles.header, ...styles.headerHealth }}>attention needed</div>
      )}

      <div style={styles.content} onClick={() => goToAction()}>
        <div
          style={{
            ...styles.description,
            ...(isExpired && { textDecoration: 'line-through' }),
          }}
        >
          {action.name || action.descriptionEN}
        </div>

        <ActionCTATypeChip ctaType={action.ctaType as any} size="small" />

        {/* Cause */}
        {withCause &&
          action.causes &&
          action.causes.length &&
          action.causes.map(cause => <CauseChip cause={cause} key={cause.id} />)}

        {/* Objectives */}
        <div
          style={{
            ...styles.objectives,
            color:
              (action.usersCount || 0) >= (action.usersObjective || 0)
                ? Colors.Red.primary
                : Colors.Grey[400],
          }}
        >
          <UserIcon size={FontStyle.sizeVerySmall} color={Colors.Grey[400]} />
          {action.usersCount || 0}
          {action.usersObjective && `/${action.usersObjective}`}
        </div>

        {/* Expires in */}
        {showExpiryDate && (
          <div
            style={{
              ...styles.expires,
              ...(expiresOn.isBefore(moment().add(3, 'day')) && styles.expiresSoon),
            }}
            title={dateFormatter(expiresOn.format('YYYY-MM-DD HH:mm'))}
          >
            {isExpired ? 'Expired' : `Expires ${expiresOn.fromNow()}`}
          </div>
        )}
      </div>
    </div>
  );
};

const styles: any = {
  action: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.Background.white,
    borderRadius: BorderStyle.Radius.small,
    border: `1px solid ${Colors.Black.transparent.max}`,
    cursor: 'default',
    // overflow: 'hidden',
  },
  actionExpired: {
    opacity: 0.5,
  },
  actionHovered: {
    backgroundColor: '#FDFBFD',
    cursor: 'pointer',
  },
  noAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 50,
    marginTop: SpacingStyle.small,
    fontStyle: 'italic',
    fontSize: FontStyle.sizeVeryVerySmall,
    color: Colors.Grey[300],
    textAlign: 'center',
  },
  priority: {
    position: 'absolute',
    top: 0,
    right: SpacingStyle[4],
    fontSize: FontStyle.sizeVerySmall,
    backgroundColor: Colors.Yellow.primary,
  },
  feedbacks: {
    position: 'absolute',
    bottom: -SpacingStyle[4],
    right: -SpacingStyle[2],
    padding: `0 ${SpacingStyle[4]}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: 600,
    backgroundColor: Colors.Red.primary,
    color: Colors.White.primary,
    borderRadius: BorderStyle.Radius.small,
    transform: 'rotate(-8deg)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: SpacingStyle[4],
    backgroundColor: Colors.Grey[100],
    padding: `${SpacingStyle[2]}px ${SpacingStyle[4]}px`,
    borderRadius: `${BorderStyle.Radius.small}px ${BorderStyle.Radius.small}px 0 0`,
  },
  headerHealth: {
    backgroundColor: Colors.Red.primary,
    justifyContent: 'center',
    color: Colors.White.primary,
    fontWeight: 600,
    fontSize: FontStyle.sizeVerySmall,
  },
  campaignImage: {
    width: 20,
    height: 20,
    objectFit: 'cover',
    borderRadius: BorderStyle.Radius.big,
  },
  campaignName: {
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 600,
    color: Colors.OffBlack.primary,
    lineHeight: 1.1,
  },
  expires: {
    fontSize: FontStyle.sizeVeryVerySmall,
    color: Colors.Grey[300],
  },
  expiresSoon: {
    fontSize: FontStyle.sizeVeryVerySmall,
    color: Colors.Orange[500],
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: SpacingStyle[4],
    gap: SpacingStyle[8],
  },
  description: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    lineClamp: 2,
    overflow: 'hidden',
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 500,
    color: Colors.OffBlack.primary,
    lineHeight: 1.1,
  },
  objectives: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle[4],
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: 500,
    color: Colors.Grey[400],
  },
};

const floatingTag: any = {
  content: {
    position: 'absolute',
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: 600,
    backgroundColor: Colors.Black.primary,
    color: Colors.White.primary,
    padding: `0 ${SpacingStyle[4]}px`,
    borderRadius: BorderStyle.Radius.small,
    transform: 'rotate(10deg)',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: SpacingStyle[2],
  },
};

export default ActionCard;
