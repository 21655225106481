import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import moment from 'moment-timezone';

import LoadingAnimation from '../../../../components/svgs/loading_animation';
import Styles from './styles';

type WeeklyEvolutionActiveUsersProps = {
  campaign: any;
};
export const WeeklyEvolutionActiveUsers = ({
  campaign,
}: WeeklyEvolutionActiveUsersProps) => {
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [pastWeekActiveUsers, setPastWeekActiveUsers] = useState(0);
  const [currentWeekActiveUsers, setCurrentWeekActiveUsers] = useState(0);

  useEffect(() => {
    fetchMetrics(campaign.id);
  }, [campaign.id]);

  const fetchMetrics = async (campaignId: string) => {
    setIsLoading(true);
    const { data: analyticsPastWeek } = await dataProvider.getCampaignAnalytics({
      campaignId,
      startDate: moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
    });
    setPastWeekActiveUsers(analyticsPastWeek?.total?.countUniqueUsers || 0);

    const { data: analyticsCurrentWeek } = await dataProvider.getCampaignAnalytics({
      campaignId,
      startDate: moment().startOf('week').format('YYYY-MM-DD'),
      endDate: moment().endOf('day').format('YYYY-MM-DD'),
    });
    setCurrentWeekActiveUsers(analyticsCurrentWeek?.total?.countUniqueUsers || 0);
    setIsLoading(false);
  };

  const computePercentage = () => {
    if (pastWeekActiveUsers === 0 && currentWeekActiveUsers === 0) {
      return 0;
    }
    if (pastWeekActiveUsers === 0) {
      return 100;
    }
    return (
      ((currentWeekActiveUsers - pastWeekActiveUsers) * 100) / pastWeekActiveUsers || 0
    );
  };

  if (isLoading) {
    return (
      <div style={Styles.card}>
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <div style={Styles.card}>
      <div style={Styles.cardNumber}>
        <span
          style={
            pastWeekActiveUsers === 0 && currentWeekActiveUsers === 0
              ? Styles.grey
              : pastWeekActiveUsers > currentWeekActiveUsers
                ? Styles.red
                : Styles.green
          }
        >
          {pastWeekActiveUsers < currentWeekActiveUsers && '+'}
          {Math.round(computePercentage())}%
          {currentWeekActiveUsers !== 0
            ? pastWeekActiveUsers > currentWeekActiveUsers
              ? '▾'
              : '▴'
            : ''}
        </span>
      </div>
      <div style={Styles.cardTitle}>
        evolution of active users
        <br /> compared to last week
      </div>
      <div style={Styles.cardNumberSubtitle}>
        (Past week: {pastWeekActiveUsers} / Current week: {currentWeekActiveUsers})
      </div>
    </div>
  );
};
