import React, { useCallback, useMemo } from 'react';
import { useRedirect } from 'react-admin';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from 'src/styles/variables';
import { CauseType } from 'types/cause';

type CauseChipProps = {
  cause?: CauseType | null;
  clickable?: boolean | 'campaigns' | string;
  locale?: 'en' | 'fr';
  size?: 'base' | 'lg';
};
const CauseChip = ({
  cause,
  clickable,
  locale = 'en',
  size = 'base',
}: CauseChipProps) => {
  const redirect = useRedirect();

  /**
   * Determines if the color of the cause is dark or light.
   * @returns {boolean}
   */
  const isColorDark = useMemo(() => {
    if (!cause?.color) {
      return false;
    }
    const hex = cause.color;
    if (!hex) {
      return false;
    }
    const r = parseInt(hex.substr(1, 2), 16);
    const g = parseInt(hex.substr(3, 2), 16);
    const b = parseInt(hex.substr(5, 2), 16);
    return r * 0.299 + g * 0.587 + b * 0.114 < 186;
  }, [cause?.color]);

  /**
   * Redirects to the cause's show page.
   */
  const onClickHandler = useCallback(() => {
    if (!clickable || !cause?.id) {
      return;
    }
    let redirectTo = `/causes/${cause.id}/show`;
    if (clickable === 'campaigns') {
      redirectTo += '/' + clickable;
    }
    if (typeof clickable === 'string') {
      redirect(clickable);
    } else {
      redirect(redirectTo);
    }
  }, [cause?.id, clickable, redirect]);

  if (!cause?.id) {
    return null;
  }

  return (
    <div
      style={{
        ...styles.container,
        ...(size === 'lg' ? styles.containerLarge : null),
        ...(clickable ? { cursor: 'pointer' } : null),
        backgroundColor: cause.color,
        color: isColorDark ? 'rgba(255,255,255,.9)' : Colors.Black.transparent.light,
      }}
      onClick={onClickHandler}
    >
      {cause.emoji}{' '}
      {(locale === 'fr' ? cause.descriptionFR : cause.descriptionEN) || cause.name}
    </div>
  );
};

const styles: any = {
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    gap: SpacingStyle[4],
    padding: `${SpacingStyle[2]}px ${SpacingStyle[8]}px`,
    color: Colors.White.primary,
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    backgroundColor: Colors.White.primary,
    backgroundFilter: 'blur(2px)',
    borderRadius: BorderStyle.Radius.normal,
  },

  containerLarge: {
    padding: `${SpacingStyle[4]}px ${SpacingStyle[12]}px`,
    fontSize: FontStyle.sizeSmall,
  },
};

export default CauseChip;
