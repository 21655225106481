import React, { createContext, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { SubtitleType } from 'types/subtitle';
import { VideoType } from 'types/video';

import { parseFromSRT, parseToSRT } from './services/subtitles.services';

export type SubtitleBlockType = {
  startTime: number;
  endTime: number;
  text: string;
};

export type SubtitleStudioContextType = {
  video?: VideoType | null;
  setVideo: (video: VideoType) => void;
  videoHtmlElement?: HTMLVideoElement | null;
  setVideoHtmlElement: (videoHtmlElement: HTMLVideoElement) => void;
  selectedSubtitle?: SubtitleType | null;
  setSelectedSubtitle: (selectedSubtitle: SubtitleType) => void;
  subtitleBlocks?: SubtitleBlockType[] | null;
  setSubtitleBlocks: (subtitleBlocks: SubtitleBlockType[]) => void;
  widthPerSecond: number;
  setWidthPerSecond: (widthPerSecond: number) => void;
};

export const SubtitleStudioContext = createContext<SubtitleStudioContextType>({
  video: null,
  setVideo: () => {},

  videoHtmlElement: null,
  setVideoHtmlElement: () => {},

  selectedSubtitle: null,
  setSelectedSubtitle: () => {},

  subtitleBlocks: null,
  setSubtitleBlocks: () => {},

  widthPerSecond: 80,
  setWidthPerSecond: () => {},
});

export const SubtitleStudioProvider = ({ children }: any) => {
  const dataProvider = useDataProvider();
  const [video, setVideo] = useState<VideoType | null>(null);
  const [videoHtmlElement, setVideoHtmlElement] = useState<HTMLVideoElement>();
  const [selectedSubtitle, setSelectedSubtitle] = useState<SubtitleType>();
  const [subtitleBlocks, setSubtitleBlocks] = useState<SubtitleBlockType[]>([]);
  const [widthPerSecond, setWidthPerSecond] = useState<number>(80);

  useEffect(() => {
    if (selectedSubtitle) {
      const subtitleBlocks = parseFromSRT(selectedSubtitle);
      setSubtitleBlocks(subtitleBlocks);
    }
  }, [selectedSubtitle]);

  useEffect(() => {
    if (subtitleBlocks && subtitleBlocks.length > 0) {
      saveSubtitlesOnServer();
    }
  }, [subtitleBlocks]);

  /**
   * Convert the subtitleBlocks to SRT and save it on the server
   */
  const saveSubtitlesOnServer = async () => {
    if (!selectedSubtitle?.id) {
      return;
    }
    const text = parseToSRT(subtitleBlocks);
    await dataProvider.update('subtitles', {
      id: selectedSubtitle.id,
      data: { text },
    } as any);
  };

  return (
    <SubtitleStudioContext.Provider
      value={{
        video,
        setVideo,
        videoHtmlElement,
        setVideoHtmlElement,
        selectedSubtitle,
        setSelectedSubtitle,
        subtitleBlocks,
        setSubtitleBlocks,
        widthPerSecond,
        setWidthPerSecond,
      }}
    >
      {children}
    </SubtitleStudioContext.Provider>
  );
};
