import React, { useState } from 'react';
import { ActionType } from 'types/action';
import { Button } from 'react-admin';

import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import ActionCtaSocialCommentsActionCardDialog from './action_card_dialog';

type ActionCtaSocialCommentsActionCardShowProps = {
  action: ActionType;
  canEdit?: boolean;
};
const ActionCtaSocialCommentsActionCardShow = ({
  action,
  canEdit = true,
}: ActionCtaSocialCommentsActionCardShowProps) => {
  const [expendIndexBlock, setExpendIndexBlock] = useState<number>();
  if (!action) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {action?.actionCtaSocialComments?.map((socialComment, index) => (
        <div
          key={socialComment.id}
          style={{
            width: '100%',
            maxWidth: 500,
            backgroundColor: Colors.Background.white,
            padding: SpacingStyle.small,
            borderRadius: BorderStyle.Radius.small,
            margin: SpacingStyle.small,
          }}
        >
          <div style={styles.row}>
            <div style={styles.label}>Platform:</div>
            <div
              style={{
                fontSize: FontStyle.sizeVerySmall,
                textTransform: 'capitalize',
              }}
            >
              {socialComment.socialPlatform}
            </div>
          </div>

          <div
            style={{
              ...styles.row,
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <div style={styles.label}>Post URL:</div>
            <a
              href={socialComment?.postUrl}
              style={{ fontSize: FontStyle.sizeVerySmall }}
              target="_blank"
            >
              {socialComment?.postUrl}
            </a>
          </div>
          {socialComment?.message1 && (
            <div>
              <div style={styles.label}>Message 1:</div>
              <div style={styles.message}>{socialComment?.message1}</div>
            </div>
          )}
          {socialComment?.message2 && (
            <div>
              <div style={styles.label}>Message 2:</div>
              <div style={styles.message}>{socialComment?.message2}</div>
            </div>
          )}
          {socialComment?.message3 && (
            <div>
              <div style={styles.label}>Message 3:</div>
              <div style={styles.message}>{socialComment?.message3}</div>
            </div>
          )}
          {socialComment?.message4 && (
            <div>
              <div style={styles.label}>Message 4:</div>
              <div style={styles.message}>{socialComment?.message4}</div>
            </div>
          )}
          {socialComment?.message5 && (
            <div>
              <div style={styles.label}>Message 5:</div>
              <div style={styles.message}>{socialComment?.message5}</div>
            </div>
          )}
          {expendIndexBlock === index && (
            <>
              {socialComment?.message6 && (
                <div>
                  <div style={styles.label}>Message 6:</div>
                  <div style={styles.message}>{socialComment?.message6}</div>
                </div>
              )}
              {socialComment?.message7 && (
                <div>
                  <div style={styles.label}>Message 7:</div>
                  <div style={styles.message}>{socialComment?.message7}</div>
                </div>
              )}
              {socialComment?.message8 && (
                <div>
                  <div style={styles.label}>Message 8:</div>
                  <div style={styles.message}>{socialComment?.message8}</div>
                </div>
              )}
              {socialComment?.message9 && (
                <div>
                  <div style={styles.label}>Message 9:</div>
                  <div style={styles.message}>{socialComment?.message9}</div>
                </div>
              )}
              {socialComment?.message10 && (
                <div>
                  <div style={styles.label}>Message 10:</div>
                  <div style={styles.message}>{socialComment?.message10}</div>
                </div>
              )}
              {socialComment?.message11 && (
                <div>
                  <div style={styles.label}>Message 11:</div>
                  <div style={styles.message}>{socialComment?.message11}</div>
                </div>
              )}
              {socialComment?.message12 && (
                <div>
                  <div style={styles.label}>Message 12:</div>
                  <div style={styles.message}>{socialComment?.message12}</div>
                </div>
              )}
              {socialComment?.message13 && (
                <div>
                  <div style={styles.label}>Message 13:</div>
                  <div style={styles.message}>{socialComment?.message13}</div>
                </div>
              )}
              {socialComment?.message14 && (
                <div>
                  <div style={styles.label}>Message 14:</div>
                  <div style={styles.message}>{socialComment?.message14}</div>
                </div>
              )}
              {socialComment?.message15 && (
                <div>
                  <div style={styles.label}>Message 15:</div>
                  <div style={styles.message}>{socialComment?.message15}</div>
                </div>
              )}
              {socialComment?.message16 && (
                <div>
                  <div style={styles.label}>Message 16:</div>
                  <div style={styles.message}>{socialComment?.message16}</div>
                </div>
              )}
              {socialComment?.message17 && (
                <div>
                  <div style={styles.label}>Message 17:</div>
                  <div style={styles.message}>{socialComment?.message17}</div>
                </div>
              )}
              {socialComment?.message18 && (
                <div>
                  <div style={styles.label}>Message 18:</div>
                  <div style={styles.message}>{socialComment?.message18}</div>
                </div>
              )}
              {socialComment?.message19 && (
                <div>
                  <div style={styles.label}>Message 19:</div>
                  <div style={styles.message}>{socialComment?.message19}</div>
                </div>
              )}
              {socialComment?.message20 && (
                <div>
                  <div style={styles.label}>Message 20:</div>
                  <div style={styles.message}>{socialComment?.message20}</div>
                </div>
              )}
            </>
          )}
          <div style={styles.gptPromptContainer}>
            <div style={styles.label}>🤖 GPT Prompt used:</div>
            <div style={styles.gptPrompt}>{socialComment?.gptPrompt}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              fontSize: FontStyle.sizeVeryVerySmall,
            }}
          >
            {socialComment.message6 && (
              <Button
                label={expendIndexBlock === index ? 'Show less' : 'Show all'}
                onClick={() =>
                  setExpendIndexBlock(expendIndexBlock === index ? undefined : index)
                }
                variant="text"
                size="small"
                style={{
                  fontSize: FontStyle.sizeVeryVerySmall,
                }}
              />
            )}
            {canEdit && (
              <ActionCtaSocialCommentsActionCardDialog
                action={action}
                actionCtaSocialComment={socialComment}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const styles: any = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: SpacingStyle.small,
    paddingBottom: SpacingStyle.small,
    borderBottom: `1px solid ${Colors.Magenta[50]}`,
  },
  label: {
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey.primary,
  },
  message: {
    marginBottom: SpacingStyle.normal,
    fontSize: FontStyle.sizeVerySmall,
  },
  gptPromptContainer: {
    paddingTop: SpacingStyle.small,
    borderTop: `1px solid ${Colors.Magenta[50]}`,
  },
  gptPrompt: {
    paddingTop: SpacingStyle[4],
    fontSize: FontStyle.sizeVerySmall,
    lineHeight: 1.4,
    color: Colors.OffBlack.primary,
    whiteSpace: 'pre-wrap',
  },
};

export default ActionCtaSocialCommentsActionCardShow;
