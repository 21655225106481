import { Colors } from '@styles/variables';

export default {
  card: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    margin: 20,
    borderRadius: 8,
    backgroundColor: Colors.Background.light,
    border: `1px solid ${Colors.Grey[100]}`,
  },
  cardNumber: {
    fontSize: 40,
    lineHeight: '1.2em',
    fontWeight: 600,
  },
  cardNumberSubtitle: {
    fontSize: 12,
    fontWeight: 600,
    color: Colors.Grey[700],
  },
  cardTitle: {
    margin: '10px 0',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.2,
    color: Colors.Grey.primary,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  tableContent: {
    width: '100%',
    maxWidth: 1000,
  },
  tableHeader: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    marginRight: 10,
    fontSize: 12,
    color: 'rgba(0,0,0,.6)',
  },
  select: {
    width: 180,
    height: 30,
    borderRadius: 4,
    border: '1px solid #E0E0E2',
    padding: 5,
    fontSize: 12,
    outline: 'none',
    color: '#333',
    cursor: 'pointer',
  },
  table: {
    borderRadius: 4,
    overflow: 'hidden',
    borderSpacing: 0,
    broderCollapse: 'collapse',
    border: '.5px #EAEAEC solid',
    backgroundColor: 'white',
  },
  tableTh: {
    width: '20%',
    padding: '8px 4px',
    fontSize: 12,
    lineHeight: '1.2em',
    backgroundColor: '#FCFCFD',
    border: '.5px #EAEAEC solid',
    cursor: 'pointer',
  },
  tableTdTitle: {
    padding: 10,
    fontWeight: 600,
    fontSize: 13,
    color: '#333',
    textTransform: 'capitalize',
    border: '.5px #EAEAEC solid',
  },
  tableTd: {
    padding: 10,
    textAlign: 'center',
    border: '.5px #EAEAEC solid',
  },
  tableTrTotal: {
    backgroundColor: '#FCFCFD',
    fontWeight: 600,
  },
  grey: {
    color: Colors.Grey.primary,
  },
  red: {
    color: Colors.Red.primary,
  },
  green: {
    color: Colors.Green.primary,
  },
} as any;
