import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Button, useDataProvider, useRecordContext } from 'react-admin';
import { UserType } from 'types/user';
import { UserTransaction } from 'types/user_transaction';

const UserTransactionsTab = () => {
  const user = useRecordContext<UserType>();
  const dataProvider = useDataProvider();
  const [transactions, setTransactions] = useState<UserTransaction[]>([]);

  const fetchLatest = useCallback(async () => {
    if (!user?.id) {
      return;
    }

    const { data } = await dataProvider.getList('users_transactions', {
      filter: { userId: user.id },
      sort: { field: 'createdAt', order: 'DESC' },
      pagination: { page: 1, perPage: 100 },
    });

    setTransactions(data);
  }, [dataProvider, user.id]);

  useEffect(() => {
    fetchLatest();
  }, [fetchLatest]);

  if (!user) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <strong>Current Coin Balance: {user.points}</strong>
        <Button
          label="Add transaction"
          variant="outlined"
          href={`#/users_transactions/create?source={"userId":"${user.id}"}`}
        />
      </div>
      <table style={{ width: '100%' }} cellSpacing={0}>
        <thead style={{ textAlign: 'left' }}>
          <tr>
            <th>Type</th>
            <th>Coins</th>
            <th>Created At</th>
          </tr>
        </thead>
        {transactions.map((userPoint, index) => (
          <tr
            key={userPoint.id}
            style={{
              backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff',
            }}
          >
            <td>{userPoint.type}</td>
            <td>{userPoint.coinsAmount}</td>
            <td>{moment(userPoint.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
            <td>
              <Button
                label="Edit"
                href={`#/users_transactions?source={"userId":"${user.id}"}`}
              />
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default UserTransactionsTab;
