import * as React from 'react';
import { List, Datagrid, ReferenceField, TextField } from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';

export const ActionOwnerList = () => (
  <List perPage={PER_PAGE} pagination={<PER_PAGE_OPTIONS />} emptyWhileLoading>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField source="actionId" reference="actions" link="show" />
      <ReferenceField source="ownerId" reference="users" link="show" />
    </Datagrid>
  </List>
);
