import * as React from 'react';
import { Edit, required, SimpleForm, TextInput } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `users/${data.userId}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const UserProfilePromptEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <CustomReferenceInput
        source="userId"
        reference="users"
        queryKey="username"
        disabled
        validate={[required()]}
      />
      <CustomReferenceInput
        source="promptId"
        reference="profile_prompts"
        queryKey="questionEN"
        validate={[required()]}
      />
      <TextInput source="answer" fullWidth />
    </SimpleForm>
  </Edit>
);
