import React from 'react';
import { EditorConfig, GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react';

const GrammarlyEditorConfig: Partial<EditorConfig> = {
  activation: 'immediate',
};

const GrammarlyClientId = 'client_TgFsVZZwjKYdmpdoJwh1Tr';

type GrammarlyProps = {
  children: any;
};
const Grammarly = ({ children }: GrammarlyProps) => {
  return (
    <GrammarlyEditorPlugin
      clientId={GrammarlyClientId}
      config={GrammarlyEditorConfig}
      style={{ width: '100%' }}
    >
      {children}
    </GrammarlyEditorPlugin>
  );
};

export default Grammarly;
