import React, { useCallback, useMemo, useState } from 'react';
import { Button, useRecordContext, useRefresh } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { ActionType } from 'types/action';
import { FaHandFist as OwnerIcon } from 'react-icons/fa6';
import dataProvider from 'src/dataProvider';
import { ActionOwnerType } from 'types/action_owner';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import TodoChecklist from '@components/todo_checklist';
import UserChip from '@models/users/components/user_chip';
import { BorderStyle, Colors, SpacingStyle } from '@styles/variables';

const OwnersCard = () => {
  const action: ActionType = useRecordContext() as ActionType;
  const { canUpdate: canUpdateFromRBP } = useRoleBasedPermissions();
  const refresh = useRefresh();
  const [isLoading, setIsLoading] = useState(false);

  const canEdit: boolean = useMemo(
    () => canUpdateFromRBP('actions_owners'),
    [canUpdateFromRBP]
  );

  const hasOwners = useMemo(() => !!action?.owners?.length, [action?.owners]);

  /**
   * Removes an owner from the action.
   */
  const onRemoveOwner = useCallback(
    async (actionOwnerId: ActionOwnerType['id']) => {
      if (isLoading) {
        return;
      }
      if (action?.owners?.length && action.owners.length <= 1) {
        window.alert(
          'Action must have at least one owner, add another owner before removing this one.'
        );
        return;
      }
      setIsLoading(true);
      try {
        await dataProvider.delete('actions_owners', { id: actionOwnerId });
        refresh();
      } catch (error) {
        console.error('Error removing owner:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, action?.owners?.length, refresh]
  );

  return (
    <Card>
      <CardHeader
        avatar={<OwnerIcon />}
        title="Owner"
        subheader="Who is responsible for this action?"
      />
      <CardContent>
        <TodoChecklist
          checked={hasOwners}
          label="Add an owner"
          cta={
            canEdit && (
              <Button
                href={`#/actions_owners/create?source={"actionId":"${action.id}"}`}
                label={hasOwners ? 'Add another owner' : 'Add an owner'}
                startIcon={<AddIcon />}
                variant="outlined"
              />
            )
          }
        >
          {hasOwners && (
            <div style={styles.owners}>
              {action.owners?.map((owner, index) => (
                <div style={styles.owner} key={index}>
                  <UserChip
                    user={owner}
                    subtext={index === 0 ? 'primary owner' : 'co-owner'}
                  />
                  {canEdit && (
                    <CloseIcon
                      style={styles.closeIcon}
                      onClick={() => onRemoveOwner((owner as any).actionOwnerId)}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </TodoChecklist>
      </CardContent>
    </Card>
  );
};
const styles: any = {
  owners: {
    display: 'flex',
    gap: SpacingStyle[24],
    flexWrap: 'wrap',
  },
  owner: {
    position: 'relative',
    display: 'flex',
    gap: SpacingStyle[8],
    alignItems: 'center',
    backgroundColor: Colors.White.primary,
    paddingTop: SpacingStyle[4],
    paddingBottom: SpacingStyle[4],
    paddingLeft: SpacingStyle[4],
    paddingRight: SpacingStyle[12],
    borderRadius: BorderStyle.Radius.big,
    userSelect: 'none',
  },
  primaryOwner: {
    fontSize: '0.75rem',
    color: Colors.Grey.primary,
  },
  closeIcon: {
    position: 'absolute',
    right: -8,
    top: -4,
    backgroundColor: Colors.Black.primary,
    fontSize: 16,
    padding: 2,
    color: Colors.White.primary,
    borderRadius: '50%',
    cursor: 'pointer',
  },
};

export default OwnersCard;
