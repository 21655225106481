import * as React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `actions/${data.actionId}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const ActionOwnerEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <CustomReferenceInput source="actionId" reference="actions" queryKey="name" />
      <CustomReferenceInput source="ownerId" reference="users" queryKey="username" />
    </SimpleForm>
  </Edit>
);
