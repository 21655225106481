import * as React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `actions/${data.actionId}/show`;

export const ActionCtaShareLinkEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic">
    <SimpleForm>
      <CustomReferenceInput source="actionId" reference="actions" disabled />
      <TextInput source="link" required fullWidth />
    </SimpleForm>
  </Edit>
);
