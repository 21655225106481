import React, { useCallback, useEffect, useRef } from 'react';
import { useLogin, useNotify, useRedirect } from 'react-admin';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import 'firebase/compat/auth';
import compatApp from 'firebase/compat/app';

const firebaseConfig = {
  apiKey: 'AIzaSyBHIwzrF7vojdla5sTYBjIVor0-I7EcbWk',
  authDomain: 'regroop-auth.firebaseapp.com',
  projectId: 'regroop-auth',
  storageBucket: 'regroop-auth.appspot.com',
  messagingSenderId: '767538065298',
  appId: '1:767538065298:web:12f944c508445dc9e65b4b',
};

import Config from '../../config';

const PhoneNumber = () => {
  const login = useLogin();
  const redirect = useRedirect();
  const notify = useNotify();
  const uiRef = useRef<firebaseui.auth.AuthUI | null>(null);

  const signInSuccessHandler = useCallback(
    async (authResult: firebase.auth.UserCredential) => {
      const { user } = authResult;
      const firebaseIDToken = await user?.getIdToken();

      try {
        const { data } = await axios.post(`${Config.API_URL}/auth/firebase`, {
          idToken: firebaseIDToken,
        });
        await login({ authToken: data.data.authToken });
        return redirect('/');
      } catch (error) {
        notify('Invalid phone number', { type: 'error' });
      }
    },
    [login, notify, redirect]
  );

  useEffect(() => {
    compatApp.initializeApp(firebaseConfig);
    const auth = compatApp.auth();

    if (!uiRef.current) {
      uiRef.current =
        firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    }

    const uiConfig = {
      signInOptions: [firebase.auth.PhoneAuthProvider.PROVIDER_ID],
      callbacks: {
        signInSuccessWithAuthResult: (authResult: firebase.auth.UserCredential) => {
          signInSuccessHandler(authResult);
          // Return type determines whether we continue the redirect automatically or not
          return false;
        },
      },
    };

    uiRef.current.start('#firebaseui-auth-container', uiConfig);
    return () => uiRef?.current?.reset();
  }, [signInSuccessHandler]);

  return (
    <div style={styles.container}>
      <div id="firebaseui-auth-container" />
    </div>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
};

export default PhoneNumber;
