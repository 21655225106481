import * as React from 'react';
import { DateField, FunctionField, Show, SimpleShowLayout, TextField } from 'react-admin';
import ReactMarkdown from 'react-markdown';
import { TopicHighlight } from 'types/topic_highlight.d';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowHighlights = <ResourceTitleActionBar mode="show" />;

export const TopicHighlightShow = () => (
  <Show actions={ShowHighlights}>
    <SimpleShowLayout>
      <DateField
        source="createdAt"
        showTime
        label={`Posted at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />

      <FunctionField
        label="Original comment"
        render={(record: TopicHighlight) => (
          <ReactMarkdown>{record.comment?.message || ''}</ReactMarkdown>
        )}
      />
      <hr />

      {/* English */}
      <TextField source="titleEN" label="Title EN" />
      <TextField source="subtitleEN" label="Subtitle EN" />
      <hr />
      {/* French */}
      <TextField source="titleFR" label="Title FR" />
      <TextField source="subtitleFR" label="Subtitle FR" />
    </SimpleShowLayout>
  </Show>
);
