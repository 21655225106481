import * as React from 'react';
import { Create, required, SimpleForm } from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';

import { AppPageSelectInput } from './components/app_page_select_input';

const redirectAfterCreate = (_basePath: any, _id: any, data: any) =>
  `actions/${data.actionId}/show`;

export const ActionCtaAppPageCreate = () => (
  <Create redirect={redirectAfterCreate}>
    <SimpleForm>
      <CustomReferenceInput
        source="actionId"
        reference="actions"
        queryKey="descriptionEN"
        validate={[required()]}
        disabled
      />
      <AppPageSelectInput />
    </SimpleForm>
  </Create>
);
