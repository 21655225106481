import React from 'react';
import { PepperPillType } from 'types/pepper_pill';

import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

/**
 * Get the color of the pill based on the type
 */
export const pepperPillColor = (type: PepperPillType['type']) => {
  if (type?.toLowerCase() === 'happy') {
    return Colors.Green.primary;
  }
  if (type?.toLowerCase() === 'truth') {
    return Colors.Red.primary;
  }
  if (type?.toLowerCase() === 'detox') {
    return Colors.Blue.primary;
  }
  return Colors.Magenta.primary;
};

type PepperPillPreviewProps = {
  pepperPill: PepperPillType;
};
const PepperPillPreview = ({ pepperPill }: PepperPillPreviewProps) => {
  return (
    <div
      style={{
        ...styles.container,
        borderColor: pepperPillColor(pepperPill?.type),
      }}
    >
      {pepperPill.type && (
        <div
          style={{
            ...styles.type,
            backgroundColor: pepperPillColor(pepperPill?.type),
          }}
        >
          {pepperPill.type} pill
        </div>
      )}
      <img src={pepperPill.imageUrl} style={styles.image} />
      <div style={styles.block}>
        <div>
          <div style={styles.title}>{pepperPill.titleEN || 'set a title'}</div>
          <div style={styles.content}>{pepperPill.contentEN || 'content goes here'}</div>
        </div>
        {pepperPill.sourceUrl && <div style={styles.source}>Sources</div>}
      </div>
    </div>
  );
};

const styles: any = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: 320,
    minHeight: 600,
    backgroundColor: Colors.OffBlack.primary,
    borderRadius: BorderStyle.Radius.big,
    border: `5px solid ${Colors.Magenta.primary}`,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
    color: Colors.White.primary,
    overflow: 'hidden',
  },
  type: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: `${SpacingStyle[4]}px ${SpacingStyle[12]}px`,
    borderBottomRightRadius: BorderStyle.Radius.small,
    backgroundColor: Colors.Magenta.primary,
    fontSize: FontStyle.sizeSmall,
    fontWeight: 700,
    textTransform: 'lowercase',
    color: Colors.OffBlack.primary,
  },
  image: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    backgroundColor: Colors.Magenta[1000],
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: SpacingStyle.normal,
    flexGrow: 1,
  },
  title: {
    marginBottom: SpacingStyle.normal,
    fontSize: FontStyle.sizeBig,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  content: {
    fontSize: FontStyle.sizeMedium,
    fontWeight: 400,
    lineHeight: 1.2,
  },
  source: {
    fontSize: FontStyle.sizeSmall,
    fontWeight: 400,
    color: Colors.Grey.primary,
  },
};

export default PepperPillPreview;
