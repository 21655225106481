import React from 'react';
import {
  Button,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  SearchInput,
} from 'react-admin';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { CampaignType } from 'types/campaign';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import CauseChip from '@models/causes/components/chip';
import { Colors } from '@styles/variables';

import CampaignBlock from './components/campaign_block';

const CampaignListActions = (
  <ResourceTitleActionBar
    mode="list"
    filters={
      <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
        <Button
          startIcon={<PhoneIphoneIcon />}
          label="Preview onboarding"
          href="#/campaigns/onboarding"
          variant="outlined"
        />
        <Filter>
          <SearchInput source="name" alwaysOn placeholder="campaign name" />
        </Filter>
      </div>
    }
  />
);

export const CampaignList = () => {
  return (
    <List
      sort={{ field: 'name', order: 'ASC' }}
      perPage={PER_PAGE}
      pagination={<PER_PAGE_OPTIONS />}
      actions={CampaignListActions}
      exporter={false}
      emptyWhileLoading
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <FunctionField
          label="Campaign"
          render={(record: CampaignType) => <CampaignBlock campaign={record} />}
        />
        <FunctionField
          label="Causes"
          render={(record: CampaignType) => {
            if (!record.causes || record.causes.length === 0) {
              return (
                <small style={{ color: Colors.Red.primary, fontWeight: '600' }}>
                  no causes
                </small>
              );
            }
            return record.causes?.map(cause => (
              <CauseChip cause={cause} key={cause.id} />
            ));
          }}
        />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};
