import * as React from 'react';
import {
  ArrayField,
  BooleanField,
  Button,
  Datagrid,
  DateField,
  FunctionField,
  ImageField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import { TopicType, TopicTypes } from 'types/topic.d';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { UserAvatar } from '@models/users/components/user_avatar';
import { SpacingStyle } from '@styles/variables';

const ShowActions = <ResourceTitleActionBar mode="show" />;

const ShowLayout = () => {
  const topic: TopicType = useRecordContext();
  return (
    <TabbedShowLayout>
      <Tab label="Summary">
        <ImageField source="thumbnailUrl" label="Thumbnail" />

        <TextField source="nameEN" label="Name EN" />
        <TextField source="descriptionEN" label="description EN" />
        <br />
        <TextField source="nameFR" label="Name FR" />
        <TextField source="descriptionFR" label="description FR" />

        {topic?.type === TopicTypes.DirectMessage && (
          <FunctionField
            label="Members"
            render={(record: TopicType) =>
              record?.users?.map((user: any) => user.username).join(', ') || ''
            }
          />
        )}

        <br />
        <DateField
          source="createdAt"
          showTime
          label={`Created at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
        />
        <BooleanField source="isPublic" label="Is public?" />
        <TextField source="type" label="Type" />
        <Button
          variant="outlined"
          component="a"
          href={`#/topics/${topic?.id}/chat`}
          label="Read all comments"
          startIcon={<InsertCommentIcon />}
        />
      </Tab>
      <Tab label="members">
        <Button
          label="Add a user to this topic"
          href={`#/users_topics/create?source={"topicId":"${topic?.id}"}`}
          variant="outlined"
        />
        <ArrayField source="users" label="Members">
          <Datagrid bulkActionButtons={false}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: SpacingStyle[8],
                alignItems: 'center',
              }}
            >
              <UserAvatar />
              <TextField source="username" />
            </div>
            <DateField source="joinedAt" showTime />
            <FunctionField
              render={(record: any) => (
                <Button
                  label="remove from topic"
                  href={`#/users_topics/${record.userTopicId}`}
                  variant="outlined"
                />
              )}
            />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="Highlights">
        <ArrayField source="highlights" label="Highlights">
          <Datagrid
            bulkActionButtons={false}
            rowClick={(_, __, record) => `/topic_highlights/${record.id}/show`}
          >
            <TextField source="titleEN" label="Title EN" />
            <DateField source="createdAt" showTime />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  );
};

export const TopicShow = () => (
  <Show actions={ShowActions}>
    <ShowLayout />
  </Show>
);
