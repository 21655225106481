const EU_API_URL = 'https://api.chilli.club/admin';
const US_API_URL = 'https://api-us.chilli.club/admin';

const CONFIG = {
  development: {
    API_URL: 'http://localhost:5050/admin',
  },
  staging: {
    API_URL: 'https://api.chilli-staging.club/admin',
  },
  production: {
    API_URL: US_API_URL,
  },
};

const main = () => {
  if (CONFIG.hasOwnProperty(process.env.REACT_APP_ENV)) {
    return CONFIG[process.env.REACT_APP_ENV];
  }
  return CONFIG.development;
};

export default main();
