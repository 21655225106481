export default {
  borderBox: {
    boxSizing: 'border-box',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxSizing: 'border-box',
    gap: 12,
  },
  button: {
    background: '#f0f0f0',
    padding: 12,
    margin: 4,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: 10,
    fontWeight: 700,
    color: '#999',
    textTransform: 'uppercase',
    lineHeight: 1.8,
    marginTop: 12,
  },
  sectionTitle: {
    margin: '24px 0 4px',
    fontSize: 14,
    fontWeight: 700,
    color: '#333',
  },
  crmEntity: {
    placeholder: {
      fontSize: '0.7em',
      fontStyle: 'italic',
      color: 'rgba(0,0,0,.3)',
      textTransform: 'none',
    },
    container: {
      flex: 1,
      border: '1px solid #eaedf3',
      borderRadius: 8,
      padding: 12,
      marginBottom: 24,
    },
    containerHover: {
      border: '1px solid #daddf3',
    },
    tagsContainer: {
      marginTop: 8,
      paddingTop: 8,
      borderTop: '1px solid #eaedf3',
    },
    image: {
      borderRadius: 1000,
      height: 24,
      width: 24,
    },
  },
} as any;
