import React from 'react';
import { Datagrid, List, ReferenceField, TextField } from 'react-admin';
import { PER_PAGE, PER_PAGE_OPTIONS } from 'src/components/list';

export const CampaignOwnerList = () => (
  <List perPage={PER_PAGE} pagination={<PER_PAGE_OPTIONS />}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField source="ownerId" reference="users" link="show" />
      <ReferenceField source="campaignId" reference="campaigns" link="show" />
    </Datagrid>
  </List>
);
