import React, { useCallback, useState } from 'react';
import { CausePostType } from 'types/cause_post';

import CausePostPreview from '@models/causes_posts/components/preview';
import { BorderStyle, Colors, SpacingStyle } from '@styles/variables';

type CausePostCardProps = {
  causePost: CausePostType;
};

const CausePostCard = ({ causePost }: CausePostCardProps) => {
  const [hovered, setHovered] = useState<boolean>(false);

  /**
   * Go to the causePost page
   */
  const goToCausePost = useCallback(() => {
    window.location.href = `#/causes_posts/${causePost.id}/show`;
  }, [causePost.id]);

  return (
    <div
      style={{
        ...styles.container,
        ...(hovered ? styles.containerHovered : null),
      }}
      onClick={goToCausePost}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <CausePostPreview
        record={causePost}
        locale="en"
        size="small"
        withCause={false}
        style={styles.post}
      />
    </div>
  );
};

const styles: any = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: SpacingStyle[4],
    border: `1px solid ${Colors.Black.transparent.max}`,
    borderRadius: BorderStyle.Radius.small,
    overflow: 'hidden',
    userSelect: 'none',
    backgroundColor: Colors.White.primary,
  },
  containerHovered: {
    boxShadow: `0 1px 4px ${Colors.Black.transparent.max}`,
    border: `1px solid ${Colors.Black.transparent.medium}`,
    cursor: 'pointer',
  },
  post: {
    padding: 0,
  },
};

export default CausePostCard;
