import React, { useState } from 'react';
import { Button } from 'react-admin';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Styles from '../styles';
import InputStyle from '../../../styles/input';

type CRMCreateDialogStep3Props = {
  crmPerson?: any;
  setCrmPerson?: any;
  crmOrganization?: any;
  setCrmOrganization?: any;
  onClose: () => void;
  closeLabel?: string;
  isSubmitting?: boolean;
};
const CRMCreateDialogStep3 = ({
  crmPerson,
  setCrmPerson,
  crmOrganization,
  setCrmOrganization,
  onClose,
  closeLabel,
  isSubmitting,
}: CRMCreateDialogStep3Props) => {
  const [showSocials, setShowSocials] = useState(false);

  const onCrmPersonInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = event.target;
    const newEntity = {
      ...crmPerson,
      [id]: value,
    };
    setCrmPerson(newEntity);
  };

  const onCrmOrganizationInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = event.target;
    const newEntity = {
      ...crmOrganization,
      [id]: value,
    };
    setCrmOrganization(newEntity);
  };

  const renderCRMPerson = () => {
    if (!crmPerson) {
      return null;
    }
    return (
      <>
        <div style={Styles.sectionTitle}>General information</div>
        <label style={Styles.label}>
          email
          <input
            type="email"
            disabled
            style={InputStyle.input}
            id="email"
            value={crmPerson.email}
            onChange={onCrmPersonInputChange}
          />
        </label>
        <div style={Styles.flexRow}>
          <label style={Styles.label}>
            First name
            <input
              type="text"
              placeholder="First name"
              style={InputStyle.input}
              id="firstname"
              value={crmPerson.firstname}
              onChange={onCrmPersonInputChange}
            />
          </label>
          <label style={Styles.label}>
            Last name
            <input
              type="text"
              placeholder="Last name"
              style={InputStyle.input}
              id="lastname"
              value={crmPerson.lastname}
              onChange={onCrmPersonInputChange}
            />
          </label>
        </div>

        <label style={Styles.label}>
          Gender
          <select
            style={InputStyle.input}
            id="gender"
            value={crmPerson.gender}
            onChange={onCrmPersonInputChange}
          >
            <option value="na">N/A</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
            <option value="other">Other</option>
          </select>
        </label>
        <label style={Styles.label}>
          Location
          <input
            type="text"
            placeholder="Country"
            style={InputStyle.input}
            id="location"
            value={crmPerson.location}
            onChange={onCrmPersonInputChange}
          />
        </label>

        <div style={Styles.sectionTitle}>Employment</div>
        <div style={Styles.flexRow}>
          <input
            type="text"
            placeholder="Organization Name"
            style={InputStyle.input}
            id="organizationName"
            value={crmPerson.organizationName}
            onChange={onCrmPersonInputChange}
          />
          <input
            type="text"
            placeholder="Job Title"
            style={InputStyle.input}
            id="jobTitle"
            value={crmPerson.jobTitle}
            onChange={onCrmPersonInputChange}
          />
        </div>

        <div
          style={{ ...Styles.sectionTitle, cursor: 'pointer' }}
          onClick={() => setShowSocials(!showSocials)}
        >
          Socials
          {showSocials ? ' ▴' : ' ▸'}
        </div>
        {showSocials && (
          <>
            <label style={Styles.label}>
              Facebook URL
              <input
                type="text"
                placeholder="https://facebook.com/xxx"
                style={InputStyle.input}
                id="facebookUrl"
                value={crmPerson.facebookUrl}
                onChange={onCrmPersonInputChange}
              />
            </label>
            <label style={Styles.label}>
              Instagram URL
              <input
                type="text"
                placeholder="https://instagram.com/xxx"
                style={InputStyle.input}
                id="instagramUrl"
                value={crmPerson.instagramUrl}
                onChange={onCrmPersonInputChange}
              />
            </label>
            <label style={Styles.label}>
              Linkedin URL
              <input
                type="text"
                placeholder="https://linkedin.com/xxx"
                style={InputStyle.input}
                id="linkedinUrl"
                value={crmPerson.linkedinUrl}
                onChange={onCrmPersonInputChange}
              />
            </label>
            <label style={Styles.label}>
              Thread URL
              <input
                type="text"
                placeholder="https://thread.com/xxx"
                style={InputStyle.input}
                id="threadUrl"
                value={crmPerson.threadUrl}
                onChange={onCrmPersonInputChange}
              />
            </label>
            <label style={Styles.label}>
              Tiktok URL
              <input
                type="text"
                placeholder="https://tiktok.com/xxx"
                style={InputStyle.input}
                id="tiktokUrl"
                value={crmPerson.tiktokUrl}
                onChange={onCrmPersonInputChange}
              />
            </label>
            <label style={Styles.label}>
              Twitter URL
              <input
                type="text"
                placeholder="https://twitter.com/xxx"
                style={InputStyle.input}
                id="twitterUrl"
                value={crmPerson.twitterUrl}
                onChange={onCrmPersonInputChange}
              />
            </label>
          </>
        )}
      </>
    );
  };

  const renderCRMOrganization = () => {
    if (!crmOrganization) {
      return null;
    }
    return (
      <>
        <div style={Styles.sectionTitle}>General information</div>
        <label style={Styles.label}>
          Organization name
          <input
            type="text"
            autoFocus
            placeholder="Organization name"
            style={InputStyle.input}
            id="name"
            value={crmOrganization.name}
            onChange={onCrmOrganizationInputChange}
          />
        </label>
        <label style={Styles.label}>
          email
          <input
            type="text"
            placeholder="press@organization.com"
            style={InputStyle.input}
            id="email"
            value={crmOrganization.email}
            onChange={onCrmOrganizationInputChange}
          />
        </label>

        <label style={Styles.label}>
          Website URL
          <input
            type="text"
            placeholder="https://organization.com"
            style={InputStyle.input}
            id="websiteUrl"
            value={crmOrganization.websiteUrl}
            onChange={onCrmOrganizationInputChange}
          />
        </label>

        <label style={Styles.label}>
          Location
          <input
            type="text"
            placeholder="Country"
            style={InputStyle.input}
            id="location"
            value={crmOrganization.location}
            onChange={onCrmOrganizationInputChange}
          />
        </label>

        <div
          style={{ ...Styles.sectionTitle, cursor: 'pointer' }}
          onClick={() => setShowSocials(!showSocials)}
        >
          Socials
          {showSocials ? ' ▴' : ' ▸'}
        </div>
        {showSocials && (
          <>
            <label style={Styles.label}>
              Facebook URL
              <input
                type="text"
                placeholder="https://facebook.com/xxx"
                style={InputStyle.input}
                id="facebookUrl"
                value={crmOrganization.facebookUrl}
                onChange={onCrmOrganizationInputChange}
              />
            </label>
            <label style={Styles.label}>
              Instagram URL
              <input
                type="text"
                placeholder="https://instagram.com/xxx"
                style={InputStyle.input}
                id="instagramUrl"
                value={crmOrganization.instagramUrl}
                onChange={onCrmOrganizationInputChange}
              />
            </label>
            <label style={Styles.label}>
              Linkedin URL
              <input
                type="text"
                placeholder="https://linkedin.com/xxx"
                style={InputStyle.input}
                id="linkedinUrl"
                value={crmOrganization.linkedinUrl}
                onChange={onCrmOrganizationInputChange}
              />
            </label>
            <label style={Styles.label}>
              Thread URL
              <input
                type="text"
                placeholder="https://thread.com/xxx"
                style={InputStyle.input}
                id="threadUrl"
                value={crmOrganization.threadUrl}
                onChange={onCrmOrganizationInputChange}
              />
            </label>
            <label style={Styles.label}>
              Tiktok URL
              <input
                type="text"
                placeholder="https://tiktok.com/xxx"
                style={InputStyle.input}
                id="tiktokUrl"
                value={crmOrganization.tiktokUrl}
                onChange={onCrmOrganizationInputChange}
              />
            </label>
            <label style={Styles.label}>
              Twitter URL
              <input
                type="text"
                placeholder="https://twitter.com/xxx"
                style={InputStyle.input}
                id="twitterUrl"
                value={crmOrganization.twitterUrl}
                onChange={onCrmOrganizationInputChange}
              />
            </label>
          </>
        )}
      </>
    );
  };

  return (
    <div style={{ width: 500 }}>
      <DialogTitle>Review contact's information</DialogTitle>
      <DialogContent>
        {renderCRMPerson()}
        {renderCRMOrganization()}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => await onClose()}
          label={isSubmitting ? 'Saving...' : closeLabel || 'Save'}
          disabled={isSubmitting}
          autoFocus
        />
      </DialogActions>
    </div>
  );
};

export default CRMCreateDialogStep3;
