import React from 'react';
import { useRecordContext } from 'react-admin';
import { SpacingStyle } from '@styles/variables';
import { TopicType } from 'types/topic';
import TagIcon from '@mui/icons-material/Tag';
import LockIcon from '@mui/icons-material/Lock';

const TopicRecordRepresentation = () => {
  const topic = useRecordContext() as TopicType;

  return (
    <div style={{ display: 'flex', gap: SpacingStyle[4], alignItems: 'center' }}>
      {topic.isPublic ? (
        <TagIcon style={{ width: 18, height: 18 }} />
      ) : (
        <LockIcon style={{ width: 18, height: 18 }} />
      )}
      {topic.nameEN ? topic.nameEN : topic.users?.map(user => user.username).join(', ')}
    </div>
  );
};

export default TopicRecordRepresentation;
