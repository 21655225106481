import React from 'react';
import { ActionType } from 'types/action';

import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

type ActionCtaExternalLinkActionCardProps = {
  action: ActionType;
};
const ActionCtaExternalLinkActionCard = ({
  action,
}: ActionCtaExternalLinkActionCardProps) => {
  if (!action) {
    return null;
  }

  return (
    <div
      style={{
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 400,
      }}
    >
      <div style={{ fontSize: FontStyle.sizeVeryVerySmall }}>External Link:</div>
      <a
        href={action?.actionCtaExternalLink?.externalUrl}
        style={{ fontSize: FontStyle.sizeVerySmall }}
        target="_blank"
      >
        {action?.actionCtaExternalLink?.externalUrl}
      </a>
      {action?.actionCtaExternalLink?.message1 && (
        <div>
          <div style={styles.label}>Message 1:</div>
          <div style={styles.message}>{action?.actionCtaExternalLink?.message1}</div>
        </div>
      )}
      {action?.actionCtaExternalLink?.message2 && (
        <div>
          <div style={styles.label}>Message 2:</div>
          <div style={styles.message}>{action?.actionCtaExternalLink?.message2}</div>
        </div>
      )}
      {action?.actionCtaExternalLink?.message3 && (
        <div>
          <div style={styles.label}>Message 3:</div>
          <div style={styles.message}>{action?.actionCtaExternalLink?.message3}</div>
        </div>
      )}
      {action?.actionCtaExternalLink?.message4 && (
        <div>
          <div style={styles.label}>Message 4:</div>
          <div style={styles.message}>{action?.actionCtaExternalLink?.message4}</div>
        </div>
      )}
      {action?.actionCtaExternalLink?.message5 && (
        <div>
          <div style={styles.label}>Message 5:</div>
          <div style={styles.message}>{action?.actionCtaExternalLink?.message5}</div>
        </div>
      )}
      {action?.actionCtaExternalLink?.message6 && (
        <div>
          <div style={styles.label}>Message 6:</div>
          <div style={styles.message}>{action?.actionCtaExternalLink?.message6}</div>
        </div>
      )}
      {action?.actionCtaExternalLink?.message7 && (
        <div>
          <div style={styles.label}>Message 7:</div>
          <div style={styles.message}>{action?.actionCtaExternalLink?.message7}</div>
        </div>
      )}
      {action?.actionCtaExternalLink?.message8 && (
        <div>
          <div style={styles.label}>Message 8:</div>
          <div style={styles.message}>{action?.actionCtaExternalLink?.message8}</div>
        </div>
      )}
      {action?.actionCtaExternalLink?.message9 && (
        <div>
          <div style={styles.label}>Message 9:</div>
          <div style={styles.message}>{action?.actionCtaExternalLink?.message9}</div>
        </div>
      )}
      {action?.actionCtaExternalLink?.message10 && (
        <div>
          <div style={styles.label}>Message 10:</div>
          <div style={styles.message}>{action?.actionCtaExternalLink?.message10}</div>
        </div>
      )}
    </div>
  );
};

const styles: any = {
  label: {
    fontSize: FontStyle.sizeVeryVerySmall,
    color: Colors.Grey.primary,
    marginTop: SpacingStyle.normal,
  },
  message: { fontSize: FontStyle.sizeVerySmall },
};

export default ActionCtaExternalLinkActionCard;
