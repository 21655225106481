import { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';

export const useReloadPage = (): boolean => {
  const [isReloading, setIsReloading] = useState(false);
  const lastReloadTime = moment();

  const handleReload = useCallback(() => {
    if (moment().diff(lastReloadTime, 'hours') >= 12) {
      setIsReloading(true);
      window.location.reload();
    }
  }, [lastReloadTime]);

  useEffect(() => {
    window.addEventListener('focus', handleReload);
    return () => {
      window.removeEventListener('focus', handleReload);
    };
  }, [handleReload]);

  return isReloading;
};
