import * as React from 'react';
import {
  SimpleShowLayout,
  Show,
  DateField,
  ReferenceField,
  TextField,
} from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = <ResourceTitleActionBar mode="show" />;

export const UserProfilePromptShow = () => (
  <Show actions={ShowActions}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="userId" reference="users" fullWidth link="show" />
      <ReferenceField source="promptId" reference="profile_prompts" link="show" />
      <TextField source="answer" />
      <DateField
        source="createdAt"
        showTime
        label={`Created at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
      <DateField
        source="updatedAt"
        showTime
        label={`Updated at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
    </SimpleShowLayout>
  </Show>
);
