import React from 'react';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Button, useDataProvider } from 'react-admin';
import { useMutation } from 'react-query';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';

type PushNotifyUserButtonProps = {
  user: any;
};
export const PushNotifyUserButton = ({ user }: PushNotifyUserButtonProps) => {
  const dataProvider = useDataProvider();
  const { isSuperAdmin } = useRoleBasedPermissions();

  const { mutate, isLoading } = useMutation(
    ['sendUserPushNotification', user],
    async () => {
      let pushNotificationBody = window.prompt('Notification body');
      await dataProvider.sendUserPushNotification({
        userId: user.id,
        pushNotificationBody,
      });
    }
  );
  if (!isSuperAdmin) {
    return <></>;
  }
  return (
    <Button
      label="Send Push Notification"
      onClick={() => mutate()}
      children={<NotificationsActiveIcon />}
      disabled={isLoading}
      variant="outlined"
    />
  );
};

type AmplitudeButtonProps = {
  user: any;
};
export const AmplitudeButton = ({ user }: AmplitudeButtonProps) => {
  const handleOnClick = () => {
    window.open(
      `https://analytics.amplitude.com/regroop/project/408094/search/user_id%3D${user.id}`,
      '_blank'
    );
  };
  return (
    <Button
      label="Amplitude"
      onClick={handleOnClick}
      children={<QueryStatsIcon />}
      variant="outlined"
    />
  );
};
