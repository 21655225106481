import * as React from 'react';
import {
  Datagrid,
  DateField,
  ImageField,
  List,
  NumberField,
  TextField,
} from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';

const ListActions = <ResourceTitleActionBar mode="list" title="Donations" />;

export const DonationList = () => (
  <List
    exporter={false}
    sort={{ field: 'launchedAt', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ImageField source="avatarUrl" title="Avatar" />
      <TextField label="Name" source="nameEN" />
      <NumberField source="maxCoins" />
      <DateField source="launchedAt" />
      <DateField source="expiredAt" />
    </Datagrid>
  </List>
);
