import * as React from 'react';
import { SimpleForm, Create } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `donations/${data.donationId}/show`;

const CreateActions = (
  <ResourceTitleActionBar mode="create" title="DonationsRecipients" />
);

export const DonationRecipientCreate = () => (
  <Create redirect={redirectAfterCreate} actions={CreateActions}>
    <SimpleForm>
      <CustomReferenceInput source="donationId" reference="donations" queryKey="nameEN" />
      <CustomReferenceInput
        source="recipientId"
        reference="recipients"
        queryKey="username"
      />
    </SimpleForm>
  </Create>
);
