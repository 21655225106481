import * as React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import CustomReferenceInput from '../../components/inputs/custom_reference_input';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `users/${data.userId}/show/feature_flags`;

export const UsersFeatureFlagsEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic">
    <SimpleForm>
      <CustomReferenceInput source="userId" reference="users" queryKey="username" />
      <CustomReferenceInput
        source="featureFlagId"
        reference="feature_flags"
        queryKey="name"
      />
    </SimpleForm>
  </Edit>
);
