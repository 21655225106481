import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

const CRMCreateDialogStep2 = () => {
  return (
    <div style={{ width: 500 }}>
      <DialogTitle>Enriching contact card</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src="https://media.tenor.com/J5mgKEy-A4AAAAAC/mario-luigi-dancing.gif"
            width={55}
          />
          <small style={{ marginTop: 16, textAlign: 'center', color: '#999' }}>
            Wait a moment,
            <br />
            we're enriching the contact card...
          </small>
        </div>
      </DialogContent>
    </div>
  );
};

export default CRMCreateDialogStep2;
