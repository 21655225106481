import React from 'react';
import { BadgeType } from 'types/badge';

import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

type BadgePreviewProps = {
  badge: BadgeType;
};
const BadgePreview = ({ badge }: BadgePreviewProps) => {
  return (
    <div
      style={{
        ...styles.container,
        background: `linear-gradient(160deg, ${badge.color1} 0%, ${badge.color2} 100%)`,
      }}
    >
      <img
        src={(badge as any)?.picture?.src || badge.imageUrl}
        alt={badge.nameEN}
        style={styles.image}
      />
      <div style={styles.name}>{badge.nameEN}</div>
      <div style={styles.description}>{badge.descriptionEN}</div>
      <div style={{ ...styles.description, color: badge.color3 }}>earned today</div>
    </div>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: BorderStyle.Radius.normal,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    width: '100%',
    maxWidth: 220,
    height: 340,
    backgroundColor: Colors.Black.transparent.max,
  },
  image: {
    width: '50%',
    borderRadius: 100,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  },
  name: {
    fontSize: FontStyle.sizeBig,
    fontWeight: 700,
    marginTop: SpacingStyle.big,
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
    fontSize: FontStyle.sizeSmall,
    lineHeight: 1.1,
    fontWeight: 500,
    padding: `${SpacingStyle.normal}px`,
  },
};

export default BadgePreview;
