import React, { useEffect, useState } from 'react';
import { Button, useDataProvider, useRefresh } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ActionType } from 'types/action';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import Callout from '@components/callout';
import InputStyle from '@styles/input';
import { FontStyle, SpacingStyle } from '@styles/variables';

type ActionCtaGoogleMapsReviewActionCardDialogProps = {
  action: ActionType;
};
const ActionCtaGoogleMapsReviewActionCardDialog = ({
  action,
}: ActionCtaGoogleMapsReviewActionCardDialogProps) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { canCreate, canUpdate } = useRoleBasedPermissions();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [googleMapsUrl, setGoogleMapsUrl] = useState('');
  const [messages, setMessages] = useState<string[]>(['']);
  const [error, setError] = useState('');
  const [type, setType] = useState<'create' | 'edit'>('create');

  useEffect(() => {
    if (!action || !action.actionCtaGoogleMapsReview) {
      return;
    }
    if (action.actionCtaGoogleMapsReview) {
      setType('edit');
      setGoogleMapsUrl(action.actionCtaGoogleMapsReview?.googleMapsUrl || '');
      const newMessages = [];
      for (let i = 1; i <= 20; i++) {
        const message = (action.actionCtaGoogleMapsReview as any)[
          `message${i}`
        ] as string;
        if (message !== null && message !== undefined) {
          newMessages.push(message);
        }
      }
      if (newMessages.length > 0) {
        setMessages(newMessages);
      }
    } else {
      setType('create');
      setGoogleMapsUrl('');
      setMessages(['Love this place']);
    }
  }, [action, action?.actionCtaGoogleMapsReview]);

  const handleClickOpen = () => {
    setOpen(true);
    setIsSubmitting(false);
  };

  const handleClose = (withConfirmation = false) => {
    if (isSubmitting) {
      window.alert("You can't close this modal while the action is being saved.");
      return;
    }
    const confirmSentence =
      'Are you sure you want to close this modal?\nYour changes will be lost.';
    if (withConfirmation && !window.confirm(confirmSentence)) {
      return;
    }
    setOpen(false);
  };

  const saveCTA = async () => {
    if (!action || !!isSubmitting) {
      return;
    }
    if (!googleMapsUrl || messages.length === 0 || messages[0] === '') {
      return;
    }
    setIsSubmitting(true);
    try {
      if (type === 'create') {
        await dataProvider.create('action_cta_googlemaps_reviews', {
          data: {
            actionId: action.id,
            googleMapsUrl,
            message1: messages[0] || null,
            message2: messages[1] || null,
            message3: messages[2] || null,
            message4: messages[3] || null,
            message5: messages[4] || null,
            message6: messages[5] || null,
            message7: messages[6] || null,
            message8: messages[7] || null,
            message9: messages[8] || null,
            message10: messages[9] || null,
            message11: messages[10] || null,
            message12: messages[11] || null,
            message13: messages[12] || null,
            message14: messages[13] || null,
            message15: messages[14] || null,
            message16: messages[15] || null,
            message17: messages[16] || null,
            message18: messages[17] || null,
            message19: messages[18] || null,
            message20: messages[19] || null,
          },
        });
      } else if (type === 'edit') {
        await dataProvider.update('action_cta_googlemaps_reviews', {
          id: action.actionCtaGoogleMapsReview?.id,
          data: {
            googleMapsUrl,
            message1: messages[0] || null,
            message2: messages[1] || null,
            message3: messages[2] || null,
            message4: messages[3] || null,
            message5: messages[4] || null,
            message6: messages[5] || null,
            message7: messages[6] || null,
            message8: messages[7] || null,
            message9: messages[8] || null,
            message10: messages[9] || null,
            message11: messages[10] || null,
            message12: messages[11] || null,
            message13: messages[12] || null,
            message14: messages[13] || null,
            message15: messages[14] || null,
            message16: messages[15] || null,
            message17: messages[16] || null,
            message18: messages[17] || null,
            message19: messages[18] || null,
            message20: messages[19] || null,
          },
        } as any);
      }
      refresh();
      setOpen(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (
    !action ||
    !canCreate('action_cta_googlemaps_reviews') ||
    !canUpdate('action_cta_googlemaps_reviews')
  ) {
    return null;
  }

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        label={action.actionCtaGoogleMapsReview ? 'Edit' : 'Add'}
        endIcon={action.actionCtaGoogleMapsReview ? <EditIcon /> : <AddIcon />}
        size="small"
        style={{
          textTransform: 'none',
          fontSize: 'inherit',
          fontWeight: 'inherit',
        }}
      />
      <Dialog onClose={() => handleClose(true)} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>What is the action about?</DialogTitle>
        <DialogContent>
          <label style={{ fontSize: FontStyle.sizeVeryVerySmall }}>
            Google Maps URL:
            <input
              type="text"
              style={InputStyle.input}
              placeholder="ex: https://maps.app.goo.gl/DTb7kmpbr3K6JWbF6"
              value={googleMapsUrl}
              onChange={e => setGoogleMapsUrl(e.target.value)}
            />
          </label>
          <Callout backgroundColor={'yellow'} emoji="👇">
            You can add up to 20 different reviews, mentionning different topics.
            <br />
            GPT will randomly take one of them and generate a unique variation of it
            before posting it.
          </Callout>
          {messages.map((message, index) => (
            <label style={{ fontSize: FontStyle.sizeVeryVerySmall }} key={index}>
              Review {index + 1}:
              <div style={{ display: 'flex' }}>
                <textarea
                  style={InputStyle.input}
                  placeholder="ex: I love this place!"
                  value={message}
                  onChange={e => {
                    const newMessages = [...messages];
                    newMessages[index] = e.target.value;
                    setMessages(newMessages);
                  }}
                />
                {index > 0 && (
                  <Button
                    label="Delete"
                    startIcon={<DeleteOutlineIcon />}
                    onClick={() => {
                      const newMessages = [...messages];
                      newMessages.splice(index, 1);
                      setMessages(newMessages);
                    }}
                    disabled={messages.length <= 1 || index === 0}
                    style={{
                      marginLeft: 10,
                    }}
                  />
                )}
              </div>
            </label>
          ))}
          {messages.length < 20 && (
            <Button
              label="Add a review"
              startIcon={<AddIcon />}
              onClick={() => {
                const newMessages = [...messages];
                newMessages.push('');
                setMessages(newMessages);
              }}
              disabled={messages[messages.length - 1] === ''}
              style={{
                marginTop: SpacingStyle.small,
                marginBottom: SpacingStyle.normal,
              }}
            />
          )}
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={saveCTA}
            label={isSubmitting ? 'Saving...' : 'Save'}
            disabled={
              isSubmitting ||
              !googleMapsUrl?.startsWith('https://') ||
              messages[messages.length - 1].length < 4
            }
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActionCtaGoogleMapsReviewActionCardDialog;
