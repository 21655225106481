import React, { useContext, useEffect, useRef, useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { SubtitleStudioContext } from '../context';
import SubtitleVideoBlock from './subtitle_video_block';

const Video = () => {
  const { video, videoHtmlElement, setVideoHtmlElement } =
    useContext(SubtitleStudioContext);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    setVideoHtmlElement(videoRef.current);

    videoRef.current.addEventListener('play', () => {
      setIsVideoPlaying(true);
    });
    videoRef.current.addEventListener('pause', () => {
      setIsVideoPlaying(false);
    });
  }, [videoRef]);

  const handleVideoClick = () => {
    if (!videoHtmlElement) {
      return;
    }
    if (videoHtmlElement.paused) {
      videoHtmlElement.play();
    } else {
      videoHtmlElement.pause();
    }
  };

  if (!video || !video.videoUrl) {
    return <div></div>;
  }
  return (
    <div style={StyleSheet.container}>
      <video
        ref={videoRef}
        src={video.videoUrl}
        style={StyleSheet.video}
        poster={video.posterUrl}
        onClick={handleVideoClick}
      />
      <SubtitleVideoBlock />
      {!isVideoPlaying && (
        <div style={StyleSheet.playButton} onClick={handleVideoClick}>
          <PlayArrowIcon
            style={{
              fontSize: 60,
              color: '#fff',
            }}
          />
        </div>
      )}
    </div>
  );
};

const StyleSheet: any = {
  container: {
    position: 'relative',
    objectFit: 'cover',
  },
  video: {
    flex: 1,
    height: '100%',
    maxHeight: '50vh',
    borderRadius: 12,
    border: '1px solid #e6e6e7',
    boxShadow: '0 2px 12px rgba(0,0,0,.1)',
    cursor: 'pointer',
  },
  playButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
};

export default Video;
