import * as React from 'react';
import { List, Datagrid, ReferenceField, TextField } from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';

export const DonationRecipientList = () => (
  <List perPage={PER_PAGE} pagination={<PER_PAGE_OPTIONS />} emptyWhileLoading>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField source="donationId" reference="donations" link="show" />
      <ReferenceField source="recipientId" reference="recipients" link="show" />
    </Datagrid>
  </List>
);
