import * as React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `actions/${data.actionId}/show`;

export const ActionCtaExternalLinkCreate = () => (
  <Create redirect={redirectAfterCreate}>
    <SimpleForm>
      <CustomReferenceInput
        source="actionId"
        reference="actions"
        queryKey="descriptionEN"
        validate={[required()]}
        disabled
      />
      <TextInput source="externalUrl" fullWidth required />
      <TextInput source="message1" multiline fullWidth />
      <TextInput source="message2" multiline fullWidth />
      <TextInput source="message3" multiline fullWidth />
      <TextInput source="message4" multiline fullWidth />
      <TextInput source="message5" multiline fullWidth />
      <TextInput source="message6" multiline fullWidth />
      <TextInput source="message7" multiline fullWidth />
      <TextInput source="message8" multiline fullWidth />
      <TextInput source="message9" multiline fullWidth />
      <TextInput source="message10" multiline fullWidth />
    </SimpleForm>
  </Create>
);
