import React from 'react';
import { TextInput } from 'react-admin';

export const validateUrl = (value: string) => {
  if (!value) {
    return undefined;
  }
  if (
    !value.match(
      /^(http(s):\/\/)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/
    )
  ) {
    return 'Invalid URL';
  }
  return undefined;
};

export default ({ source, ...props }: any) => {
  return <TextInput source={source} {...props} fullWidth validate={validateUrl} />;
};
