import React from 'react';

export default ({ ...props }) => (
  <svg
    width="48"
    height="42"
    viewBox="0 0 48 42"
    fill="#140F14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.3393 38.9374C14.3393 40.4524 13.1136 41.6807 11.6017 41.6807C10.0898 41.6807 8.86409 40.4524 8.86409 38.9374C8.86409 37.4224 10.0898 36.1941 11.6017 36.1941C13.1136 36.1941 14.3393 37.4224 14.3393 38.9374ZM30.2474 29.5806L32.2888 31.5231C27.3744 34.3958 11.5642 30.4738 8.99037 36.165C3.86496 35.1499 0 30.6197 0 25.1855C0 18.6997 5.28695 12.1118 18.5 12.1118L17.2083 14.5508C21.3611 13.913 25.8801 11.9402 28.4272 6.72663L30.2747 8.68053C31.6602 7.03499 32.9378 4.19079 31.6272 0.675143C35.3005 -0.030494 39.951 1.4583 42.4469 5.00529L43.5702 2.73676C43.5702 2.73676 48 6.28376 48 12.5125C48 22.1332 38.649 29.5806 30.2479 29.5806H30.2474ZM34.1794 8.86862L43.5782 13.1879L44.3097 9.97153L35.3915 5.94804L34.1794 8.86805V8.86862ZM14.0987 29.0972L11.976 26.6731C11.5403 27.3582 10.8503 27.914 10.079 28.0832C8.53704 28.4224 7.0667 27.397 6.70381 25.7389C6.34092 24.0808 7.24815 22.5328 8.79015 22.1936C9.56144 22.0244 10.426 22.2067 11.1057 22.6958L12.0215 19.6042C12.0215 19.6042 10.1319 18.5287 7.90966 19.0171C4.55206 19.7553 2.59598 22.97 3.36271 26.4736C4.12945 29.9773 7.20094 32.0868 10.4943 31.3629C12.7802 30.8602 14.0993 29.0972 14.0993 29.0972H14.0987ZM23.7722 29.6062L21.5351 16.4585L18.227 17.5358L18.9829 21.3968L16.7282 21.9451L15.8096 17.2274L12.8701 17.8447L14.7648 30.1586L18.2828 29.9226L17.276 24.7546L19.5677 24.3801L20.6524 29.9226L23.7722 29.6062ZM28.9084 26.9336L25.9029 15.1755L22.8525 16.1724L25.7061 28.0827L28.9084 26.933V26.9336ZM37.5541 23.9099L36.0297 20.641L33.2364 22.4313L29.4203 13.3589L27.0752 14.8221L31.6272 26.7438L37.5535 23.9099H37.5541ZM40.1552 21.5889L44.0372 16.0464L41.485 14.1495L39.7536 17.0336L33.1038 10.24L30.8622 12.5325L40.1546 21.5883L40.1552 21.5889Z" />
  </svg>
);
