import React, { useMemo, useCallback } from 'react';
import { Button, useDataProvider, useRecordContext, useRefresh } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { ActionCTAType, ActionType } from 'types/action.d';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import TodoChecklist from '@components/todo_checklist';
import Callout from '@components/callout';
import LoadingAnimation from '@components/svgs/loading_animation';

const DescriptionsCard = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const action: ActionType = useRecordContext();
  const { canUpdate: canUpdateFromRBP } = useRoleBasedPermissions();
  const canEdit: boolean = useMemo(() => canUpdateFromRBP('actions'), [canUpdateFromRBP]);

  const [generating, setGenerating] = React.useState(false);

  const handleGenerate = useCallback(async () => {
    setGenerating(true);

    try {
      const { data } = await dataProvider.generateActionInformation({
        actionId: action.id,
      });

      if (data) {
        refresh();
      }
    } catch (error) {
      console.error('[DescriptionsCard.handleGenerate]', error);
    } finally {
      setGenerating(false);
    }
  }, [action.id, dataProvider, refresh]);

  if (!action) {
    return <></>;
  }

  return (
    <Card>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          flex: 1,
        }}
      >
        <CardHeader
          avatar={<DescriptionIcon />}
          title={<span>In-app information</span>}
          subheader="Title and description help users understand the action"
        />
        <Button
          onClick={handleGenerate}
          label={'generate info'}
          disabled={generating}
          endIcon={generating ? <LoadingAnimation /> : null}
          variant="contained"
          title="Let Pepper propose another title and description"
        />
      </div>
      <CardContent>
        <Callout emoji="💡" backgroundColor="grey">
          Click the button above to generate a meaningful title and description using the
          action's context.
        </Callout>
        {action?.ctaType === ActionCTAType.QUESTION && (
          <Callout emoji="📝" backgroundColor="grey">
            To update <strong>descriptions</strong>, edit the question itself.
          </Callout>
        )}

        {/* Title EN */}
        <TodoChecklist
          checked={!!action?.titleEN}
          label="Set an English title"
          cta={
            canEdit &&
            !action.actionCtaQuestion && (
              <UpdateFieldDialog
                resource="actions"
                record={action}
                type="text"
                field="titleEN"
                buttonLabel="Update"
                buttonVariant="outlined"
                maxLength={50}
              />
            )
          }
        >
          {action.titleEN && <small>{action.titleEN}</small>}
        </TodoChecklist>

        {/* Title FR */}
        <TodoChecklist
          checked={!!action?.titleFR}
          label="Set a French title"
          cta={
            canEdit &&
            !action.actionCtaQuestion && (
              <UpdateFieldDialog
                resource="actions"
                record={action}
                type="text"
                field="titleFR"
                buttonLabel="Update"
                buttonVariant="outlined"
                maxLength={50}
              />
            )
          }
        >
          {action.titleFR && <small>{action.titleFR}</small>}
        </TodoChecklist>

        {/* Description EN */}
        <TodoChecklist
          checked={!!action?.descriptionEN}
          label="Set an English description"
          cta={
            canEdit &&
            !action.actionCtaQuestion && (
              <UpdateFieldDialog
                resource="actions"
                record={action}
                type="textarea"
                field="descriptionEN"
                buttonLabel="Update"
                buttonVariant="outlined"
                maxLength={300}
              />
            )
          }
        >
          {action.descriptionEN && <small>{action.descriptionEN}</small>}
        </TodoChecklist>

        {/* Description FR */}
        <TodoChecklist
          checked={!!action?.descriptionFR}
          label="Set a French description"
          cta={
            canEdit &&
            !action.actionCtaQuestion && (
              <UpdateFieldDialog
                resource="actions"
                record={action}
                type="textarea"
                formatter={value => {
                  if (!value) {
                    return '';
                  }
                  return value.replaceAll('\n', ' ');
                }}
                field="descriptionFR"
                buttonLabel="Update"
                buttonVariant="outlined"
                maxLength={300}
              />
            )
          }
        >
          {action.descriptionFR && <small>{action.descriptionFR}</small>}
        </TodoChecklist>
      </CardContent>
    </Card>
  );
};

export default DescriptionsCard;
