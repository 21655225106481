import React, { useCallback } from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useDataProvider,
  useRecordContext,
} from 'react-admin';
import { CampaignType } from 'types/campaign';
import { ActionType } from 'types/action';
import moment from 'moment-timezone';
import AddIcon from '@mui/icons-material/Add';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { CampaignCauseList } from '@components/lists/campaign_cause_list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import Calendar, { CalendarItems } from '@components/calendar';
import ActionCard from '@components/calendar/components/action_card';

import CampaignBlock from './components/campaign_block';
import DraftActionsBlock from './components/draft_actions_block';

const ShowActions = <ResourceTitleActionBar mode="show" />;

const CampaignShowComponent = () => {
  const campaign: CampaignType = useRecordContext();
  const { canRead, canCreate } = useRoleBasedPermissions();
  const dataProvider = useDataProvider();

  /**
   * Fetch calendar items
   * @param daysDisplayed
   * @returns {Promise<CalendarItems>}
   */
  const fetchCalendarItems = useCallback(
    async (daysDisplayed?: string[]) => {
      if (!campaign?.id || !daysDisplayed) {
        return {};
      }
      const { data } = await dataProvider.getList('actions', {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'publishedAt', order: 'ASC' },
        filter: {
          campaignId: campaign.id,
          publishedAt: daysDisplayed,
        },
      });

      const result: CalendarItems = {};
      data.forEach((action: ActionType) => {
        const day = moment(action.publishedAt).format('YYYY-MM-DD');
        if (!day) {
          return;
        }
        if (!result[day]) {
          result[day] = [];
        }
        result[day].push(<ActionCard key={action.id} action={action} />);
      });
      return result;
    },
    [dataProvider, campaign?.id]
  );

  return (
    <>
      <TabbedShowLayout>
        {/* Home */}
        <Tab label="Home">
          <CampaignBlock campaign={campaign} withCauses />
          <TextField
            source="descriptionEN"
            label="Context (for GPT)"
            fullWidth
            component="pre"
            style={{ whiteSpace: 'pre-wrap' }}
          />
          <Calendar fetchItems={fetchCalendarItems} />
          <DraftActionsBlock />
        </Tab>

        <Tab label="Causes" path="causes">
          <CampaignCauseList />
        </Tab>

        {canRead('campaigns_owners') && (
          <Tab label="Managers" path="managers">
            <ArrayField source="owners" label="Owners">
              <Datagrid bulkActionButtons={false}>
                <ReferenceField source="id" reference="users" link="show" label="Owner" />
              </Datagrid>
            </ArrayField>
            {canCreate('campaigns_owners') && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <Button
                  label="Add a campaign manager"
                  startIcon={<AddIcon />}
                  href={`#/campaigns_owners/create?source={"campaignId":"${campaign?.id}"}`}
                  variant="outlined"
                />
              </div>
            )}
          </Tab>
        )}
      </TabbedShowLayout>
    </>
  );
};

export const CampaignShow = () => (
  <Show actions={ShowActions}>
    <CampaignShowComponent />
  </Show>
);
