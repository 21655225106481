import React from 'react';

const PropertiesArrayExample = () => (
  <code component="pre" style={{ whiteSpace: 'pre-wrap', fontSize: 10, color: '#999' }}>
    {`example: [{
  querySelector: '[name="firstname"]',
  property: 'firstname',
  value: '%{firstname}',
  prefil: true,
  shouldUpdate: true,
}, ...]`}
  </code>
);

export default PropertiesArrayExample;
