import React from 'react';
import { Create } from 'react-admin';

import { convertFileToBase64 } from '@services/file';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import DonationForm from './components/donation_form';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `donations/${data.id}/show`;

const CreateActions = <ResourceTitleActionBar mode="create" title="Donations" />;

export const DonationCreate = () => {
  const handleSave = async (data: any) => {
    if (data.avatar && data.avatar.rawFile) {
      data.avatar = await convertFileToBase64(data.avatar.rawFile);
    }

    if (data.thumbnail && data.thumbnail.rawFile) {
      data.thumbnail = await convertFileToBase64(data.thumbnail.rawFile);
    }

    if (data.buckets && data.buckets.length > 0) {
      data.buckets = JSON.stringify(data.buckets);
    }

    return data;
  };

  return (
    <Create
      redirect={redirectAfterCreateEdit}
      actions={CreateActions}
      transform={handleSave}
    >
      <DonationForm mode="create" />
    </Create>
  );
};

export default DonationCreate;
