import React, { useCallback, useState } from 'react';
import { Deal, DealType } from 'types/deal.d';

import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

type DealCardProps = {
  deal: Deal;
};

const DealCard = ({ deal }: DealCardProps) => {
  const [hovered, setHovered] = useState<boolean>(false);

  /**
   * Go to the deal page
   */
  const goToDeal = useCallback(() => {
    window.location.href = `#/deals/${deal.id}/show`;
  }, [deal.id]);

  return (
    <div
      style={{
        ...styles.container,
        ...(hovered ? styles.containerHovered : null),
      }}
      onClick={goToDeal}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        style={{
          ...styles.typeContainer,
          backgroundColor:
            deal.type === DealType.AffiliateLink
              ? Colors.Blue.primary
              : Colors.Green.primary,
        }}
      >
        {deal.type === DealType.AffiliateLink ? 'affiliate link' : 'coupon code'}
      </div>
      <div style={styles.avatarContainer}>
        <img src={deal.avatarUrl} style={styles.avatar} />
      </div>

      <div style={styles.name}>{deal.nameEN}</div>
    </div>
  );
};

const styles: any = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: SpacingStyle[4],
    border: `1px solid ${Colors.Black.transparent.max}`,
    borderRadius: BorderStyle.Radius.small,
    overflow: 'hidden',
    userSelect: 'none',
    backgroundColor: Colors.White.primary,
  },
  containerHovered: {
    boxShadow: `0 1px 4px ${Colors.Black.transparent.max}`,
    border: `1px solid ${Colors.Black.transparent.medium}`,
    cursor: 'pointer',
  },
  typeContainer: {
    position: 'absolute',
    top: SpacingStyle[4],
    right: SpacingStyle[4],
    padding: `0 ${SpacingStyle[4]}px`,
    backgroundColor: Colors.OffBlack.primary,
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: '600',
    color: Colors.White.primary,
    borderRadius: BorderStyle.Radius.small,
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    objectFit: 'cover',
    backgroundColor: Colors.White.primary,
  },
  name: {
    marginTop: SpacingStyle[4],
    fontSize: FontStyle.sizeSmall,
    fontWeight: '600',
    textAlign: 'center',
    lineHeight: 1.2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default DealCard;
