import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { Bucket } from 'types/donation';
import { useNotify } from 'react-admin';

type ConfigureBucketValueDialogProps = {
  open: boolean;
  nBucketsAdded: number;
  initialBucketState?: Bucket | null;
  onClose: () => void;
  onSave: (
    bucket: {
      value: number;
      descriptionEN: string;
      descriptionFR: string;
    },
    initialBucketValue: number
  ) => void;
};

const DEFAULT_BUCKET_VALUES = [0, 50, 100, 500, 1000];

const generateInitialBucketState = (nBucketsAdded: number = 0) => ({
  value: DEFAULT_BUCKET_VALUES[nBucketsAdded],
  descriptionEN: '',
  descriptionFR: '',
});

const ConfigureBucketDialog = ({
  open,
  nBucketsAdded,
  initialBucketState,
  onClose,
  onSave,
}: ConfigureBucketValueDialogProps) => {
  const notify = useNotify();
  const [bucket, setBucket] = useState(
    initialBucketState || generateInitialBucketState(nBucketsAdded + 1)
  );

  const initialBucketValue = initialBucketState?.value || 0;

  // Reset the bucket state when the dialog is closed or opened
  useEffect(() => {
    if (!open) {
      setBucket({
        value: 0,
        descriptionEN: '',
        descriptionFR: '',
      });
    }
  }, [open]);

  useEffect(() => {
    if (open && initialBucketState) {
      setBucket(initialBucketState);
    }
  }, [open, initialBucketState]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'value') {
      const numValue = parseInt(value, 10);
      if (isNaN(numValue) || numValue <= 0) {
        notify('Value should be a positive number', { type: 'warning' });
        return;
      }
      setBucket(prev => ({ ...prev, [name]: numValue }));
    } else {
      setBucket(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSave = () => {
    onSave(bucket, initialBucketValue);
    onClose(); // Close the dialog after saving
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Configure Bucket</DialogTitle>
      <DialogContent>
        <TextField
          label="Value"
          name="value"
          type="number"
          value={bucket.value}
          onChange={handleChange}
          fullWidth
          margin="normal"
          inputProps={{ min: 25, step: 25 }}
        />
        <TextField
          label="Description EN"
          placeholder="It's like buying a coffee for the activist"
          name="descriptionEN"
          value={bucket.descriptionEN}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description FR"
          name="descriptionFR"
          placeholder="C'est comme acheter un café pour l'activiste"
          value={bucket.descriptionFR}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigureBucketDialog;
