import React from 'react';
import { useRecordContext } from 'react-admin';
import { Colors } from 'src/styles/variables';
import { CRMPersonType } from 'types/crm_person';

export const CRMPersonImage = ({ ...props }) => {
  const crmPerson: CRMPersonType = useRecordContext(props);

  const width = props['width'] || 28;
  const styles: any = {
    width: width,
    height: width,
    borderRadius: width,
    backgroundColor: Colors.Grey[100],
    objectFit: 'cover',
  };

  if (crmPerson.imageUrl) {
    return <img src={crmPerson.imageUrl} style={styles} />;
  }
  let firstletter = crmPerson.firstname
    ? crmPerson.firstname[0]
    : crmPerson.email
      ? crmPerson.email[0]
      : crmPerson.id[0];
  return (
    <div
      style={{
        ...styles,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: Colors.OffBlack.primary,
        fontSize: '1em',
        fontWeight: '600',
        textDecoration: 'none',
      }}
    >
      {firstletter.toUpperCase()}
    </div>
  );
};
