import * as React from 'react';
import {
  BooleanInput,
  Edit,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

import { SelectTopicType } from './create';

const redirectAfterEdit = (basePath: any, id: any, data: any) => `topics/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const TopicEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <TextInput source="nameEN" label="Name EN" fullWidth required />
      <TextInput source="nameFR" label="Name FR" fullWidth required />
      <TextInput source="descriptionEN" label="Description EN" fullWidth required />
      <TextInput source="descriptionFR" label="Description FR" fullWidth required />
      <BooleanInput source="isPublic" label="Is public?" />
      <SelectTopicType />
      <ImageField source="thumbnailUrl" label="Current thumbnail" />
      <ImageInput source="picture" accept="image/*" maxSize={5000000}>
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
