import React, { useCallback } from 'react';
import { useMediaQuery } from '@mui/material';
import { Button, Edit, useRecordContext, useRedirect } from 'react-admin';
import { ZodError } from 'zod';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';

import CardStepper, { CardStepperStep } from '@components/card_stepper';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { SpacingStyle } from '@styles/variables';

import { ActionBuilderStepId, STEP_SCHEMAS } from './steps';
import OwnersCard from './components/owners_card';
import CauseCard from './components/cause_card';
import CTACard from './components/cta_card';
import AvailableCountriesCard from './components/available_countries_card';
import DescriptionsCard from './components/descriptions_card';
import DifficultyCard from './components/difficulty_card';
import PointsCard from './components/points_card';
import PreviewCard from './components/preview_card';
import PriorityCard from './components/priority_card';
import PublishCard from './components/publish_card';

const CardGrid = ({
  isMediumScreen,
  children,
  twoColumns = true,
}: {
  isMediumScreen: boolean;
  children: React.ReactNode;
  twoColumns?: boolean;
}) => <div style={styles.cardsGrid(isMediumScreen, twoColumns)}>{children}</div>;

const ActionBuilder = () => {
  const isMediumScreen = useMediaQuery('(max-width: 900px)');
  const action = useRecordContext();
  const redirect = useRedirect();

  const [validationMessage, setValidationMessage] = React.useState<string | null>(null);

  /**
   * Verify if the current step is valid
   * @param stepId
   * @returns {boolean}
   */
  const handleVerifyStep = async (stepId: ActionBuilderStepId) => {
    const schema = STEP_SCHEMAS[stepId];
    const data = action;

    try {
      schema.parse(data);
      setValidationMessage(null);
      return true;
    } catch (error: any) {
      if (error instanceof ZodError) {
        console.warn('Data is invalid:', error.errors);

        let message = "Something's wrong: ";
        for (const err of error.errors) {
          if (err.path?.length) {
            message += `\n- Field ${err.path.join('.')}: ${err.message}.`;
          } else {
            message += `\n- ${err.message}.`;
          }
        }

        setValidationMessage(message);
      } else {
        console.error('Error in step validation:', error);
      }
    }

    return false;
  };

  const steps = [
    {
      id: ActionBuilderStepId.Cause,
      title: 'Basic Information [1/2]',
      description: "Let's get started by setting up the core details of your action",
      children: <CauseCard />,
    },
    {
      id: ActionBuilderStepId.Owners,
      title: 'Basic Information [2/2]',
      description: "Let's get started by setting up the core details of your action",
      children: <OwnersCard />,
    },
    {
      id: ActionBuilderStepId.Content,
      title: 'In-app Content',
      description: 'Write some engaging content to inspire users to take action',
      children: (
        <CardGrid isMediumScreen={isMediumScreen}>
          <DescriptionsCard />
          <PreviewCard />
        </CardGrid>
      ),
    },
    {
      id: ActionBuilderStepId.CtaConfiguration,
      title: 'Call to Action',
      description: 'What do you want users to do?',
      children: (
        <CardGrid isMediumScreen={isMediumScreen} twoColumns={false}>
          <CTACard />
        </CardGrid>
      ),
    },
    {
      id: ActionBuilderStepId.Country,
      title: 'Segmentation [1/2]',
      description: 'Define who should see and take this action',
      children: <AvailableCountriesCard />,
    },
    {
      id: ActionBuilderStepId.Difficulty,
      title: 'Segmentation [2/2]',
      description: 'Define who should see and take this action',
      children: <DifficultyCard />,
    },
    {
      id: ActionBuilderStepId.Priority,
      title: 'Engagement [1/2]',
      description: 'Configure the reward and priority of this action',
      children: <PriorityCard />,
    },
    {
      id: ActionBuilderStepId.Points,
      title: 'Engagement [1/2]',
      description: 'Configure the reward and priority of this action',
      children: <PointsCard />,
    },
    {
      id: ActionBuilderStepId.Publishing,
      title: 'Publishing',
      description: 'Publication settings',
      children: (
        <CardGrid isMediumScreen={isMediumScreen}>
          <PublishCard />
        </CardGrid>
      ),
    },
  ] as CardStepperStep[];

  /**
   * Redirect to the action show page when the action is completed
   */
  const onCompleted = useCallback(() => {
    redirect(`/actions/${action.id}/show`);
  }, [action, redirect]);

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <CardStepper
          steps={steps}
          fullHeight
          buttonPosition="top"
          onCompleted={onCompleted}
          onNext={id => handleVerifyStep(id as ActionBuilderStepId)}
          validationMessage={validationMessage}
          style={{ marginTop: 0 }}
        />
      </div>
    </div>
  );
};

const ActionBuilderContext = () => {
  const params = useParams();
  const record = useRecordContext();

  const actionsBar = (
    <ResourceTitleActionBar
      mode="edit"
      actions={
        <Button
          label="Exit"
          variant="outlined"
          endIcon={<CloseIcon />}
          onClick={() => {
            const confirm = window.confirm(
              'Are you sure you want to exit?\nAll unsaved changes will be lost.'
            );
            if (confirm) {
              const actionId = record?.id || params.id;
              window.location.href = `#/actions/${actionId}/show`;
            }
          }}
        />
      }
    />
  );
  return (
    <Edit actions={actionsBar} component="div">
      <ActionBuilder />
    </Edit>
  );
};

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  innerContainer: {
    width: '100%',
    maxWidth: 1200,
    marginBottom: SpacingStyle.huge,
  },
  cardsGrid: (isMediumScreen: boolean, twoColumns: boolean) => ({
    display: isMediumScreen ? 'flex' : 'grid',
    flexDirection: 'column' as const,
    gridTemplateColumns: twoColumns ? 'repeat(2, 1fr)' : undefined,
  }),
};

export default ActionBuilderContext;
