import React from 'react';
import { ActionCTAType } from 'types/action';

import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import ActionCTAEmoji from './action_cta_emoji';

type ActionCTATypeChipProps = {
  ctaType: ActionCTAType;
  size?: 'small' | 'medium';
};
const ActionCTATypeChip = ({ ctaType, size = 'medium' }: ActionCTATypeChipProps) => {
  const getBackgroundColor = () => {
    if (ctaType === 'app_page') {
      return Colors.Pink[200];
    }
    if (ctaType === 'email') {
      return Colors.Red[100];
    }
    if (ctaType === 'external_link') {
      return Colors.Blue[200];
    }
    if (ctaType === 'google_maps') {
      return Colors.Orange[200];
    }
    if (ctaType === 'social_comment') {
      return '#A6F4C5';
    }
    if (ctaType === 'question') {
      return Colors.Yellow[200];
    }
    if (ctaType === 'social_post') {
      return '#32D583';
    }
    if (ctaType === 'webpage') {
      return Colors.Red[300];
    }
    return '#E5E7EB';
  };
  return (
    <div style={StyleSheet.container}>
      <div
        style={
          {
            ...StyleSheet.ctaType,
            ...(size === 'small' ? StyleSheet.ctaTypeSmall : {}),
            backgroundColor: getBackgroundColor(),
          } as any
        }
      >
        <ActionCTAEmoji type={ctaType} />
        {ctaType?.replace(/_/g, ' ')}
      </div>
    </div>
  );
};

const StyleSheet = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  ctaType: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle[4],
    padding: `${SpacingStyle[4]}px ${SpacingStyle[8]}px`,
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 500,
    color: 'rgba(0,0,0,.6)',
    lineHeight: 1,
    borderRadius: BorderStyle.Radius.small,
    backgroundColor: '#E5E7EB',
    whiteSpace: 'nowrap',
  },
  ctaTypeSmall: {
    padding: `${SpacingStyle[2]}px ${SpacingStyle[4]}px`,
    fontSize: FontStyle.sizeVeryVerySmall,
    borderRadius: BorderStyle.Radius.tiny,
  },
};

export default ActionCTATypeChip;
