/**
 * Renders a preview of an In-App Toast Notification
 */
import React from 'react';

type ToastNotificationPreviewProps = {
  label?: string;
  emoji?: string;
  title?: string;
  body?: string;
};
export default ({ label, emoji, title, body }: ToastNotificationPreviewProps) => {
  return (
    <div style={styles.background}>
      {label && <div style={styles.label}>{label}</div>}
      <div style={styles.toastNotification}>
        <div style={styles.toastNotificationEmojiContainer}>
          <div style={styles.toastNotificationEmoji}>{emoji || '🔔'}</div>
        </div>
        <div style={styles.toastNotificationText}>
          <div style={styles.toastNotificationTitle}>{title || 'Enter a title...'}</div>
          <div style={styles.toastNotificationBody}>
            {body ? body : <span style={{ color: '#bbb' }}>Type something in...</span>}
          </div>
        </div>
        <div style={styles.toastNotificationClose}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3996 4.60059L4.59961 13.4006M4.59961 4.60059L13.3996 13.4006"
              stroke="#6C737F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

const styles: any = {
  background: {
    width: 320,
    marginBottom: 20,
    backgroundColor: '#ddd',
    backgroundImage:
      'url(https://res.cloudinary.com/hsiz9ovy1/image/upload/v1685642235/in_app_notifications/app_preview_command_center_vypios.jpg)',
    backgroundSize: 'cover',
    borderRadius: 12,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    padding: '0px 12px',
    backdropFilter: 'blur(4px)',
    backgroundColor: 'black',
    fontSize: 14,
    fontWeight: '600',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '0 0 10px 10px',
    minWidth: 120,
  },
  toastNotification: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '300px',
    margin: '16px 0',
    padding: 12,
    backgroundColor: '#fff',
    borderRadius: 12,
    boxShadow: '0px 12px 16px -4px rgba(60, 24, 40, .5)',
  },
  toastNotificationEmojiContainer: {
    height: '100%',
  },
  toastNotificationEmoji: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    fontSize: 24,
    borderRadius: 8,
    border: '1px solid #F0F1F3',
  },
  toastNotificationText: {
    display: 'flex',
    marginLeft: 10,
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '16px',
  },
  toastNotificationTitle: {
    fontWeight: 'bold',
  },
  toastNotificationBody: {
    color: '#384250',
    marginTop: 4,
  },
  toastNotificationClose: {
    position: 'absolute',
    top: -8,
    right: -4,
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    color: '#6C737F',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.2)',
  },
};
