import React from 'react';
import { Create, required, SimpleForm, TextInput } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `deals/${data.dealId}/show`;

const CreateActions = <ResourceTitleActionBar mode="create" title="Deals" />;

export const DealCouponCreate = () => {
  return (
    <Create redirect={redirectAfterCreateEdit} actions={CreateActions}>
      <SimpleForm>
        <CustomReferenceInput
          source="dealId"
          reference="deals"
          optionText="nameEN"
          validate={required()}
        />
        <TextInput source="code" validate={required()} inputProps={{ maxLength: 100 }} />
      </SimpleForm>
    </Create>
  );
};
