import * as React from 'react';
import {
  DateTimeInput,
  Edit,
  ImageField,
  ImageInput,
  required,
  SimpleForm,
  TextInput,
  useRecordContext,
} from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import TranslateButton from '@components/translate_button';
import { SpacingStyle } from '@styles/variables';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `causes_posts/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" title="Causes Posts" />;

const EditForm = () => {
  const record = useRecordContext(); // Get the current record
  const medias = record?.medias || []; // Get medias array safely
  const imageUrl = medias[0]?.imageUrl || ''; // Get the first image's URL, if available

  return (
    <SimpleForm>
      <CustomReferenceInput
        source="causeId"
        reference="causes"
        queryKey="descriptionEN"
        label="Cause"
        validate={[required()]}
      />

      {/* Display image using the imageUrl */}
      {imageUrl ? (
        <ImageField source="imageUrl" record={{ imageUrl }} title="Cause Post Image" />
      ) : null}

      <ImageInput label="Post Image" source="picture" accept="image/*" maxSize={4000000}>
        <ImageField source="src" title="title" />
      </ImageInput>

      <div style={styles.languagesContent}>
        <div style={{ flex: 1 }}>
          <div style={styles.title}>🇺🇸 English</div>
          <TextInput
            label="Title EN"
            source="titleEN"
            validate={[required()]}
            fullWidth
          />
          <TextInput
            label="Content EN"
            source="contentEN"
            validate={[required()]}
            fullWidth
            multiline
          />
        </div>
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={styles.title}>🇫🇷 French</div>
            <TranslateButton
              keys={[
                { from: 'titleEN', to: 'titleFR' },
                { from: 'contentEN', to: 'contentFR' },
              ]}
            />
          </div>
          <TextInput
            label="Title FR"
            source="titleFR"
            validate={[required()]}
            fullWidth
          />
          <TextInput
            label="Content FR"
            source="contentFR"
            validate={[required()]}
            fullWidth
            multiline
          />
        </div>
      </div>
      <DateTimeInput source="publishedAt" label="Published At" fullWidth />
    </SimpleForm>
  );
};

export const CausePostEdit = () => (
  <Edit
    redirect={redirectAfterCreateEdit}
    mutationMode="pessimistic"
    actions={EditActions}
  >
    <EditForm />
  </Edit>
);

const styles: any = {
  languagesContent: {
    display: 'flex',
    gap: SpacingStyle.big,
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    marginTop: SpacingStyle.normal,
  },
};
