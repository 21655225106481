import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, useDataProvider, useRefresh } from 'react-admin';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import CRMTagIcon from '@mui/icons-material/Style';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import CRMTagChip from '@models/crm/crm_tags/components/chip';

import Styles from '../styles';

type CRMAddTagDialogProps = {
  crmOrganizationId?: string;
  crmPersonId?: string;
};
const CRMAddTagDialog = ({ crmOrganizationId, crmPersonId }: CRMAddTagDialogProps) => {
  const dataprovider = useDataProvider();
  const refresh = useRefresh();
  const { canCreate } = useRoleBasedPermissions();
  const inputRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState<any[]>([]);
  const [error, setError] = useState('');

  const fetchTags = useCallback(async () => {
    const { data } = await dataprovider.getList('crm_tags', {
      pagination: { page: 1, perPage: 100 },
      filter: {},
      sort: { field: 'name', order: 'ASC' },
    });
    const options = data.map(tag => ({
      ...tag,
      label: tag.name,
    }));
    setTags(options);
  }, [dataprovider]);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef) {
        inputRef?.current?.focus();
      }
    }, 10);
  }, [open]);

  const handleClickOpen = () => {
    setError('');
    setOpen(true);
  };
  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const createTaggable = async (tag: any) => {
    try {
      setError('');
      await dataprovider.create('crm_tags_taggables', {
        data: {
          crmTagId: tag.id,
          crmOrganizationId,
          crmPersonId,
        },
      });
      refresh();
      handleClose();
    } catch (error) {
      setError('Tag already assigned');
    }
  };

  if (canCreate('crm_tags_taggables')) {
    return <></>;
  }
  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
        label="Add a tag"
      />

      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="xs"
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={Styles.flexRow} id="form-dialog-title">
          <CRMTagIcon /> Add a tag
        </DialogTitle>
        <DialogContent style={{ minHeight: 100 }}>
          <Autocomplete
            autoHighlight
            autoSelect
            fullWidth
            options={tags}
            openOnFocus
            onChange={(event, newValue) => createTaggable(newValue)}
            renderInput={params => (
              <TextField {...params} inputRef={inputRef} label="Search a tag" />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <CRMTagChip tag={option} />
              </li>
            )}
          />
          {error && <small style={{ color: 'red' }}>{error}</small>}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CRMAddTagDialog;
