import React from 'react';
import { BsCheck } from 'react-icons/bs';

import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

type TodoChecklistProps = {
  checked?: boolean;
  label?: React.ReactNode;
  cta?: React.ReactNode;
  children?: React.ReactNode;
};
const TodoChecklist = ({ checked, label, cta, children }: TodoChecklistProps) => (
  <div style={styles.container}>
    <div style={styles.row}>
      <div
        style={{
          ...styles.radio,
          ...(checked && {
            backgroundColor: Colors.Magenta[800],
            border: `1px solid ${Colors.Magenta[800]}`,
          }),
        }}
      >
        {checked && <BsCheck strokeWidth={0.5} />}
      </div>
      <div style={styles.label}>{label}</div>
      <div style={styles.cta}>{cta}</div>
    </div>
    {children && <div style={styles.children}>{children}</div>}
  </div>
);

const styles: any = {
  container: {
    marginBottom: SpacingStyle[12],
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle.small,
    height: 40,
  },
  radio: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    backgroundColor: Colors.White.primary,
    border: `1px solid ${Colors.Grey[200]}`,
    borderRadius: BorderStyle.Radius.normal,
    fontSize: FontStyle.sizeMedium,
    color: Colors.OffWhite.primary,
  },
  label: {
    flexGrow: 1,
    fontSize: FontStyle.sizeSmall,
    fontWeight: 500,
    lineHeight: 1,
  },
  cta: {
    fontSize: FontStyle.sizeVerySmall,
  },
  children: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: SpacingStyle[4],
    backgroundColor: Colors.Magenta[50],
    border: `1px solid ${Colors.Magenta[100]}`,
    borderRadius: BorderStyle.Radius.small,
    padding: `${SpacingStyle[8]}px ${SpacingStyle[12]}px`,
  },
};

export default TodoChecklist;
