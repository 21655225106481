import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import { CreateButton, useDataProvider } from 'react-admin';
import { CausePostType } from 'types/cause_post';

import { SpacingStyle } from '@styles/variables';

import CausePostsCalendar from './components/list/calendar';
import CausePostPreview from './components/preview';

export const CausePostList = () => {
  const [drafts, setDrafts] = useState<CausePostType[]>();
  const dataProvider = useDataProvider();

  const fetchCausesPostes = useCallback(async () => {
    const { data } = await dataProvider.getList('causes_posts', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'createdAt', order: 'ASC' },
      filter: { publishedAt: null },
    });
    setDrafts(data);
  }, [dataProvider]);

  useEffect(() => {
    fetchCausesPostes();
  }, [fetchCausesPostes]);

  return (
    <>
      <Card>
        <CardHeader title="Published Posts" />
        <CardContent>
          <CausePostsCalendar />
        </CardContent>
      </Card>

      <Card>
        <CardHeader
          title="Posts (drafts)"
          action={
            <CreateButton
              resource="causes_posts"
              variant="outlined"
              label="Create a post"
            />
          }
        />
        <CardContent>
          <div style={styles.draftsContainer}>
            {drafts?.map((draft: CausePostType) => (
              <CausePostPreview
                key={draft.id}
                record={draft}
                locale="en"
                editable
                style={{ maxWidth: 300 }}
              />
            ))}
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const styles: any = {
  draftsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: SpacingStyle.big,
  },
};
