import React from 'react';
import { useRecordContext } from 'react-admin';
import { Colors } from 'src/styles/variables';
import { CRMOrganizationType } from 'types/crm_organization';

export const CRMOrganizationImage = ({ ...props }) => {
  const crmOrganization: CRMOrganizationType = useRecordContext(props);

  const width = props['width'] || 28;
  const styles: any = {
    width: width,
    height: width,
    borderRadius: width,
    backgroundColor: Colors.Grey[100],
    objectFit: 'cover',
  };

  if (crmOrganization.imageUrl) {
    return <img src={crmOrganization.imageUrl} style={styles} />;
  }
  let firstletter = crmOrganization.name
    ? crmOrganization.name[0]
    : crmOrganization.email
      ? crmOrganization.email[0]
      : crmOrganization.id[0];
  return (
    <div
      style={{
        ...styles,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: Colors.OffBlack.primary,
        fontSize: '1em',
        fontWeight: '600',
        textDecoration: 'none',
      }}
    >
      {firstletter.toUpperCase()}
    </div>
  );
};
