import * as React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import ResourceTitleActionBar from '../../components/resource_title_action_bar';

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const VideoCreate = () => (
  <Create actions={CreateActions}>
    <SimpleForm>
      <TextInput
        source="name"
        fullWidth
        placeholder="if empty, will be replaced with last part of URL"
      />
      <TextInput source="videoUrl" fullWidth required />
      <TextInput source="posterUrl" fullWidth />
    </SimpleForm>
  </Create>
);
