import React, { useEffect, useState } from 'react';
import { Button, useDataProvider, useRefresh } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  ActionType,
  ActionCTAQuestionCTAType,
  QuestionType,
  ResponseCTAType,
} from 'types/action.d';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import InputStyle from '@styles/input';
import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

type ActionQuestionsActionCardDialogProps = {
  action: ActionType;
  actionCtaQuestion?: ActionCTAQuestionCTAType;
};

const ActionQuestionsActionCardDialog = ({
  action,
  actionCtaQuestion,
}: ActionQuestionsActionCardDialogProps) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { canCreate, canUpdate } = useRoleBasedPermissions();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questionEN, setQuestionEN] = useState('');
  const [questionFR, setQuestionFR] = useState('');
  const [questionType, setQuestionType] = useState<QuestionType>(
    QuestionType.SINGLE_CHOICE
  );
  const [responses, setResponses] = useState<ResponseCTAType[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!action) {
      return;
    }
    if (actionCtaQuestion) {
      setQuestionEN(actionCtaQuestion.questionEN || '');
      setQuestionFR(actionCtaQuestion.questionFR || '');

      const newResponses: ResponseCTAType[] = [];
      if (actionCtaQuestion.responses && actionCtaQuestion.responses.length > 0) {
        actionCtaQuestion.responses.forEach(response => {
          newResponses.push(response);
        });

        setResponses(newResponses);
      }
    }
  }, [action, actionCtaQuestion]);

  const handleClickOpen = () => {
    setOpen(true);
    setIsSubmitting(false);
  };

  const handleClose = (withConfirmation = false) => {
    if (isSubmitting) {
      window.alert("You can't close this modal while the action is being saved.");
      return;
    }
    const confirmSentence =
      'Are you sure you want to close this modal?\nYour changes will be lost.';
    if (withConfirmation && !window.confirm(confirmSentence)) {
      return;
    }
    setOpen(false);
  };

  const saveCTA = async () => {
    if (!action || !!isSubmitting) {
      return;
    }

    if (
      !questionEN ||
      !questionFR ||
      (responses && responses.length < 2) ||
      !questionType
    ) {
      return;
    }

    setIsSubmitting(true);
    try {
      if (!actionCtaQuestion) {
        await dataProvider.create('action_cta_questions', {
          data: {
            actionId: action.id,
            questionEN,
            questionFR,
            type: questionType,
            responses,
          },
        });
      } else {
        await dataProvider.update('action_cta_questions', {
          id: actionCtaQuestion.id,
          data: {
            questionEN,
            questionFR,
            type: questionType,
            responses,
          },
        } as any);
      }
      refresh();
      setOpen(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (
    !action ||
    !canCreate('action_cta_social_comments') ||
    !canUpdate('action_cta_social_comments')
  ) {
    return null;
  }

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        label={actionCtaQuestion ? 'Edit' : 'Add'}
        endIcon={actionCtaQuestion ? <EditIcon /> : <AddIcon />}
        size="small"
        style={{
          textTransform: 'none',
          fontSize: 'inherit',
          fontWeight: 'inherit',
        }}
      />
      <Dialog onClose={() => handleClose(true)} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>Ask a question</DialogTitle>
        <DialogContent>
          <label style={{ fontSize: FontStyle.sizeVeryVerySmall }}>
            Question Type
            <select
              style={InputStyle.input}
              value={questionType}
              onChange={e => setQuestionType(e.target.value as QuestionType)}
              required
            >
              <option value={QuestionType.SINGLE_CHOICE}>Single choice</option>
              <option value={QuestionType.MULTIPLE_CHOICE}>Multiple choice</option>
            </select>
          </label>
          <br />
          <label style={{ fontSize: FontStyle.sizeVeryVerySmall }}>
            Question EN
            <input
              type="text"
              style={InputStyle.input}
              placeholder={'Ask your question in English'}
              value={questionEN}
              onChange={e => setQuestionEN(e.target.value)}
              required
              maxLength={100}
            />
          </label>
          <label style={{ fontSize: FontStyle.sizeVeryVerySmall }}>
            Question FR
            <input
              type="text"
              style={InputStyle.input}
              placeholder={'Ask your question in French'}
              value={questionFR}
              onChange={e => setQuestionFR(e.target.value)}
              required
              maxLength={100}
            />
          </label>
          <br />
          {responses.map((response, index) => (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: SpacingStyle.normal,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    gap: SpacingStyle.small,
                  }}
                >
                  <label
                    style={{
                      fontSize: FontStyle.sizeVeryVerySmall,
                      width: '100%',
                    }}
                  >
                    Response EN {index + 1}:
                    <input
                      type="text"
                      style={InputStyle.input}
                      placeholder={`Response ${index + 1} in English`}
                      value={response.responseEN}
                      onChange={e => {
                        const newResponses = [...responses];
                        newResponses[index].responseEN = e.target.value;
                        setResponses(newResponses);
                      }}
                      maxLength={50}
                    />
                  </label>
                  <label
                    style={{
                      fontSize: FontStyle.sizeVeryVerySmall,
                      width: '100%',
                    }}
                  >
                    Response FR {index + 1}:
                    <input
                      type="text"
                      style={InputStyle.input}
                      placeholder={`Response ${index + 1} in French`}
                      value={response.responseFR}
                      onChange={e => {
                        const newResponses = [...responses];
                        newResponses[index].responseFR = e.target.value;
                        setResponses(newResponses);
                      }}
                      maxLength={50}
                    />
                  </label>
                </div>
                {responses[index] && (
                  <Button
                    label="Delete"
                    startIcon={<DeleteOutlineIcon />}
                    onClick={() => {
                      const newResponses = [...responses];
                      newResponses.splice(index, 1);
                      setResponses(newResponses);
                    }}
                    disabled={
                      !(
                        responses[index] &&
                        responses[index].responseEN &&
                        responses[index].responseFR
                      )
                    }
                    style={{
                      marginLeft: 10,
                    }}
                  />
                )}
              </div>
            </div>
          ))}
          {responses.length < 10 && (
            <Button
              label="Add a response"
              startIcon={<AddIcon />}
              onClick={() => {
                const newResponses = [...responses];
                newResponses.push({
                  responseEN: '',
                  responseFR: '',
                  usersResponses: [],
                });
                setResponses(newResponses);
              }}
              disabled={
                !questionEN ||
                !questionFR ||
                (responses[responses.length - 1] &&
                  (!responses[responses.length - 1].responseEN ||
                    !responses[responses.length - 1].responseFR))
              }
              style={{
                marginTop: SpacingStyle.small,
                marginBottom: SpacingStyle.small,
              }}
            />
          )}
          {error && <div style={styles.error}>{error}</div>}
          {responses.length < 2 && (
            <div style={styles.error}>
              A question needs <strong>two</strong> responses minimum.
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={saveCTA}
            label={isSubmitting ? 'Saving...' : 'Save'}
            disabled={
              isSubmitting ||
              !questionEN ||
              !questionFR ||
              responses.length < 2 ||
              !responses[1].responseEN ||
              !responses[1].responseFR
            }
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

const styles: any = {
  error: {
    color: Colors.Red.primary,
    fontSize: FontStyle.sizeVerySmall,
  },
};

export default ActionQuestionsActionCardDialog;
