import * as Sentry from '@sentry/react';

const init = () => {
  if (window.location.host.includes('localhost')) {
    return;
  }
  Sentry.init({
    dsn: 'https://b4a336bb48a260a28178ddbbb4c3f806@o1394370.ingest.sentry.io/4505746308988928',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          'https://api.regroop-staging.club/',
          'https://api.regroop.club/',
          'https://api.chilli-staging.club/',
          'https://api.chilli.club/',
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export default init;
