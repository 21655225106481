import React, { useContext, useEffect, useState } from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { SubtitleStudioContext, SubtitleStudioContextType } from '../context';

const WIDTH_PER_SECOND_DELTA = 40;

const Controllers = () => {
  const { videoHtmlElement, widthPerSecond, setWidthPerSecond } =
    useContext<SubtitleStudioContextType>(SubtitleStudioContext);
  const [videoCurrentPosition, setVideoCurrentPosition] = useState<number>(0);
  const [videoDuration, setVideoDuration] = useState<number>(0);

  useEffect(() => {
    if (!videoHtmlElement) {
      return;
    }
    videoHtmlElement.addEventListener('loadeddata', onLoadedDataHandler);
    videoHtmlElement.addEventListener('timeupdate', onTimeUpdateHandler);
    return () => {
      videoHtmlElement.removeEventListener('loadeddata', onLoadedDataHandler);
      videoHtmlElement.removeEventListener('timeupdate', onTimeUpdateHandler);
    };
  }, [videoHtmlElement]);

  const onLoadedDataHandler = (e: any) => {
    if (!e.target) {
      return;
    }
    const videoDuration = e.target.duration || 1;
    setVideoDuration(videoDuration);
  };

  const onTimeUpdateHandler = (e: any) => {
    if (!e.target) {
      return;
    }
    const videoCurrentPosition = e.target.currentTime || 0;
    setVideoCurrentPosition(videoCurrentPosition);
  };

  // format seconds to MM:SS with leading zeros:
  const formatSeconds = (seconds?: number) => {
    if (!seconds) {
      return '00:00';
    }
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = Math.round(seconds - minutes * 60);
    return `${minutes.toString().padStart(2, '0')}:${secondsLeft
      .toString()
      .padStart(2, '0')}`;
  };

  const onZoomOut = () => {
    if (widthPerSecond <= WIDTH_PER_SECOND_DELTA) {
      return;
    }
    setWidthPerSecond(widthPerSecond - WIDTH_PER_SECOND_DELTA);
  };

  const onZoomIn = () => {
    setWidthPerSecond(widthPerSecond + WIDTH_PER_SECOND_DELTA);
  };

  const playPauseHandler = () => {
    if (!videoHtmlElement) {
      return;
    }
    if (videoHtmlElement.paused) {
      videoHtmlElement.play();
    } else {
      videoHtmlElement.pause();
    }
  };

  return (
    <div style={StyleSheet.container}>
      <div style={StyleSheet.content}>
        {/* Zoom */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100px',
          }}
        >
          <ZoomOutIcon
            onClick={onZoomOut}
            htmlColor="#767b8b"
            style={StyleSheet.control}
          />
          <div style={StyleSheet.zoomLevel}>x{widthPerSecond / 80}</div>
          <ZoomInIcon onClick={onZoomIn} htmlColor="#767b8b" style={StyleSheet.control} />
        </div>

        {/* Time */}
        <div style={StyleSheet.time}>
          <span style={{ fontWeight: '600' }}>{formatSeconds(videoCurrentPosition)}</span>{' '}
          / {formatSeconds(videoDuration)}
        </div>

        {/* Play / Pause */}
        {!videoHtmlElement?.paused ? (
          <PauseCircleOutlineIcon
            style={{
              ...StyleSheet.control,
              fontSize: 34,
            }}
            onClick={playPauseHandler}
          />
        ) : (
          <PlayCircleOutlineIcon
            style={{
              ...StyleSheet.control,
              fontSize: 34,
            }}
            onClick={playPauseHandler}
          />
        )}
      </div>
    </div>
  );
};

const StyleSheet: any = {
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderTop: '1px solid #F7F7F9',
    borderBottom: '1px solid #E7E7E9',
    padding: '0 24px',
    backgroundColor: '#fff',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '12px 0',
    width: '100%',
    maxWidth: 800,
  },
  time: {
    fontVariantNumeric: 'tabular-nums',
    fontSize: 10,
    color: '#767b8b',
  },
  control: {
    cursor: 'pointer',
    fontSize: 20,
    color: '#767b8b',
  },
  zoomLevel: {
    fontSize: 12,
    color: '#767b8b',
  },
};

export default Controllers;
