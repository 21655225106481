import { Cloudinary } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';

const CLOUDINARY_CLOUDNAME = 'hsiz9ovy1';
const CLOUDINARY_REGEX =
  /^.+\.cloudinary\.com\/(?:[^/]+\/)(?:(image|video|raw)\/)?(?:(upload|fetch|private|authenticated|sprite|facebook|twitter|youtube|vimeo)\/)?(?:(?:[^_/]+_[^,/]+,?)*\/)?(?:v(\d+|\w{1,2})\/)?([^.^\s]+)(?:\.(.+))?$/;

const extractCloudinaryPublicId = (url: string): string => {
  if (!url) {
    return '';
  }
  const parts = CLOUDINARY_REGEX.exec(url);
  return parts && parts.length > 2 ? parts[parts.length - 2] : url;
};

/**
 * @returns the compressed image URL if the image is hosted on Cloudinary, or the original URL otherwise
 */
type CompressedImageUrlOptions = {
  width?: number;
  height?: number;
};
export const compressedImageUrl = (
  url: string,
  { width, height }: CompressedImageUrlOptions
) => {
  if (!url) {
    return;
  }

  if (!url.includes('res.cloudinary.com')) {
    return url;
  }

  const cloudinary = new Cloudinary({
    cloud: { cloudName: CLOUDINARY_CLOUDNAME },
    url: {
      secure: true,
      analytics: false,
    },
  });

  const resizeAction = fill();
  if (width) {
    resizeAction.width(width);
  }
  if (height) {
    resizeAction.height(height);
  }
  const publicId = extractCloudinaryPublicId(url);
  if (url.includes('/video/')) {
    const video = cloudinary.video(publicId);
    video.resize(resizeAction);
    video.format('jpg');
    return video.toURL();
  }
  const image = cloudinary.image(publicId);
  image.resize(resizeAction);
  image.quality('auto');
  return image.toURL();
};
