import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { ResponseCTAType } from 'types/action';

import { UserAvatar } from '@models/users/components/user_avatar';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

type UsersResponsesDialogProps = {
  open: boolean;
  onClose: () => void;
  response?: ResponseCTAType | null;
};

const UsersResponsesDialog = ({ open, onClose, response }: UsersResponsesDialogProps) => {
  if (!response) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Users who responded "{response.responseEN}"</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: SpacingStyle.small,
            flexWrap: 'wrap',
          }}
        >
          {response.usersResponses.map(({ user }) => (
            <div
              key={user.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: SpacingStyle[4],
                backgroundColor: Colors.Grey[100],
                padding: `${SpacingStyle[2]}px ${SpacingStyle[8]}px ${SpacingStyle[2]}px ${SpacingStyle[4]}px`,
                borderRadius: BorderStyle.Radius.big,
                fontSize: FontStyle.sizeSmall,
              }}
            >
              <UserAvatar record={user} width={18} />
              <a
                href={`#/users/${user.id}/show`}
                style={{
                  color: Colors.OffBlack.primary,
                  textDecoration: 'none',
                  fontWeight: '500',
                }}
              >
                {user.username}
              </a>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UsersResponsesDialog;
