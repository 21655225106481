import React from 'react';
import { ActionType } from 'types/action';

import Callout from '@components/callout';

type ActionCtaShareLinkActionCardProps = {
  action: ActionType;
};
const ActionCtaShareLinkActionCard = ({ action }: ActionCtaShareLinkActionCardProps) => {
  if (!action || !action.actionCtaShareLink) {
    return null;
  }

  return (
    <Callout emoji="ℹ️" backgroundColor="grey">
      Users will be asked to share chilli with their friends by sharing a custom link that
      looks like this:
      <br />
      <code>https://web.chilli.club/users/XXX</code>
    </Callout>
  );
};

export default ActionCtaShareLinkActionCard;
