import * as React from 'react';
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  ImageField,
  DateField,
} from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';

export const UserProfileMediaList = () => (
  <List perPage={PER_PAGE} pagination={<PER_PAGE_OPTIONS />} emptyWhileLoading>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <DateField source="createdAt" showTime />
      <ReferenceField source="userId" reference="users" link="show" />
      <ImageField source="url" />
      <TextField source="tagline" />
    </Datagrid>
  </List>
);
