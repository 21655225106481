import * as React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `users/${data.userId}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const UserBadgeEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <CustomReferenceInput source="userId" reference="users" queryKey="username" />
      <CustomReferenceInput source="badgeId" reference="badges" queryKey="nameEN" />
    </SimpleForm>
  </Edit>
);
