import * as React from 'react';
import {
  DeleteButton,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRecordContext,
} from 'react-admin';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const redirectAfterEdit = (basePath: any, id: any, data: any) => `videos/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const VideoEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm toolbar={<VideoEditToolbar />}>
      <TextInput
        source="name"
        fullWidth
        placeholder="if empty, will be replaced with last part of URL"
      />
      <TextInput source="videoUrl" fullWidth required />
      <TextInput source="posterUrl" fullWidth />
    </SimpleForm>
  </Edit>
);

const VideoEditToolbar = ({ ...props }) => {
  const record = useRecordContext(props);
  const { canDelete } = useRoleBasedPermissions();
  return (
    <Toolbar {...props}>
      <SaveButton />
      {canDelete('videos') && (
        <DeleteButton
          redirect={(basePath: any) => redirectAfterEdit(basePath, record.id, record)}
        />
      )}
    </Toolbar>
  );
};
