import * as React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import ResourceTitleActionBar from 'src/components/resource_title_action_bar';

import CategoryInput from './components/category_input';

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const ProfileSkillCreate = () => (
  <Create actions={CreateActions}>
    <SimpleForm>
      <TextInput source="emoji" fullWidth />
      <TextInput source="labelEN" label="Label EN" fullWidth required />
      <TextInput source="labelFR" label="Label FR" fullWidth required />
      <TextInput source="key" fullWidth required />
      <CategoryInput />
    </SimpleForm>
  </Create>
);
