import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Card, CardContent, CardHeader } from '@mui/material';
import { httpClient } from 'src/dataProvider';
import moment from 'moment-timezone';

import ellipsis from '@services/ellipsis';
import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

import Config from '../../../../config';

const TableHeaderCell: React.FC<{
  label?: string;
  field: string;
  sortBy?: string;
  orderBy?: string;
  fieldClickHandler?: (field: string) => void;
}> = ({ label, field, sortBy, orderBy, fieldClickHandler }) => (
  <th>
    <div
      style={{ ...tableStyles.header, ...tableStyles.cell }}
      onClick={() => fieldClickHandler?.(field)}
    >
      {label || field}
      {sortBy &&
        orderBy &&
        fieldClickHandler &&
        sortBy === field &&
        (orderBy === 'asc' ? (
          <ArrowUpwardIcon fontSize="inherit" />
        ) : (
          <ArrowDownwardIcon fontSize="inherit" />
        ))}
    </div>
  </th>
);

const D0Actions: React.FC = () => {
  const [sortBy, setSortBy] = useState('completionRate');
  const [orderBy, setOrderBy] = useState('desc');
  const [dateRange, setDateRange] = useState('7d');

  const [actions, setActions] = useState<
    [
      {
        name: string;
        publishedAt: string;
        missionName: string;
        campaignName: string;
        totalAssigment: number;
        totalCompleted: number;
        totalSkipped: number;
        totalPending: number;
        completionRate: number;
        skipRate: number;
      },
    ]
  >();

  const sortedActions = useMemo(() => {
    if (!actions) {
      return [];
    }
    // Sort by sortBy
    return actions.sort((a: any, b: any) => {
      if (a[sortBy] === b[sortBy]) {
        return 0;
      }
      if (orderBy === 'asc') {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      }
      return a[sortBy] < b[sortBy] ? 1 : -1;
    });
  }, [actions, sortBy, orderBy]);

  const fetchData = useCallback(async () => {
    let query = '';
    if (dateRange === '7d') {
      const from = moment().subtract(7, 'days').format('YYYY-MM-DD');
      query = `?from=${from}`;
    }
    if (dateRange === '14d') {
      const from = moment().subtract(14, 'days').format('YYYY-MM-DD');
      query = `?from=${from}`;
    }
    if (dateRange === '30d') {
      const from = moment().subtract(30, 'days').format('YYYY-MM-DD');
      query = `?from=${from}`;
    }
    const {
      json: { data },
    } = await httpClient(`${Config.API_URL}/actions/statistics/d0${query}`);
    setActions(data);
  }, [dateRange]);

  const fieldClickHandler = useCallback(
    (field: string) => {
      if (sortBy === field) {
        setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
      } else {
        setSortBy(field);
        setOrderBy('asc');
      }
    },
    [sortBy, orderBy]
  );

  const getHealthEmoji = useCallback((action: any) => {
    if (action.completionRate >= 80) {
      return '🟢';
    }
    if (action.completionRate >= 50) {
      return '🟠';
    }
    return '🔴';
  }, []);

  const ActionsTable = useMemo(() => {
    if (!sortedActions.length) {
      return null;
    }
    return (
      <table cellPadding={0} cellSpacing={0} width={'100%'}>
        <thead>
          <tr>
            <TableHeaderCell field="Health" />
            <TableHeaderCell
              label="Campaign"
              field="campaignName"
              sortBy={sortBy}
              orderBy={orderBy}
              fieldClickHandler={fieldClickHandler}
            />
            <TableHeaderCell
              label="Action"
              field="name"
              sortBy={sortBy}
              orderBy={orderBy}
              fieldClickHandler={fieldClickHandler}
            />
            <TableHeaderCell
              label="Published at"
              field="publishedAt"
              sortBy={sortBy}
              orderBy={orderBy}
              fieldClickHandler={fieldClickHandler}
            />
            <TableHeaderCell
              label="Assigments"
              field="totalAssigment"
              sortBy={sortBy}
              orderBy={orderBy}
              fieldClickHandler={fieldClickHandler}
            />
            <TableHeaderCell
              label="Completed"
              field="totalCompleted"
              sortBy={sortBy}
              orderBy={orderBy}
              fieldClickHandler={fieldClickHandler}
            />
            <TableHeaderCell
              label="Skipped"
              field="totalSkipped"
              sortBy={sortBy}
              orderBy={orderBy}
              fieldClickHandler={fieldClickHandler}
            />
            <TableHeaderCell
              label="Pending"
              field="totalPending"
              sortBy={sortBy}
              orderBy={orderBy}
              fieldClickHandler={fieldClickHandler}
            />
            <TableHeaderCell
              label="Completion rate"
              field="completionRate"
              sortBy={sortBy}
              orderBy={orderBy}
              fieldClickHandler={fieldClickHandler}
            />
            <TableHeaderCell
              label="Skip rate"
              field="skipRate"
              sortBy={sortBy}
              orderBy={orderBy}
              fieldClickHandler={fieldClickHandler}
            />
          </tr>
        </thead>
        <tbody>
          {sortedActions.map((action: any, index: number) => (
            <tr
              key={action.id}
              style={{
                ...tableStyles.rows,
                ...(index % 2 ? tableStyles.rowsEven : null),
              }}
            >
              <td style={tableStyles.cell}>{getHealthEmoji(action)}</td>
              <td style={tableStyles.cell}>{ellipsis(action.campaignName)}</td>
              <td style={{ ...tableStyles.nameCell, ...tableStyles.cell }}>
                <a
                  href={`#/actions/${action.id}/show`}
                  style={{ color: Colors.Black.primary, textDecoration: 'none' }}
                >
                  {ellipsis(action.name, 100)}
                </a>
              </td>
              <td style={tableStyles.cell}>
                {moment(action.publishedAt).format('MMM D YYYY')}
              </td>
              <td style={{ ...tableStyles.numberCell, ...tableStyles.cell }}>
                {action.totalAssigment}
              </td>
              <td style={{ ...tableStyles.numberCell, ...tableStyles.cell }}>
                {action.totalCompleted}
              </td>
              <td style={{ ...tableStyles.numberCell, ...tableStyles.cell }}>
                {action.totalSkipped}
              </td>
              <td style={{ ...tableStyles.numberCell, ...tableStyles.cell }}>
                {action.totalPending}
              </td>
              <td style={{ ...tableStyles.numberCell, ...tableStyles.cell }}>
                {Math.round(action.completionRate)}%
              </td>
              <td style={{ ...tableStyles.numberCell, ...tableStyles.cell }}>
                {Math.round(action.skipRate)}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [sortedActions, sortBy, orderBy, fieldClickHandler, getHealthEmoji]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Card>
      <CardHeader
        title="Actions assigned at D0"
        action={
          <div style={filtersStyles.container}>
            <div
              style={{
                ...filtersStyles.button,
                ...(dateRange === '7d' && filtersStyles.buttonActive),
              }}
              onClick={() => setDateRange('7d')}
            >
              last 7 days
            </div>
            <div
              style={{
                ...filtersStyles.button,
                ...(dateRange === '14d' && filtersStyles.buttonActive),
              }}
              onClick={() => setDateRange('14d')}
            >
              last 14 days
            </div>
            <div
              style={{
                ...filtersStyles.button,
                ...(dateRange === '30d' && filtersStyles.buttonActive),
              }}
              onClick={() => setDateRange('30d')}
            >
              last 30 days
            </div>
          </div>
        }
      />
      <CardContent>{ActionsTable}</CardContent>
    </Card>
  );
};

const filtersStyles: any = {
  container: {
    display: 'inline-flex',
    gap: SpacingStyle[4],
    border: `1px solid ${Colors.OffBlack.primary}`,
    borderRadius: 40,
    overflow: 'hidden',
  },
  button: {
    padding: `${SpacingStyle[4]}px ${SpacingStyle[8]}px`,
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: 600,
    cursor: 'pointer',
  },
  buttonActive: {
    backgroundColor: Colors.OffBlack.primary,
    color: Colors.White.primary,
  },
};

const tableStyles: any = {
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle[4],
    fontSize: FontStyle.sizeVeryVerySmall,
    backgroundColor: Colors.OffBlack.primary,
    color: Colors.White.primary,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  rows: {
    fontSize: FontStyle.sizeSmall,
  },
  rowsEven: {
    backgroundColor: Colors.Grey[25],
  },
  cell: {
    padding: SpacingStyle[4],
  },
  nameCell: {
    fontWeight: 600,
    lineHeight: 1.2,
    textAlign: 'left',
  },
  numberCell: {
    textAlign: 'right',
  },
};

export default D0Actions;
