import * as React from 'react';
import { SimpleForm, Create } from 'react-admin';

import CustomReferenceInput from '../../components/inputs/custom_reference_input';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `topics/${data.topicId}/show`;

export const UsersTopicsCreate = () => (
  <Create redirect={redirectAfterCreate}>
    <SimpleForm>
      <CustomReferenceInput source="topicId" reference="topics" queryKey="nameEN" />
      <CustomReferenceInput
        source="userId"
        reference="users"
        queryKey="username"
        sort={{
          field: 'username',
          order: 'ASC',
        }}
        suggestionLimit={100}
      />
    </SimpleForm>
  </Create>
);
