import React from 'react';

import { BorderStyle, Colors, SpacingStyle } from '@styles/variables';

type CardStepperProgressionBarProps = {
  steps: any[];
  activeStep: number;
  style?: React.CSSProperties;
};
const CardStepperProgressionBar = ({
  steps,
  activeStep,
  style,
}: CardStepperProgressionBarProps) => (
  <div style={{ ...styles.container, ...style }}>
    {steps.map((_step, index) => (
      <div
        key={index}
        style={{
          ...styles.step,
          ...(index <= activeStep ? styles.activeStep : {}),
        }}
      />
    ))}
  </div>
);

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: SpacingStyle.normal,
    marginBottom: SpacingStyle.big,
  },
  step: {
    flex: 1,
    height: 8,
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: Colors.Black.transparent.max,
  },
  activeStep: {
    backgroundColor: Colors.OffBlack.primary,
  },
};

export default CardStepperProgressionBar;
