import React, { useState } from 'react';
import { Button } from 'react-admin';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import OrganizationAddIcon from '@mui/icons-material/DomainAdd';
import PersonAddIcon from '@mui/icons-material/PersonAddAlt1';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import dataProvider from 'src/dataProvider';

import { EMAIL_REGEX } from '@models/action_ctas/emails/components/email_recipient/email_recipient';
import InputStyle from '@styles/input';
import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

import Styles from '../styles';

type CRMCreateDialogStep1Props = {
  initialEmail?: string;
  onConfirm: (data: any) => void;
};
const CRMCreateDialogStep1 = ({ initialEmail, onConfirm }: CRMCreateDialogStep1Props) => {
  const [email, setEmail] = useState(initialEmail || '');
  const [isVerifying, setIsVerifying] = useState(false);
  const [emailStatus, setEmailStatus] = useState<string>('');
  const statusDefinitions: any = {
    deliverable: {
      message: 'High level of confidence that the email address is working.',
      color: Colors.Green.primary,
    },
    undeliverable: {
      message: 'High level of confidence that the email address is NOT working.',
      color: Colors.Red.primary,
    },
    risky: {
      message:
        'Email seems to be deliverable but should be used with caution due to low quality or deliverability.',
      color: Colors.Orange[500],
    },
    unknown: {
      message:
        "The domain is not responding, validity of the email account can't be determined.",
      color: Colors.Grey.primary,
    },
  };

  const validateEmail = async () => {
    try {
      setIsVerifying(true);
      setEmailStatus('');
      if (!email || !email.match(EMAIL_REGEX)) {
        setEmailStatus('Invalid email format');
        return;
      }
      const { data } = await dataProvider.crmEmailValidation(email);
      setEmailStatus(data.state);
    } catch (error: any) {
      setEmailStatus(error.message);
      console.error(error);
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <div style={{ width: 500 }}>
      <DialogTitle>Contact creation</DialogTitle>
      <DialogContent>
        <label style={Styles.label}>
          email *
          <div
            style={{
              display: 'flex',
              gap: SpacingStyle[4],
            }}
          >
            <input
              type="email"
              autoFocus
              placeholder="press@organization.com"
              style={InputStyle.input}
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              data-1p-ignore
            />
            <Button
              startIcon={<MarkEmailReadIcon />}
              label="Verify"
              onClick={() => validateEmail()}
              disabled={isVerifying}
              variant="outlined"
              style={{ width: 110 }}
            />
          </div>
        </label>
        {emailStatus && (
          <div
            style={{
              fontSize: FontStyle.sizeVerySmall,
              color: statusDefinitions[emailStatus]?.color || Colors.Grey.primary,
            }}
          >
            {emailStatus && statusDefinitions?.hasOwnProperty(emailStatus)
              ? statusDefinitions[emailStatus].message
              : emailStatus}
          </div>
        )}
        <label style={{ ...Styles.label, marginTop: 20 }}>Type of contact</label>
        <div style={Styles.flexRow}>
          <Button
            onClick={async () => await onConfirm({ type: 'CRMPerson', email })}
            style={Styles.button}
            label="Person"
            fullWidth
            startIcon={<PersonAddIcon />}
            disabled={isVerifying}
          />
          <Button
            onClick={async () => await onConfirm({ type: 'CRMOrganization', email })}
            style={Styles.button}
            label="Organization"
            fullWidth
            startIcon={<OrganizationAddIcon />}
            disabled={isVerifying}
          />
        </div>
      </DialogContent>
    </div>
  );
};

export default CRMCreateDialogStep1;
