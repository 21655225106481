import React from 'react';
import { Button, useDataProvider } from 'react-admin';
import { useMutation } from 'react-query';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';

type AddRemoveFFToBetaUsersProps = {
  featureFlag: any;
};
export const AddFFToBetaUsers = ({ featureFlag }: AddRemoveFFToBetaUsersProps) => {
  const dataProvider = useDataProvider();
  const { isSuperAdmin, isProduct } = useRoleBasedPermissions();

  const { mutate, isLoading } = useMutation(
    ['addFFToBetaUsers', featureFlag],
    async () => {
      await dataProvider.addFFToBetaUsers({
        featureFlagId: featureFlag.id,
      });
      window.alert('Added to chilli team');
    }
  );
  if (!isSuperAdmin && !isProduct) {
    return <></>;
  }
  return (
    <Button
      label="Add to chilli team"
      onClick={() => mutate()}
      children={<GroupAddIcon />}
      disabled={isLoading}
      variant="outlined"
    />
  );
};

export const RemoveFFFromBetaUsers = ({ featureFlag }: AddRemoveFFToBetaUsersProps) => {
  const dataProvider = useDataProvider();
  const { isSuperAdmin, isProduct } = useRoleBasedPermissions();

  const { mutate, isLoading } = useMutation(
    ['removeFFFromBetaUsers', featureFlag],
    async () => {
      await dataProvider.removeFFFromBetaUsers({
        featureFlagId: featureFlag.id,
      });
      window.alert('Removed from chilli team');
    }
  );
  if (!isSuperAdmin && !isProduct) {
    return <></>;
  }
  return (
    <Button
      label="Remove from chilli team"
      onClick={() => mutate()}
      children={<GroupRemoveIcon />}
      disabled={isLoading}
      variant="outlined"
    />
  );
};
