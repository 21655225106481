import * as React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import ResourceTitleActionBar from 'src/components/resource_title_action_bar';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `profile_prompts/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const ProfilePromptEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <TextInput disabled source="id" fullWidth />
      <TextInput source="questionEN" label="Question EN" fullWidth required />
      <TextInput source="questionFR" label="Question FR" fullWidth required />
      <TextInput source="placeholderEN" label="Placeholder EN" fullWidth />
      <TextInput source="placeholderFR" label="Placeholder FR" fullWidth />
    </SimpleForm>
  </Edit>
);
