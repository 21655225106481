import * as React from 'react';
import {
  BooleanField,
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
} from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ListActions = (
  <ResourceTitleActionBar
    mode="list"
    filters={
      <Filter>
        <SearchInput source="name" alwaysOn />
      </Filter>
    }
  />
);

export const FeatureFlagList = () => (
  <List
    sort={{ field: 'name', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    exporter={false}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="description" />
      <BooleanField source="enabledAt" looseValue label="Enabled" />
    </Datagrid>
  </List>
);
