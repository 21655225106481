import * as React from 'react';
import {
  Edit,
  ImageField,
  ImageInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import ResourceTitleActionBar from 'src/components/resource_title_action_bar';

const EditActions = <ResourceTitleActionBar mode="edit" resourceName="Persons" />;

export const CRMPersonEdit = () => (
  <Edit mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <TextInput source="firstname" fullWidth />
      <TextInput source="lastname" fullWidth />
      <TextInput source="email" fullWidth />
      <SelectInput
        source="gender"
        fullWidth
        choices={[
          { id: 'female', name: 'Female' },
          { id: 'male', name: 'Male' },
          { id: 'other', name: 'Other' },
        ]}
      />
      <TextInput source="location" fullWidth />

      <TextInput source="organizationName" fullWidth />
      <TextInput source="jobTitle" fullWidth />

      <ImageField source="imageUrl" fullWidth />
      <ImageInput
        source="picture"
        accept="image/*"
        maxSize={5000000}
        label="Profile picture"
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <hr style={{ width: '100%', height: 1 }} />
      <TextInput source="notes" fullWidth multiline />
      <hr style={{ width: '100%', height: 1 }} />

      <TextInput source="facebookUrl" fullWidth />
      <TextInput source="instagramUrl" fullWidth />
      <TextInput source="linkedinUrl" fullWidth />
      <TextInput source="threadUrl" fullWidth />
      <TextInput source="tiktokUrl" fullWidth />
      <TextInput source="twitterUrl" fullWidth />
    </SimpleForm>
  </Edit>
);
