import * as React from 'react';
import { List, Datagrid, ReferenceField, DateField } from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';

export const UserProfileTagList = () => (
  <List perPage={PER_PAGE} pagination={<PER_PAGE_OPTIONS />} emptyWhileLoading>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <DateField source="createdAt" showTime />
      <ReferenceField source="userId" reference="users" link="show" />
      <ReferenceField source="tagId" reference="profile_tags" link="show" />
    </Datagrid>
  </List>
);
