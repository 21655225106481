import * as React from 'react';
import {
  Create,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const redirectAfterCreate = (_basePath: any, _id: any, data: any) =>
  `campaigns/${data.id}/show`;

const CreateActions = () => <ResourceTitleActionBar mode="create" />;

export const CampaignCreate = ({ ...props }) => (
  <Create actions={<CreateActions />} redirect={redirectAfterCreate} {...props}>
    <SimpleForm>
      <ImageInput
        source="picture"
        accept="image/*"
        maxSize={5000000}
        validate={[required()]}
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <TextInput source="name" fullWidth validate={[required()]} />
      <TextInput
        label="Context"
        source="descriptionEN"
        fullWidth
        multiline
        helperText="Give as much context as possible to the campaign, this will help GPT generate better content for actions."
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);
