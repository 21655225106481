import React from 'react';
import { SelectInput, required } from 'react-admin';

const BADGE_EMOJIS = [
  { id: '🔔', name: '🔔' },
  { id: '🗞️', name: '🗞️' },
  { id: '🔗', name: '🔗' },
  { id: '📱', name: '📱' },
  { id: '🚨', name: '🚨' },
  { id: '🏆', name: '🏆' },
  { id: '🫶', name: '🫶' },
  { id: '☝️', name: '☝️' },
  { id: '✊', name: '✊' },
];

export const BadgeEmojiSelectInput = () => (
  <SelectInput
    source="badgeEmoji"
    choices={BADGE_EMOJIS}
    defaultValue={BADGE_EMOJIS[0].id}
    validate={required()}
    label="Emoji"
  />
);
