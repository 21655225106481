/**
 * Renders a preview of the Notification Center
 */
import React from 'react';

type NotificationCenterPreviewProps = {
  notifications: any[];
};
export default ({ notifications }: NotificationCenterPreviewProps) => {
  return (
    <div style={styles.background}>
      <div style={styles.today}>Today</div>
      {notifications.map((notification, index) => (
        <div style={styles.notification} key={index}>
          <div style={styles.notificationImageContainer}>
            <img style={styles.notificationImage} src={notification.imageUrl} />
            <div style={styles.notificationEmoji}>{notification.badgeEmoji || '🔔'}</div>
          </div>
          <div style={styles.notificationText}>
            <div style={styles.notificationTitle}>
              {notification.title || 'Enter a title'}
            </div>{' '}
            <div style={styles.notificationBody}>
              {notification.body ? (
                notification.body
              ) : (
                <span
                  style={{
                    color: '#bbb',
                  }}
                >
                  Type something in...
                </span>
              )}
              <span style={{ color: '#999', fontSize: '.7em', lineHeight: '0' }}>
                {' '}
                – 1min
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const styles: any = {
  background: {
    display: 'flex',
    flexDirection: 'column',
    width: 320,
    height: 500,
    marginBottom: 20,
    paddingTop: 40,
    backgroundColor: '#ddd',
    backgroundImage:
      "url('https://res.cloudinary.com/hsiz9ovy1/image/upload/v1685652840/in_app_notifications/notification_center_tlj5rp.jpg')",
    backgroundSize: 'cover',
    borderRadius: 8,
    overflow: 'hidden',
    boxShadow: '0px 4px 10px 4px rgba(0,0,0,.2)',
  },
  today: {
    margin: '0 12px',
    fontSize: 20,
    fontWeight: 'bold',
  },
  notification: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginTop: 16,
    padding: '0 12px 12px 12px',
    backgroundColor: '#fff',
  },
  notificationImageContainer: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    alignItems: 'flex-start',
  },
  notificationImage: {
    width: 42,
    height: 42,
    borderRadius: '50%',
  },
  notificationEmoji: {
    position: 'absolute',
    top: 24,
    right: -4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 22,
    height: 22,
    backgroundColor: '#e0e0ff',
    border: '2px solid #fff',
    fontSize: 11,
    borderRadius: 100,
  },
  notificationText: {
    marginLeft: 10,
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '1.2em',
  },
  notificationTitle: {
    fontWeight: 'bold',
  },
  notificationBody: {
    color: '#384250',
    fontWeight: '500',
  },
};
