import React from 'react';

const emojis = {
  app_page: '📱',
  email: '✉️',
  external_link: '🔗',
  google_maps: '📍',
  nothing: '🙅',
  social_comment: '💬',
  social_post: '💬',
  webpage: '🌐',
  question: '❓',
  phone_call: '📞',
  share_link: '🔗',
};

type ActionCTAEmojiProps = {
  type: keyof typeof emojis;
  style?: React.CSSProperties;
};

const ActionCTAEmoji = ({ type, style }: ActionCTAEmojiProps) => {
  if (!type || !emojis[type]) {
    return <span style={style}>🙅</span>;
  }
  return <span style={style}>{emojis[type]}</span>;
};

export default ActionCTAEmoji;
