import * as React from 'react';
import { Edit } from 'react-admin';
import { BadgeType } from 'types/badge';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

import BadgeForm from './components/badge_form';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `badges/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" title="Badges" />;

const transform = (data: BadgeType) => {
  const { users, ...rest } = data;
  return rest;
};

export const BadgeEdit = () => (
  <Edit
    redirect={redirectAfterCreateEdit}
    mutationMode="pessimistic"
    actions={EditActions}
    transform={transform}
  >
    <BadgeForm type="edit" />
  </Edit>
);
