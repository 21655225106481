import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import D0Actions from './d0_actions';

const ActionsStatistics: React.FC = () => {
  return (
    <div>
      <ResourceTitleActionBar title="Statistics" mode="show" />

      <D0Actions />
    </div>
  );
};

export default ActionsStatistics;
