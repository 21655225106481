import React, { useState } from 'react';
import { ActionType, QuestionType, ResponseCTAType } from 'types/action.d';
import SeeSVG from '@mui/icons-material/Visibility';

import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import UsersResponsesDialog from './users_responses_dialog';

type ActionCtaQuestionsActionCardShowProps = {
  action: ActionType;
};

const ActionCtaQuestionsActionCardShow = ({
  action,
}: ActionCtaQuestionsActionCardShowProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentResponse, setCurrentResponse] = useState<ResponseCTAType | null>(null);

  if (!action || !action.actionCtaQuestion) {
    return null;
  }

  const openModal = (response: ResponseCTAType) => {
    setCurrentResponse(response);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentResponse(null);
  };

  return (
    <div style={{ width: '100%' }}>
      <h4 style={{ margin: 0 }}>
        {action.actionCtaQuestion.type === QuestionType.MULTIPLE_CHOICE &&
          'Multiple-choice question'}
        {action.actionCtaQuestion.type === QuestionType.SINGLE_CHOICE &&
          'Single-choice question'}
      </h4>
      <ul>
        <li>🇺🇸 {action.actionCtaQuestion.questionEN}</li>
        <li>🇫🇷 {action.actionCtaQuestion.questionFR}</li>
      </ul>

      <h4 style={{ margin: 0 }}>
        {action.actionCtaQuestion.usersResponsesCount}{' '}
        {`response${
          parseFloat(action.actionCtaQuestion.usersResponsesCount || '0') > 1 ? 's' : ''
        }`}
      </h4>
      <div>
        {action.actionCtaQuestion.responses.map((response, index) => {
          let percentage = 0;
          const totalUsersResponsesCount = parseInt(
            action.actionCtaQuestion?.usersResponsesCount || '0',
            10
          );
          const usersResponsesCount = parseInt(response.usersResponsesCount || '0', 10);
          if (usersResponsesCount > 0) {
            percentage = usersResponsesCount / totalUsersResponsesCount;
          }
          return (
            <div
              key={response.id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  maxWidth: 500,
                  backgroundColor: Colors.Background.white,
                  padding: SpacingStyle.small,
                  borderRadius: BorderStyle.Radius.small,
                  margin: SpacingStyle.small,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <p
                  style={{
                    fontSize: FontStyle.sizeSmall,
                    fontWeight: '500',
                    margin: 0,
                  }}
                >
                  {index + 1}. {response.responseEN} / {response.responseFR}
                </p>
                <p
                  style={{
                    margin: 0,
                    fontSize: FontStyle.sizeVerySmall,
                    color: Colors.Grey.primary,
                  }}
                >
                  {(percentage * 100).toFixed(2) || 0}%
                </p>
              </div>

              <button
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => openModal(response)}
              >
                <p
                  style={{
                    fontSize: FontStyle.sizeVerySmall,
                    color: Colors.OffBlack.primary,
                    fontWeight: 'bold',
                    alignItems: 'center',
                  }}
                >
                  {usersResponsesCount} responses
                </p>
                <SeeSVG style={{ fontSize: FontStyle.sizeSmall }} />
              </button>
            </div>
          );
        })}
      </div>

      <UsersResponsesDialog
        open={modalIsOpen}
        onClose={closeModal}
        response={currentResponse}
      />
    </div>
  );
};

export default ActionCtaQuestionsActionCardShow;
