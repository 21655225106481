import React, { useState, useEffect, useCallback } from 'react';
import { Box, IconButton, FormControlLabel, Radio } from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { Button, useInput } from 'react-admin';
import { Bucket } from 'types/donation';
import { useFormContext } from 'react-hook-form';

import ConfigureBucketDialog from './configure_bucket_dialog';

interface RadioBucketInputProps {
  source: string;
}

const RadioBucketInput: React.FC<RadioBucketInputProps> = ({ source }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBucket, setSelectedBucket] = useState<Bucket | null>(null);

  const { getValues } = useFormContext();
  const {
    field: { value: buckets, onChange },
  } = useInput({ source });

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setSelectedBucket(null);
    setIsModalOpen(false);
  };

  /**
   * Generate donation buckets based on the maxCoins value
   */
  const handleGenerateBuckets = useCallback(() => {
    const donationMaxCoins = getValues('maxCoins');
    const maxCoinsGivable = Math.max(donationMaxCoins / 1000, 1000);
    const increament = Math.round(maxCoinsGivable / 4 / 10) * 10;
    const newBuckets = [
      {
        value: increament,
        descriptionEN: 'To edit',
        descriptionFR: 'À éditer',
        isDefault: false,
      },
      {
        value: increament * 2,
        descriptionEN: 'To edit',
        descriptionFR: 'À éditer',
        isDefault: false,
      },
      {
        value: increament * 3,
        descriptionEN: 'To edit',
        descriptionFR: 'À éditer',
        isDefault: true,
      },
      {
        value: increament * 4,
        descriptionEN: 'To edit',
        descriptionFR: 'À éditer',
        isDefault: false,
      },
    ];
    onChange(newBuckets);
  }, [onChange, getValues]);

  /**
   * Save a bucket to the list
   * @param bucketData
   * @param initialBucketValue
   */
  const handleSaveBucket = useCallback(
    (bucketData: Bucket, initialBucketValue?: number) => {
      const newBuckets = [
        ...(buckets || []).filter(
          (bucket: Bucket) =>
            bucket.value !== bucketData.value && bucket.value !== initialBucketValue
        ),
        { ...bucketData, isDefault: false },
      ].sort((a: Bucket, b: Bucket) => a.value - b.value);

      onChange(newBuckets);
    },
    [buckets, onChange]
  );

  /**
   * Select a bucket as the default one
   */
  const handleSelectDefaultBucket = useCallback(
    (value: number) => {
      const newBuckets = (buckets || []).map((bucket: Bucket) => ({
        ...bucket,
        isDefault: bucket.value === value,
      }));
      onChange(newBuckets);
    },
    [buckets, onChange]
  );

  /**
   * Edit a bucket from the list
   */
  const handleEditBucket = useCallback(
    (value: number) => {
      const bucket = buckets.find((bucket: Bucket) => bucket.value === value);
      if (bucket) {
        setSelectedBucket(bucket);
        setIsModalOpen(true);
      }
    },
    [buckets]
  );

  /**
   * Delete a bucket from the list
   */
  const handleDeleteBucket = useCallback(
    (value: number) => {
      const newBuckets = (buckets || []).filter(
        (bucket: Bucket) => bucket.value !== value
      );
      onChange(newBuckets);
    },
    [buckets, onChange]
  );

  useEffect(() => {
    if (
      buckets &&
      buckets.length > 0 &&
      !buckets.some((bucket: Bucket) => bucket.isDefault)
    ) {
      const defaultBucket = buckets[2] || buckets[0];
      if (defaultBucket) {
        handleSelectDefaultBucket(defaultBucket.value);
      }
    }
  }, [buckets, handleSelectDefaultBucket]);

  return (
    <Box>
      <strong></strong>
      {buckets && buckets.length > 0 && (
        <Box>
          {buckets.map((bucket: Bucket) => (
            <Box
              key={bucket.value}
              display="flex"
              alignItems="center"
              marginBottom={1}
              minWidth={400}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={bucket.isDefault}
                    onChange={() => handleSelectDefaultBucket(bucket.value)}
                    value={bucket.value}
                    name="bucket-radio"
                  />
                }
                label={
                  <div style={{ lineHeight: '1em' }}>
                    {bucket.value} {bucket.isDefault && <small>(most popular)</small>}
                    <br />
                    <small>
                      {bucket.descriptionEN} | {bucket.descriptionFR}
                    </small>
                  </div>
                }
                style={{ flexGrow: 1 }}
              />
              <IconButton
                onClick={() => handleEditBucket(bucket.value)}
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteBucket(bucket.value)}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
      {(!buckets || buckets.length === 0) && (
        <Button
          onClick={handleGenerateBuckets}
          label="Generate donation buckets"
          variant="outlined"
          startIcon={<AddIcon />}
        />
      )}
      {buckets && buckets.length < 4 && (
        <Button
          onClick={handleOpenModal}
          label="Add a bucket"
          variant="outlined"
          startIcon={<AddIcon />}
          disabled={buckets && buckets.length === 4}
        />
      )}
      <ConfigureBucketDialog
        open={isModalOpen}
        nBucketsAdded={buckets ? buckets.length : 0}
        initialBucketState={selectedBucket}
        onClose={handleCloseModal}
        onSave={handleSaveBucket}
      />
    </Box>
  );
};

export default RadioBucketInput;
