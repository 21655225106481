import React, { useCallback } from 'react';
import {
  Button,
  Create,
  DeleteButton,
  FormDataConsumer,
  ImageField,
  ImageInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
} from 'react-admin';
import { PepperPillType, PepperPillTypes } from 'types/pepper_pill.d';
import { useFormContext } from 'react-hook-form';

import { useOpenAI } from '@hooks/useOpenAI';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import UrlInput from '@components/inputs/url_input';
import Callout from '@components/callout';
import PepperAvatar from '@components/pepper_avatar';

import PepperPillPreview from './components/preview';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `pepper_pills/${data.id}/show`;

const CreateActions = <ResourceTitleActionBar mode="create" title="Pepper Pills" />;

/**
 * Toolbar for Pepper Pill Create and Edit
 */
export const PepperPillToolbar = ({ ...props }) => {
  const { getValues, setValue } = useFormContext();

  const { isLoading: isOpenAILoading, fixGrammar } = useOpenAI();

  const fixGrammarHandler = useCallback(async () => {
    const [title, content] = getValues(['titleEN', 'contentEN']);

    try {
      if (title) {
        const data = await fixGrammar({ text: title, saveInClipboard: false });
        setValue('titleEN', data);
      }
      if (content) {
        const data = await fixGrammar({ text: content, saveInClipboard: false });
        setValue('contentEN', data);
      }
    } catch (error) {
      console.error('[PepperPillCreate.fixGrammarHandler]', error);
    }
  }, [fixGrammar, getValues, setValue]);

  return (
    <Toolbar {...props}>
      <Button
        onClick={fixGrammarHandler}
        label="Fix title and content grammar"
        disabled={isOpenAILoading}
        startIcon={<PepperAvatar />}
        variant="outlined"
        size="medium"
        title="Let Pepper fix your grammar"
      />
      <SaveButton disabled={isOpenAILoading} />
      <DeleteButton disabled={isOpenAILoading} />
    </Toolbar>
  );
};

export const PepperPillCreate = () => {
  return (
    <Create redirect={redirectAfterCreateEdit} actions={CreateActions}>
      <SimpleForm toolbar={<PepperPillToolbar />}>
        <SelectInput
          source="type"
          choices={Object.keys(PepperPillTypes).map((type: any) => ({
            id: type.toUpperCase(),
            name: type,
          }))}
          fullWidth
          validate={[required()]}
        />

        <ImageInput
          source="picture"
          accept="image/*"
          maxSize={5000000}
          validate={[required()]}
        >
          <ImageField source="src" title="title" />
        </ImageInput>

        <Callout emoji="🇺🇸">
          Write Pepper Pill in <strong>English</strong>, it will be automatically
          translated to French for French users.
        </Callout>
        <TextInput label="Title 🇺🇸" source="titleEN" validate={[required()]} fullWidth />
        <TextInput
          label="Content 🇺🇸"
          source="contentEN"
          validate={[required()]}
          multiline
          minRows={8}
          inputProps={{ maxLength: 500 }}
          helperText="Max 500 characters"
          fullWidth
        />

        <UrlInput label="Source (url)" source="sourceUrl" />

        <FormDataConsumer>
          {({ formData }) => {
            const imageUrl = formData.picture?.src;
            return (
              <PepperPillPreview
                pepperPill={{ ...formData, imageUrl } as PepperPillType}
              />
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
