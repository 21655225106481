import React from 'react';
import { useRecordContext } from 'react-admin';

const SettingsNotificationTimeOfDay = ['anytime', 'morning', 'afternoon', 'evening'];

const UserSettingsNotificationTimeOfDay = ({ source }: any) => {
  const record = useRecordContext();
  return <div>{SettingsNotificationTimeOfDay[record[source]]}</div>;
};

export default UserSettingsNotificationTimeOfDay;
