import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import LoadingAnimation from 'src/components/svgs/loading_animation';
import Styles from './styles';

type UniqueActiveUsersPercentageProps = {
  campaign: any;
};
export const UniqueActiveUsersPercentage = ({
  campaign,
}: UniqueActiveUsersPercentageProps) => {
  const dataProvider = useDataProvider();
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchActiveUsersMetrics(campaign.id);
  }, [campaign.id]);

  const fetchActiveUsersMetrics = async (campaignId: string) => {
    setIsLoading(true);
    const { data: analyticsAll } = await dataProvider.getCampaignAnalytics({
      campaignId,
      startDate: '2023-01-01',
    });
    setTotalActiveUsers(analyticsAll?.total?.countUniqueUsers || 0);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div style={Styles.card}>
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <div style={Styles.card}>
      <div style={Styles.cardNumber}>
        {Math.round((totalActiveUsers / (campaign.usersCount || 1)) * 100)}%
      </div>
      <div style={Styles.cardTitle}>
        percentage
        <br />
        of active users
      </div>
      <div style={Styles.cardNumberSubtitle}>
        (Active users: {totalActiveUsers} / Total members: {campaign?.usersCount || 0})
      </div>
    </div>
  );
};
