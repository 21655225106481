import React, { useEffect, useMemo, useState } from 'react';
import { Button, useDataProvider, useRecordContext, useRefresh } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import PublishIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import moment from 'moment-timezone';
import { CausePostType } from 'types/cause_post';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { dateFormatter } from '@services/date';
import TodoChecklist from '@components/todo_checklist';
import Callout from '@components/callout';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

const PublishCard = () => {
  const causePost: CausePostType = useRecordContext();
  const dataprovider = useDataProvider();
  const refresh = useRefresh();
  const { isSuperAdmin, canUpdate: canUpdateFromRBP } = useRoleBasedPermissions();
  const canEdit: boolean = useMemo(
    () => canUpdateFromRBP('causes_posts'),
    [canUpdateFromRBP]
  );

  const [isCauseIdDefined, setIsCauseIdDefined] = useState(false);
  const [isTitleENDefined, setIsTitleENDefined] = useState(false);
  const [isTitleFRDefined, setIsTitleFRDefined] = useState(false);
  const [isContentENDefined, setIsContentENDefined] = useState(false);
  const [isContentFRDefined, setIsContentFRDefined] = useState(false);
  const [areMediasDefined, setAreMediasDefined] = useState(false);
  const [userTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    setIsCauseIdDefined(!!causePost.causeId);
    setIsTitleENDefined(!!causePost.titleEN);
    setIsTitleFRDefined(!!causePost.titleFR);
    setIsContentENDefined(!!causePost.contentEN);
    setIsContentFRDefined(!!causePost.contentFR);
    setAreMediasDefined(!!causePost.medias?.length);
  }, [causePost]);

  const isReadyToPublish = useMemo(() => {
    if (isSuperAdmin) {
      return true;
    }
    return (
      isCauseIdDefined &&
      isTitleENDefined &&
      isTitleFRDefined &&
      isContentENDefined &&
      isContentFRDefined &&
      areMediasDefined
    );
  }, [
    isSuperAdmin,
    isCauseIdDefined,
    isTitleENDefined,
    isTitleFRDefined,
    isContentENDefined,
    isContentFRDefined,
    areMediasDefined,
  ]);

  const publishedInThePast = useMemo(() => {
    return (
      !!causePost?.publishedAt &&
      moment(causePost?.publishedAt).diff(moment(), 'days') <= 0
    );
  }, [causePost?.publishedAt]);

  /**
   * @returns list of missing properties
   */
  const MissingPropertiesBlock = () => {
    const messages = [];
    if (!isCauseIdDefined) {
      messages.push('A cause');
    }
    if (!isTitleENDefined) {
      messages.push('An English title');
    }
    if (!isTitleENDefined) {
      messages.push('An English title');
    }
    if (!isTitleFRDefined) {
      messages.push('A French title');
    }
    if (!isContentENDefined) {
      messages.push('An English content');
    }
    if (!isContentFRDefined) {
      messages.push('A French content');
    }
    if (!areMediasDefined) {
      messages.push('At least one media');
    }
    if (messages.length === 0) {
      if (causePost.publishedAt) {
        return <></>;
      }
      return (
        <div
          style={{
            fontSize: FontStyle.sizeSmall,
            fontWeight: 500,
            color: Colors.Magenta.primary,
          }}
        >
          Ready to be published!
        </div>
      );
    }
    return (
      <div
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          fontSize: FontStyle.sizeVerySmall,
          fontWeight: 500,
          color: Colors.Red.primary,
        }}
      >
        <div>Before publishing, you first need to define:</div>
        <ul style={{ margin: SpacingStyle[4] }}>
          {messages.map((message, i) => (
            <li key={i}>{message}</li>
          ))}
        </ul>
      </div>
    );
  };

  const UnpublishCausePost = () => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
      const confirmed = window.confirm(
        'Are you sure you want to unpublish this post?\nIt will be removed from the app.'
      );
      if (!confirmed) {
        return;
      }
      try {
        setIsLoading(true);
        await dataprovider.update('causes_posts', {
          id: causePost.id,
          data: {
            publishedAt: null,
          },
        } as any);
        await refresh();
      } finally {
        setIsLoading(false);
      }
    };
    if (!causePost?.publishedAt) {
      return <></>;
    }
    return (
      <Button
        variant="outlined"
        onClick={handleClick}
        label="Unpublish"
        endIcon={<EventBusyIcon style={{ width: '.8em' }} />}
        style={{
          fontSize: FontStyle.sizeVerySmall,
        }}
        color="error"
        disabled={isLoading}
      />
    );
  };

  const PublishedBlock = () => {
    const isPublishedAtInThePast = new Date(causePost?.publishedAt) < new Date();
    return (
      <>
        <div style={{ fontSize: FontStyle.sizeSmall, fontWeight: '500' }}>
          {isPublishedAtInThePast ? 'Was published ' : 'Will be published '}
          {moment(causePost?.publishedAt).fromNow()}
        </div>
        <div
          style={{
            fontSize: FontStyle.sizeBig,
            fontWeight: 600,
            lineHeight: 1,
          }}
        >
          {dateFormatter(causePost?.publishedAt, { short: true, withTime: true })}
        </div>
        <div
          style={{
            fontSize: FontStyle.sizeVerySmall,
            fontWeight: 500,
            color: Colors.Grey[500],
          }}
        >
          ({userTimezone})
        </div>
        <Callout emoji="🕰️" backgroundColor={Colors.Grey[25]}>
          <strong>Users will see this post at the following local times:</strong>
          <table>
            <tbody>
              <tr>
                <td>
                  • <strong>Los Angeles</strong>
                </td>
                <td>
                  {moment(causePost?.publishedAt)
                    .tz('America/Los_Angeles')
                    .format('dddd @ hh:mma')}
                </td>
              </tr>
              <tr>
                <td>
                  • <strong>NYC</strong>
                </td>
                <td>
                  {moment(causePost?.publishedAt)
                    .tz('America/New_York')
                    .format('dddd @ hh:mma')}
                </td>
              </tr>
              <tr>
                <td>
                  • <strong>London</strong>
                </td>
                <td>
                  {moment(causePost?.publishedAt)
                    .tz('Europe/London')
                    .format('dddd @ hh:mma')}
                </td>
              </tr>
              <tr>
                <td>
                  • <strong>Paris</strong>
                </td>
                <td>
                  {moment(causePost?.publishedAt)
                    .tz('Europe/Paris')
                    .format('dddd @ hh:mma')}
                </td>
              </tr>
              <tr>
                <td>
                  • <strong>Sydney</strong>
                </td>
                <td>
                  {moment(causePost?.publishedAt)
                    .tz('Australia/Sydney')
                    .format('dddd @ hh:mma')}
                </td>
              </tr>
            </tbody>
          </table>
        </Callout>
      </>
    );
  };

  if (!causePost) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader
        avatar={<PublishIcon />}
        title="Publish"
        subheader="When will this post be available and for how long?"
      />
      <CardContent>
        {/* publishedAt */}
        <TodoChecklist
          checked={!!causePost?.publishedAt}
          label="Set a publication date"
          cta={
            canEdit && (
              <div style={{ display: 'flex' }}>
                <UpdateFieldDialog
                  resource="causes_posts"
                  record={causePost}
                  type="date"
                  field="publishedAt"
                  formatter={date => moment(date).format('YYYY-MM-DDTHH:mm:ssZ')}
                  buttonLabel={causePost.publishedAt ? 'Edit' : 'Publish'}
                  buttonVariant="outlined"
                  disabled={
                    !isReadyToPublish || (!!causePost?.publishedAt && publishedInThePast)
                  }
                />
                <UnpublishCausePost />
              </div>
            )
          }
        >
          {causePost.publishedAt && <PublishedBlock />}
          <MissingPropertiesBlock />
        </TodoChecklist>
      </CardContent>
    </Card>
  );
};

export default PublishCard;
