import React from 'react';

export default ({ ...props }) => (
  <svg
    width="49"
    height="50"
    viewBox="0 0 49 50"
    fill="#140F14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.00585947 24.7335C0.00585947 45.8819 26.3221 59.5227 42.7248 40.2071L40.1786 37.5655C45.904 33.146 48.0059 27.635 48.0059 22.1033H44.2229C50.1789 14.4769 47.2817 3.75032 42.5091 0.500673C39.7173 7.45852 31.6472 5.82799 31.6472 5.82799L32.5458 2.21165C16.2755 -3.92737 0.00443543 7.11034 0.00585947 24.7328V24.7335ZM19.4575 8.25384C10.3387 10.6804 5.37021 19.6775 7.90997 29.1224H7.90926C10.449 38.5673 19.1385 43.9316 28.0829 41.5521C34.2931 39.9002 37.6923 34.9353 37.6923 34.9353L31.5668 28.5976C30.4553 30.5172 28.6318 32.1164 26.5378 32.6739C22.3497 33.7882 18.1887 31.1787 16.9861 26.7072C15.7842 22.2357 18.0812 17.9088 22.2692 16.7945C24.3633 16.237 26.7592 16.6272 28.6838 17.8746L30.8106 9.34109C30.8106 9.34109 25.4933 6.6461 19.4568 8.2517L19.4575 8.25384Z"
    />
  </svg>
);
