import * as React from 'react';
import {
  SimpleShowLayout,
  Show,
  DateField,
  ReferenceField,
  TextField,
} from 'react-admin';
export const UsersFeatureFlagsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="userId" reference="users" fullWidth link="show" />
      <ReferenceField
        source="featureFlagId"
        reference="feature_flags"
        fullWidth
        link="show"
      />
      <DateField
        source="createdAt"
        showTime
        label={`Created at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
      <DateField
        source="updatedAt"
        showTime
        label={`Updated at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
    </SimpleShowLayout>
  </Show>
);
