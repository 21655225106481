import React from 'react';
import { AutocompleteInput, required as RARequired } from 'react-admin';
import { countries } from 'countries-list';

const countriesChoices = Object.entries(countries).map(([countryCode, country]) => ({
  id: countryCode,
  name: `${country.name} (${countryCode})`,
}));

type CountryInputProps = {
  source: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  emptyText?: string;
  fullWidth?: boolean;
  multiple?: boolean;
  onChange?: (event: any) => void;
};
const CountryInput = ({
  source,
  label,
  required,
  disabled,
  helperText,
  emptyText,
  fullWidth,
  multiple = false,
  onChange,
}: CountryInputProps) => (
  <AutocompleteInput
    choices={countriesChoices}
    source={source}
    label={label}
    validate={required ? [RARequired()] : []}
    disabled={disabled}
    helperText={helperText}
    emptyText={emptyText}
    fullWidth={fullWidth}
    style={{ minWidth: 300 }}
    multiple={multiple}
    onChange={onChange}
  />
);

export default CountryInput;
