import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  Form,
  FunctionField,
  List,
  ReferenceField,
  SearchInput,
} from 'react-admin';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
import { CommentType } from 'types/comment';
import { TopicType } from 'types/topic';

import ellipsis from '@services/ellipsis';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import { SpacingStyle } from '@styles/variables';

const ListActions = () => {
  return (
    <ResourceTitleActionBar
      mode="list"
      actions={
        <div>
          <Filter>
            <SearchInput source="message" alwaysOn />
          </Filter>
        </div>
      }
    />
  );
};

export const TopicCommentList = () => {
  const location = useLocation();
  const [listFilters, setListFilters] = useState({} as any);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get('source');
    if (!source) {
      return;
    }
    try {
      const options = JSON.parse(decodeURIComponent(source));
      if (options.topicId) {
        setListFilters({ ...listFilters, topicId: options.topicId });
      }
    } catch (error) {
      console.error('Error parsing source', error);
    }
  }, [location]);

  return (
    <List
      actions={<ListActions />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={PER_PAGE}
      pagination={<PER_PAGE_OPTIONS />}
      exporter={false}
      empty={false}
      emptyWhileLoading
      filter={listFilters}
    >
      <Form>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: SpacingStyle.normal,
            padding: SpacingStyle.normal,
          }}
        >
          <CustomReferenceInput
            reference="topics"
            source="topicId"
            queryKey={'nameEN'}
            optionText="nameENHashtagged"
            defaultValue={listFilters.topicId}
            sort={{ field: 'nameEN', order: 'ASC' }}
            onChange={(topicId: TopicType['id']) =>
              setListFilters({
                ...listFilters,
                topicId: topicId ? topicId : undefined,
              })
            }
          />
        </div>
      </Form>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <ReferenceField source="topicId" reference="topics" link="show" />

        <ReferenceField source="authorId" reference="users" link="show" />

        <DateField
          source="createdAt"
          label={`Posted at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
          showTime
        />

        <FunctionField
          label="Message"
          render={(record: CommentType) => {
            const message = record.message || record.silentMessageEN || '';
            const isInTheFuture = new Date(record.createdAt) > new Date();
            return (
              <ReactMarkdown>
                {(isInTheFuture ? '_[not published yet]_ ' : '') + ellipsis(message, 200)}
              </ReactMarkdown>
            );
          }}
        />

        <ReferenceField source="parentCommentId" reference="topic_comments" link="show">
          <FunctionField
            label="Parent Comment"
            render={(record: CommentType) => ellipsis(record.message, 20)}
          />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
