import React, { useContext, useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { SubtitleStudioContext } from '../context';

const VideoTimeline = () => {
  const wavesurferRef = useRef<any>();
  const [wavesurfer, setWavesurfer] = useState<any>();
  const { video, videoHtmlElement, widthPerSecond } = useContext(SubtitleStudioContext);
  const [frames, setFrames] = useState<any[]>([]);
  const [frameCount, setFrameCount] = useState<number>(0);
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [videoCurrentPosition, setVideoCurrentPosition] = useState<number>(0);

  useEffect(() => {
    if (!videoHtmlElement) {
      return;
    }
    setVideoDuration(videoHtmlElement.duration);
    setVideoCurrentPosition(videoHtmlElement.currentTime);
    videoHtmlElement.onloadeddata = () => setVideoDuration(videoHtmlElement.duration);
    videoHtmlElement.ontimeupdate = () =>
      setVideoCurrentPosition(videoHtmlElement.currentTime);
  }, [videoHtmlElement]);

  useEffect(() => {
    if (!videoHtmlElement || !widthPerSecond || !videoDuration) {
      return;
    }
    setFrameCount((Math.round(videoDuration) * widthPerSecond) / 80);
  }, [videoDuration, widthPerSecond]);

  useEffect(() => {
    const frameTimes = getFrameTimes();
    const frames = frameTimes.map(time => getFrame(time));
    setFrames(frames);
  }, [video?.posterUrl, videoDuration, frameCount]);

  useEffect(() => {
    createWaveform();
  }, [wavesurferRef, wavesurferRef?.current, video, frames]);

  useEffect(() => {
    updateCursorPosition();
  }, [wavesurfer, videoCurrentPosition, videoDuration]);

  const createWaveform = () => {
    if (!wavesurferRef.current || !video) {
      return;
    }
    const wavesurferInstance = WaveSurfer.create({
      container: wavesurferRef.current,
      url: video.videoUrl,
      waveColor: '#C6C5F8',
      autoplay: false,
      barGap: 3,
      barWidth: 2,
      barRadius: 4,
      barHeight: 1,
      barAlign: 'bottom',
      height: 80,
      cursorWidth: 0,
      normalize: true,
      interact: false,
    });
    setWavesurfer(wavesurferInstance);
  };

  const updateCursorPosition = () => {
    if (!wavesurfer || !videoCurrentPosition || !videoDuration) {
      return;
    }
    const position = videoCurrentPosition / videoDuration;
    if (isFinite(position)) {
      wavesurfer.seekTo(position);
      setCursorPosition(position * 100);
    }
  };

  /**
   * @returns an array of frameCount frame times
   */
  const getFrameTimes = () => {
    const frameTimes = [];
    const increase = videoDuration / frameCount;
    for (let i = 0; i < frameCount; i++) {
      frameTimes.push(increase * i);
    }
    return frameTimes;
  };

  const getFrame = (time: number) => {
    if (!video?.posterUrl) {
      return;
    }
    return video.posterUrl.replace('so_0', `so_${Math.round(time)}`);
  };

  const onClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    const clickedPosition = e.nativeEvent.offsetX;
    if (!videoHtmlElement || !videoDuration || !clickedPosition) {
      return;
    }
    const clickedTime =
      (clickedPosition / e.currentTarget.offsetWidth) * videoDuration || 1;
    videoHtmlElement.currentTime = clickedTime;
  };

  if (!frames || !frames.length) {
    return <div style={{ ...StyleSheet.container, height: 80 }} />;
  }

  return (
    <div style={StyleSheet.container} onClick={onClickHandler}>
      {frames.map((frameUrl, index) => (
        <div
          key={index}
          style={{
            ...StyleSheet.frame,
            width: `${Math.min(frameCount, 100)}%`,
            backgroundImage: `url(${frameUrl})`,
          }}
        />
      ))}
      <div style={StyleSheet.wave}>
        <div ref={wavesurferRef} style={StyleSheet.waveform} />
      </div>
      <div
        style={{
          ...StyleSheet.cursor,
          top: -2,
          bottom: -2,
          left: `${cursorPosition}%`,
        }}
      />
    </div>
  );
};

const StyleSheet: any = {
  container: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    height: 80,
    margin: '8px 0',
    backgroundColor: '#000',
    border: '2px solid #595EDA',
    borderRadius: 8,
    overflow: 'hidden',
    boxShadow: '0 0 4px rgba(255,255,255,.3), inset 0 0px 2px rgba(0,0,0,.9)',
  },
  wave: {
    position: 'absolute',
    top: 2,
    left: 0,
    width: '100%',
  },
  frame: {
    height: 80,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity: 0.6,
  },
  cursor: {
    position: 'absolute',
    top: 1,
    bottom: 2,
    width: 4,
    boxShadow: '0 0 3px rgba(0,0,0,.9)',
    backgroundColor: 'white',
    borderRadius: 4,
    zIndex: 100,
  },
};

export default VideoTimeline;
