import React, { useMemo } from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  ReferenceField,
  useRecordContext,
} from 'react-admin';
import { UserType } from 'types/user';
import { CommandCenterUserRole } from 'types/command_center_user_role.d';
import AddIcon from '@mui/icons-material/Add';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';

const ManagedCampaignsTab = () => {
  const user = useRecordContext<UserType>();
  const { canCreate } = useRoleBasedPermissions();

  const managesAllCampaigns = useMemo(() => {
    return (
      user?.commandCenterRole === CommandCenterUserRole.campaign_admin ||
      user?.commandCenterRole === CommandCenterUserRole.super_admin
    );
  }, [user]);

  const cantManageCampaigns = useMemo(() => {
    return (
      !managesAllCampaigns &&
      user?.commandCenterRole !== CommandCenterUserRole.campaign_manager
    );
  }, [managesAllCampaigns, user]);

  if (!user) {
    return null;
  }

  return (
    <>
      {managesAllCampaigns ? (
        <p>{user.username} can manage all campaigns 👍</p>
      ) : cantManageCampaigns ? (
        <p>
          Because of their role on the Command Center,{' '}
          <strong>{user.username} can't manage any campaign</strong>. Change role first.
        </p>
      ) : (
        <>
          <ArrayField source="ownedCampaigns" label="Campaigns managed">
            <Datagrid bulkActionButtons={false}>
              <ReferenceField
                source="id"
                reference="campaigns"
                link="show"
                label="Campaign"
              />
            </Datagrid>
          </ArrayField>
          {canCreate('campaigns_owners') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <Button
                disabled={cantManageCampaigns}
                label="Add to another campaign"
                startIcon={<AddIcon />}
                href={`#/campaigns_owners/create?source={"ownerId":"${user.id}"}`}
                variant="outlined"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ManagedCampaignsTab;
