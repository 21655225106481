import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, CardContent } from '@mui/material';
import QRCodeStyling from 'qr-code-styling';
import { Button } from 'react-admin';
import DownloadIcon from '@mui/icons-material/Download';

import { URL_REGEX } from '@modules/regex';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import Callout from '@components/callout';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

const WIDTH = 400;
const IMAGE_MARGIN = 50;
const IMAGE_SIZE = 0.4;
const DOTS_TYPE = 'rounded';
const CORNERS_TYPE = 'dot';
const CORNERS_DOT_TYPE = 'dot';

/**
 * Black QR Code
 */
const qrCodeBlack = new QRCodeStyling({
  width: WIDTH,
  height: WIDTH,
  image:
    'https://res.cloudinary.com/hsiz9ovy1/image/upload/v1715671032/public_assets/CHILLI_SYMBOL_MAGENTA_RGB_fgbytw.png',
  dotsOptions: {
    color: Colors.Black.primary,
    type: DOTS_TYPE,
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: IMAGE_MARGIN,
    imageSize: IMAGE_SIZE,
  },
  cornersSquareOptions: {
    type: CORNERS_TYPE,
    color: Colors.Magenta.primary,
  },
  cornersDotOptions: {
    type: CORNERS_DOT_TYPE,
    color: Colors.Magenta.primary,
  },
  backgroundOptions: {
    color: 'transparent',
  },
});

/**
 * OffBlack QR Code
 */
const qrCodeOffBlack = new QRCodeStyling({
  width: WIDTH,
  height: WIDTH,
  image:
    'https://res.cloudinary.com/hsiz9ovy1/image/upload/v1715671032/public_assets/CHILLI_SYMBOL_BLACK_RGB_mjfx59.png',
  dotsOptions: {
    color: Colors.OffBlack.primary,
    type: DOTS_TYPE,
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: IMAGE_MARGIN,
    imageSize: IMAGE_SIZE,
  },
  cornersSquareOptions: {
    type: CORNERS_TYPE,
    color: Colors.OffBlack.primary,
  },
  cornersDotOptions: {
    type: CORNERS_DOT_TYPE,
    color: Colors.OffBlack.primary,
  },
  backgroundOptions: {
    color: 'transparent',
  },
});

/**
 * Magenta QR Code
 */
const qrCodeMagenta = new QRCodeStyling({
  width: WIDTH,
  height: WIDTH,
  image:
    'https://res.cloudinary.com/hsiz9ovy1/image/upload/v1715671032/public_assets/CHILLI_SYMBOL_MAGENTA_RGB_fgbytw.png',
  dotsOptions: {
    color: Colors.Magenta.primary,
    type: DOTS_TYPE,
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: IMAGE_MARGIN,
    imageSize: IMAGE_SIZE,
  },
  cornersSquareOptions: {
    type: CORNERS_TYPE,
  },
  cornersDotOptions: {
    type: CORNERS_DOT_TYPE,
  },
  backgroundOptions: {
    color: 'transparent',
  },
});

/**
 * White QR Code
 */
const qrCodeWhite = new QRCodeStyling({
  width: WIDTH,
  height: WIDTH,
  image:
    'https://res.cloudinary.com/hsiz9ovy1/image/upload/v1715671033/public_assets/CHILLI_SYMBOL_WHITE_RGB_cdbyeq.png',
  dotsOptions: {
    color: Colors.OffWhite.primary,
    type: DOTS_TYPE,
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: IMAGE_MARGIN,
    imageSize: IMAGE_SIZE,
  },
  cornersSquareOptions: {
    type: CORNERS_TYPE,
  },
  cornersDotOptions: {
    type: CORNERS_DOT_TYPE,
  },
  backgroundOptions: {
    color: 'transparent',
  },
});

const QRCodeGenerator = () => {
  const [url, setUrl] = useState('https://chilli.club');
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [size, setSize] = useState(WIDTH);

  const refMagenta = useRef<HTMLDivElement>(null);
  const refBlack = useRef<HTMLDivElement>(null);
  const refOffBlack = useRef<HTMLDivElement>(null);
  const refWhite = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      !refMagenta?.current ||
      !refBlack?.current ||
      !refOffBlack?.current ||
      !refWhite?.current
    ) {
      return;
    }
    qrCodeMagenta.append(refMagenta.current);
    qrCodeBlack.append(refBlack.current);
    qrCodeOffBlack.append(refOffBlack.current);
    qrCodeWhite.append(refWhite.current);
  }, [refMagenta, refBlack, refWhite]);

  useEffect(() => {
    qrCodeMagenta.update({
      data: url,
      width: size,
      height: size,
      imageOptions: { margin: size / IMAGE_MARGIN },
    });
    qrCodeBlack.update({
      data: url,
      width: size,
      height: size,
      imageOptions: { margin: size / IMAGE_MARGIN },
    });
    qrCodeOffBlack.update({
      data: url,
      width: size,
      height: size,
      imageOptions: { margin: size / IMAGE_MARGIN },
    });
    qrCodeWhite.update({
      data: url,
      width: size,
      height: size,
      imageOptions: { margin: size / IMAGE_MARGIN },
    });
  }, [url, size]);

  useEffect(() => {
    try {
      const urlObj = new URL(url);
      const params = urlObj.searchParams;
      if (utmSource) {
        params.set('utm_source', utmSource);
      }
      if (utmMedium) {
        params.set('utm_medium', utmMedium);
      }
      if (utmCampaign) {
        params.set('utm_campaign', utmCampaign);
      }
      setUrl(urlObj.toString());
    } catch (error) {
      console.error(error);
    }
  }, [url, utmSource, utmMedium, utmCampaign]);

  const isURLValid = useMemo(() => URL_REGEX.test(url), [url]);

  const onUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    try {
      const urlObj = new URL(event.target.value);
      const params = urlObj.searchParams;
      setUrl(event.target.value);
      setUtmSource(params.get('utm_source') || '');
      setUtmMedium(params.get('utm_medium') || '');
      setUtmCampaign(params.get('utm_campaign') || '');
    } catch (error) {
      setUrl(event.target.value);
    }
  };

  const onMagentaDownloadClick = () => {
    if (!isURLValid) {
      window.alert('Cannot download, invalid URL');
      return;
    }
    qrCodeMagenta.download({
      name: 'chilli-qr-code-magenta',
      extension: 'png',
    });
  };

  const onBlackDownloadClick = () => {
    if (!isURLValid) {
      window.alert('Cannot download, invalid URL');
      return;
    }
    qrCodeBlack.download({
      name: 'chilli-qr-code-black',
      extension: 'png',
    });
  };

  const onOffBlackDownloadClick = () => {
    if (!isURLValid) {
      window.alert('Cannot download, invalid URL');
      return;
    }
    qrCodeOffBlack.download({
      name: 'chilli-qr-code-offblack',
      extension: 'png',
    });
  };

  const onWhiteDownloadClick = () => {
    if (!isURLValid) {
      window.alert('Cannot download, invalid URL');
      return;
    }
    qrCodeWhite.download({
      name: 'chilli-qr-code-white',
      extension: 'png',
    });
  };

  const convertStringToUrl = (str: string) => {
    return str.replace(/[^a-zA-Z0-9]/gi, '_');
  };

  return (
    <div>
      <ResourceTitleActionBar title="QR Code" mode="list" />
      <Card>
        <CardContent>
          <label>
            URL (required):
            <input defaultValue={url} onChange={onUrlChange} style={styles.input} />
          </label>
          {!isURLValid && (
            <Callout backgroundColor="red" emoji="🙅">
              <strong>Invalid URL!</strong>
            </Callout>
          )}

          <div style={styles.utmsContainer}>
            <div style={{ width: '30%' }}>
              <label>
                UTM Source:
                <input
                  value={utmSource}
                  onChange={e => setUtmSource(convertStringToUrl(e.target.value))}
                  style={styles.input}
                  placeholder="e.g. instagram"
                />
                <span style={styles.utmsDescription}>
                  Identifies which site sent the traffic, and is a required parameter.
                </span>
              </label>
            </div>
            <div style={{ width: '30%' }}>
              <label>
                UTM Medium:
                <input
                  value={utmMedium}
                  onChange={e => setUtmMedium(convertStringToUrl(e.target.value))}
                  style={styles.input}
                  placeholder="e.g. social"
                />
                <span style={styles.utmsDescription}>
                  Identifies what type of link was used.
                </span>
              </label>
            </div>
            <div style={{ width: '30%' }}>
              <label>
                UTM Campaign:
                <input
                  value={utmCampaign}
                  onChange={e => setUtmCampaign(convertStringToUrl(e.target.value))}
                  style={styles.input}
                  placeholder="e.g. summer_of_heat"
                />
                <span style={styles.utmsDescription}>
                  Identifies a specific campaign.
                </span>
              </label>
            </div>
          </div>

          <label>
            Size:
            <br />
            <select
              value={size}
              onChange={e => setSize(parseInt(e.target.value, 10))}
              style={{ ...styles.input, width: 'auto' }}
            >
              <option value={WIDTH}>Small ({WIDTH}px)</option>
              <option value={WIDTH * 2}>Medium ({WIDTH * 2}px)</option>
              <option value={WIDTH * 4}>Large ({WIDTH * 4}px)</option>
              <option value={WIDTH * 8}>Extra Large ({WIDTH * 8}px)</option>
            </select>
          </label>

          <div style={styles.qrCodesContainer}>
            {/* Black */}
            <div style={styles.qrCodeContainer}>
              <div
                ref={refBlack}
                style={{ ...styles.qrCode, backgroundColor: Colors.White.primary }}
              />
              <Button
                onClick={onBlackDownloadClick}
                label="download"
                variant="outlined"
                size="large"
                startIcon={<DownloadIcon />}
              />
            </div>
            {/* OffBlack */}
            <div style={styles.qrCodeContainer}>
              <div
                ref={refOffBlack}
                style={{ ...styles.qrCode, backgroundColor: Colors.White.primary }}
              />
              <Button
                onClick={onOffBlackDownloadClick}
                label="download"
                variant="outlined"
                size="large"
                startIcon={<DownloadIcon />}
              />
            </div>
            {/* Magenta */}
            <div style={styles.qrCodeContainer}>
              <div
                ref={refMagenta}
                style={{ ...styles.qrCode, backgroundColor: Colors.OffBlack.primary }}
              />
              <Button
                onClick={onMagentaDownloadClick}
                label="download"
                variant="outlined"
                size="large"
                startIcon={<DownloadIcon />}
              />
            </div>
            {/* White */}
            <div style={styles.qrCodeContainer}>
              <div
                ref={refWhite}
                style={{ ...styles.qrCode, backgroundColor: Colors.OffBlack.primary }}
              />
              <Button
                onClick={onWhiteDownloadClick}
                label="download"
                variant="outlined"
                size="large"
                startIcon={<DownloadIcon />}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const styles: any = {
  input: {
    width: '100%',
    padding: SpacingStyle.normal,
    marginBottom: SpacingStyle.normal,
    border: `1px solid ${Colors.Grey[300]}`,
    borderRadius: BorderStyle.Radius.normal,
    fontSize: FontStyle.sizeMedium,
  },
  error: {
    fontWeight: '600',
    color: Colors.Red.primary,
    backgroundColor: Colors.Red[100],
    border: `1px solid ${Colors.Red[200]}`,
    padding: SpacingStyle.normal,
    marginBottom: SpacingStyle.small,
    borderRadius: BorderStyle.Radius.small,
  },
  utmsContainer: {
    display: 'flex',
    justifyContent: 'start',
    gap: SpacingStyle.normal,
    marginTop: SpacingStyle.normal,
    marginBottom: SpacingStyle.normal,
  },
  utmsDescription: {
    fontSize: FontStyle.sizeSmall,
    color: Colors.Grey[500],
    display: 'block',
    marginTop: -SpacingStyle.small,
  },
  qrCodesContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'column',
    margin: SpacingStyle.normal,
    gap: SpacingStyle.big,
  },
  qrCodeContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  qrCode: {
    padding: SpacingStyle.small,
  },
};

export default QRCodeGenerator;
