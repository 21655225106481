import React from 'react';
import { useRecordContext, useRedirect } from 'react-admin';

const FriendshipStatus = ['pending', 'confirmed', 'blocked'];

export const UserFriendshipCustomList = () => {
  const { friendships } = useRecordContext();
  const redirect = useRedirect();

  return (
    <table width={'100%'} style={{ borderSpacing: 0 }}>
      <tbody>
        <tr style={{ textAlign: 'left', backgroundColor: '#d5d5d5' }}>
          <th>Username</th>
          <th>Created At</th>
          <th>Status</th>
        </tr>
        {friendships
          .sort((f1: any, f2: any) => (f1.friend.status > f2.friend.status ? 1 : -1))
          .map((friendship: any, i: number) => (
            <tr
              key={i}
              style={{
                height: 100,
                backgroundColor: i % 2 === 1 ? '#f9f9f9' : undefined,
                cursor: 'pointer',
              }}
              onClick={() => {
                redirect('show', 'users', friendship.friend.id);
              }}
            >
              <td>
                <strong>{friendship.friend.username}</strong>
              </td>
              <td>{new Date(friendship.createdAt).toDateString()}</td>
              <td>{FriendshipStatus[friendship.status]}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
