import React from 'react';
import { FunctionField } from 'react-admin';
import parsePhoneNumber from 'libphonenumber-js';

type PhoneNumberFieldProps = {
  source: string;
};
export const PhoneNumberField = ({ source }: PhoneNumberFieldProps) => (
  <FunctionField
    label="Phone Number"
    render={(resource: any) => {
      if (!resource[source]) {
        return;
      }
      try {
        const parsed = parsePhoneNumber(resource[source]);
        if (!parsed) {
          return resource[source];
        }
        if (parsed.isValid()) {
          return `+${parsed.countryCallingCode} ${parsed.formatNational()}`;
        }
      } catch {
        return resource[source];
      }
    }}
  />
);
