import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  UrlField,
} from 'react-admin';

export const ActionCtaWebpageList = () => (
  <List exporter={false} emptyWhileLoading>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ReferenceField source="actionId" reference="actions" link="show" />
      <UrlField source="websiteUrl" />
      <TextField
        source="propertiesArray"
        component="pre"
        style={{
          whiteSpace: 'pre-wrap',
          background: '#eee',
          fontFamily: 'courier',
          padding: '10px',
        }}
      />
      <TextField
        source="extraInjectedJavascript"
        component="pre"
        style={{
          whiteSpace: 'pre-wrap',
          background: '#eee',
          fontFamily: 'courier',
          padding: '10px',
        }}
      />
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
