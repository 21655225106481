import React from 'react';
import {
  Button,
  DeleteButton,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRecordContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import Callout from '@components/callout';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import Favicon from '@components/svgs/logos/favicon';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { SpacingStyle } from '@styles/variables';

import { CountrySelect } from './components/country';
import EmailRecipientsInput from './components/email_recipient/email_recipients_input';
import { validateEmailContent } from './create';

const redirectAfterEdit = (_basePath: any, _id: any, data: any) =>
  `actions/${data.actionId}/show`;

const EditActions = () => {
  const params = useParams();
  const record = useRecordContext();
  let actionId = params?.actionId ? params.actionId : record?.actionId;
  return (
    <ResourceTitleActionBar
      mode="edit"
      actions={
        <Button
          href={`#/actions/${actionId}/show`}
          startIcon={<Favicon width={15} height={12} />}
          label="Back to Action"
          variant="outlined"
        />
      }
    />
  );
};

/**
 * @param {*} props
 */
const EditToolbar = ({ ...props }) => {
  const record = useRecordContext(props);
  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton
        redirect={(basePath: any) => redirectAfterEdit(basePath, record.id, record)}
      />
    </Toolbar>
  );
};

export const ActionCtaEmailEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={<EditActions />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <CustomReferenceInput source="actionId" reference="actions" disabled />

      <CountrySelect />
      <br />

      <EmailRecipientsInput source="to" isRequired />
      <EmailRecipientsInput source="cc" />
      <EmailRecipientsInput source="bcc" />
      <br />
      <br />

      <Callout emoji="🤖" backgroundColor="grey">
        Write a default subject.
        <br />
        GPT will overwrite it based on the <strong>body</strong> of the email, before
        sending it.
      </Callout>
      <TextInput source="subject" required fullWidth validate={validateEmailContent} />

      <Callout emoji="👇" backgroundColor="grey">
        <ul style={{ paddingLeft: SpacingStyle.normal }}>
          <li>Write as a concerned person, not on your name,</li>
          <li>
            If you cite a number, or give example, put the link of your sources in the
            email,
          </li>
          <li>Be polite and be precise ;)</li>
        </ul>
      </Callout>
      <TextInput
        source="body"
        label="Content"
        required
        multiline
        fullWidth
        validate={validateEmailContent}
        minRows={10}
      />

      <br />
      <Callout emoji="📝" backgroundColor="grey">
        Summaries are displayed to chilli members only.
        <br />
        Those have been autogenerated based on the content of the email.
      </Callout>
      <TextInput label="Summary 🇺🇸" source="summaryEN" required multiline fullWidth />
      <TextInput label="Summary 🇫🇷" source="summaryFR" required multiline fullWidth />
    </SimpleForm>
  </Edit>
);
