import React from 'react';
import { ActionType } from 'types/action';

import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

type ActionCtaGoogleMapsReviewActionCardShowProps = {
  action: ActionType;
};
const ActionCtaGoogleMapsReviewActionCardShow = ({
  action,
}: ActionCtaGoogleMapsReviewActionCardShowProps) => {
  if (!action) {
    return null;
  }

  return (
    <div
      style={{
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 400,
      }}
    >
      <div style={{ fontSize: FontStyle.sizeVeryVerySmall }}>Google Maps URL:</div>
      <a
        href={action?.actionCtaGoogleMapsReview?.googleMapsUrl}
        style={{ fontSize: FontStyle.sizeVerySmall }}
        target="_blank"
      >
        {action?.actionCtaGoogleMapsReview?.googleMapsUrl}
      </a>
      {action?.actionCtaGoogleMapsReview?.message1 && (
        <div>
          <div style={styles.label}>Review 1:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message1}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message2 && (
        <div>
          <div style={styles.label}>Review 2:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message2}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message3 && (
        <div>
          <div style={styles.label}>Review 3:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message3}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message4 && (
        <div>
          <div style={styles.label}>Review 4:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message4}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message5 && (
        <div>
          <div style={styles.label}>Review 5:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message5}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message6 && (
        <div>
          <div style={styles.label}>Review 6:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message6}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message7 && (
        <div>
          <div style={styles.label}>Review 7:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message7}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message8 && (
        <div>
          <div style={styles.label}>Review 8:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message8}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message9 && (
        <div>
          <div style={styles.label}>Review 9:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message9}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message10 && (
        <div>
          <div style={styles.label}>Review 10:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message10}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message11 && (
        <div>
          <div style={styles.label}>Review 11:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message11}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message12 && (
        <div>
          <div style={styles.label}>Review 12:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message12}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message13 && (
        <div>
          <div style={styles.label}>Review 13:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message13}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message14 && (
        <div>
          <div style={styles.label}>Review 14:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message14}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message15 && (
        <div>
          <div style={styles.label}>Review 15:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message15}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message16 && (
        <div>
          <div style={styles.label}>Review 16:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message16}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message17 && (
        <div>
          <div style={styles.label}>Review 17:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message17}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message18 && (
        <div>
          <div style={styles.label}>Review 18:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message18}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message19 && (
        <div>
          <div style={styles.label}>Review 19:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message19}</div>
        </div>
      )}
      {action?.actionCtaGoogleMapsReview?.message20 && (
        <div>
          <div style={styles.label}>Review 20:</div>
          <div style={styles.message}>{action?.actionCtaGoogleMapsReview?.message20}</div>
        </div>
      )}
    </div>
  );
};

const styles: any = {
  label: {
    fontSize: FontStyle.sizeVeryVerySmall,
    color: Colors.Grey.primary,
    marginTop: SpacingStyle.normal,
  },
  message: { fontSize: FontStyle.sizeVerySmall },
};

export default ActionCtaGoogleMapsReviewActionCardShow;
