import React from 'react';
import { SelectInput, required } from 'react-admin';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';

export const expiresInValues = (canSetExtendedExpiringDate: boolean = false) => {
  let values = [
    { id: 1, name: '1 day' },
    { id: 2, name: '2 days' },
    { id: 3, name: '3 days' },
    { id: 4, name: '4 days' },
    { id: 5, name: '5 days' },
    { id: 6, name: '6 days' },
    { id: 7, name: '7 days' },
    { id: 31, name: '1 month' },
  ];
  if (canSetExtendedExpiringDate) {
    values.push({ id: 61, name: '2 months' });
    values.push({ id: 91, name: '3 months' });
    values.push({ id: 183, name: '6 months' });
  }
  return values;
};

export const ExpiresInInput = ({ source }: any) => {
  const { isSuperAdmin, isCampaignAdmin } = useRoleBasedPermissions();
  return (
    <SelectInput
      source={source}
      choices={expiresInValues(isSuperAdmin || isCampaignAdmin)}
      emptyValue={7}
      validate={[required()]}
    />
  );
};
