import * as React from 'react';
import {
  DeleteButton,
  Edit,
  FormDataConsumer,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import { CommentEntityType } from 'types/comment.d';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { dateFormatter } from '@services/date';
import ellipsis from '@services/ellipsis';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import { appPages } from '../action_ctas/app_page/components/app_page_select_input';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `in_app_notifications/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

/**
 * @param {*} props
 * @returns Edit toolbar with delete button for super admins
 */
const InAppNotificationEditToolbar = ({ ...props }) => {
  const { isSuperAdmin } = useRoleBasedPermissions();
  return (
    <Toolbar {...props}>
      <SaveButton />
      {isSuperAdmin && <DeleteButton />}
    </Toolbar>
  );
};

export const InAppNotificationEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm toolbar={<InAppNotificationEditToolbar />}>
      <TextInput source="entityType" disabled fullWidth />
      <FormDataConsumer>
        {({ formData }) =>
          (formData.entityType === 'AppPage' && (
            <>
              <SelectInput
                source="page"
                choices={appPages}
                fullWidth
                required
                label="Choose a screen to open"
              />
              <CustomReferenceInput
                source="appPageObjectId"
                reference="topic_comments"
                queryKey="message"
                label="Choose a comment"
                filters={{
                  parentCommentId: null,
                  entityType: CommentEntityType.TOPIC,
                  silentMessageEN: null,
                  silentMessageFR: null,
                }}
                optionText={option =>
                  `[${dateFormatter(option.createdAt, {
                    short: true,
                    withTime: true,
                  })}]\t${ellipsis(option.message, 120)}`
                }
                sort={{ field: 'createdAt', order: 'DESC' }}
              />
            </>
          )) ||
          (formData.entityType === 'ExternalLink' && (
            <TextInput
              source="websiteUrl"
              type="url"
              fullWidth
              required
              label="Write a valid URL"
            />
          ))
        }
      </FormDataConsumer>
      <br />
      <TextInput source="titleEN" required label="Title EN" fullWidth />
      <TextInput source="descriptionEN" required label="Description EN" fullWidth />
      <TextInput source="titleFR" required label="Title FR" fullWidth />
      <TextInput source="descriptionFR" required label="Description FR" fullWidth />
    </SimpleForm>
  </Edit>
);
