import React, { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import { GiTwoCoins } from 'react-icons/gi';
import { ActionType } from 'types/action';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import TodoChecklist from '@components/todo_checklist';
import { FontStyle } from '@styles/variables';

import { pointValues } from '../points';

const PointsCard = () => {
  const action: ActionType = useRecordContext();
  const { canUpdate: canUpdateFromRBP } = useRoleBasedPermissions();
  const canEdit: boolean = useMemo(() => canUpdateFromRBP('actions'), [canUpdateFromRBP]);

  return (
    <Card>
      <CardHeader
        avatar={<GiTwoCoins />}
        title="Coins"
        subheader="How many coins are earned by users who complete this action?"
      />
      <CardContent>
        <TodoChecklist
          checked={!!action?.points}
          label="Coins earned by users who complete this action"
          cta={
            canEdit && (
              <UpdateFieldDialog
                resource="actions"
                record={action}
                type="select"
                field="points"
                buttonLabel="Edit"
                buttonVariant="outlined"
                selectOptions={pointValues}
              />
            )
          }
        >
          <>
            <div
              style={{
                fontSize: FontStyle.sizeBig,
                fontWeight: 600,
                lineHeight: 1,
              }}
            >
              {action?.points}
            </div>
            <div style={{ fontSize: FontStyle.sizeSmall, fontWeight: '500' }}>coins</div>
          </>
        </TodoChecklist>
      </CardContent>
    </Card>
  );
};

export default PointsCard;
