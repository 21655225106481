import React from 'react';
import { SelectInput, required } from 'react-admin';

export const usersObjectiveValues = [
  { id: 10, name: '10 people' },
  { id: 20, name: '20 people' },
  { id: 30, name: '30 people' },
  { id: 40, name: '40 people' },
  { id: 50, name: '50 people' },
  { id: 100, name: '100 people' },
  { id: 150, name: '150 people' },
  { id: 200, name: '200 people' },
  { id: 300, name: '300 people' },
  { id: 400, name: '400 people' },
  { id: 500, name: '500 people' },
  { id: 1000, name: '1,000 people' },
];

export const UserObjectiveInput = ({ source }: any) => (
  <SelectInput
    source={source}
    choices={usersObjectiveValues}
    emptyValue={100}
    validate={[required()]}
  />
);
