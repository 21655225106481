import React, { useCallback, useState } from 'react';
import { UserType } from 'types/user';

import { Colors, SpacingStyle } from '@styles/variables';

import { UserAvatar } from './user_avatar';

type UserChipProps = {
  user?: UserType;
  subtext?: string;
  style?: any;
  redirectToUserPageOnClick?: boolean;
  onClick?: () => void;
};
const UserChip = ({
  user,
  subtext,
  style,
  redirectToUserPageOnClick = false,
  onClick,
}: UserChipProps) => {
  const [isHovered, setIsHovered] = useState(false);

  /**
   * Redirects to the user page when the user clicks on the chip.
   */
  const onClickHandler = useCallback(() => {
    if (onClick) {
      onClick();
      return;
    }
    if (redirectToUserPageOnClick) {
      window.location.href = `#/users/${user?.id}/show`;
    }
  }, [onClick, redirectToUserPageOnClick, user?.id]);

  if (!user) {
    return null;
  }

  return (
    <div
      style={{
        ...styles.container,
        ...(isHovered && !!redirectToUserPageOnClick ? { cursor: 'pointer' } : null),
        ...(style ? style : {}),
      }}
      onClick={onClickHandler}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <UserAvatar record={user} width={'2em'} />
      <div>
        <div style={styles.username}>{user.username}</div>
        {subtext && <div style={styles.subtext}>{subtext}</div>}
      </div>
    </div>
  );
};

const styles: any = {
  container: {
    display: 'inline-flex',
    gap: SpacingStyle[4],
    justifyContent: 'flex-start',
    alignItems: 'center',
    userSelect: 'none',
  },
  username: {
    fontSize: '1em',
    fontWeight: '500',
    lineHeight: '1.1em',
  },
  subtext: {
    fontSize: '0.75em',
    lineHeight: '1em',
    color: Colors.Grey.primary,
  },
};

export default UserChip;
