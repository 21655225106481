import * as React from 'react';
import {
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  FunctionField,
  useRecordContext,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
} from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';
import Grammarly from '@components/grammarly';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import { VideoField } from '../videos/components/video_field';

import { validateSRT } from './create';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `videos/${data.videoId}/show/subtitles`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const SubtitleEdit = () => (
  <Edit
    redirect={redirectAfterCreateEdit}
    mutationMode="pessimistic"
    actions={EditActions}
  >
    <SimpleForm toolbar={<EditToolbar />}>
      <CustomReferenceInput source="videoId" reference="videos" disabled />
      <SelectInput
        source="locale"
        choices={[
          { id: 'en', name: 'English' },
          { id: 'fr', name: 'French' },
          { id: 'es', name: 'Spanish' },
          { id: 'it', name: 'Italian' },
          { id: 'de', name: 'German' },
          { id: 'pt', name: 'Portuguese' },
          { id: 'ru', name: 'Russian' },
          { id: 'ja', name: 'Japanese' },
        ]}
        validate={[required()]}
        disabled
      />
      <FunctionField
        label="Text"
        style={{ width: '100%' }}
        render={(resource: any) => {
          const textField = (
            <TextInput
              source="text"
              fullWidth
              multiline
              required
              validate={validateSRT}
            />
          );

          if (resource.locale === 'en') {
            return <Grammarly>{textField}</Grammarly>;
          }

          return textField;
        }}
      />
      <div
        style={{
          position: 'fixed',
          bottom: 50,
          right: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '300px',
          width: '100%',
          marginTop: '50px',
        }}
      >
        <VideoField
          source="video"
          controls
          videoStyle={{
            width: '100%',
            borderRadius: 10,
            boxShadow: '0 0 30px 10px rgba(0,0,0,0.4)',
          }}
        />
      </div>
    </SimpleForm>
  </Edit>
);

const EditToolbar = ({ ...props }) => {
  const record = useRecordContext();
  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteWithConfirmButton
        confirmContent="Are you sure you want to delete this subtitle?"
        redirect={() => redirectAfterCreateEdit('subtitles', record.id, record)}
      />
    </Toolbar>
  );
};
