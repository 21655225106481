import React from 'react';
import { useRecordContext } from 'react-admin';

import { EmailRecipientsStyles } from './style';
import EmailRecipient from './email_recipient';

const EmailRecipientsField = ({ source, record }: any) => {
  record = record || useRecordContext();
  const recipients = record[source] ? record[source].split(',') : [];

  return (
    <div style={EmailRecipientsStyles.container}>
      <div style={EmailRecipientsStyles.label}>{source}</div>
      <div style={EmailRecipientsStyles.input}>
        {recipients?.length === 0 && (
          <small style={{ color: '#aaa' }}>No {source.toUpperCase()} recipients</small>
        )}
        {recipients.map((recipient: any, index: number) => (
          <EmailRecipient key={`${index}-${recipient}`} recipient={recipient} />
        ))}
      </div>
    </div>
  );
};

export default EmailRecipientsField;
