import React from 'react';
import { Button, Title } from 'react-admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import BusinessIcon from '@mui/icons-material/Business';
import LabelIcon from '@mui/icons-material/Label';
import PersonIcon from '@mui/icons-material/Person';

import CRMSearchDialog from '@components/crm/search_dialog';
import CRMCreateDialog from '@components/crm/create_dialog';

const CRMIndex = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      marginTop: 36,
      alignItems: 'center',
    }}
  >
    <Card style={{ maxWidth: 420, width: '100%', padding: 24 }}>
      <Title title="CRM" />
      <CardContent>
        <CRMSearchDialog
          buttonLabel="Search for an org, a person, a tag..."
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            marginBottom: 24,
          }}
        />
        <CRMCreateDialog
          label="Create a new org or person"
          ctaStyle={{ width: '100%' }}
        />
      </CardContent>
    </Card>
    <Card>
      <CardContent>
        <Button
          variant="outlined"
          href="/#/crm_organizations"
          label="organizations"
          startIcon={<BusinessIcon />}
          style={Styles.button}
        />
        <Button
          variant="outlined"
          href="/#/crm_persons"
          label="persons"
          startIcon={<PersonIcon />}
          style={Styles.button}
        />
        <Button
          variant="outlined"
          href="/#/crm_tags"
          label="Manage Tags"
          startIcon={<LabelIcon />}
          style={Styles.button}
        />
      </CardContent>
    </Card>
  </div>
);

const Styles = {
  button: {
    width: '100%',
    marginTop: 24,
    height: 40,
  },
};

export default CRMIndex;
