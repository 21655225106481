import React from 'react';
import { UserType } from 'types/user';
import { useRecordContext } from 'react-admin';

import UserChip from './user_chip';

const UserRecordRepresentation = () => {
  const user = useRecordContext() as UserType;
  return <UserChip user={user} />;
};
export default UserRecordRepresentation;
