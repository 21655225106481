import * as React from 'react';
import { Datagrid, DateField, List, ReferenceField } from 'react-admin';
import SocialPlatformField from './components/social_platform_field';

export const ActionCtaSocialPostList = () => (
  <List exporter={false} emptyWhileLoading>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ReferenceField source="actionId" reference="actions" link="show" />
      <SocialPlatformField source="socialPlatform" />
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
