import * as React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import ResourceTitleActionBar from 'src/components/resource_title_action_bar';

import CategoryInput from './components/category_input';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `profile_skills/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const ProfileSkillEdit = () => {
  return (
    <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
      <SimpleForm>
        <TextInput disabled source="id" fullWidth />
        <TextInput source="emoji" fullWidth />
        <TextInput source="labelEN" label="Label EN" fullWidth required />
        <TextInput source="labelFR" label="Label FR" fullWidth required />
        <TextInput source="key" fullWidth />
        <CategoryInput />
      </SimpleForm>
    </Edit>
  );
};
