import React, { useCallback, useState } from 'react';
import { Button } from 'react-admin';
import { useDropzone } from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import dataProvider from 'src/dataProvider';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import LoadingAnimation from '@components/svgs/loading_animation';
import { CRMPersonIcon, CRMTagIcon } from '@components/icons';
import Callout from '@components/callout';
import { BorderStyle, Colors, SpacingStyle } from '@styles/variables';

const CRMPersonsImport = () => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedResult, setUploadedResult] = useState<any>(null);

  const onDrop = useCallback((acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const reader: any = new FileReader();

    reader.onload = () => {
      setFile(reader.result);
    };

    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'] },
  });

  const onSubmit = async () => {
    try {
      setIsUploading(true);
      const { data } = await dataProvider.crmPersonImport(file);
      setUploadedResult(data);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  };

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <strong>File rejected:</strong>
      {errors.map(e => (
        <div key={e.code}>{e.message}</div>
      ))}
    </li>
  ));

  return (
    <div>
      <ResourceTitleActionBar title="Import a CSV of Persons" mode="list" />
      {uploadedResult && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: 800,
            width: '100%',
            margin: 'auto',
            marginTop: SpacingStyle.big,
            backgroundColor: Colors.Grey[25],
            border: `1px solid ${Colors.Grey[50]}`,
            padding: SpacingStyle.normal,
            borderRadius: BorderStyle.Radius.small,
          }}
        >
          <strong style={{ color: Colors.Green.primary }}>
            Imported {uploadedResult.imported?.length || 0} persons:
          </strong>
          <ul>
            {uploadedResult.imported?.map((person: string, index: number) => (
              <li key={`${index}-imported`}>{person}</li>
            ))}
          </ul>
          <br />
          <strong style={{ color: Colors.Red.primary }}>
            Skipped {uploadedResult.errors?.length} persons:
          </strong>
          <ul>
            {uploadedResult.errors?.map((error: string, index: number) => (
              <li key={`${index}-error`}>{error}</li>
            ))}
          </ul>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: SpacingStyle.big,
            }}
          >
            <Button
              variant="outlined"
              size="small"
              href="/#/crm_persons"
              label="Back to directory"
              startIcon={<CRMPersonIcon />}
            />
          </div>
        </div>
      )}
      {isUploading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 400,
            width: '100%',
            margin: 'auto',
            marginTop: SpacingStyle.big,
          }}
        >
          {' '}
          <LoadingAnimation stroke={Colors.Magenta.primary} />
        </div>
      )}
      {!isUploading && !uploadedResult && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: 800,
            width: '100%',
            margin: 'auto',
          }}
        >
          <Callout backgroundColor="yellow" emoji="👇">
            <p>
              <a
                href="https://docs.google.com/spreadsheets/d/1c9Y5vZWJbznelG2tMg4KDL_Imkycx3WfAG0hNLs-sHU/template/preview"
                target="_blank"
              >
                Duplicate this Google Spreadsheet template
              </a>{' '}
              and fill it out with your data.
              <br />
              Then export it as <strong>a CSV</strong>, and upload it here.
              <br />
              <strong>Important:</strong> The first row of the CSV must be the column
              names, not a contact.
            </p>
            <p>
              A{' '}
              <strong>
                tag <CRMTagIcon size={12} />
              </strong>{' '}
              can be associated to each person. If the tag doesn't exist, it will be
              created.
            </p>
          </Callout>
          <div
            {...getRootProps()}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: `1px dashed ${Colors.Magenta.primary}`,
              height: 400,
              width: '100%',
              margin: 'auto',
              borderRadius: BorderStyle.Radius.normal,
              cursor: 'pointer',
              backgroundColor: Colors.Magenta[50],
              color: Colors.Magenta.primary,
            }}
          >
            <input {...getInputProps()} />
            <div>
              {!file && "Drag 'n' drop a CSV file here, or click to select a file."}
              {acceptedFiles.map(file => (
                <div key={file.path}>{file.path}</div>
              ))}
              <ul>{fileRejectionItems}</ul>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: SpacingStyle.big,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={onSubmit}
              label="Submit"
              startIcon={<UploadFileIcon />}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CRMPersonsImport;
