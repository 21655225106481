import React from 'react';
import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';

export const ActionCtaEmailList = () => (
  <List exporter={false} emptyWhileLoading>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ReferenceField source="actionId" reference="actions" link="show" />
      <TextField source="to" />
      <TextField source="cc" />
      <TextField source="bcc" />
      <TextField source="subject" />
      <TextField source="summaryEN" />
      <TextField source="summaryFR" />
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
