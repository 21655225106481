import React, { ReactElement, useCallback, useMemo } from 'react';
import {
  AutocompleteInput,
  OptionText,
  ReferenceInput,
  SortPayload,
  required,
} from 'react-admin';

type CustomReferenceInputProps = {
  source: string;
  reference: string;
  queryKey?: string | string[];
  validate?: any;
  disabled?: boolean;
  label?: string;
  sort?: any;
  suggestionLimit?: number;
  onChange?: any;
  defaultValue?: any;
  createElement?: ReactElement;
  filters?: any;
  helperText?: string;
  optionText?: string | ((option: any) => string);
};

const CustomReferenceInput = ({
  source,
  reference,
  queryKey,
  validate,
  disabled,
  label,
  sort,
  suggestionLimit = 25,
  onChange,
  defaultValue,
  createElement,
  filters = {},
  helperText,
  optionText,
}: CustomReferenceInputProps) => {
  /**
   * Option text callback
   */
  const optionTextCallback = useCallback(
    (s: any) => {
      if (typeof optionText === 'function') {
        return optionText(s);
      }
      if (optionText && s[optionText]) {
        return s[optionText];
      }
      if (Array.isArray(queryKey)) {
        return queryKey.map(key => s[key]).join(' ');
      }
      if (typeof queryKey === 'string') {
        return s[queryKey];
      }
      if (!queryKey && !!sort?.field) {
        return s[sort.field];
      }
      return 'id';
    },
    [optionText, queryKey, sort]
  ) as OptionText;

  /**
   * Sort callback
   */
  const sortCallback = useMemo(() => {
    if (sort) {
      return sort;
    }
    if (Array.isArray(queryKey)) {
      return { field: queryKey[0], order: 'ASC' };
    }
    if (typeof queryKey === 'string') {
      return { field: queryKey, order: 'ASC' };
    }
    return {};
  }, [sort, queryKey]) as SortPayload;

  /**
   * Filter to query
   */
  const filterToQuery = useCallback(
    (search: string) => {
      if (typeof queryKey === 'string') {
        return { [queryKey]: search };
      }
      if (Array.isArray(queryKey)) {
        return queryKey.reduce(
          (acc, key) => {
            return { ...acc, [key]: search };
          },
          {
            operator: 'or',
          }
        );
      }
      return { q: search };
    },
    [queryKey]
  );

  if (!reference) {
    return <></>;
  }

  return (
    <ReferenceInput
      source={source}
      reference={reference}
      fullWidth
      disabled={!!disabled}
      sort={sortCallback}
      filter={filters}
      perPage={suggestionLimit}
    >
      <AutocompleteInput
        label={label}
        filterToQuery={filterToQuery}
        fullWidth
        validate={validate ? [required()] : undefined}
        disabled={!!disabled}
        defaultValue={defaultValue}
        create={createElement}
        onChange={value => {
          if (onChange) {
            onChange(value);
          }
        }}
        optionText={optionTextCallback}
        helperText={helperText}
      />
    </ReferenceInput>
  );
};

export default CustomReferenceInput;
