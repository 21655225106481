import React from 'react';
import { useRecordContext } from 'react-admin';
import { UserType } from 'types/user';

import { dateFormatter } from '@services/date';
import { Colors, SpacingStyle } from '@styles/variables';

const SpyBanner = () => {
  const user = useRecordContext<UserType>();
  if (!user?.spyDetectedAt) {
    return;
  }

  return (
    <div
      style={{
        padding: SpacingStyle.big,
        fontWeight: 600,
        color: Colors.White.primary,
        textAlign: 'center',
        backgroundColor: Colors.Red.primary,
      }}
    >
      This user was detected as a spy on {dateFormatter(user.spyDetectedAt)} 🕵️
    </div>
  );
};

export default SpyBanner;
