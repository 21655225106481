import React from 'react';
import { SelectInput, required } from 'react-admin';

export const priorityValues = [
  { id: 1, name: 'Default  (priority 1)' },
  { id: 2, name: 'Code red (priority 2)' },
  { id: 3, name: 'Urgent (priority 3)' },
  { id: 4, name: 'Critical mass (priority 4)' },
  { id: 5, name: 'All hands on deck (priority 5)' },
];

export const priorityInput = ({ source }: any) => (
  <SelectInput
    source={source}
    choices={priorityValues}
    emptyValue={1}
    validate={[required()]}
  />
);
