import React from 'react';
import { ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const CampaignOwnerShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="ownerId" reference="users" link="show" />
      <ReferenceField source="campaignId" reference="campaigns" link="show" />
    </SimpleShowLayout>
  </Show>
);
