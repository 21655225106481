import React, { useCallback } from 'react';
import { Button } from 'react-admin';
import TranslateIcon from '@mui/icons-material/Translate';
import { useFormContext } from 'react-hook-form';

import { useOpenAI } from '@hooks/useOpenAI';
import LoadingAnimation from '@components/svgs/loading_animation';

type Keys = {
  from: string;
  to: string;
  copyOnly?: boolean;
};
type TranslateButtonProps = {
  keys: Keys[];
};
const TranslateButton = ({ keys }: TranslateButtonProps) => {
  const { isLoading: isOpenAILoading, translateText } = useOpenAI();
  const { getValues, setValue } = useFormContext();

  const handleTranslation = useCallback(async () => {
    const values = getValues();
    keys.forEach(async ({ from, to, copyOnly }) => {
      if (values[from]) {
        if (copyOnly) {
          setValue(to, values[from]);
        } else {
          const translatedText = await translateText({ text: values[from], from, to });
          setValue(to, translatedText);
        }
      }
    });
  }, [translateText, getValues, setValue, keys]);

  return (
    <Button
      onClick={handleTranslation}
      disabled={isOpenAILoading}
      startIcon={
        isOpenAILoading ? <LoadingAnimation width={18} height={18} /> : <TranslateIcon />
      }
      label="Transalte from English to French"
      variant="outlined"
    />
  );
};

export default TranslateButton;
