import * as React from 'react';
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from 'react-admin';

export const ActionCtaExternalLinkShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="actionId" reference="actions" link="show" />
      <DateField source="createdAt" showTime />
      <UrlField source="externalUrl" />
      <TextField source="message1" multiline />
      <TextField source="message2" multiline />
      <TextField source="message3" multiline />
      <TextField source="message4" multiline />
      <TextField source="message5" multiline />
      <TextField source="message6" multiline />
      <TextField source="message7" multiline />
      <TextField source="message8" multiline />
      <TextField source="message9" multiline />
      <TextField source="message10" multiline />
    </SimpleShowLayout>
  </Show>
);
