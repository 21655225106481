import React from 'react';
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = <ResourceTitleActionBar mode="show" />;

export const DealCouponShow = () => (
  <Show actions={ShowActions}>
    <SimpleShowLayout>
      <TextField source="code" />
      <ReferenceField label="Deal" source="dealId" reference="deals" />
      <DateField label="Created At" source="createdAt" showTime />
      <DateField label="Refeemed At" source="redeemedAt" showTime />
    </SimpleShowLayout>
  </Show>
);
