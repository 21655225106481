import { useCallback, useMemo } from 'react';
import { useGetIdentity, usePermissions } from 'react-admin';
import { CommandCenterUserRole } from 'types/command_center_user_role.d';

export type RoleBasedPermissionsType = {
  [tableName: string]: {
    read: true | false | 'maybe';
    create: true | false | 'maybe';
    update: true | false | 'maybe';
    delete: true | false | 'maybe';
  };
};

export const useRoleBasedPermissions = () => {
  const { permissions } = usePermissions();
  const { data } = useGetIdentity();

  const isTableValid = useCallback(
    (tableName: string) => {
      return !!permissions && !!permissions[tableName];
    },
    [permissions]
  );

  /**
   * Check if the user has permission to read, create, update, or delete a table
   * @param tableName
   * @param action
   * @returns {boolean}
   */
  const hasPermission = useCallback(
    (tableName: string, action: 'read' | 'create' | 'update' | 'delete') => {
      if (!tableName || !permissions || !permissions.hasOwnProperty(tableName)) {
        return false;
      }
      return (
        permissions[tableName][action] === true ||
        permissions[tableName][action] === 'maybe'
      );
    },
    [permissions]
  );

  /**
   * Check if the user has permission to read, create, update, or delete a table
   * @param tableName
   * @returns {boolean}
   */
  const canRead = useCallback(
    (tableName: string) => hasPermission(tableName, 'read'),
    [hasPermission]
  );

  /**
   * Check if the user has permission to create a record in a table
   * @param tableName
   * @returns {boolean}
   */
  const canCreate = useCallback(
    (tableName: string) => hasPermission(tableName, 'create'),
    [hasPermission]
  );

  /**
   * Check if the user has permission to update a record in a table
   * @param tableName
   * @returns {boolean}
   */
  const canUpdate = useCallback(
    (tableName: string) => hasPermission(tableName, 'update'),
    [hasPermission]
  );

  /**
   * Check if the user has permission to delete a record in a table
   * @param tableName
   * @returns {boolean}
   */
  const canDelete = useCallback(
    (tableName: string) => hasPermission(tableName, 'delete'),
    [hasPermission]
  );

  /**
   * Check if the user is a super admin
   */
  const isSuperAdmin = useMemo(
    () => data?.commandCenterRole === CommandCenterUserRole.super_admin,
    [data]
  );

  /**
   * Check if the user is a campaign admin
   */
  const isCampaignAdmin = useMemo(
    () => data?.commandCenterRole === CommandCenterUserRole.campaign_admin,
    [data]
  );

  /**
   * Check if the user is a campaign manager
   */
  const isCampaignManager = useMemo(
    () => data?.commandCenterRole === CommandCenterUserRole.campaign_manager,
    [data]
  );

  /**
   * Check if the user is a product
   */
  const isProduct = useMemo(
    () => data?.commandCenterRole === CommandCenterUserRole.product,
    [data]
  );

  /**
   * Check if the user is a read only
   */
  const isReadOnly = useMemo(
    () => data?.commandCenterRole === CommandCenterUserRole.read_only,
    [data]
  );

  return {
    canRead,
    canCreate,
    canUpdate,
    canDelete,
    isTableValid,
    isSuperAdmin,
    isCampaignAdmin,
    isCampaignManager,
    isProduct,
    isReadOnly,
  };
};
