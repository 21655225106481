import React from 'react';
import {
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
  useRecordContext,
} from 'react-admin';
import { PepperPillType } from 'types/pepper_pill';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

import PepperPillPreview from './components/preview';

const ShowActions = <ResourceTitleActionBar mode="show" />;

const Content = () => {
  const pepperPill: PepperPillType = useRecordContext();
  return (
    <SimpleShowLayout>
      <TextField source="createdAt" />
      <TextField source="type" />
      <ImageField source="imageUrl" title="Image" />
      <hr />
      <TextField label="Title EN" source="titleEN" />
      <TextField label="Content EN" source="contentEN" />
      <hr />
      <TextField label="Title FR" source="titleFR" />
      <TextField label="Content FR" source="contentFR" />
      <UrlField source="sourceUrl" />

      <PepperPillPreview pepperPill={pepperPill} />
    </SimpleShowLayout>
  );
};

export const PepperPillShow = () => (
  <Show actions={ShowActions}>
    <Content />
  </Show>
);
