import React, { useContext, useEffect, useState } from 'react';
import {
  SubtitleBlockType,
  SubtitleStudioContext,
  SubtitleStudioContextType,
} from '../context';

const SubtitleVideoBlock = () => {
  const { videoHtmlElement, subtitleBlocks } =
    useContext<SubtitleStudioContextType>(SubtitleStudioContext);
  const [currentSubtitleBlock, setCurrentSubtitleBlock] = useState<SubtitleBlockType>();

  useEffect(() => {
    if (!videoHtmlElement || !subtitleBlocks) {
      return;
    }
    videoHtmlElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => videoHtmlElement.removeEventListener('timeupdate', handleTimeUpdate);
  }, [videoHtmlElement, subtitleBlocks]);

  const handleTimeUpdate = () => {
    if (!videoHtmlElement || !subtitleBlocks) {
      return;
    }
    const currentTime = videoHtmlElement.currentTime;
    const currentSubtitleBlock = subtitleBlocks.find(subtitleBlock => {
      const startTime = Math.round(subtitleBlock.startTime * 100) / 100;
      const endTime = Math.round(subtitleBlock.endTime * 100) / 100;

      return currentTime >= startTime && currentTime < endTime;
    });
    setCurrentSubtitleBlock(currentSubtitleBlock);
  };

  if (!currentSubtitleBlock) {
    return null;
  }
  return <div style={StyleSheet.container}>{currentSubtitleBlock.text || 'no text'}</div>;
};

const StyleSheet: any = {
  container: {
    position: 'absolute',
    left: 10,
    right: 10,
    bottom: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 8px',
    backgroundColor: 'rgba(0,0,0,.6)',
    fontSize: 14,
    fontWeight: 500,
    color: 'white',
    lineHeight: 1.2,
    borderRadius: 4,
    textAlign: 'center',
    cursor: 'default',
    userSelect: 'none',
  },
};

export default SubtitleVideoBlock;
