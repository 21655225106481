import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ListActions = <ResourceTitleActionBar mode="list" />;

export const CampaignCauseList = () => (
  <List
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    exporter={false}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="emoji" />
      <TextField source="name" />
    </Datagrid>
  </List>
);
