import * as React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const ProfilePromptCreate = () => (
  <Create actions={CreateActions}>
    <SimpleForm>
      <TextInput source="questionEN" label="Question EN" fullWidth required />
      <TextInput source="questionFR" label="Question FR" fullWidth required />
      <TextInput source="placeholderEN" label="Placeholder EN" fullWidth />
      <TextInput source="placeholderFR" label="Placeholder FR" fullWidth />
    </SimpleForm>
  </Create>
);
