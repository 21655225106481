const InputStyle: any = {
  input: {
    display: 'flex',
    boxSizing: 'border-box',
    padding: 10,
    borderRadius: 8,
    border: '1px solid #e0e0e0',
    width: '100%',
  },
};

export default InputStyle;
