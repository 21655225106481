import React, { useMemo, useState } from 'react';
import { Button, Confirm, useDataProvider } from 'react-admin';
import { useMutation } from 'react-query';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';

type UndoUserActionProps = {
  userActionId: string;
};
export const UndoUserAction = ({ userActionId }: UndoUserActionProps) => {
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const { isSuperAdmin, isProduct } = useRoleBasedPermissions();
  const canUndo = useMemo(() => isSuperAdmin || isProduct, [isSuperAdmin, isProduct]);

  const { mutate, isLoading } = useMutation(['delete', userActionId], async () => {
    await dataProvider.delete('users_actions', {
      id: userActionId,
    });
  });

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    mutate(undefined, {
      onSuccess: () => {
        setOpen(false);
        window.location.reload();
      },
    });
  };

  if (!canUndo) {
    return <></>;
  }

  return (
    <>
      <Button label="Undo" children={<NotificationsActiveIcon />} onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="User's action"
        content="Are you sure you want to undo this user's action?"
        onClose={handleDialogClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};
