// difficulty_input.tsx
import React from 'react';
import { SelectInput, required } from 'react-admin';
import { ActionDifficulty } from 'types/action.d';

export const difficultyValues = [
  { id: ActionDifficulty.Easy, name: 'Easy (good for new users)' },
  { id: ActionDifficulty.Medium, name: 'Normal' },
];

export const difficultyInput = ({ source }: any) => (
  <SelectInput
    source={source}
    choices={difficultyValues}
    emptyValue={ActionDifficulty.Easy}
    validate={[required()]}
  />
);
