import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  FunctionField,
} from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ListActions = <ResourceTitleActionBar mode="list" />;

export const SubtitleList = () => (
  <List
    sort={{ field: 'createdAt', order: 'DESC' }}
    actions={ListActions}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ReferenceField source="videoId" reference="videos" link="show" />
      <TextField source="locale" />
      <DateField source="createdAt" />
      <FunctionField
        label="Text"
        render={(resource: any) => {
          return `${resource.text?.slice(0, 100)}...`;
        }}
        component="pre"
        style={{ whiteSpace: 'pre-wrap' }}
      />
    </Datagrid>
  </List>
);
