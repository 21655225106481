import ResourceTitleActionBar from '@components/resource_title_action_bar';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardContent } from '@mui/material';
import { CampaignType } from 'types/campaign';
import { httpClient } from 'src/dataProvider';

import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import Config from '../../../../config';
import Callout from '@components/callout';

const CampaignsAsInOnboarding: React.FC = () => {
  const [activeCampaigns, setActiveCampaigns] = useState<CampaignType[]>([]);

  const fetchCampaigns = useCallback(async () => {
    const { data } = await httpClient(`${Config.API_URL}/campaigns/onboarding`).then(
      ({ json }) => ({
        data: json.data,
      })
    );
    setActiveCampaigns(data);
  }, []);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  return (
    <div>
      <ResourceTitleActionBar title="Onboarding preview" mode="show" />
      <Card>
        <CardContent>
          <Callout emoji="👇" backgroundColor="grey">
            That's how campaigns are displayed to users when they go through the
            onboarding.
          </Callout>
          <div style={styles.appContainer}>
            <div style={styles.appProgressBar} />
            <div style={styles.appTitle}>which campaign brought you here?</div>
            <div style={styles.appSubtitle}>
              choose the campaign that led you to join (if any)
            </div>
            {activeCampaigns.map((campaign: CampaignType) => (
              <a
                key={campaign.id}
                href={`#/campaigns/${campaign.id}/show`}
                style={styles.campaignContainer}
              >
                <img
                  style={styles.campaignImage}
                  src={campaign.imageUrl}
                  alt={campaign.name}
                />
                <span style={styles.campaignName}>{campaign.name}</span>
              </a>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const styles: any = {
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: SpacingStyle.big,
    maxWidth: 345,
    width: '100%',
    minHeight: 644,
    backgroundColor: Colors.OffBlack.primary,
    borderRadius: BorderStyle.Radius.normal,
  },
  appProgressBar: {
    width: '100%',
    height: 4,
    marginBottom: SpacingStyle.big,
    backgroundColor: Colors.Magenta.primary,
    borderRadius: BorderStyle.Radius.normal,
  },
  appTitle: {
    marginBottom: SpacingStyle.normal,
    fontSize: FontStyle.sizeVeryBig,
    fontWeight: 500,
    color: Colors.OffWhite.primary,
    textAlign: 'center',
    lineHeight: 1.3,
  },
  appSubtitle: {
    marginBottom: SpacingStyle.normal,
    fontSize: FontStyle.sizeMedium,
    fontWeight: 500,
    color: Colors.OffWhite.primary,
    textAlign: 'center',
    lineHeight: 1.2,
  },
  campaignContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: SpacingStyle.normal,
    padding: SpacingStyle.small,
    gap: SpacingStyle.small,
    backgroundColor: Colors.White.transparent.medium,
    borderRadius: BorderStyle.Radius.normal,
    border: `1px solid ${Colors.White.transparent.light}`,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  campaignImage: {
    width: 32,
    height: 32,
    borderRadius: BorderStyle.Radius.big,
  },
  campaignName: {
    fontSize: FontStyle.sizeSmall,
    fontWeight: 600,
    color: Colors.OffWhite.primary,
  },
};

export default CampaignsAsInOnboarding;
