import React from 'react';
import { Datagrid, DateField, List, TextField } from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ListActions = () => <ResourceTitleActionBar mode="list" />;

export const TopicHighlightList = () => (
  <List
    sort={{ field: 'createdAt', order: 'DESC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={<ListActions />}
    exporter={false}
    empty={false}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="titleEN" label="Title EN" />
      <TextField source="titleFR" label="Title FR" />

      <TextField source="subtitleEN" label="Subtitle EN" />
      <TextField source="subtitleFR" label="Subtitle FR" />

      <DateField
        source="createdAt"
        label={`Posted at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
        showTime
      />
    </Datagrid>
  </List>
);
