import * as React from 'react';
import { FunctionField, Show, SimpleShowLayout, TextField } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

import CRMTagChip from './components/chip';

const ShowActions = <ResourceTitleActionBar mode="show" resourceName="Tags" />;

export const CRMTagShow = () => (
  <Show actions={ShowActions}>
    <SimpleShowLayout>
      <TextField source="name" />
      <FunctionField
        label="Color"
        render={(resource: any) => <CRMTagChip tag={resource} />}
      />
    </SimpleShowLayout>
  </Show>
);
