import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileCopyIcon from '@mui/icons-material/FileCopy';

type CopyableProps = {
  text: string;
  style?: any;
};
const Copyable = ({ text, style }: CopyableProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const iconStyle = {
    position: 'absolute',
    right: -16,
    width: 12,
    height: 12,
    color: '#b9bdbf',
    backgroundColor: 'white',
  };

  return (
    <span
      onClick={copyToClipboard}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        ...style,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        cursor: 'pointer',
        borderRadius: 4,
        backgroundColor: isHovered ? 'rgba(0,100,150,.04)' : 'transparent',
        padding: '4 14 4 4',
        margin: '-4 -14 -4 -4',
      }}
    >
      {text}{' '}
      {isHovered ? (
        isCopied ? (
          <FileCopyIcon sx={iconStyle} />
        ) : (
          <ContentCopyIcon sx={iconStyle} />
        )
      ) : null}
    </span>
  );
};

export default Copyable;
