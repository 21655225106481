import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { CommandCenterUserRole } from 'types/command_center_user_role.d';

import CustomReferenceInput from '@components/inputs/custom_reference_input';

export const CampaignOwnerEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput disabled source="id" fullWidth />
      <CustomReferenceInput
        source="campaignId"
        reference="campaigns"
        queryKey="name"
        validate
      />

      <CustomReferenceInput
        source="ownerId"
        reference="users"
        queryKey="username"
        filters={{ commandCenterRole: CommandCenterUserRole.campaign_manager }}
        validate
      />
    </SimpleForm>
  </Edit>
);
