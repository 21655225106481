import * as React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `donations/${data.recipientId}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const DonationRecipientEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <CustomReferenceInput source="donationId" reference="donations" queryKey="nameEN" />
      <CustomReferenceInput
        source="recipientId"
        reference="recipients"
        queryKey="recipientname"
      />
    </SimpleForm>
  </Edit>
);
