import React, { useEffect, useState } from 'react';
import { Button, useDataProvider, useRefresh } from 'react-admin';
import Dialog from '@mui/material/Dialog';

import { EMAIL_REGEX } from '@models/action_ctas/emails/components/email_recipient/email_recipient';

import CRMCreateDialogStep1 from './step1';
import CRMCreateDialogStep2 from './step2';
import CRMCreateDialogStep3 from './step3';

type CRMCreateDialogProps = {
  label?: string;
  ctaStyle?: any;
  onClose?: () => void;
  initialEmail?: string;
};
const CRMCreateDialog = ({
  label,
  ctaStyle,
  onClose,
  initialEmail,
}: CRMCreateDialogProps) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const [entityType, setEntityType] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [newCRMPerson, setNewCRMPerson] = useState<any>(null);
  const [newCRMOrganization, setNewCRMOrganization] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      setLoading(false);
      setEntityType(null);
      setStep(1);
      setNewCRMPerson(null);
      setNewCRMOrganization(null);
      setErrorMessage(null);
    }
  }, [open]);

  const handleClickOpen = () => {
    setStep(1);
    setNewCRMPerson(null);
    setNewCRMOrganization(null);
    setErrorMessage(null);
    setOpen(true);
  };
  const handleClose = () => {
    if (step === 2) {
      window.alert("You can't close this modal while we're enriching the contact card.");
      return;
    }
    !!onClose && onClose();
    setOpen(false);
  };

  const handleOnConfirmStep1 = async ({ type, email }: any) => {
    if (!email || !email.match(EMAIL_REGEX)) {
      setErrorMessage('Invalid email address.');
      return;
    }
    setEntityType(type);
    setErrorMessage(null);
    setStep(2);
    try {
      if (type === 'CRMPerson') {
        const { data: crmPerson } = await dataProvider.create('crm_persons', {
          data: { email },
        });
        setNewCRMPerson(crmPerson);
      } else if (type === 'CRMOrganization') {
        const { data: crmOrganization } = await dataProvider.create('crm_organizations', {
          data: { email },
        });
        setNewCRMOrganization(crmOrganization);
      }
      refresh();
      setStep(3);
    } catch (error: any) {
      const statusCode = error?.body?.error?.statusCode;
      if (statusCode) {
        if (statusCode === 'UniqueViolationError') {
          setErrorMessage('This email address is already used on a contact.');
        } else {
          setErrorMessage(statusCode);
        }
      } else {
        setErrorMessage('An error occured.');
      }
      setStep(1);
    }
  };

  const handleOnCloseStep3 = async () => {
    setLoading(true);
    try {
      if (entityType === 'CRMPerson') {
        if (!newCRMPerson) {
          throw new Error('No new CRMPerson.');
        }
        const { data: crmPerson } = await dataProvider.update('crm_persons', {
          id: newCRMPerson.id,
          data: newCRMPerson,
        } as any);
        setNewCRMPerson(crmPerson);
      } else if (entityType === 'CRMOrganization') {
        if (!newCRMOrganization) {
          throw new Error('No new CRMOrganization.');
        }
        const { data: crmOrganization } = await dataProvider.update('crm_organizations', {
          id: newCRMOrganization.id,
          data: newCRMOrganization,
        } as any);
        setNewCRMOrganization(crmOrganization);
      }
      refresh();
      handleClose();
    } catch (error: any) {
      const statusCode = error?.body?.error?.statusCode;
      if (statusCode) {
        setErrorMessage(statusCode);
      } else {
        console.error(error);
        setErrorMessage('An error occured:' + error);
      }
      setStep(1);
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        label={label || 'Create'}
        style={ctaStyle}
      />

      <Dialog onClose={handleClose} open={open}>
        {step === 1 && (
          <CRMCreateDialogStep1
            onConfirm={handleOnConfirmStep1}
            initialEmail={initialEmail}
          />
        )}
        {step === 2 && <CRMCreateDialogStep2 />}
        {step === 3 && (
          <CRMCreateDialogStep3
            crmPerson={newCRMPerson}
            setCrmPerson={setNewCRMPerson}
            crmOrganization={newCRMOrganization}
            setCrmOrganization={setNewCRMOrganization}
            onClose={handleOnCloseStep3}
            isSubmitting={loading}
          />
        )}
        {errorMessage && (
          <div
            style={{
              margin: 24,
              padding: 8,
              backgroundColor: '#fcc',
              fontSize: 12,
              fontWeight: 500,
              color: 'red',
              textAlign: 'center',
              borderRadius: 8,
              border: '1px solid #faa',
            }}
          >
            {errorMessage}
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default CRMCreateDialog;
