import React, { useMemo } from 'react';
import {
  Button,
  Loading,
  RecordContextProvider,
  useGetOne,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import { CausePostType } from 'types/cause_post';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import ellipsis from '@services/ellipsis';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { SpacingStyle } from '@styles/variables';

import PublishCard from './components/show/publish_card';
import PreviewsCard from './components/show/previews_card';

const ShowActions = () => {
  const causePost: CausePostType = useRecordContext();

  return (
    <ResourceTitleActionBar
      mode="show"
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: SpacingStyle[4],
          }}
        >
          {ellipsis(causePost?.titleEN || '', 50)}
          {!causePost?.publishedAt && '(draft)'}
        </div>
      }
    />
  );
};

/**
 * Displays a delete button block for super admins and campaign admins+managers for draft cause post
 * @returns Delete button block
 */
const ActionsButtonBlock = () => {
  const causePost: CausePostType = useRecordContext();
  const { canCreate: canCreateFromRPB, canDelete: canDeleteFromRBP } =
    useRoleBasedPermissions();
  const canDelete = useMemo(
    () => canDeleteFromRBP('causes_posts') && !causePost?.publishedAt,
    [canDeleteFromRBP, causePost?.publishedAt]
  );

  const canDuplicate = useMemo(
    () => canCreateFromRPB('causes_posts'),
    [canCreateFromRPB]
  );

  if (!canDelete && !canDuplicate) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        gridColumn: 'span 2',
        margin: SpacingStyle.big,
        gap: SpacingStyle.normal,
      }}
    >
      {canDelete && (
        <Button
          href={`#/causes_posts/${causePost.id}`}
          label={`Delete this ${causePost.publishedAt ? 'post' : 'draft'}`}
          startIcon={<DeleteIcon />}
          variant="outlined"
          color="error"
        />
      )}
    </div>
  );
};

const ShowCardsLayout = () => {
  return (
    <div
      style={{
        width: '100%',
        maxWidth: 1200,
        marginBottom: 200,
      }}
    >
      {/* <AuthorCard />
      <CauseCard />

      <DescriptionsCard /> */}
      <PreviewsCard />

      <PublishCard />
      <ActionsButtonBlock />
    </div>
  );
};

export const CausePostShow = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const { data, isLoading } = useGetOne(
    'causes_posts',
    { id },
    { onError: () => redirect('/causes_posts') }
  );
  if (isLoading) {
    return <Loading />;
  }
  return (
    <RecordContextProvider value={data}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ShowActions />
        <ShowCardsLayout />
      </div>
    </RecordContextProvider>
  );
};
