import React, { useMemo } from 'react';
import { useGetList, useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import { ActionType } from 'types/action.d';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import TodoChecklist from '@components/todo_checklist';
import { CauseIcon } from '@components/icons';
import CauseChip from '@models/causes/components/chip';

const CauseCard = () => {
  const action: ActionType = useRecordContext();
  const { canUpdate: canUpdateFromRBP } = useRoleBasedPermissions();
  const canEdit: boolean = useMemo(() => canUpdateFromRBP('actions'), [canUpdateFromRBP]);

  const { data: causes } = useGetList('causes', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'name', order: 'ASC' },
  });

  return (
    <Card>
      <CardHeader
        avatar={<CauseIcon />}
        title="Cause"
        subheader="By default, the cause is the campaign's cause."
      />
      <CardContent>
        <TodoChecklist
          checked={!!action?.cause}
          label="Choose a cause"
          cta={
            canEdit && (
              <UpdateFieldDialog
                resource="actions"
                record={action}
                type="select"
                field="causeId"
                buttonLabel={!action?.causeId ? 'Choose a cause' : 'Edit'}
                buttonVariant="outlined"
                selectOptions={[
                  ...(causes ? causes : []),
                  {
                    id: undefined,
                    name: 'Other',
                  },
                ]}
              />
            )
          }
        >
          {action?.cause && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
              }}
            >
              <CauseChip cause={action.cause} clickable />
            </div>
          )}
        </TodoChecklist>
      </CardContent>
    </Card>
  );
};

export default CauseCard;
