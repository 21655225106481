import * as React from 'react';
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from 'react-admin';

export const ActionCtaWebpageShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="actionId" reference="actions" link="show" />
      <DateField source="createdAt" showTime />
      <TextField source="updatedAt" />
      <UrlField source="websiteUrl" />
      <TextField
        source="propertiesArray"
        component="pre"
        style={{
          whiteSpace: 'pre-wrap',
          background: '#eee',
          fontFamily: 'courier',
          padding: '10px',
        }}
      />
      <TextField
        source="extraInjectedJavascript"
        component="pre"
        style={{
          whiteSpace: 'pre-wrap',
          background: '#eee',
          fontFamily: 'courier',
          padding: '10px',
        }}
      />
    </SimpleShowLayout>
  </Show>
);
