import React, { useMemo } from 'react';
import { useGetList, useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import { ActionType } from 'types/action.d';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import { CauseIcon } from '@components/icons';
import CauseChip from '@models/causes/components/chip';
import { SpacingStyle, Colors, BorderStyle } from '@styles/variables';

const CauseCard = () => {
  const action: ActionType = useRecordContext();
  const { canUpdate: canUpdateFromRBP } = useRoleBasedPermissions();
  const canEdit: boolean = useMemo(() => canUpdateFromRBP('actions'), [canUpdateFromRBP]);

  const { data: causes } = useGetList('causes', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'name', order: 'ASC' },
  });

  return (
    <Card>
      <CardHeader
        avatar={<CauseIcon />}
        title="Cause"
        subheader="What is the cause of this action?"
        action={
          canEdit ? (
            <UpdateFieldDialog
              resource="actions"
              record={action}
              type="select"
              field="causeId"
              buttonLabel={!action?.causeId ? 'Select cause' : 'Change cause'}
              buttonVariant="outlined"
              selectOptions={[
                {
                  id: undefined,
                  name: 'Select one',
                  selected: !action?.causeId,
                  disabled: true,
                },
                ...(causes ? causes : []),
              ]}
            />
          ) : null
        }
      />
      <CardContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: SpacingStyle[4],
            backgroundColor: Colors.Magenta[50],
            border: `1px solid ${Colors.Magenta[100]}`,
            borderRadius: BorderStyle.Radius.small,
            padding: `${SpacingStyle[8]}px ${SpacingStyle[12]}px`,
          }}
        >
          {action?.cause ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: SpacingStyle[4],
              }}
            >
              <CauseChip cause={action.cause} clickable size="lg" />
            </div>
          ) : (
            <div style={{ fontStyle: 'italic' }}>No cause selected</div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default CauseCard;
