import React, { useState } from 'react';
import { Button } from 'react-admin';

import SymbolSVG from '@components/svgs/logos/wordmark';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import QRCode from './qr_code';
import Separator from './separator';
import PhoneNumber from './phone_number';

const LoginPage = () => {
  const [loginType, setLoginType] = useState<'qrCode' | 'phoneNumber'>('qrCode');

  return (
    <div style={styles.container}>
      <div style={styles.left}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <SymbolSVG width="10vw" height="10vw" fill={Colors.Magenta.primary} />
          <div style={styles.hero}>
            join the frontline
            <br />
            of the climate resistance
          </div>
        </div>
        <div
          style={{
            fontSize: FontStyle.sizeNormal,
            fontWeight: 500,
            color: Colors.OffWhite.primary,
          }}
        >
          command center
        </div>
      </div>
      <div style={styles.right}>
        <div style={styles.card}>
          <div style={styles.title}>Sign in</div>

          {loginType === 'qrCode' && (
            <>
              <QRCode />
              <Separator />
              <Button
                variant="contained"
                size="medium"
                onClick={() => setLoginType('phoneNumber')}
                label="with phone number"
                style={{ backgroundColor: Colors.OffBlack.primary }}
              />
            </>
          )}
          {loginType === 'phoneNumber' && <PhoneNumber />}

          <div style={styles.footer}>
            Don't have an account?
            <br />
            <a
              href="https://apps.apple.com/us/app/chilli-fight-climate-change/id1645549437"
              target="_blank"
              style={styles.footerLink}
            >
              Download the iOS app
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: Colors.OffBlack.primary,
    padding: SpacingStyle[36],
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '40%',
    height: '100%',
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%',
    height: '100%',
    backgroundColor: Colors.Magenta.primary,
    borderRadius: BorderStyle.Radius.big,
  },
  hero: {
    fontSize: FontStyle.sizeVeryHuge,
    fontWeight: 600,
    color: Colors.OffWhite.primary,
    lineHeight: 1.2,
    textAlign: 'center',
    marginTop: SpacingStyle[40],
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: FontStyle.sizeVeryVeryHuge,
    fontWeight: 700,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: FontStyle.sizeNormal,
    fontWeight: 500,
    color: Colors.OffBlack.primary,
    textAlign: 'center',
  },
  footer: {
    marginTop: SpacingStyle.big,
    fontSize: FontStyle.sizeSmall,
    fontWeight: 500,
    color: Colors.OffBlack.primary,
    textAlign: 'center',
  },
  footerLink: {
    fontWeight: 600,
    color: Colors.OffBlack.primary,
  },
};

export default LoginPage;
