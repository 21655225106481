import React from 'react';
import { SelectField, SelectInput, required } from 'react-admin';

export const selectCTAChoices = [
  { id: 'external_link', name: 'Open an external link' },
  { id: 'social_comment', name: 'Post a comment on social media' },
  { id: 'google_maps', name: 'Review a place on Google Maps' },
  { id: 'email', name: 'Send an email' },
  { id: 'question', name: 'Answer a question' },
  { id: 'phone_call', name: 'Make a phone call' },
  { id: 'share_link', name: 'Share chilli' },
];

export const CtaTypeField = ({ source }: any) => (
  <SelectField source={source} choices={selectCTAChoices} />
);

export const CtaTypeInput = ({ source, onChange, isRequired = true }: any) => (
  <SelectInput
    source={source}
    choices={selectCTAChoices}
    emptyValue={'nothing'}
    validate={[isRequired && required()]}
    onChange={(event: any) => {
      onChange?.(event.target.value);
    }}
  />
);
