import React, { useState } from 'react';
import { Button } from 'react-admin';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { parsePhoneNumber } from 'libphonenumber-js';

import Styles from '@components/crm/styles';
import Copyable from '@components/copyable';

import CRMTagChip from '../../crm_tags/components/chip';

type CRMPersonProps = {
  crmPerson: any;
  onClick?: (entityType: string, entityId: string, entity: any) => void;
  ctaIcon?: any;
  ctaLabel?: string;
};
const CRMPerson = ({ crmPerson, onClick, ctaIcon, ctaLabel }: CRMPersonProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const renderImage = () => {
    if (!crmPerson.imageUrl) {
      const name =
        `${crmPerson.firstname || ''} ${crmPerson.lastname || ''}`.trim() ||
        crmPerson.email;
      return (
        <img
          style={Styles.crmEntity.image}
          src={`https://ui-avatars.com/api?name=${name}&background=EFEFEF`}
        />
      );
    }
    return <img style={Styles.crmEntity.image} src={crmPerson.imageUrl} />;
  };

  const renderName = () => {
    if (!crmPerson.firstname && !crmPerson.lastname) {
      return <div style={{ fontWeight: 600 }}>No firstname/lastname</div>;
    }
    return (
      <Copyable
        style={{ fontWeight: 600, textTransform: 'capitalize' }}
        text={`${crmPerson.firstname || ''} ${crmPerson.lastname || ''}`.trim()}
      />
    );
  };

  const renderGender = () => {
    if (!crmPerson.gender) {
      return null;
    }
    return <small style={{ fontSize: 10 }}>({crmPerson.gender})</small>;
  };

  const renderEmail = () => {
    if (!crmPerson.email) {
      return;
    }
    return (
      <div style={{ ...Styles.flexRow, marginBottom: 8, fontSize: 12 }}>
        ✉️ <Copyable text={crmPerson.email.trim()} />
      </div>
    );
  };

  const renderPhoneNumber = () => {
    if (!crmPerson.phoneNumber) {
      return;
    }
    let phoneNumber = crmPerson.phoneNumber;
    try {
      const pN = parsePhoneNumber(crmPerson.phoneNumber);
      if (pN.isValid()) {
        phoneNumber = `+${pN.countryCallingCode} ${pN.formatNational()}`;
      }
    } catch (e) {
      console.error(crmPerson);
    }
    return (
      <div style={{ ...Styles.flexRow, marginBottom: 8, fontSize: 12 }}>
        📞 <Copyable text={phoneNumber} />
      </div>
    );
  };

  const renderJobInfo = () => {
    return (
      <div style={{ fontSize: 12, textTransform: 'capitalize' }}>
        {crmPerson.jobTitle && (
          <div style={{ ...Styles.flexRow, marginBottom: 8 }}>
            🧑‍💼 <Copyable text={crmPerson.jobTitle.trim()} />
          </div>
        )}
        {crmPerson.organizationName && (
          <div style={{ ...Styles.flexRow, marginBottom: 8 }}>
            🏢 <Copyable text={crmPerson.organizationName.trim()} />
          </div>
        )}
      </div>
    );
  };

  const renderLocation = () => {
    if (crmPerson.location === null) {
      return null;
    }
    return (
      <div
        style={{
          fontSize: 12,
          textTransform: 'capitalize',
          marginBottom: 8,
        }}
      >
        📍 <span style={{ marginLeft: 8 }}>{crmPerson.location?.trim()}</span>
      </div>
    );
  };

  const renderTags = () => {
    if (!crmPerson.crmTags || crmPerson.crmTags.length === 0) {
      return null;
    }
    return (
      <div style={{ ...Styles.flexRow, ...Styles.crmEntity.tagsContainer }}>
        {crmPerson.crmTags.map((tag: any) => (
          <CRMTagChip key={tag.id} tag={tag} />
        ))}
      </div>
    );
  };

  const renderSocial = (url: any, icon: JSX.Element) => {
    if (!url) {
      return null;
    }
    return (
      <a
        style={{
          ...Styles.flexRow,
          marginRight: 8,
          color: '#555',
          textDecoration: 'none',
          fontSize: '0.8em',
        }}
        href={url}
        target="_blank"
        onClick={e => !!onClick && e.preventDefault()}
      >
        {React.cloneElement(icon, {
          style: {
            color: '#555a5b',
            width: '.9em',
            height: '.9em',
          },
        })}
      </a>
    );
  };

  const renderSocials = () => (
    <div style={{ ...Styles.flexRow, marginTop: 8 }}>
      {renderSocial(crmPerson.facebookUrl, <FacebookIcon />)}
      {renderSocial(crmPerson.instagramUrl, <InstagramIcon />)}
      {renderSocial(crmPerson.linkedinUrl, <LinkedInIcon />)}
      {renderSocial(crmPerson.threadUrl, <div>Thread</div>)}
      {renderSocial(crmPerson.tiktokUrl, <div>TikTok</div>)}
      {renderSocial(crmPerson.twitterUrl, <TwitterIcon />)}
    </div>
  );

  const onClickHandler = () => {
    if (!onClick) {
      window.location.href = `#/crm_persons/${crmPerson.id}/show`;
      return;
    }
    return onClick('crm_persons', crmPerson.id, crmPerson);
  };

  return (
    <div
      style={{
        ...Styles.crmEntity.container,
        ...(isHovered ? Styles.crmEntity.containerHover : null),
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{ ...Styles.flexRow, justifyContent: 'space-between' }}>
        <div style={{ ...Styles.flexRow, marginBottom: 8 }}>
          {renderImage()}
          {renderName()}
          {renderGender()}
        </div>
        {(ctaLabel || ctaIcon) && (
          <Button
            style={Styles.flexRow}
            label={ctaLabel}
            startIcon={ctaIcon}
            onClick={onClickHandler}
          />
        )}
      </div>
      {renderLocation()}
      {renderJobInfo()}
      {renderEmail()}
      {renderPhoneNumber()}
      {renderSocials()}
      {renderTags()}
    </div>
  );
};

export default CRMPerson;
