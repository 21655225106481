import React, { useCallback, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';

import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

const EmailRecipients = ({ recipient, onDelete }: any) => {
  const dataProvider = useDataProvider();
  const [email, setEmail] = useState('loading');
  const [icon, setIcon] = useState<JSX.Element>();
  const [entityType, setEntityType] = useState<'crm_persons' | 'crm_organizations'>();
  const [entityId, setEntityId] = useState<string>();
  const [isHovered, setIsHovered] = useState(false);

  /**
   * Get email from recipient
   */
  const getEmail = useCallback(async () => {
    if (!recipient) {
      setEmail('no recipient');
      return;
    }
    if (recipient.match(EMAIL_REGEX)) {
      setEmail(recipient);
    } else if (recipient.startsWith('{{')) {
      let entity;
      const entityId = recipient.replace(/{{[A-Za-z]*:/, '').replace('}}', '');
      setEntityId(entityId);
      if (recipient.startsWith('{{CRMPerson:')) {
        setIcon(<PersonIcon />);
        setEntityType('crm_persons');
        const { data } = await dataProvider.getOne('crm_persons', {
          id: entityId,
        });
        entity = data;
      } else if (recipient.startsWith('{{CRMOrganization:')) {
        setIcon(<BusinessIcon />);
        setEntityType('crm_organizations');
        const { data } = await dataProvider.getOne('crm_organizations', {
          id: entityId,
        });
        entity = data;
      }
      if (!entity) {
        setEmail(`${entityId} not found`);
      } else if (!entity.email) {
        setEmail(`${entityId} has no email`);
      } else {
        setEmail(entity.email);
      }
    } else {
      setEmail(`${recipient} not a valid entity`);
    }
  }, [recipient, dataProvider]);

  useEffect(() => {
    getEmail();
  }, [recipient, getEmail]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: Colors.Grey[50],
        borderRadius: BorderStyle.Radius.big,
        border: `1px solid ${Colors.Grey[200]}`,
        padding: `${SpacingStyle[2]}px ${SpacingStyle[8]}px`,
        fontSize: FontStyle.sizeVerySmall,
        ...(isHovered &&
          entityId && {
            backgroundColor: Colors.Grey[200],
            border: `1px solid ${Colors.Grey[300]}`,
            cursor: 'pointer',
          }),
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {icon &&
        React.cloneElement(icon, {
          style: {
            width: '.6em',
            height: '.6em',
            marginRight: SpacingStyle[2],
          },
          htmlColor: Colors.OffBlack.primary,
        })}
      {entityId ? (
        <a
          href={`#/${entityType}/${entityId}/show`}
          style={{
            color: Colors.OffBlack.primary,
            textDecoration: 'none',
          }}
        >
          {email}
        </a>
      ) : (
        email
      )}
      {onDelete && (
        <ClearIcon
          style={{
            width: '.7em',
            height: '.7em',
            marginLeft: SpacingStyle[4],
            cursor: 'pointer',
          }}
          onClick={onDelete}
        />
      )}
    </div>
  );
};

export default EmailRecipients;
