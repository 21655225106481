import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

import { ActionIcon } from '@components/icons';

import ActionsCalendar from './actions_calendar';

const CausesCalendars = () => {
  return (
    <Card>
      <CardHeader avatar={<ActionIcon />} title="Actions" />
      <CardContent>
        <ActionsCalendar />
      </CardContent>
    </Card>
  );
};

export default CausesCalendars;
