import * as React from 'react';
import { DateTimeInput, Edit, SimpleForm, TextInput } from 'react-admin';
import ResourceTitleActionBar from 'src/components/resource_title_action_bar';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `feature_flags/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const FeatureFlagEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <TextInput source="name" fullWidth />
      <TextInput source="description" fullWidth />
      <DateTimeInput source="enabledAt" fullWidth parse={v => (v === '' ? null : v)} />
    </SimpleForm>
  </Edit>
);
