import React, { useState } from 'react';
import { Datagrid, DateInput, Form, FunctionField, List } from 'react-admin';
import { ActionType } from 'types/action';
import { CampaignType } from 'types/campaign';
import moment from 'moment-timezone';
import { CauseType } from 'types/cause';

import { dateFormatter } from '@services/date';
import ellipsis from '@services/ellipsis';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import ActionCTATypeChip from '@components/action_cta_type_chip';
import CauseChip from '@models/causes/components/chip';
import { Colors, SpacingStyle } from '@styles/variables';

import { CtaTypeInput } from './components/cta_type';

const ActionListActions = () => <ResourceTitleActionBar mode="list" />;

export const ActionList = () => {
  const [listFilters, setListFilters] = useState({} as any);

  return (
    <List
      sort={{ field: 'publishedAt', order: 'DESC' }}
      perPage={PER_PAGE}
      pagination={<PER_PAGE_OPTIONS />}
      exporter={false}
      actions={<ActionListActions />}
      emptyWhileLoading
      empty={false}
      filter={listFilters}
    >
      {/* Filters */}
      <Form>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: SpacingStyle.normal,
            padding: SpacingStyle.normal,
          }}
        >
          <DateInput
            source="publishedAt"
            label="Published at"
            onChange={e =>
              setListFilters({ ...listFilters, publishedAt: e?.target?.value })
            }
            style={{ minWidth: 200 }}
          />
          <CustomReferenceInput
            source="causeId"
            reference="causes"
            label="Causes"
            queryKey="name"
            sort={{ field: 'name', order: 'ASC' }}
            onChange={(causeId: CauseType['id']) =>
              setListFilters({
                ...listFilters,
                causeId: causeId ? causeId : undefined,
              })
            }
          />
          <CustomReferenceInput
            source="campaignId"
            reference="campaigns"
            label="Campaign"
            queryKey="name"
            sort={{ field: 'name', order: 'ASC' }}
            onChange={(campaignId: CampaignType['id']) =>
              setListFilters({
                ...listFilters,
                campaignId: campaignId ? campaignId : undefined,
              })
            }
          />
          <CtaTypeInput
            source="ctaType"
            isRequired={false}
            onChange={(ctaType: ActionType['ctaType']) =>
              setListFilters({
                ...listFilters,
                ctaType: ctaType ? ctaType : undefined,
              })
            }
            style={{ minWidth: 1200 }}
          />
        </div>
      </Form>

      {/* Datagrid */}
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <FunctionField
          label="Published at"
          render={(action: ActionType) => {
            if (!action.publishedAt) {
              return <div style={{ color: Colors.Grey.primary }}>draft</div>;
            }
            const isExpired = moment(action.publishedAt)
              .add(action.expiresIn, 'days')
              .isBefore(moment(), 'minutes');
            const expiresOn = moment(action.publishedAt).add(action.expiresIn, 'days');

            return (
              <div
                style={{
                  color: isExpired ? Colors.Grey.primary : 'inherit',
                }}
              >
                {dateFormatter(action.publishedAt)}
                <small>
                  {isExpired ? ' (expired)' : ` (expires ${expiresOn.fromNow()})`}
                </small>
              </div>
            );
          }}
        />
        <FunctionField
          label="Description"
          render={(action: ActionType) => <>{ellipsis(action.descriptionEN, 75)}</>}
        />
        <FunctionField
          label="Cause"
          render={(action: ActionType) => <CauseChip cause={action.cause!} />}
        />
        <FunctionField
          label="CTA"
          render={(action: ActionType) => <ActionCTATypeChip ctaType={action.ctaType} />}
        />
        <FunctionField
          label="Objective"
          render={(action: ActionType) => (
            <div style={{ textAlign: 'right' }}>
              <strong>{action.usersCount || 0}</strong>
              <small> / {action.usersObjective || 0}</small>
            </div>
          )}
        />
      </Datagrid>
    </List>
  );
};
