import React from 'react';
import { SelectInput, required } from 'react-admin';

const SocialPlatformInput = ({ source }: any) => (
  <SelectInput
    source={source}
    choices={[
      // { id: "instagram_feed", name: "instagram_feed" },
      // { id: "instagram_stories", name: "instagram_stories" },
      // { id: "facebook_stories", name: "facebook_stories" },
      { id: 'twitter', name: 'twitter' },
    ]}
    defaultValue={'twitter'}
    validate={[required()]}
  />
);

export default SocialPlatformInput;
