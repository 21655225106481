import React from 'react';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import D0Actions from '@models/actions/components/statistics/d0_actions';

import NorthStarMetricCard from './components/north_star_metric';
import CausesCalendars from './components/causes_calendars';

const Dashboard = () => {
  return (
    <div>
      <ResourceTitleActionBar title="Dashboard" mode="list" />
      <NorthStarMetricCard />
      <CausesCalendars />
      <D0Actions />
      <div style={{ height: '25vh' }} />
    </div>
  );
};

export default Dashboard;
