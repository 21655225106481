import React, { useEffect, useState } from 'react';
import { Button, useDataProvider, useRefresh } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ActionType } from 'types/action';

import { validateUrl } from '@components/inputs/url_input';
import Callout from '@components/callout';
import { FontStyle, SpacingStyle } from '@styles/variables';
import InputStyle from '@styles/input';

type ActionCtaExternalLinkCreateEditDialogProps = {
  action: ActionType;
};

const ActionCtaExternalLinkCreateEditDialog = ({
  action,
}: ActionCtaExternalLinkCreateEditDialogProps) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [externalUrl, setExternalUrl] = useState('');
  const [messages, setMessages] = useState<string[]>([]);
  const [error, setError] = useState('');
  const [type, setType] = useState<'create' | 'edit'>('create');

  useEffect(() => {
    if (!action || !action.actionCtaExternalLink) {
      return;
    }
    if (action.actionCtaExternalLink) {
      setType('edit');
      setExternalUrl(action.actionCtaExternalLink?.externalUrl || '');
      const newMessages = [];
      for (let i = 1; i <= 10; i++) {
        const message = (action.actionCtaExternalLink as any)[`message${i}`] as string;
        if (message !== null && message !== undefined) {
          newMessages.push(message);
        }
      }
      if (newMessages.length > 0) {
        setMessages(newMessages);
      }
    } else {
      setType('create');
      setExternalUrl('');
      setMessages(['']);
    }
  }, [action]);

  const handleClickOpen = () => {
    setOpen(true);
    setIsSubmitting(false);
  };

  const handleClose = () => {
    if (isSubmitting) {
      window.alert("You can't close this modal while the CTA is being saved.");
      return;
    }
    const confirm = window.confirm(
      'Are you sure you want to close this modal without saving?\nYou will lose any unsaved changes.'
    );
    confirm && setOpen(false);
  };

  const saveCTA = async () => {
    if (!action) {
      return;
    }

    setIsSubmitting(true);
    try {
      const data = {
        externalUrl,
        message1: messages[0] || null,
        message2: messages[1] || null,
        message3: messages[2] || null,
        message4: messages[3] || null,
        message5: messages[4] || null,
        message6: messages[5] || null,
        message7: messages[6] || null,
        message8: messages[7] || null,
        message9: messages[8] || null,
        message10: messages[9] || null,
      };

      if (type === 'create') {
        await dataProvider.create('action_cta_external_links', {
          data: {
            actionId: action.id,
            ...data,
          },
        });
      } else if (type === 'edit') {
        await dataProvider.update('action_cta_external_links', {
          id: action.actionCtaExternalLink?.id,
          data,
        } as any);
      }
      refresh();
      setOpen(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const buttonLabel = () => {
    if (type === 'create') {
      return isSubmitting ? 'Creating...' : 'Create';
    } else if (type === 'edit') {
      return isSubmitting ? 'Saving...' : 'Save';
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        label={type === 'create' ? 'Add' : 'Edit'}
        endIcon={<EditIcon />}
      />
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>Set a URL to redirect the user to</DialogTitle>
        <DialogContent>
          <input
            type="url"
            autoFocus
            style={InputStyle.input}
            placeholder="ex: https://chilli.club/survey-123"
            value={externalUrl}
            maxLength={255}
            onChange={e => {
              if (e.target.value.length > 250) {
                setError('The URL is too long.');
              } else if (!e.target.value.startsWith('https://')) {
                setError('The URL must start with https://');
              } else {
                setError(validateUrl(e.target.value) || '');
              }
              setExternalUrl(e.target.value);
            }}
          />
          {error && (
            <div
              style={{
                color: 'red',
                marginTop: SpacingStyle.small,
                fontSize: FontStyle.sizeVerySmall,
              }}
            >
              {error}
            </div>
          )}

          <Callout backgroundColor={'yellow'} emoji="👇">
            You can add up to 10 different messages. This is <b>optional</b>, but can be
            useful for links that require user commenting.
            <br />
            Only <b>one</b> message will be copied to the user's clipboard.
          </Callout>

          {messages.map((message, index) => (
            <label style={{ fontSize: FontStyle.sizeVeryVerySmall }} key={index}>
              Message {index + 1}:
              <div style={{ display: 'flex' }}>
                <textarea
                  style={InputStyle.input}
                  placeholder="ex: Dear CEO, I am writing to you to express my concerns..."
                  value={message}
                  onChange={e => {
                    const newMessages = [...messages];
                    newMessages[index] = e.target.value;
                    setMessages(newMessages);
                  }}
                />
                <Button
                  label="Delete"
                  startIcon={<DeleteOutlineIcon />}
                  onClick={() => {
                    const newMessages = [...messages];
                    newMessages.splice(index, 1);
                    setMessages(newMessages);
                  }}
                  style={{
                    marginLeft: 10,
                  }}
                />
              </div>
            </label>
          ))}
          {messages.length < 10 && (
            <Button
              label="Add a message"
              startIcon={<AddIcon />}
              onClick={() => {
                const newMessages = [...messages];
                newMessages.push('');
                setMessages(newMessages);
              }}
              disabled={messages[messages.length - 1] === ''}
              style={{
                marginTop: SpacingStyle.small,
                marginBottom: SpacingStyle.normal,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={saveCTA}
            label={buttonLabel()}
            disabled={isSubmitting || error.length > 0 || !externalUrl}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActionCtaExternalLinkCreateEditDialog;
