import * as React from 'react';
import { Edit, NumberInput, SimpleForm } from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import UserTransactionTypeInput from './components/user_transaction_type_input';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `users/${data.userId}/show/transactions`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const UserTransactionsEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <CustomReferenceInput
        source="userId"
        reference="users"
        queryKey="username"
        sort={{
          field: 'username',
          order: 'ASC',
        }}
        suggestionLimit={100}
      />
      <UserTransactionTypeInput />
      <NumberInput source="coinsAmount" />
    </SimpleForm>
  </Edit>
);
