import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = <ResourceTitleActionBar mode="show" />;

export const CampaignCauseShow = () => (
  <Show actions={ShowActions}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="emoji" />
      <TextField source="descriptionFR" />
      <TextField source="descriptionEN" />
    </SimpleShowLayout>
  </Show>
);
