import React from 'react';
import { useRecordContext } from 'react-admin';
import { Colors } from 'src/styles/variables';

export const UserAvatar = ({ ...props }) => {
  const user = useRecordContext(props);

  const width = props.width || 28;
  const styles: any = {
    width: width,
    height: width,
    borderRadius: width,
    backgroundColor: user.avatarUrl ? Colors.White.primary : Colors.Magenta[600],
    objectFit: 'cover',
    userSelect: 'none',
  };

  if (user.avatarUrl) {
    return <img src={user.avatarUrl} style={styles} />;
  }
  let firstletter = user.firstname ? user.firstname[0] : user.username[0];
  return (
    <div
      style={{
        ...styles,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: Colors.OffBlack.primary,
        fontSize: '1em',
        fontWeight: '600',
        textDecoration: 'none',
      }}
    >
      {firstletter.toUpperCase()}
    </div>
  );
};
