import React from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import { Deal, DealType } from 'types/deal.d';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { SpacingStyle } from '@styles/variables';

import DealCard from './components/deal_card';
import { dealLocationChoices } from './components/deal_form';

const ShowActions = <ResourceTitleActionBar mode="show" />;

export const formatDealLocation = (record: any) => {
  return (
    dealLocationChoices.find(choice => choice.id === record.locationContinent)?.name ||
    '🌍 Global'
  );
};

const ShowTabs = () => {
  const deal: Deal = useRecordContext();
  const { canCreate } = useRoleBasedPermissions();

  if (!deal) {
    return null;
  }

  return (
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="launchedAt" />
        <TextField source="expiredAt" />

        <hr style={{ width: '100%', height: 1 }} />

        <TextField source="type" />

        <FunctionField label="Location" render={formatDealLocation} />

        <NumberField source="coinValue" />
        {deal.type === DealType.AffiliateLink && <TextField source="affiliateUrl" />}

        <hr style={{ width: '100%', height: 1 }} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: SpacingStyle.big,
            marginBottom: 120,
          }}
        >
          <DealCard deal={deal} language="en" />
          <DealCard deal={deal} language="fr" />
        </div>
      </Tab>

      {deal.type === DealType.CouponCode && (
        <Tab label="Coupons">
          {canCreate('deals_coupons') && (
            <Button
              href={`#/deals_coupons/create?source={"dealId":"${deal?.id}"}`}
              label="Add coupons to this deal"
              startIcon={<AddIcon />}
            />
          )}
          <ArrayField source="coupons" label="Coupons">
            <Datagrid
              bulkActionButtons={false}
              rowClick={id => `/deals_coupons/${id}/show`}
            >
              <TextField source="code" />
              <DateField source="createdAt" showTime />
              <TextField source="userTransactionId" />
              <DateField source="redeemedAt" showTime />
            </Datagrid>
          </ArrayField>
        </Tab>
      )}

      <Tab label="Transactions">
        <ArrayField source="transactions" label="Transactions">
          <Datagrid bulkActionButtons={false}>
            <ReferenceField source="userId" reference="users" link="show" />
            <NumberField source="coinsAmount" />
            <DateField source="createdAt" showTime />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  );
};

export const DealShow = () => (
  <Show actions={ShowActions}>
    <ShowTabs />
  </Show>
);
