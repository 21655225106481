// difficulty_card.tsx
import React, { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import DifficultyIcon from '@mui/icons-material/FitnessCenter';
import { ActionType } from 'types/action';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import Callout from '@components/callout';
import { difficultyValues } from '@models/actions/components/difficulty';
import { BorderStyle, Colors, SpacingStyle } from '@styles/variables';

const DifficultyCard = () => {
  const action: ActionType = useRecordContext();
  const {
    canUpdate: canUpdateFromRBP,
    isSuperAdmin,
    isCampaignAdmin,
  } = useRoleBasedPermissions();
  const canEdit: boolean = useMemo(
    () => canUpdateFromRBP('actions') && (isSuperAdmin || isCampaignAdmin),
    [canUpdateFromRBP, isSuperAdmin, isCampaignAdmin]
  );

  return (
    <Card>
      <CardHeader
        avatar={<DifficultyIcon />}
        title="Difficulty"
        subheader="How challenging is this action?"
        action={
          canEdit && (
            <UpdateFieldDialog
              resource="actions"
              record={action}
              type="select"
              field="difficulty"
              buttonLabel="Edit"
              buttonVariant="outlined"
              selectOptions={difficultyValues}
            />
          )
        }
      />
      <CardContent>
        <Callout emoji="🎯" backgroundColor="grey" style={{ marginTop: 0 }}>
          New users will only see easy actions for their first few days, helping them
          build confidence and engagement.
          {!canEdit && (
            <div>
              <br />
              <i>This setting is only updatable by admins, please reach out!</i>
            </div>
          )}
        </Callout>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: SpacingStyle[4],
            backgroundColor: Colors.Magenta[50],
            border: `1px solid ${Colors.Magenta[100]}`,
            borderRadius: BorderStyle.Radius.small,
            padding: `${SpacingStyle[8]}px ${SpacingStyle[12]}px`,
          }}
        >
          <div
            style={{
              marginTop: SpacingStyle.normal,
              marginBottom: SpacingStyle.normal,
              fontWeight: 600,
              lineHeight: 1,
            }}
          >
            {difficultyValues.find(d => d.id === action?.difficulty)?.name || 'Easy'}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default DifficultyCard;
