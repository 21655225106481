import * as React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import SocialPlatformInput from './components/social_platform_input';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `actions/${data.actionId}/show`;

export const ActionCtaSocialCommentEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic">
    <SimpleForm>
      <CustomReferenceInput source="actionId" reference="actions" disabled />
      <SocialPlatformInput source="socialPlatform" />
      <TextInput source="postUrl" fullWidth required />
      <TextInput source="message1" required multiline fullWidth />
      <TextInput source="message2" multiline fullWidth />
      <TextInput source="message3" multiline fullWidth />
      <TextInput source="message4" multiline fullWidth />
      <TextInput source="message5" multiline fullWidth />
      <TextInput source="message6" multiline fullWidth />
      <TextInput source="message7" multiline fullWidth />
      <TextInput source="message8" multiline fullWidth />
      <TextInput source="message9" multiline fullWidth />
      <TextInput source="message10" multiline fullWidth />
      <TextInput source="message11" multiline fullWidth />
      <TextInput source="message12" multiline fullWidth />
      <TextInput source="message13" multiline fullWidth />
      <TextInput source="message14" multiline fullWidth />
      <TextInput source="message15" multiline fullWidth />
      <TextInput source="message16" multiline fullWidth />
      <TextInput source="message17" multiline fullWidth />
      <TextInput source="message18" multiline fullWidth />
      <TextInput source="message19" multiline fullWidth />
      <TextInput source="message20" multiline fullWidth />
    </SimpleForm>
  </Edit>
);
