import React, { useMemo } from 'react';
import {
  CreateButton,
  EditButton,
  useGetRecordRepresentation,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { Box, useMediaQuery } from '@mui/material';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

type ResourceTitleActionBarProps = {
  mode: 'list' | 'create' | 'edit' | 'show';
  actions?:
    | false
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined
    | null;
  filters?:
    | false
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined
    | null;
  title?:
    | false
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  resourceName?: string;
  hideEditButton?: boolean;
};
const ResourceTitleActionBar = ({
  mode,
  title,
  resourceName,
  actions,
  filters,
  hideEditButton = false,
}: ResourceTitleActionBarProps) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const getRecordRepresentation = useGetRecordRepresentation(resource);
  const isMediumScreen = useMediaQuery('(max-width: 900px)');
  const { isTableValid, canCreate, canUpdate } = useRoleBasedPermissions();

  /**
   * Display create button if the mode is list and the table is valid
   */
  const displayCreateButton = useMemo(() => {
    if (mode === 'list' && isTableValid(resource)) {
      return canCreate(resource);
    }
    return false;
  }, [mode, resource, isTableValid, canCreate]);

  /**
   * Display edit button if the mode is show and the table is valid
   */
  const displayEditButton = useMemo(() => {
    if (mode === 'show' && isTableValid(resource)) {
      return canUpdate(resource);
    }
    return false;
  }, [mode, resource, isTableValid, canUpdate]);

  return (
    <Box
      style={{
        ...styles.container,
        ...(isMediumScreen ? styles.containerMedium : {}),
      }}
    >
      <div style={styles.title}>
        {title ? (
          title
        ) : (
          <>
            {resourceName || resource.replace(/_/g, ' ')}
            {record && ' · '}
            {record && (getRecordRepresentation(record) || '')}
            {['create', 'edit'].includes(mode) && <div style={styles.mode}>{mode}</div>}
          </>
        )}
      </div>
      {(isTableValid(resource) || actions || filters) && (
        <div style={styles.actions}>
          {filters}
          {displayCreateButton && <CreateButton variant="outlined" />}
          {displayEditButton && !hideEditButton && <EditButton variant="outlined" />}
          {actions}
        </div>
      )}
    </Box>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: SpacingStyle[32],
    padding: `0 ${SpacingStyle[32]}px`,
  },
  containerMedium: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle[8],
    fontSize: FontStyle.sizeBig,
    fontWeight: 600,
    color: Colors.OffBlack.primary,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle.small,
  },
  mode: {
    color: Colors.Grey[400],
  },
};

export default ResourceTitleActionBar;
