import React, { useMemo } from 'react';
import { Deal } from 'types/deal.d';
import ReactMarkdown from 'react-markdown';

import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

interface DealCardProps {
  deal: Deal;
  language: 'en' | 'fr';
}

const DealCard: React.FC<DealCardProps> = ({ deal, language }) => {
  const name = useMemo(
    () => (language === 'fr' ? deal?.nameFR : deal?.nameEN),
    [deal, language]
  );
  const tagline = useMemo(
    () => (language === 'fr' ? deal?.taglineFR : deal?.taglineEN),
    [deal, language]
  );
  const description = useMemo(
    () => (language === 'fr' ? deal?.descriptionFR : deal?.descriptionEN),
    [deal, language]
  );

  if (!deal) {
    return null;
  }

  return (
    <div style={styles.container}>
      {language && <div style={styles.languageTag}>{language.toUpperCase()}</div>}
      <img src={deal?.thumbnailUrl} style={styles.thumbnail} />
      <div style={styles.avatarContainer}>
        <img src={deal?.avatarUrl} style={styles.avatar} />
      </div>

      <div style={styles.name}>{name}</div>
      <div style={styles.tagline}>{tagline}</div>
      <div style={styles.description}>
        <ReactMarkdown>{description || ''}</ReactMarkdown>
      </div>
    </div>
  );
};

const styles: any = {
  container: {
    position: 'relative',
    width: '100%',
    maxWidth: 260,
    borderRadius: BorderStyle.Radius.small,
    overflow: 'hidden',
    boxShadow: `0 1px 8px ${Colors.Black.transparent.max}`,
  },
  languageTag: {
    position: 'absolute',
    top: SpacingStyle[4],
    right: SpacingStyle[4],
    padding: SpacingStyle[4],
    color: Colors.White.primary,
    backgroundColor: Colors.Black.transparent.max,
    borderRadius: BorderStyle.Radius.small,
    fontSize: FontStyle.sizeSmall,
    fontWeight: 'bold',
    lineHeight: 1,
  },
  thumbnail: {
    width: '100%',
    height: 120,
    objectFit: 'cover',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: -50,
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: 50,
    objectFit: 'cover',
  },
  name: {
    margin: SpacingStyle[12],
    fontSize: FontStyle.sizeMedium,
    fontWeight: 'bold',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tagline: {
    margin: SpacingStyle[12],
    fontSize: FontStyle.sizeSmall,
    textAlign: 'center',
    color: Colors.Grey.primary,
    lineHeight: 1.42,
  },
  description: {
    margin: SpacingStyle[12],
    fontSize: FontStyle.sizeSmall,
  },
};

export default DealCard;
