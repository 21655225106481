import React from 'react';
import { useRecordContext } from 'react-admin';
import { SpacingStyle } from 'src/styles/variables';
import { BadgeType } from 'types/badge';

const BadgeRecordRepresentation = () => {
  const badge = useRecordContext() as BadgeType;
  return (
    <div style={styles.container}>
      <img src={badge.imageUrl} style={{ width: '2em', borderRadius: 100 }} />
      {badge.nameEN}
    </div>
  );
};

const styles: any = {
  container: {
    display: 'inline-flex',
    gap: SpacingStyle[4],
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
};

export default BadgeRecordRepresentation;
