import { VideoType } from "./video";

export enum SubtitleLocales {
  EN = "en",
  FR = "fr",
  ES = "es",
  IT = "it",
  DE = "de",
  PT = "pt",
  RU = "ru",
  JA = "ja",
}

export enum SubtitleGenerationStatus {
  STARTING = "starting",
  PENDING = "pending",
  SUCCESS = "success",
  ERROR = "error",
}

export type SubtitleType = {
  id: string;
  createdAt: string;
  updatedAt: string;

  videoId: VideoType["id"];
  video?: VideoType;

  locale: SubtitleLocales;
  text?: string;
  error?: string;
  generationStatus?: SubtitleGenerationStatus;
};
