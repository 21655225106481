import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-admin';
import { Card, CardHeader, CardContent } from '@mui/material';

import Callout from '@components/callout';

import CardStepperProgressionBar from './components/card_stepper_progression_bar';

export interface CardStepperStep {
  id?: string;
  title?: string;
  description?: string;
  avatar?: React.ReactNode;
  children: React.ReactNode;
  onPrevious?: () => Promise<void>;
  onNext?: () => Promise<boolean | void>;
  validationMessage?: string;
}

type CardStepperProps = {
  steps: CardStepperStep[];
  onCompleted?: () => void;
  fullHeight?: boolean;
  style?: React.CSSProperties;
  onNext?: (id?: string) => void;
  validationMessage?: string | null;
  buttonPosition?: 'top' | 'bottom';
};

const CardStepper = ({
  steps,
  onCompleted,
  fullHeight,
  style,
  onNext,
  buttonPosition = 'bottom',
  validationMessage: propValidationMessage,
}: CardStepperProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string | null>();

  const canGoBack = useMemo(() => activeStepIndex > 0, [activeStepIndex]);
  const canGoNext = useMemo(
    () => activeStepIndex < steps.length,
    [activeStepIndex, steps]
  );
  const isLastStep = useMemo(
    () => activeStepIndex === steps.length - 1,
    [activeStepIndex, steps]
  );

  const activeStep = useMemo(() => {
    if (!steps || activeStepIndex >= steps.length) {
      return null;
    }
    return steps[activeStepIndex];
  }, [activeStepIndex, steps]);

  const onPreviousHandler = useCallback(async () => {
    if (isTransitioning) {
      return;
    }
    if (activeStepIndex <= 0) {
      return;
    }
    setIsTransitioning(true);
    try {
      await activeStep?.onPrevious?.();
      setValidationMessage(null);
      setActiveStepIndex(activeStepIndex - 1);
    } catch (error) {
      console.error('Error in previous step handler:', error);
    } finally {
      setIsTransitioning(false);
    }
  }, [isTransitioning, activeStepIndex, activeStep]);

  const onNextHandler = useCallback(async () => {
    if (isTransitioning) {
      return;
    }
    if (activeStepIndex > steps.length - 1) {
      return;
    }
    setIsTransitioning(true);
    try {
      let result: boolean | void = true;
      if (activeStep?.onNext) {
        result = await activeStep.onNext();
      } else {
        result = await onNext?.(activeStep?.id);
      }

      if (result === false) {
        if (activeStep?.validationMessage) {
          setValidationMessage(
            activeStep?.validationMessage || 'Please complete all required fields'
          );
        }
        return;
      }

      setValidationMessage(null);
      if (activeStepIndex === steps.length - 1) {
        await onCompleted?.();
      } else {
        setActiveStepIndex(activeStepIndex + 1);
      }
    } catch (error) {
      console.error('Error in next step handler:', error);
      setValidationMessage('An unexpected error occurred');
    } finally {
      setIsTransitioning(false);
    }
  }, [isTransitioning, activeStepIndex, steps.length, activeStep, onNext, onCompleted]);

  useEffect(() => {
    setValidationMessage(propValidationMessage || null);
  }, [propValidationMessage]);

  return (
    <div style={{ ...(fullHeight ? styles.containerFullHeight : null) }}>
      <CardStepperProgressionBar
        steps={steps}
        activeStep={activeStepIndex}
        style={{ margin: '32px 32px 0' }}
      />

      {buttonPosition === 'top' && (
        <div style={styles.header}>
          {canGoBack ? (
            <Button
              onClick={onPreviousHandler}
              label="Back"
              variant="outlined"
              size="large"
              disabled={isTransitioning}
            />
          ) : (
            <div />
          )}
          {canGoNext && (
            <Button
              onClick={onNextHandler}
              label={isLastStep ? 'Finish' : 'Continue'}
              variant="contained"
              size="large"
              disabled={isTransitioning}
            />
          )}
        </div>
      )}

      {activeStep?.children && (
        <Card style={{ ...(fullHeight ? styles.cardFullHeight : null), ...style }}>
          {activeStep.title && (
            <CardHeader title={activeStep.title} subheader={activeStep.description} />
          )}
          <CardContent style={{ ...(fullHeight ? styles.cardContentFullHeight : null) }}>
            {activeStep.children}

            {/* Validation Message */}
            {validationMessage && (
              <Callout
                emoji="⚠️"
                backgroundColor="red"
                style={{ marginTop: 16, maxWidth: 'none' }}
              >
                {validationMessage}
              </Callout>
            )}
          </CardContent>
        </Card>
      )}

      {buttonPosition === 'bottom' && (
        <div style={styles.footer}>
          {canGoBack ? (
            <Button
              onClick={onPreviousHandler}
              label="back"
              variant="outlined"
              size="large"
              disabled={isTransitioning}
            />
          ) : (
            <div />
          )}
          {canGoNext && (
            <Button
              onClick={onNextHandler}
              label={isLastStep ? 'Finish' : 'Continue'}
              variant="contained"
              size="large"
              disabled={isTransitioning}
            />
          )}
        </div>
      )}
    </div>
  );
};

const styles: any = {
  containerFullHeight: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cardFullHeight: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  cardContentFullHeight: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    marginTop: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 24px',
  },
};

export default CardStepper;
