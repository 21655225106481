import React from 'react';
import {
  List,
  Datagrid,
  ReferenceField,
  DateField,
  NumberField,
  TextField,
} from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ListActions = <ResourceTitleActionBar mode="show" />;

export const UsersTransactionsList = () => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={PER_PAGE}
      pagination={<PER_PAGE_OPTIONS />}
      exporter={false}
      actions={ListActions}
      emptyWhileLoading
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <DateField source="createdAt" showTime />
        <ReferenceField source="userId" reference="users" link="show" />
        <TextField source="type" />
        <NumberField source="coinsAmount" />
      </Datagrid>
    </List>
  );
};
