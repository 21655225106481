import React from 'react';

export default ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M20.5474 0.43656C27.7337 7.31856 22.4544 17.7046 16.0476 17.0962L15.943 17.3525L21.2813 19.6039C19.0807 22.2876 15.7399 24 11.9988 24C5.37216 24 0 18.6274 0 12C0 5.37264 5.37216 0 11.9988 0C13.4124 0 14.7684 0.24528 16.0277 0.69408L13.2386 5.43864L13.5002 5.55672C16.051 2.99688 18.5249 5.24064 20.5474 0.43656Z" />
  </svg>
);
