import * as React from 'react';
import { Create } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

import BadgeForm from './components/badge_form';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `badges/${data.id}/show`;

const CreateActions = <ResourceTitleActionBar mode="create" title="Badges" />;

export const BadgeCreate = () => (
  <Create redirect={redirectAfterCreateEdit} actions={CreateActions}>
    <BadgeForm type="create" />
  </Create>
);
