import React from 'react';
import { SelectInput, required } from 'react-admin';
import { SocialPlatforms } from './social_platform_field';

export default ({ source }: any) => (
  <SelectInput
    source={source}
    choices={SocialPlatforms}
    emptyValue={'nothing'}
    validate={[required()]}
  />
);
