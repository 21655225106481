import * as React from 'react';
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from 'react-admin';

export const ActionCtaShareLinkShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="actionId" reference="actions" link="show" />
      <DateField source="createdAt" showTime />
      <TextField source="updatedAt" />
      <UrlField source="link" />
    </SimpleShowLayout>
  </Show>
);
