import React, { useContext, useEffect, useRef, useState } from 'react';
import { SubtitleBlockType, SubtitleStudioContext } from '../context';
import SubtitleScriptBlock from './subtitle_script_block';

const SubtitleScript = () => {
  const { subtitleBlocks } = useContext(SubtitleStudioContext);

  if (!subtitleBlocks) {
    return null;
  }
  return (
    <div style={StyleSheet.container} id="subtitle-script">
      {subtitleBlocks.map((subtitleBlock: SubtitleBlockType, index: number) => (
        <SubtitleScriptBlock
          key={`${index}-${subtitleBlock.startTime}-${subtitleBlock.endTime}`}
          subtitleBlock={subtitleBlock}
          subtitleBlockIndex={index}
        />
      ))}
    </div>
  );
};

const StyleSheet: any = {
  container: {
    position: 'relative',
    flex: 2,
    maxWidth: 800,
    height: '100%',
    maxHeight: '50vh',
    marginRight: 24,
    overflowY: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
  },
};

export default SubtitleScript;
