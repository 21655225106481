import React from 'react';
import { SelectInput, required } from 'react-admin';

export const pointValues = [
  { id: 0, name: 0 },
  { id: 5, name: 5 },
  { id: 10, name: 10 },
  { id: 15, name: 15 },
  { id: 20, name: 20 },
  { id: 50, name: 50 },
];

export const PointInput = ({ source }: any) => (
  <SelectInput
    source={source}
    choices={pointValues}
    emptyValue={10}
    validate={[required()]}
  />
);
