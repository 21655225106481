import { SubtitleType } from 'types/subtitle';
import { SubtitleBlockType } from '../context';

/**
 * Parse the SRT file
 * @returns subtitleBlocks array
 */
export const parseFromSRT = (subtitle: SubtitleType) => {
  if (!subtitle || !subtitle.text) {
    return [];
  }
  const subtitleBlocks: SubtitleBlockType[] = [];
  const blocks = subtitle.text.split('\n\n') || [];
  for (const block of blocks) {
    const subtitleLines = block.split('\n');
    if (subtitleLines.length < 3) {
      continue;
    }
    const [startTime, endTime] = subtitleLines[1].split(' --> ');
    const text = subtitleLines.slice(2).join(' ').trim();
    subtitleBlocks.push({
      startTime: convertTimeToSeconds(startTime),
      endTime: convertTimeToSeconds(endTime),
      text,
    });
  }
  return subtitleBlocks;
};

/**
 * From the subtitleBlocks array, generate the subtitle text in SRT format
 * @returns subtitle text in SRT format
 */
export const parseToSRT = (subtitleBlocks: SubtitleBlockType[]) => {
  if (!subtitleBlocks || subtitleBlocks.length === 0) {
    return '';
  }
  let srt = '';
  for (let i = 0; i < subtitleBlocks.length; i++) {
    const subtitleBlock = subtitleBlocks[i];
    const from = convertSecondsToTime(subtitleBlock.startTime);
    const to = convertSecondsToTime(subtitleBlock.endTime);
    srt += `${i + 1}\n`;
    srt += `${from} --> ${to}\n`;
    srt += `${subtitleBlock.text}\n\n`;
  }
  return srt;
};

/**
 * @param time HH:MM:SS,MMM
 * @returns time in seconds
 */
export const convertTimeToSeconds = (time: string) => {
  if (!time) {
    return 0;
  }
  const [hours, minutes, secondsWithMS] = time.split(':');
  const [seconds, milliseconds] = secondsWithMS?.split(',') || [0, 0];
  return (
    parseInt(hours) * 3600 +
    parseInt(minutes) * 60 +
    parseInt(seconds) +
    parseInt(milliseconds) / 1000
  );
};

/**
 * @param seconds
 * @returns time in HH:MM:SS,MMM
 */
export const convertSecondsToTime = (seconds: number, withHours = true) => {
  try {
    const substringStart = withHours ? 11 : 14;
    const newTime = new Date(seconds * 1000).toISOString().substring(substringStart, 23);
    return newTime.replace('.', ',');
  } catch (error) {
    console.error(error);
    console.error(seconds, withHours);
  }
};

export const checkErrors = (subtitleBlock: SubtitleBlockType) => {
  const newErrors = [];
  if (!subtitleBlock) {
    return [];
  }
  if (subtitleBlock.startTime < 0) {
    newErrors.push('Start time must be positive');
  }
  if (subtitleBlock.startTime >= subtitleBlock.endTime) {
    newErrors.push('START time must be before END time: delete it');
  }
  if (subtitleBlock.text.length === 0) {
    newErrors.push("Can't be empty: delete it");
  }
  if (subtitleBlock.endTime - subtitleBlock.startTime < 0.3) {
    newErrors.push('Must be at least 300ms long: extend it in the Timeline');
  }
  if (subtitleBlock.text.split(' ').length > 20) {
    newErrors.push("Shouldn't be longer than 20 words: split it");
  }
  if (subtitleBlock.text.length > 80) {
    newErrors.push("Shouldn't be longer than 80 characters: split it");
  }
  return newErrors;
};
