import { AuthProvider } from 'react-admin';

import { RoleBasedPermissionsType } from '@hooks/useRoleBasedPermissions';

import Config from './config';
import { httpClient } from './dataProvider';

let roleBasedPermissions: RoleBasedPermissionsType = {};

type LoginProps = {
  authToken?: string;
};
const authProvider: AuthProvider = {
  login: async ({ authToken }: LoginProps) => {
    if (authToken) {
      localStorage.setItem('authToken', authToken);
    }
  },

  checkError: error => {
    const status = error?.status || 500;
    if (status === 401) {
      localStorage.removeItem('authToken');
      return Promise.reject();
    }
    if (status === 403) {
      return Promise.reject({
        redirectTo: '',
        logoutUser: false,
        message: error.body?.error?.message,
      });
    }
    return Promise.resolve();
  },

  checkAuth: () =>
    localStorage.getItem('authToken') ? Promise.resolve() : Promise.reject(),

  logout: async () => {
    const token = localStorage.getItem('authToken');
    const request = new Request(`${Config.API_URL}/logout`, {
      method: 'POST',
      headers: new Headers({ Authorization: `Bearer ${token}` }),
    });
    await fetch(request);
    localStorage.removeItem('authToken');
    return Promise.resolve();
  },

  // get the user's profile
  getIdentity: async () => {
    if (!localStorage.getItem('authToken')) {
      return Promise.reject();
    }
    const {
      json: { data },
    } = await httpClient(`${Config.API_URL}/users/me`);
    return data;
  },

  // get the user permissions
  getPermissions: async (): Promise<RoleBasedPermissionsType> => {
    if (!localStorage.getItem('authToken')) {
      return Promise.reject();
    }

    if (Object.keys(roleBasedPermissions).length) {
      return Promise.resolve(roleBasedPermissions);
    }

    try {
      const {
        json: { data },
      } = await httpClient(`${Config.API_URL}/rbp`);
      roleBasedPermissions = data;
      return Promise.resolve(data);
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  },
};

export default authProvider;
