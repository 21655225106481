import { QueryClient } from 'react-query';

const isDialogOpened = () => !!document.querySelector('.MuiDialog-root');

/**
 * This is a custom QueryClient to override the defaultOptions of react-query
 * to prevent refetching when the window is focused and a MuiDialog is opened.
 */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: () => {
        return !isDialogOpened;
      },
      refetchOnReconnect: () => {
        return !isDialogOpened;
      },
    },
  },
});

export default queryClient;
