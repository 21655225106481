import * as React from 'react';
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from 'react-admin';
import SocialPlatformField from './components/social_platform_field';

export const ActionCtaSocialPostShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="actionId" reference="actions" link="show" />
      <DateField source="createdAt" showTime />
      <TextField source="updatedAt" />
      <SocialPlatformField source="socialPlatform" />

      <h3>If Instagram / Facebook</h3>
      <UrlField source="imageUrl" />
      <UrlField source="videoUrl" />
      <UrlField source="stickerUrl" />

      <h3>If Twitter</h3>
      <TextField source="message1" />
      <TextField source="message2" />
      <TextField source="message3" />
      <TextField source="message4" />
      <TextField source="message5" />
      <TextField source="message6" />
      <TextField source="message7" />
      <TextField source="message8" />
      <TextField source="message9" />
      <TextField source="message10" />
      <TextField source="message11" />
      <TextField source="message12" />
      <TextField source="message13" />
      <TextField source="message14" />
      <TextField source="message15" />
      <TextField source="message16" />
      <TextField source="message17" />
      <TextField source="message18" />
      <TextField source="message19" />
      <TextField source="message20" />
    </SimpleShowLayout>
  </Show>
);
