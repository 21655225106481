import React from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import PreviewIcon from '@mui/icons-material/RemoveRedEye';
import { CausePostType } from 'types/cause_post';

import CausePostPreview from '../preview';

const PreviewsCard = () => {
  const causePost: CausePostType = useRecordContext();
  return (
    <Card>
      <CardHeader
        title="Previews"
        subheader="Both in English and French"
        avatar={<PreviewIcon />}
      />
      <CardContent
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-evenly',
        }}
      >
        <CausePostPreview record={causePost} locale="en" editable />
        <CausePostPreview record={causePost} locale="fr" editable />
      </CardContent>
    </Card>
  );
};

export default PreviewsCard;
