import React from 'react';
import { SelectInput } from 'react-admin';

const UserTransactionTypeInput = () => (
  <SelectInput
    source="type"
    required
    choices={[
      { id: 'ACTION_DONE', name: 'Action' },
      { id: 'DONATION_MADE', name: 'Donation Made' },
      { id: 'DONATION_RECEIVED', name: 'Donation Received' },
    ]}
  />
);

export default UserTransactionTypeInput;
