import * as React from 'react';
import {
  Edit,
  FormDataConsumer,
  ImageField,
  ImageInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import { PepperPillType, PepperPillTypes } from 'types/pepper_pill.d';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import UrlInput from '@components/inputs/url_input';
import Callout from '@components/callout';

import PepperPillPreview from './components/preview';
import { PepperPillToolbar } from './create';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `pepper_pills/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" title="Pepper Pills" />;

export const PepperPillEdit = () => (
  <Edit
    redirect={redirectAfterCreateEdit}
    mutationMode="pessimistic"
    actions={EditActions}
  >
    <SimpleForm toolbar={<PepperPillToolbar />}>
      <SelectInput
        source="type"
        choices={Object.keys(PepperPillTypes).map((type: any) => ({
          id: type.toUpperCase(),
          name: type,
        }))}
        fullWidth
        validate={[required()]}
      />

      <ImageField source="imageUrl" title="Image" />
      <ImageInput source="picture" accept="image/*" maxSize={5000000}>
        <ImageField source="src" title="title" />
      </ImageInput>

      <Callout emoji="🇺🇸">
        Write Pepper Pill in <strong>English</strong>, it will be automatically translated
        to French for French users.
      </Callout>
      <TextInput label="Title 🇺🇸" source="titleEN" validate={[required()]} fullWidth />
      <TextInput
        label="Content 🇺🇸"
        source="contentEN"
        validate={[required()]}
        multiline
        minRows={8}
        inputProps={{ maxLength: 500 }}
        helperText="Max 500 characters"
        fullWidth
      />
      <UrlInput label="Source" source="sourceUrl" />

      <FormDataConsumer>
        {({ formData }) => {
          const imageUrl = formData.picture?.src || formData.imageUrl;
          return (
            <PepperPillPreview pepperPill={{ ...formData, imageUrl } as PepperPillType} />
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
