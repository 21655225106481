import React, { useContext, useEffect, useRef, useState } from 'react';
import TimerIcon from '@mui/icons-material/TimerOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SplitscreenOutlinedIcon from '@mui/icons-material/SplitscreenOutlined';
import {
  SubtitleBlockType,
  SubtitleStudioContext,
  SubtitleStudioContextType,
} from '../context';
import { checkErrors, convertSecondsToTime } from '../services/subtitles.services';

type SubtitleScriptBlockProps = {
  subtitleBlock: SubtitleBlockType;
  subtitleBlockIndex: number;
};
const SubtitleScriptBlock = ({
  subtitleBlock,
  subtitleBlockIndex,
}: SubtitleScriptBlockProps) => {
  const [isCurrentlyPlaying, setIsCurrentlyPlaying] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isSwitchButtonHovered, setIsSwitchButtonHovered] = useState(false);
  const [isDeleteButtonHovered, setIsDeleteButtonHovered] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const blockRef = useRef<HTMLDivElement>(null);
  const { videoHtmlElement, subtitleBlocks, setSubtitleBlocks } =
    useContext<SubtitleStudioContextType>(SubtitleStudioContext);

  useEffect(() => {
    if (!videoHtmlElement) {
      return;
    }
    videoHtmlElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => videoHtmlElement.removeEventListener('timeupdate', handleTimeUpdate);
  }, [videoHtmlElement]);

  useEffect(() => {
    if (!videoHtmlElement || !isSelected) {
      return;
    }
    videoHtmlElement.currentTime = subtitleBlock.startTime;
  }, [videoHtmlElement, isSelected]);

  useEffect(() => {
    if (!subtitleBlock) {
      return;
    }
    checkForErrors();
  }, [subtitleBlock, subtitleBlock.text]);

  useEffect(() => {
    if (isCurrentlyPlaying) {
      scrollToBlock();
    }
  }, [isCurrentlyPlaying]);

  /**
   * Check if the subtitle block is currently playing
   * Called on every timeupdate event
   */
  const handleTimeUpdate = () => {
    if (!videoHtmlElement) {
      return;
    }
    const startTime = Math.round(subtitleBlock.startTime * 100) / 100;
    const endTime = Math.round(subtitleBlock.endTime * 100) / 100;
    setIsCurrentlyPlaying(
      videoHtmlElement.currentTime >= startTime && videoHtmlElement.currentTime < endTime
    );
  };

  /**
   * Check for errors in the subtitle block
   */
  const checkForErrors = () => {
    const newErrors = checkErrors(subtitleBlock);
    setErrors(newErrors);
  };

  /**
   * Scroll to the subtitle block if it is hidden
   */
  const scrollToBlock = () => {
    if (!isCurrentlyPlaying || !blockRef || !blockRef.current) {
      return;
    }
    const parentElement = blockRef.current?.parentElement;
    if (!parentElement) {
      return;
    }

    const isHiddenTop = parentElement.scrollTop > blockRef.current.offsetTop;
    const isHiddenBottom =
      parentElement.scrollTop + parentElement.offsetHeight <
      blockRef.current.offsetTop + blockRef.current.offsetHeight;
    if (isHiddenTop || isHiddenBottom) {
      parentElement.scrollTo({
        top:
          blockRef.current.offsetTop -
          parentElement.offsetHeight +
          blockRef.current.offsetHeight * 2,
        behavior: 'smooth',
      });
    }
  };

  /**
   * Called when the user changes the input text of the subtitle block
   * @param event
   */
  const onInputChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (!subtitleBlocks) {
      return;
    }
    const newSubtitleBlocks = [...subtitleBlocks];
    newSubtitleBlocks[subtitleBlockIndex].text = event.target.value;
    setSubtitleBlocks(newSubtitleBlocks);
  };

  /**
   * Called when the user focuses on the input text of the subtitle block
   */
  const onFocusHandler = () => {
    setIsSelected(true);

    // pause the video
    if (videoHtmlElement) {
      videoHtmlElement.pause();
    }
  };

  /**
   * Called when the user clicks on the split button
   */
  const onSplitHandler = () => {
    if (!subtitleBlocks) {
      return;
    }
    const newSubtitleBlocks = [...subtitleBlocks];
    const middleTime = (subtitleBlock.startTime + subtitleBlock.endTime) / 2;
    const subtitleBlock1: SubtitleBlockType = {
      startTime: subtitleBlock.startTime,
      endTime: middleTime,
      text: subtitleBlock.text,
    };
    const subtitleBlock2: SubtitleBlockType = {
      startTime: middleTime,
      endTime: subtitleBlock.endTime,
      text: subtitleBlock.text,
    };
    newSubtitleBlocks.splice(subtitleBlockIndex, 1, subtitleBlock1, subtitleBlock2);
    setSubtitleBlocks(newSubtitleBlocks);
  };

  /**
   * Called when the user clicks on the delete button
   */
  const onDeleteHandler = () => {
    if (!subtitleBlocks) {
      return;
    }
    const newSubtitleBlocks = [...subtitleBlocks];
    newSubtitleBlocks.splice(subtitleBlockIndex, 1);
    setSubtitleBlocks(newSubtitleBlocks);
  };

  return (
    <div
      ref={blockRef}
      style={StyleSheet.container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          ...StyleSheet.leftBorder,
          ...(isCurrentlyPlaying ? StyleSheet.leftBorderActive : undefined),
        }}
      />
      <div style={StyleSheet.timeContainer}>
        <div style={StyleSheet.time}>
          <TimerIcon sx={StyleSheet.timerIcon} />
          {convertSecondsToTime(subtitleBlock.startTime, false)}
        </div>
        <div style={StyleSheet.time}>
          <TimerIcon sx={StyleSheet.timerIcon} />
          {convertSecondsToTime(subtitleBlock.endTime, false)}
        </div>
      </div>
      <div
        style={{
          ...StyleSheet.textInputContainer,
          ...(isSelected ? StyleSheet.textInputContainerSelected : undefined),
          ...(errors.length > 0 ? StyleSheet.textInputContainerError : undefined),
        }}
      >
        <div style={{ flex: 1 }}>
          <input
            style={StyleSheet.textInput}
            value={subtitleBlock.text}
            onChange={onInputChangeHandler}
            onFocus={onFocusHandler}
            onBlur={() => setIsSelected(false)}
          />
          {errors.length > 0 &&
            errors.map((error, i) => (
              <div style={StyleSheet.error} key={i}>
                {error}
              </div>
            ))}
        </div>
        {isHovered && (
          <div style={StyleSheet.actionButtons}>
            <div
              style={{
                ...StyleSheet.actionButton,
                ...(isSwitchButtonHovered ? StyleSheet.actionButtonHovered : null),
              }}
              onClick={onSplitHandler}
              onMouseEnter={() => setIsSwitchButtonHovered(true)}
              onMouseLeave={() => setIsSwitchButtonHovered(false)}
            >
              <SplitscreenOutlinedIcon style={StyleSheet.actionIcon} />
              Split
            </div>
            {subtitleBlocks && subtitleBlocks.length > 1 && (
              <div
                style={{
                  ...StyleSheet.actionButton,
                  ...(isDeleteButtonHovered ? StyleSheet.actionButtonHovered : null),
                }}
                onClick={onDeleteHandler}
                onMouseEnter={() => setIsDeleteButtonHovered(true)}
                onMouseLeave={() => setIsDeleteButtonHovered(false)}
              >
                <DeleteOutlineIcon style={StyleSheet.actionIcon} />
                Delete
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const StyleSheet: any = {
  container: {
    display: 'flex',
    margin: 16,
  },
  leftBorder: {
    width: 3,
    marginRight: 8,
    borderRadius: 4,
    backgroundColor: 'transparent',
  },
  leftBorderActive: {
    backgroundColor: '#595EDA',
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    marginRight: 12,
    fontSize: 9,
    color: '#767b8b',
  },
  time: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  timerIcon: {
    width: 10,
    color: '#767b8b',
  },
  textInputContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.025)',
    borderRadius: 8,
    border: '1px solid transparent',
    fontSize: 14,
    overflow: 'hidden',
  },
  textInputContainerSelected: {
    backgroundColor: '#C6C5F8',
  },
  textInputContainerError: {
    border: '1px solid #FAA',
  },
  textInput: {
    width: '100%',
    padding: 12,
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    marginRight: 16,
    cursor: 'pointer',
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: 4,
    width: 56,
    padding: 4,
    fontSize: 8,
    fontWeight: 500,
    color: '#767b8b',
    backgroundColor: 'white',
    borderRadius: 4,
  },
  actionButtonHovered: {
    backgroundColor: '#F0F0F0',
  },
  actionIcon: {
    height: 16,
    width: 16,
    color: '#969bAb',
  },
  error: {
    color: '#F00',
    fontSize: 10,
    margin: '4px 8px',
  },
};

export default SubtitleScriptBlock;
