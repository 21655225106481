import React, { useCallback } from 'react';
import {
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  useDataProvider,
  useRecordContext,
} from 'react-admin';
import { CauseType } from 'types/cause';
import { CampaignType } from 'types/campaign';
import { ActionType } from 'types/action';
import moment from 'moment-timezone';
import { CausePostType } from 'types/cause_post';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import Calendar, { GroupedCalendarItems } from '@components/calendar';
import ActionCard from '@components/calendar/components/action_card';
import CausePostCard from '@components/calendar/components/cause_post_card';
import { Colors, SpacingStyle } from '@styles/variables';

import CampaignBlock from '../campaigns/components/campaign_block';

import CauseChip from './components/chip';

const ShowActions = <ResourceTitleActionBar mode="show" />;

const ShowComponent = () => {
  const dataProvider = useDataProvider();
  const record = useRecordContext<CauseType>();

  /**
   * Fetch calendar items
   * @param daysDisplayed
   * @returns {Promise<CalendarItems>}
   */
  const fetchCalendarItems = useCallback(
    async (daysDisplayed?: string[]) => {
      if (!daysDisplayed || !record) {
        return {};
      }

      const { data: actions } = await dataProvider.getList('actions', {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'publishedAt', order: 'ASC' },
        filter: {
          publishedAt: daysDisplayed,
          causeId: record.id,
        },
      });

      const { data: causesPosts } = await dataProvider.getList('causes_posts', {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'publishedAt', order: 'ASC' },
        filter: {
          publishedAt: daysDisplayed,
          causeId: record.id,
        },
      });

      const result: GroupedCalendarItems = {
        actions: {
          name: 'Actions',
          backgroundColor: record.color,
          items: {},
        },
        causesPosts: {
          name: 'Posts',
          backgroundColor: Colors.Background.grey,
          items: {},
        },
      };

      actions.forEach((action: ActionType) => {
        const day = moment(action.publishedAt).format('YYYY-MM-DD');
        if (!day) {
          return;
        }
        if (!result.actions.items[day]) {
          result.actions.items[day] = [];
        }
        result.actions.items[day].push(<ActionCard key={action.id} action={action} />);
      });

      causesPosts.forEach((causePost: CausePostType) => {
        const day = moment(causePost.publishedAt).format('YYYY-MM-DD');
        if (!day) {
          return;
        }
        if (!result.causesPosts.items[day]) {
          result.causesPosts.items[day] = [];
        }
        result.causesPosts.items[day].push(
          <CausePostCard key={causePost.id} causePost={causePost} />
        );
      });
      return result;
    },
    [dataProvider, record]
  );

  return (
    <TabbedShowLayout>
      <Tab label="Summary">
        <FunctionField
          label="Preview (EN)"
          render={(cause: CauseType) => <CauseChip cause={cause} locale="en" />}
        />
        <FunctionField
          label="Preview (FR)"
          render={(cause: CauseType) => <CauseChip cause={cause} locale="en" />}
        />

        <div style={{ height: SpacingStyle.big * 2 }} />

        <Calendar fetchItems={fetchCalendarItems} />
      </Tab>
      <Tab label="Campaigns" path="campaigns">
        <FunctionField
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: SpacingStyle.big,
          }}
          render={(cause: CauseType) =>
            cause.campaigns
              ?.sort((a, b) => (a.name < b.name ? -1 : 1))
              ?.map((campaign: CampaignType) => (
                <CampaignBlock key={campaign.id} campaign={campaign} clickable />
              ))
          }
        />
      </Tab>
    </TabbedShowLayout>
  );
};

export const CauseShow = () => (
  <Show actions={ShowActions}>
    <ShowComponent />
  </Show>
);
