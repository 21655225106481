import * as React from 'react';
import {
  Button,
  ImageField,
  Show,
  Tab,
  TabbedShowLayout,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { VideoType } from 'types/video';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { ActionIcon, CampaignIcon } from '@components/icons';
import SubtitlesStudio from '@components/subtitles_studio';

import { SubtitleList } from './components/subtitle_list';
import { VideoField } from './components/video_field';

const VideoShowActions = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  if (!record) {
    return <></>;
  }
  return (
    <ResourceTitleActionBar
      mode="show"
      filters={
        <>
          {record.campaignStory && (
            <Button
              label="Go to Campaign"
              startIcon={<CampaignIcon size={16} />}
              onClick={() =>
                redirect(`/campaigns/${record.campaignStory.campaignId}/show/stories`)
              }
              variant="outlined"
            />
          )}
          {(record.action || record.actionDone) && (
            <Button
              label="Go to Action"
              startIcon={<ActionIcon size={15} />}
              onClick={() =>
                redirect(`/actions/${record.action?.id || record.actionDone?.id}/show`)
              }
              variant="outlined"
            />
          )}
        </>
      }
    />
  );
};

const VideoShowComponent = () => {
  const record: VideoType = useRecordContext();
  return (
    <TabbedShowLayout>
      <Tab label="summary">
        <ImageField source="posterUrl" />
        <VideoField source="videoUrl" width="200" />
        <VideoField source="shareableUrl" width="200" />
      </Tab>
      <Tab label="Subtitles" path="subtitles">
        <SubtitleList />
      </Tab>
      <Tab label="Subtitles Editor (beta)" path="subtitles-beta">
        <SubtitlesStudio videoId={record?.id} />
      </Tab>
    </TabbedShowLayout>
  );
};

export const VideoShow = () => (
  <Show actions={<VideoShowActions />}>
    <VideoShowComponent />
  </Show>
);
