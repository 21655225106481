import React from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  ImageField,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import { ColorField } from 'react-admin-color-picker';
import AddIcon from '@mui/icons-material/Add';
import { BadgeType } from 'types/badge';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import BadgePreview from './components/preview';

const ShowActions = <ResourceTitleActionBar mode="show" />;

const ShowTabs = () => {
  const badge: BadgeType = useRecordContext();
  const { canCreate } = useRoleBasedPermissions();

  return (
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="createdAt" />
        <TextField source="type" />
        <NumberField source="score" />
        <NumberField source="coinsAmount" />
        <TextField
          label="Page (once users have claimed the badge, they will be redirected to this page)"
          source="page"
        />
        <ImageField source="imageUrl" title="Image" />
        <hr />
        <TextField label="Name EN" source="nameEN" />
        <TextField
          label="Description EN (displayed before user won it)"
          source="descriptionEN"
        />
        <TextField
          label="Message EN (displayed after user won it)"
          source="congratulationMessageEN"
        />
        <hr />
        <TextField label="Name FR" source="nameFR" />
        <TextField
          label="Description FR (affiché avant que l'user le gagne)"
          source="descriptionFR"
        />
        <TextField
          label="Message FR (affiché quand l'user l'a gagné)"
          source="congratulationMessageFR"
        />
        <ColorField source="color1" />
        <ColorField source="color2" />
        <ColorField source="color3" />
        <BadgePreview badge={badge} />
        <br />
        <br />
        <br />
        <br />
      </Tab>
      <Tab label="Users">
        {canCreate('users_badges') && (
          <Button
            href={`#/users_badges/create?source={"badgeId":"${badge?.id}"}`}
            label="Add this badge to a user"
            startIcon={<AddIcon />}
          />
        )}
        <ArrayField source="users" label="Users">
          <Datagrid
            bulkActionButtons={false}
            rowClick={(_id, _resource, record) => {
              return `/users_badges/${record.userBadgeId}/show`;
            }}
          >
            <ReferenceField source="id" reference="users" link="show" label="Username" />
            <DateField source="seenAt" showTime />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  );
};

export const BadgeShow = () => (
  <Show actions={ShowActions}>
    <ShowTabs />
  </Show>
);
