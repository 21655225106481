import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import { useSearchParams } from 'react-router-dom';

import CustomReferenceInput from '@components/inputs/custom_reference_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `campaigns/${data.campaignId}/show/causes`;

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const CampaignCauseCreate = () => {
  const [searchParams] = useSearchParams();

  const campaignId = searchParams.get('campaignId');

  return (
    <Create redirect={redirectAfterCreate} actions={CreateActions}>
      <SimpleForm>
        <CustomReferenceInput
          source="campaignId"
          reference="campaigns"
          queryKey="name"
          validate
          defaultValue={campaignId as string}
          disabled={!!campaignId}
        />

        <CustomReferenceInput
          source="causeId"
          reference="causes"
          queryKey="name"
          validate
        />
      </SimpleForm>
    </Create>
  );
};
