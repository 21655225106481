import * as React from 'react';
import { SimpleForm, Create, TextInput, required } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `users/${data.userId}/show`;

const CreateActions = <ResourceTitleActionBar mode="create" title="Users Prompts" />;

export const UserProfilePromptCreate = () => (
  <Create redirect={redirectAfterCreate} actions={CreateActions}>
    <SimpleForm>
      <CustomReferenceInput
        source="userId"
        reference="users"
        queryKey="username"
        validate={[required()]}
      />
      <CustomReferenceInput
        source="promptId"
        reference="profile_prompts"
        queryKey="questionEN"
        validate={[required()]}
      />
      <TextInput source="answer" required fullWidth />
    </SimpleForm>
  </Create>
);
