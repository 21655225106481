import * as React from 'react';
import { Datagrid, DateField, FunctionField, List, TextField } from 'react-admin';
import { VideoType } from 'types/video';
import { SubtitleType } from 'types/subtitle';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import { VideoField } from './components/video_field';

export const LOCALE_EMOJIS: any = {
  en: '🇺🇸',
  es: '🇪🇸',
  fr: '🇫🇷',
  it: '🇮🇹',
  pt: '🇵🇹',
};

const ListActions = <ResourceTitleActionBar mode="list" />;

export const VideoList = () => (
  <List
    sort={{ field: 'createdAt', order: 'DESC' }}
    exporter={false}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <DateField source="createdAt" />
      <TextField source="name" />
      <VideoField source="videoUrl" width="70" />
      <VideoField source="shareableUrl" width="70" />
      <FunctionField
        label="Subtitles available"
        render={(record: VideoType) => (
          <>
            {record.subtitles
              ?.sort((s1: SubtitleType, s2: SubtitleType) =>
                s1.locale < s2.locale ? -1 : 1
              )
              .map((subtitle: SubtitleType, i: number) => (
                <p style={{ fontSize: '20px' }} key={`${subtitle.id}-${i}`}>
                  {Object.keys(LOCALE_EMOJIS).includes(subtitle.locale)
                    ? LOCALE_EMOJIS[subtitle.locale]
                    : subtitle.locale}
                </p>
              ))}
          </>
        )}
      />
    </Datagrid>
  </List>
);
