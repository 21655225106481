import React from 'react';

import LoadingAnimation from '@components/svgs/loading_animation';
import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

const Loading = () => (
  <div style={styles.container}>
    <LoadingAnimation stroke={Colors.Magenta.primary} />
    <div style={styles.text}>fetching data...</div>
  </div>
);

const styles: any = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.Background.grey,
    color: Colors.OffBlack.primary,
  },
  text: {
    marginTop: SpacingStyle.normal,
    fontSize: FontStyle.sizeMedium,
    fontWeight: 600,
  },
};

export default Loading;
