import * as React from 'react';
import { SimpleForm, Create } from 'react-admin';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import Callout from '@components/callout';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `actions/${data.actionId}/show`;

const CreateActions = <ResourceTitleActionBar mode="create" title="Actions Owners" />;

export const ActionOwnerCreate = () => {
  const { canCreate } = useRoleBasedPermissions();
  return (
    <Create redirect={redirectAfterCreate} actions={CreateActions}>
      <SimpleForm>
        <CustomReferenceInput source="actionId" reference="actions" queryKey="name" />
        <CustomReferenceInput source="ownerId" reference="users" queryKey="username" />
        {canCreate('users') && (
          <Callout emoji="❔" backgroundColor="grey">
            Can't find the user you're looking for? Click here to{' '}
            <a href="#/users/create">create one</a>.
          </Callout>
        )}
      </SimpleForm>
    </Create>
  );
};
