import React from 'react';
import { Button, useRecordContext } from 'react-admin';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { SubtitleType } from 'types/subtitle';
import { VideoType } from 'types/video';
import { BorderStyle, Colors } from 'src/styles/variables';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';

import Config from '../../../config';
import { LOCALE_EMOJIS } from '../list';

export const VideoField = ({ ...props }) => {
  const record = useRecordContext(props);
  const { canCreate } = useRoleBasedPermissions();

  const video = record[props.source];
  const subtitles = record?.subtitles || video?.subtitles;
  const width = props.width || '140';
  const controls = typeof props.controls === 'undefined' ? true : props.controls;
  const buttonStyle = {
    backgroundColor: '#dedede',
    maxWidth: '200px',
    ...props.buttonStyle,
  };
  const videoStyle = {
    borderRadius: 4,
    ...props.videoStyle,
  };

  if (!video || video.name === '202307-no-action' || video.name === '202307-thank-you') {
    if (props.showButtons && canCreate('videos')) {
      let url = `#/videos/create?source={"${props.reference}":"${record.id}","actionVideoType": "${props.type}"}`;
      return (
        <Button
          href={url}
          label="Add video"
          startIcon={<VideoCallIcon />}
          style={buttonStyle}
        />
      );
    }
    return <small style={{ color: '#ccc' }}>no_video</small>;
  }
  return (
    <div>
      <video
        src={video.videoUrl || video}
        controls={controls}
        width={width}
        style={videoStyle}
        crossOrigin="anonymous"
      >
        {subtitles?.map((subtitle: SubtitleType, i: number) => (
          <track
            key={i}
            src={`${Config.API_URL}/subtitles/${subtitle.id}.vtt`}
            srcLang={subtitle.locale}
            title={subtitle.locale}
            kind="subtitles"
            default={record.locale ? subtitle.locale === record.locale : i === 0}
          />
        ))}
      </video>
      {props.showSubtitles ? (
        <>
          <br />
          <strong>
            <small>Subtitles: </small>
          </strong>
          {subtitles?.map((subtitle: SubtitleType, i: number) => (
            <span key={i}>
              {Object.keys(LOCALE_EMOJIS).includes(subtitle.locale)
                ? LOCALE_EMOJIS[subtitle.locale]
                : subtitle.locale}
            </span>
          ))}
        </>
      ) : null}
      {props.showButtons ? (
        <>
          <br />
          <Button
            href={`#/videos/${video.id}/show`}
            label="Show Video"
            startIcon={<VideoCameraFrontIcon />}
            style={buttonStyle}
          />
        </>
      ) : null}
    </div>
  );
};

type VideoPosterProps = {
  video?: VideoType;
  height?: number | string;
  width?: number | string;
  showControls?: boolean;
  style?: any;
};
export const VideoPoster = ({
  video,
  height = 140,
  width = 80,
  showControls = true,
  style,
}: VideoPosterProps) => {
  if (!video) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width,
          height,
          borderRadius: BorderStyle.Radius.normal,
          backgroundColor: Colors.Grey.primary,
          ...style,
        }}
      ></div>
    );
  }
  return (
    <a
      href={`#/videos/${video.id}/show`}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width,
        height,
        borderRadius: BorderStyle.Radius.normal,
        backgroundColor: Colors.OffBlack.primary,
        backgroundImage: `url(${video.posterUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        ...style,
      }}
    >
      {showControls && (
        <PlayCircleIcon
          style={{
            color: Colors.OffWhite.primary,
            height: 30,
            width: 30,
          }}
          stroke={Colors.OffBlack.primary}
          strokeWidth={0.2}
        />
      )}
    </a>
  );
};
