/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AppBar,
  Layout,
  Menu,
  useGetIdentity,
  useLogout,
  useSidebarState,
} from 'react-admin';
import { Link, To, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import BullWorkerIcon from '@mui/icons-material/DynamicForm';
import LockIcon from '@mui/icons-material/Lock';
import QRCode from '@mui/icons-material/QrCode2';
import Calendar from '@mui/icons-material/InsertInvitation';
import { TbMessage, TbPolaroidFilled, TbTagStarred } from 'react-icons/tb';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import Symbol from '@components/svgs/logos/symbol';
import {
  ActionIcon,
  BadgeIcon,
  CampaignIcon,
  CauseIcon,
  CausePostIcon,
  CRMOrganizationIcon,
  CRMPersonIcon,
  CRMTagIcon,
  DonationIcon,
  FeatureFlagIcon,
  InAppNotificationsIcon,
  UserIcon,
} from '@components/icons';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import { httpClient } from './dataProvider';
import Config from './config';

const MenuItemIcon = ({ icon, compact }: any) => {
  const size = useMemo(() => {
    if (compact) {
      return FontStyle.sizeBig;
    }
    return FontStyle.sizeMedium;
  }, [compact]);
  return React.cloneElement(icon, {
    size,
    style: {
      width: size,
      height: size,
    },
  });
};

type CustomMenuSectionProps = {
  title: string;
  compact: boolean;
  children?: React.ReactNode;
  shouldRender?: boolean;
};
const CustomMenuSection = ({
  title,
  compact,
  children,
  shouldRender = true,
}: CustomMenuSectionProps) => {
  if (!shouldRender) {
    return null;
  }

  return (
    <>
      <div
        style={{
          marginTop: SpacingStyle.normal,
          marginBottom: SpacingStyle[4],
          marginLeft: SpacingStyle[20],
          fontSize: FontStyle.sizeVerySmall,
          fontWeight: 600,
          color: Colors.Grey[400],
          textTransform: 'uppercase',
          cursor: 'default',
          height: compact ? '10px' : 'auto',
        }}
      >
        {!compact && title}
      </div>
      {children}
    </>
  );
};

type CustomMenuItemProps = {
  to?: To;
  model?: string;
  onClick?: () => void;
  icon: React.ReactNode;
  label: string;
  compact: boolean;
  hasPermission?: boolean;
};
const CustomMenuItem = ({
  to,
  model,
  icon,
  label,
  compact,
  hasPermission,
  onClick,
  ...props
}: CustomMenuItemProps) => {
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);
  const location = useLocation();
  const { isTableValid, canRead } = useRoleBasedPermissions();
  const [hasPermissionToRead, setHasPermissionToRead] = useState(false);

  useEffect(() => {
    const mainPath = location.pathname.split('/')[1];
    setActive(`/${mainPath}` === to || mainPath === model);
  }, [location.pathname, to, model]);

  useEffect(() => {
    if (model) {
      setHasPermissionToRead(isTableValid(model) && canRead(model));
    }
    if (hasPermission) {
      setHasPermissionToRead(hasPermission);
    }
  }, [isTableValid, canRead, model, hasPermission]);

  const width = useMemo(() => {
    if (compact) {
      return '43px';
    }
    return 'auto';
  }, [compact]);

  const backgroundColor = useMemo(() => {
    if (active) {
      return Colors.Magenta[100];
    }
    if (hovered) {
      return Colors.Magenta[50];
    }
    return 'transparent';
  }, [active, hovered]);

  const marginVertical = useMemo(() => {
    if (compact) {
      return SpacingStyle[4];
    }
    return SpacingStyle[16];
  }, [compact]);

  const color = useMemo(() => {
    if (active) {
      return Colors.Magenta.primary;
    }
    return Colors.Grey[800];
  }, [active]);

  const Component = to || model ? Link : ('div' as any);

  if (!hasPermissionToRead) {
    return <></>;
  }

  return (
    <Component
      to={to || `/${model}`}
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        width,
        margin: `${SpacingStyle[2]}px ${marginVertical}px`,
        padding: `${SpacingStyle[4]}px ${SpacingStyle[8]}px`,
        color,
        fontSize: FontStyle.sizeSmall,
        fontWeight: '500',
        borderRadius: BorderStyle.Radius.normal,
        gap: SpacingStyle[8],
        backgroundColor,
        cursor: 'pointer',
        textDecoration: 'none',
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...props}
    >
      {icon && <MenuItemIcon icon={icon} compact={compact} />}
      {!compact && label}
    </Component>
  );
};

const AdminFooter = ({ compact }: { compact: boolean }) => {
  const { data, isLoading, error } = useGetIdentity();
  const logout = useLogout();

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  if (isLoading) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <div
      style={{
        padding: `${SpacingStyle[12]}px ${SpacingStyle[16]}px`,
        fontSize: FontStyle.sizeVerySmall,
        fontWeight: '500',
        color: Colors.Grey[400],
        textTransform: 'uppercase',
      }}
    >
      {!compact && <div>{data?.username}</div>}
      <div
        onClick={handleLogout}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: SpacingStyle[8],
          cursor: 'pointer',
          marginTop: SpacingStyle[4],
        }}
      >
        <LockIcon
          style={{
            fontSize: compact ? FontStyle.sizeMedium : FontStyle.sizeVerySmall,
          }}
        />
        {!compact && 'logout'}
      </div>
    </div>
  );
};

const CustomMenu = ({ ...props }) => {
  const [_open, setOpen] = useSidebarState();
  const isMediumScreen = useMediaQuery('(max-width: 1080px)');
  const { isSuperAdmin, isCampaignAdmin, isCampaignManager, isProduct, isReadOnly } =
    useRoleBasedPermissions();

  useEffect(() => {
    setOpen(!isMediumScreen);
  }, [isMediumScreen, setOpen]);

  /**
   * Redirect to BullBoard
   */
  const redirectToBullBoard = async () => {
    const {
      data: { redirectTo },
    } = await httpClient(`${Config.API_URL}/bullboard/jwt`).then(({ json }) => ({
      data: json.data,
    }));
    if (redirectTo) {
      window.open(redirectTo, '_blank');
    }
  };

  return (
    <Menu {...props} style={{ justifyContent: 'space-between' }}>
      <div style={{ flex: 1 }}>
        <a
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: SpacingStyle.small,
            marginTop: SpacingStyle.normal,
            marginLeft: isMediumScreen ? SpacingStyle[12] : SpacingStyle.big,
            marginBottom: isMediumScreen ? SpacingStyle[12] : 'auto',
            color: Colors.Magenta.primary,
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
          href="#"
        >
          <Symbol height={30} width={30} fill={Colors.Magenta.primary} />
          {window.location.hostname === 'localhost' && 'localhost'}
        </a>

        {/* Content */}
        <CustomMenuSection title="Content" compact={isMediumScreen}>
          <CustomMenuItem
            model="causes"
            label="Causes"
            icon={<CauseIcon />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isCampaignAdmin}
          />
          <CustomMenuItem
            model="campaigns"
            label="Campaigns"
            icon={<CampaignIcon />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="actions"
            label="Actions"
            icon={<ActionIcon />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="causes_posts"
            label="Posts"
            icon={<CausePostIcon />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isCampaignAdmin || isProduct}
          />
        </CustomMenuSection>

        {/* Contact Directory */}
        <CustomMenuSection title="Contact Directory" compact={isMediumScreen}>
          <CustomMenuItem
            model="crm_organizations"
            label="Organizations"
            icon={<CRMOrganizationIcon />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="crm_persons"
            label="Persons"
            icon={<CRMPersonIcon />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="crm_tags"
            label="Tags"
            icon={<CRMTagIcon />}
            compact={isMediumScreen}
          />
        </CustomMenuSection>

        {/* Shop */}
        <CustomMenuSection
          title="Shop"
          compact={isMediumScreen}
          shouldRender={isSuperAdmin || isCampaignAdmin || isProduct}
        >
          <CustomMenuItem
            to="/shop"
            label="Calendar"
            icon={<Calendar />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isCampaignAdmin || isProduct}
          />
          <CustomMenuItem
            model="donations"
            label="Donations"
            icon={<DonationIcon />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            to="/deals"
            label="Deals"
            icon={<BadgeIcon />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isCampaignAdmin || isProduct}
          />
        </CustomMenuSection>

        {/* Users */}
        <CustomMenuSection
          title="Users"
          compact={isMediumScreen}
          shouldRender={isSuperAdmin || isCampaignAdmin || isProduct}
        >
          <CustomMenuItem
            model="users"
            label="Users"
            icon={<UserIcon />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="profile_prompts"
            label="Prompts"
            icon={<TbMessage />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="profile_skills"
            label="Skills"
            icon={<TbPolaroidFilled />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="profile_tags"
            label="Tags"
            icon={<TbTagStarred />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            to="/in_app_notifications"
            label="In-app notifications"
            icon={<InAppNotificationsIcon />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isCampaignAdmin || isProduct}
          />
        </CustomMenuSection>

        {/* Product */}
        <CustomMenuSection title="Product" compact={isMediumScreen}>
          <CustomMenuItem
            to="/qr_code_generator"
            label="QR Code Generator"
            icon={<QRCode />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isCampaignAdmin || isProduct}
          />
          <CustomMenuItem
            model="badges"
            label="Badges"
            icon={<BadgeIcon />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isProduct}
          />
          <CustomMenuItem
            model="feature_flags"
            label="Feature Flags"
            icon={<FeatureFlagIcon />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isProduct}
          />
          <CustomMenuItem
            onClick={redirectToBullBoard}
            label="Workers"
            icon={<BullWorkerIcon />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isProduct}
          />
        </CustomMenuSection>
      </div>
      <AdminFooter compact={isMediumScreen} />
    </Menu>
  );
};

const CustomAppBar = () => <AppBar style={{ display: 'none' }} />;

export const CustomLayout = ({ ...props }) => (
  <Layout {...props} menu={CustomMenu} appBar={CustomAppBar} />
);
