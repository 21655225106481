import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment-timezone';

import ellipsis from '@services/ellipsis';
import Favicon from '@components/svgs/logos/favicon';
import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

const MAX_ELLIPSIS_LENGTH = 200;

export type ChatMessageProps = {
  author: string;
  role: 'user' | 'agent';
  avatarUrl?: string;
  text: string;
  postedAt: string;
};
const ChatMessage = ({ author, avatarUrl, text, postedAt }: ChatMessageProps) => {
  const [ellipsisLength, setEllipsisLength] = useState(MAX_ELLIPSIS_LENGTH);

  const isEllipsized = useMemo(
    () => text && text.length > ellipsisLength,
    [text, ellipsisLength]
  );

  const shouldMessageBeEllipsized = useMemo(
    () => text && text.length > MAX_ELLIPSIS_LENGTH,
    [text]
  );

  const toggleEllipsis = useCallback(() => {
    setEllipsisLength(isEllipsized ? text.length : MAX_ELLIPSIS_LENGTH);
  }, [isEllipsized, text, ellipsisLength]);

  return (
    <div style={styles.container}>
      <div style={styles.left}>
        {avatarUrl ? (
          <img src={avatarUrl} alt={author} style={styles.avatar} />
        ) : (
          <div style={styles.avatar}>
            <Favicon width={12} height={12} fill={Colors.Magenta.primary} />
          </div>
        )}
      </div>

      <div style={styles.messageContainer}>
        <div style={styles.header}>
          <div style={styles.author}>{author}</div>
          <div style={styles.postedAt}>{moment(postedAt).fromNow()}</div>
        </div>
        <div style={styles.text} onClick={toggleEllipsis}>
          {ellipsis(text, ellipsisLength)}
          {shouldMessageBeEllipsized && (
            <span style={styles.ellipsis}>
              {isEllipsized ? 'read more' : 'read less'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    gap: SpacingStyle.small,
    marginBottom: SpacingStyle.big,
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: Colors.Magenta[100],
  },
  messageContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 24,
  },
  author: {
    fontSize: FontStyle.sizeMedium,
    fontWeight: '600',
  },
  postedAt: {
    fontSize: FontStyle.sizeVeryVerySmall,
    color: Colors.Grey[500],
  },
  text: {
    fontSize: FontStyle.sizeSmall,
    lineHeight: 1.3,
    whiteSpace: 'pre-line',
  },
  ellipsis: {
    marginLeft: SpacingStyle[4],
    color: Colors.Grey[800],
    fontWeight: '600',
    cursor: 'pointer',
  },
};

export default ChatMessage;
