import * as React from 'react';
import {
  Datagrid,
  DateField,
  ImageField,
  List,
  NumberField,
  TextField,
} from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';

const ListActions = <ResourceTitleActionBar mode="list" title="Badges" />;

export const BadgeList = () => (
  <List
    exporter={false}
    sort={{ field: 'createdAt', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ImageField source="imageUrl" title="Image" />
      <TextField label="Name" source="nameEN" />
      <TextField source="type" />
      <NumberField source="coinsAmount" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);
