import React, { useState, useCallback, useMemo } from 'react';
import {
  useRecordContext,
  useDataProvider,
  useRefresh,
  Button,
  SimpleForm,
} from 'react-admin';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import { ActionType } from 'types/action.d';
import { getCountryData, getEmojiFlag, TCountryCode } from 'countries-list';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import LoadingAnimation from '@components/svgs/loading_animation';
import CountryInput from '@components/inputs/country_input';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

const AvailableCountriesCard = () => {
  const action: ActionType = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { canUpdate: canUpdateFromRPB } = useRoleBasedPermissions();
  const canEdit: boolean = useMemo(() => canUpdateFromRPB('actions'), [canUpdateFromRPB]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState<string[] | undefined | null>(
    action.availableCountries
  );
  const [updating, setUpdating] = useState(false);

  const isActionAvaibleGlobally = useMemo(
    () => action.availableCountries === null || action.availableCountries?.length === 0,
    [action.availableCountries]
  );

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => {
    setDialogOpen(false);
    setSelectedCountries(action.availableCountries);
  };

  /**
   * Update the available countries for the action
   */
  const handleUpdate = useCallback(async () => {
    setUpdating(true);
    try {
      await dataProvider.update('actions', {
        id: action.id,
        data: { availableCountries: selectedCountries },
      } as any);
      refresh();
      setDialogOpen(false);
    } catch (error) {
      console.error('[AvailableCountriesCard.handleUpdate]', error);
    } finally {
      setUpdating(false);
    }
  }, [action.id, dataProvider, refresh, selectedCountries]);

  /**
   * Make the action globally available
   */
  const handleMakeGlobal = useCallback(async () => {
    setUpdating(true);
    try {
      await dataProvider.update('actions', {
        id: action.id,
        data: { availableCountries: null },
      } as any);
      refresh();
    } catch (error) {
      console.error('[AvailableCountriesCard.handleMakeGlobal]', error);
    } finally {
      setUpdating(false);
    }
  }, [action.id, dataProvider, refresh]);

  return (
    <Card>
      <CardHeader
        avatar={<PublicIcon />}
        title="Available countries"
        subheader="Manage which countries this action is available in"
      />
      <CardContent>
        <div
          style={{
            border: `1px solid ${Colors.Magenta[100]}`,
            padding: SpacingStyle[12],
            borderRadius: BorderStyle.Radius.small,
            backgroundColor: Colors.Magenta[50],
            fontSize: FontStyle.sizeVerySmall,
            fontWeight: 500,
          }}
        >
          {isActionAvaibleGlobally
            ? 'This action is available to all users globally.'
            : 'This action is only available for users based in specific countries.'}
        </div>

        {!isActionAvaibleGlobally && (
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            sx={{ mt: 2, flexWrap: 'wrap' }}
            useFlexGap
          >
            {action.availableCountries?.map(country => (
              <Chip
                key={country}
                label={`${getEmojiFlag(country as TCountryCode)} ${
                  getCountryData(country as TCountryCode).name
                }`}
              />
            ))}
          </Stack>
        )}

        {canEdit && (
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button
              onClick={handleOpen}
              label={
                action && action.availableCountries
                  ? 'Update country list'
                  : 'Set countries'
              }
              variant="outlined"
              disabled={updating}
            />
            {!isActionAvaibleGlobally && (
              <Button
                onClick={handleMakeGlobal}
                label="Make global"
                variant="outlined"
                disabled={updating}
                endIcon={updating ? <LoadingAnimation /> : null}
              />
            )}
          </Stack>
        )}

        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogTitle>Update Available Countries</DialogTitle>
          <DialogContent>
            <SimpleForm
              defaultValues={{ availableCountries: action.availableCountries }}
              toolbar={false}
            >
              <CountryInput
                source="availableCountries"
                label="Available Countries"
                multiple={true}
                fullWidth
                helperText="Select countries where this action is available. Leave empty for global availability."
                emptyText="Globally available"
                disabled={updating}
                onChange={(event: any) => setSelectedCountries(event)}
              />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <Button label="Cancel" onClick={handleClose} disabled={updating} />
            <Button label="Update" onClick={handleUpdate} disabled={updating} />
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default AvailableCountriesCard;
