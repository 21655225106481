import React, { useCallback, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { ActionType } from 'types/action';
import moment from 'moment-timezone';
import { CauseType } from 'types/cause';

import Calendar, { GroupedCalendarItems } from '@components/calendar';
import ActionCard from '@components/calendar/components/action_card';

const ActionsCalendar = () => {
  const dataProvider = useDataProvider();
  const [causes, setCauses] = useState<CauseType[]>([]);

  const fetchCauses = useCallback(async () => {
    const { data } = await dataProvider.getList('causes', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    });
    setCauses(data);
  }, [dataProvider]);

  /**
   * Fetch calendar items
   * @param daysDisplayed
   * @returns {Promise<CalendarItems>}
   */
  const fetchCalendarItems = useCallback(
    async (daysDisplayed?: string[]) => {
      if (!daysDisplayed || causes.length === 0) {
        return {};
      }

      const { data } = await dataProvider.getList('actions', {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'publishedAt', order: 'ASC' },
        filter: { publishedAt: daysDisplayed },
      });

      const result: GroupedCalendarItems = causes.reduce((acc, cause) => {
        acc[cause.slug] = {
          name: `${cause.emoji} ${cause.descriptionEN}`,
          backgroundColor: cause.color,
          items: {},
        };
        return acc;
      }, {} as any);

      data.forEach((action: ActionType) => {
        const day = moment(action.publishedAt).format('YYYY-MM-DD');
        if (!day) {
          return;
        }
        const cause = causes.find(cause => cause.id === action.causeId) || action.cause;
        if (!cause) {
          return;
        }
        if (!result[cause.slug]) {
          result[cause.slug] = {
            name: `${cause.emoji} ${cause.descriptionEN || cause.name}`,
            backgroundColor: cause.color,
            items: {},
          };
        }
        if (!result[cause.slug].items[day]) {
          result[cause.slug].items[day] = [];
        }
        result[cause.slug].items[day].push(
          <ActionCard key={action.id} action={action} />
        );
      });
      return result;
    },
    [dataProvider, causes]
  );

  useEffect(() => {
    fetchCauses();
  }, [fetchCauses]);

  return <Calendar fetchItems={fetchCalendarItems} forcedUnitOfTime="week" />;
};

export default ActionsCalendar;
