import * as React from 'react';
import { Create, DateTimeInput, SimpleForm, TextInput } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `feature_flags/${data.id}/show`;

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const FeatureFlagCreate = () => (
  <Create actions={CreateActions} redirect={redirectAfterCreate}>
    <SimpleForm>
      <TextInput source="name" fullWidth required />
      <TextInput source="description" fullWidth required />
      <DateTimeInput source="enabledAt" fullWidth parse={v => (v === '' ? null : v)} />
    </SimpleForm>
  </Create>
);
