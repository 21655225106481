import * as React from 'react';
import { Datagrid, Filter, List, SearchInput, TextField } from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ListActions = (
  <ResourceTitleActionBar
    mode="list"
    filters={
      <Filter>
        <SearchInput source="questionEN" alwaysOn />
      </Filter>
    }
  />
);

export const ProfilePromptList = () => (
  <List
    exporter={false}
    sort={{ field: 'questionEN', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="questionEN" label="Question EN" />
      <TextField source="placeholderEN" label="Placeholder EN" />
    </Datagrid>
  </List>
);
