import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import LoadingAnimation from '@components/svgs/loading_animation';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

export type CalendarItems = {
  [day: string]: JSX.Element[];
};
export type GroupedCalendarItems = {
  [groupingKey: string]: {
    name?: string;
    backgroundColor?: string;
    items: CalendarItems;
  };
};
type CalendarProps = {
  fetchItems: (daysDisplayed?: string[]) => Promise<CalendarItems | GroupedCalendarItems>;
  forcedUnitOfTime?: 'week' | 'month';
};
const Calendar = ({ forcedUnitOfTime, fetchItems }: CalendarProps) => {
  const [groups, setGroups] = useState<GroupedCalendarItems>({
    '': {
      items: {},
    },
  });
  const [daysDisplayed, setDaysDisplayed] = useState<moment.Moment[]>([
    moment('2000-01-01'),
  ]);
  const [selectedTime, setSelectedTime] = useState(moment());
  const [unitOfTime, setUnitOfTime] = useState(
    forcedUnitOfTime === 'month' ? 'month' : 'week'
  );
  const [isFetching, setIsFetching] = useState(false);

  /**
   * Fetch items and set
   * @param daysDisplayed
   */
  const fetchItemsAndSet = useCallback(
    async (daysDisplayed: string[]) => {
      try {
        setIsFetching(true);
        const results = await fetchItems(daysDisplayed);
        if (!results || Object.keys(results).length === 0) {
          setGroups({ '': { items: {} } });
          return;
        }
        // If first key of items is an array, then force the items into a single group
        if (Array.isArray(Object.values(results)[0])) {
          setGroups({
            '': {
              items: results as CalendarItems,
            },
          });
        } else {
          setGroups(results as GroupedCalendarItems);
        }
      } finally {
        setIsFetching(false);
      }
    },
    [fetchItems]
  );

  useEffect(() => {
    const days = [];
    const startOf = selectedTime
      .clone()
      .startOf(unitOfTime as moment.unitOfTime.StartOf)
      .startOf('week');
    const endOf = selectedTime
      .clone()
      .endOf(unitOfTime as moment.unitOfTime.StartOf)
      .endOf('week');
    const day = startOf.clone().subtract(1, 'day');
    while (day.isBefore(endOf, 'day')) {
      days.push(day.add(1, 'day').clone());
    }
    setDaysDisplayed(days);
    fetchItemsAndSet(days.map(day => day.format('YYYY-MM-DD')));
  }, [selectedTime, unitOfTime, fetchItemsAndSet]);

  const goToPreviousWindow = useCallback(() => {
    setSelectedTime(
      selectedTime.clone().subtract(1, unitOfTime as moment.DurationInputArg2)
    );
  }, [selectedTime, unitOfTime]);

  const goToNextWindow = useCallback(() => {
    setSelectedTime(selectedTime.clone().add(1, unitOfTime as moment.DurationInputArg2));
  }, [selectedTime, unitOfTime]);

  /**
   * Render the page's header
   * @returns {JSX.Element}
   */
  const header = useMemo(
    () => (
      <div style={headerStyles.header}>
        <div>
          <span style={{ fontSize: FontStyle.sizeNormal, fontWeight: 700 }}>
            {selectedTime.format('MMMM')}{' '}
          </span>
          <span>{selectedTime.format('YYYY')}</span>
        </div>
        <div style={headerStyles.headerRight}>
          {isFetching && <LoadingAnimation width={18} height={18} />}
          {!forcedUnitOfTime && (
            <div style={headerStyles.switch}>
              <div
                style={{
                  ...headerStyles.switchItem,
                  ...(unitOfTime === 'week' && headerStyles.switchItemActive),
                }}
                onClick={() => setUnitOfTime('week')}
              >
                Week
              </div>
              <div
                style={{
                  ...headerStyles.switchItem,
                  ...(unitOfTime === 'month' && headerStyles.switchItemActive),
                }}
                onClick={() => setUnitOfTime('month')}
              >
                Month
              </div>
            </div>
          )}
          <div
            style={{
              ...headerStyles.headerToday,
              ...(selectedTime.isSame(
                moment(),
                unitOfTime as moment.unitOfTime.StartOf
              ) && headerStyles.headerTodayCurrentWindow),
            }}
            onClick={() => setSelectedTime(moment())}
          >
            Today
          </div>
          <ChevronLeftIcon onClick={goToPreviousWindow} style={{ cursor: 'pointer' }} />
          <ChevronRightIcon onClick={goToNextWindow} style={{ cursor: 'pointer' }} />
        </div>
      </div>
    ),
    [
      selectedTime,
      forcedUnitOfTime,
      unitOfTime,
      goToPreviousWindow,
      goToNextWindow,
      isFetching,
    ]
  );

  /**
   * Render the day header
   * @param day
   * @param index
   * @returns {JSX.Element}
   */
  const renderDayHeader = (day: moment.Moment) => (
    <div
      style={{
        ...styles.dayNumber,
        ...(day.isSame(moment(), 'day') && styles.dayNumberToday),
        ...(!day.isSame(selectedTime, 'month') &&
          unitOfTime === 'month' &&
          styles.dayNumberNotCurrentMonth),
      }}
    >
      {day.format('D') === '1' ? day.format('MMM D') : day.format('D')}
    </div>
  );

  return (
    <div>
      {header}
      <div style={styles.week}>
        {[0, 1, 2, 3, 4, 5, 6].map(index => (
          <div key={index} style={styles.weekDay}>
            {moment().startOf('week').add(index, 'day').format('ddd')}
          </div>
        ))}
      </div>
      <div style={styles.calendar}>
        {Object.keys(groups).map((group, index) => (
          <div
            key={index}
            style={{
              ...styles.group,
              backgroundColor: groups[group].backgroundColor,
            }}
          >
            {group && (
              <div style={{ fontSize: FontStyle.sizeNormal, fontWeight: 700 }}>
                {groups[group].name || group}
              </div>
            )}

            {daysDisplayed
              .reduce((weeks, day, index) => {
                if (index % 7 === 0) {
                  weeks.push([]);
                }
                weeks[weeks.length - 1].push(day);
                return weeks;
              }, [] as moment.Moment[][])
              .map((week, index) => (
                <div key={index} style={styles.week}>
                  {week.map(day => {
                    const itemsOfThDay =
                      groups[group].items[day.format('YYYY-MM-DD')] || [];
                    return (
                      <div
                        key={day.format('YYYY-MM-DD')}
                        style={{
                          ...styles.day,
                          ...(day.isSame(moment(), 'day') && styles.dayToday),
                          borderColor: groups[group].backgroundColor || 'transparent',
                        }}
                      >
                        {renderDayHeader(day)}
                        <div style={styles.dayContainer}>{itemsOfThDay}</div>
                      </div>
                    );
                  })}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles: any = {
  calendar: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.normal,
  },
  week: {
    display: 'flex',
  },
  weekDay: {
    width: 'calc(100% / 7)',
    marginBottom: SpacingStyle.small,
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: 400,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    padding: SpacingStyle[4],
    gap: SpacingStyle[4],
    backgroundColor: Colors.White.primary,
    borderRadius: BorderStyle.Radius.tiny,
    border: `1px solid ${Colors.Black.transparent.max}`,
  },
  day: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(100% / 7)',
    minHeight: 140,
    backgroundColor: Colors.White.primary,
    borderRadius: BorderStyle.Radius.tiny,
    border: `0.5px solid ${Colors.Grey[50]}`,
  },
  dayToday: {
    backgroundColor: '#fffdfc',
  },
  dayNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: SpacingStyle[2],
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 600,
    textAlign: 'center',
    color: Colors.Black.primary,
    minWidth: 24,
    height: 24,
  },
  dayNumberToday: {
    backgroundColor: Colors.Magenta.primary,
    borderRadius: BorderStyle.Radius.big,
    fontWeight: 800,
    color: Colors.White.primary,
  },
  dayNumberNotCurrentMonth: {
    color: Colors.Grey[400],
  },
  dayContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: SpacingStyle[2],
    marginBottom: SpacingStyle.normal,
    width: '98%',
    gap: SpacingStyle.normal,
  },
};

const headerStyles: any = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: SpacingStyle.small,
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle.small,
    fontSize: FontStyle.sizeNormal,
  },
  switch: {
    display: 'flex',
    border: `1px solid ${Colors.OffBlack.primary}`,
    borderRadius: BorderStyle.Radius.big,
    overflow: 'hidden',
  },
  switchItem: {
    padding: `${SpacingStyle[2]}px ${SpacingStyle[8]}px`,
    cursor: 'pointer',
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 500,
    borderRadius: BorderStyle.Radius.big,
  },
  switchItemActive: {
    backgroundColor: Colors.OffBlack.primary,
    color: Colors.OffWhite.primary,
  },
  headerToday: {
    border: `1px solid ${Colors.Grey[400]}`,
    padding: `${SpacingStyle[2]}px ${SpacingStyle[8]}px`,
    borderRadius: BorderStyle.Radius.big,
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 600,
    cursor: 'pointer',
  },
  headerTodayCurrentWindow: {
    color: Colors.Grey[400],
    cursor: 'default',
  },
};

export default Calendar;
