import React, { useContext } from 'react';

import { SubtitleBlockType, SubtitleStudioContext } from '../context';
import SubtitleTimelineBlock from './subtitle_timeline_block';

const SubtitleTimeline = () => {
  const { subtitleBlocks } = useContext(SubtitleStudioContext);

  if (!subtitleBlocks) {
    return <div></div>;
  }

  return (
    <div style={StyleSheet.container}>
      {subtitleBlocks.map((subtitleBlock: SubtitleBlockType, index: number) => (
        <SubtitleTimelineBlock
          key={`${index}-${subtitleBlock.startTime}-${subtitleBlock.endTime}`}
          subtitleBlockIndex={index}
        />
      ))}
    </div>
  );
};

const StyleSheet: any = {
  container: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    margin: '4px 0',
    height: 60,
    userSelect: 'none',
    background: '#C6C5F8',
    borderRadius: 4,
    overflow: 'hidden',
  },
};

export default SubtitleTimeline;
