import React from 'react';

import { Colors, FontStyle } from '@styles/variables';

const Separator = () => (
  <div style={styles.separator}>
    <div style={styles.line} />
    <div style={styles.text}>or</div>
    <div style={styles.line} />
  </div>
);

const styles: any = {
  separator: {
    display: 'flex',
    alignItems: 'center',
    margin: `${FontStyle.sizeNormal}px 0`,
  },
  line: {
    flex: 1,
    height: 1,
    background: Colors.Magenta[900],
  },
  text: {
    fontSize: FontStyle.sizeSmall,
    color: Colors.Magenta[1100],
    margin: `0 ${FontStyle.sizeNormal}px`,
  },
};

export default Separator;
