import React, { useState } from 'react';
import { Datagrid, DateField, Form, List, SearchInput, TextField } from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { SpacingStyle } from '@styles/variables';

import { SelectTopicType } from './create';
import TopicRecordRepresentation from './components/record_representation';

const ListActions = <ResourceTitleActionBar mode="list" />;

export const TopicList = () => {
  const [listFilters, setListFilters] = useState({} as any);

  return (
    <List
      exporter={false}
      sort={{ field: 'createdAt', order: 'ASC' }}
      perPage={PER_PAGE}
      pagination={<PER_PAGE_OPTIONS />}
      actions={ListActions}
      emptyWhileLoading
      empty={false}
      filter={listFilters}
    >
      {/* Filters */}
      <Form>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: SpacingStyle.normal,
            padding: SpacingStyle.normal,
          }}
        >
          <SelectTopicType
            onChange={(e: any) =>
              setListFilters({ ...listFilters, type: e?.target?.value })
            }
          />
          <SearchInput
            source="nameEN"
            alwaysOn
            onChange={(e: any) => {
              setListFilters({ ...listFilters, nameEN: e?.target?.value });
            }}
          />
        </div>
      </Form>

      {/* Datagrid */}
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="type" label="Type" />
        <TopicRecordRepresentation />
        <DateField source="createdAt" label="Created At" showTime />
      </Datagrid>
    </List>
  );
};
