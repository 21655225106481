import React from 'react';
import { SelectField } from 'react-admin';

export const SocialPlatforms = [
  { id: 'instagram', name: 'Instagram' },
  { id: 'twitter', name: 'Twitter' },
  { id: 'linkedin', name: 'LinkedIn' },
  { id: 'facebook', name: 'Facebook' },
  { id: 'tiktok', name: 'TikTok' },
];

export default ({ source }: any) => (
  <SelectField source={source} choices={SocialPlatforms} />
);
