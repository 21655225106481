import React from 'react';
import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  Labeled,
} from 'react-admin';

import { PhoneNumberField } from '@components/phone_number_field';

import { CountryField } from './components/country';

export const ActionCtaPhoneCallShow = () => (
  <Show>
    <SimpleShowLayout>
      <ReferenceField source="actionId" reference="actions" link="show" />
      <DateField source="createdAt" showTime />
      <TextField source="updatedAt" />
      <CountryField />
      <TextField source="script" component="pre" style={{ whiteSpace: 'pre-wrap' }} />
      <PhoneNumberField source="phoneNumber" />
      <hr />
      <strong></strong>
      <FunctionField
        render={(resource: any) => {
          return (
            <>
              <Labeled label="Country">
                <CountryField />
              </Labeled>
              <a
                href={`#/action_cta_phone_calls/${resource.id}/edit`}
                style={{ marginLeft: '1em' }}
              >
                edit
              </a>
            </>
          );
        }}
      />
      <FunctionField
        render={(resource: any) => {
          return (
            <>
              <Labeled label="Script">
                <TextField
                  source="script"
                  component="pre"
                  style={{ whiteSpace: 'pre-wrap' }}
                />
              </Labeled>
              <a
                href={`#/action_cta_phone_calls/${resource.id}/edit`}
                style={{ marginLeft: '1em' }}
              >
                edit
              </a>
            </>
          );
        }}
      />
    </SimpleShowLayout>
  </Show>
);
