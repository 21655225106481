import React, { useState } from 'react';
import { List, Datagrid, ReferenceField, Form, DateField } from 'react-admin';
import { UserType } from 'types/user';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import { SpacingStyle } from '@styles/variables';

const UsersTopicsListActions = () => <ResourceTitleActionBar mode="list" />;

export const UsersTopicsList = () => {
  const [listFilters, setListFilters] = useState({} as any);

  return (
    <List
      sort={{ field: 'createdAt', order: 'ASC' }}
      perPage={PER_PAGE}
      pagination={<PER_PAGE_OPTIONS />}
      exporter={false}
      actions={<UsersTopicsListActions />}
      emptyWhileLoading
      empty={false}
      filter={listFilters}
    >
      {/* Filters */}
      <Form>
        <div style={{ maxWidth: 300, paddingLeft: SpacingStyle.normal }}>
          <CustomReferenceInput
            source="userId"
            reference="users"
            label="User"
            queryKey="username"
            sort={{ field: 'username', order: 'ASC' }}
            onChange={(userId: UserType['id']) =>
              setListFilters({
                ...listFilters,
                userId: userId ? userId : undefined,
              })
            }
          />
        </div>
      </Form>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <DateField source="createdAt" showTime />
        <ReferenceField source="userId" reference="users" link="show" />
        <ReferenceField source="topicId" reference="topics" link="show" />
      </Datagrid>
    </List>
  );
};
