import React from 'react';

import { Colors } from '@styles/variables';

const DevBanner = () => {
  if (!window.location.hostname.includes('localhost')) {
    return;
  }

  return (
    <div
      style={{
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: Colors.Magenta.primary,
        background: `repeating-linear-gradient(-45deg,${Colors.Magenta.primary},${Colors.Magenta.primary} 10px,${Colors.Magenta[1000]} 10px,${Colors.Magenta[1000]} 20px)`,
        height: 18,
        zIndex: 9999,
      }}
    />
  );
};

export default DevBanner;
